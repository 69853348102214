import { WorksheetContentType } from "../enums/WorksheetContentType";
import TextareaAutosize from "react-autosize-textarea";
import { z } from "zod";
import { IJobInstanceWorksheetContentItem } from "../models/IJobInstanceWorksheet";
import Files from "../../../containers/app/components/files/Index";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { isPhoto } from "../../../services/fileService";
import { logError } from "../../../services/errorLogger";
import { formDataFileSchema } from "../../../containers/app/components/files/ExistingFile";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../containers/app/components/DayPicker";
import format from "date-fns/format";
import dateService from "../../../services/dateService";
import uuidv4 from "uuid/v4";

type OnChange = (response: string) => void;

type ContentItemProps = {
  contentItem: IJobInstanceWorksheetContentItem;
  onChange: OnChange;
  value: string;
  enforceRequiredFields: boolean;
};

export function JobInstanceWorksheetFormContentItem({
  contentItem,
  onChange,
  value,
  enforceRequiredFields,
}: {
  contentItem: IJobInstanceWorksheetContentItem;
  onChange: OnChange;
  value: string;
  enforceRequiredFields: boolean;
}) {
  const props: ContentItemProps = {
    onChange,
    contentItem,
    value,
    enforceRequiredFields,
  };

  return <div className="form-group">{getContentItem(contentItem, props)}</div>;
}

function getContentItem(
  contentItem: IJobInstanceWorksheetContentItem,
  props: ContentItemProps
) {
  switch (contentItem.type) {
    case WorksheetContentType.QuestionBoolean:
      return <WorksheetContentItemQuestionBoolean {...props} />;
    case WorksheetContentType.QuestionShortAnswer:
      return <WorksheetContentItemQuestionShortAnswer {...props} />;
    case WorksheetContentType.QuestionLongAnswer:
      return <WorksheetContentItemQuestionParagraph {...props} />;
    case WorksheetContentType.QuestionNumber:
      return <WorksheetContentItemQuestionNumber {...props} />;
    case WorksheetContentType.QuestionCheckbox:
      return <WorksheetContentItemQuestionCheckbox {...props} />;
    case WorksheetContentType.QuestionOptionList:
      return <WorksheetContentItemQuestionOptionList {...props} />;
    case WorksheetContentType.QuestionDate:
      return <WorksheetContentItemQuestionDate {...props} />;
    case WorksheetContentType.QuestionFileUpload:
      return <WorksheetContentItemQuestionFileUpload {...props} />;
    default:
      return <></>;
  }
}

function WorksheetContentItemQuestionBoolean(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <Label id={id} contentItem={props.contentItem} />
      <DropDownField
        id={id}
        contentItem={props.contentItem}
        onChange={props.onChange}
        value={props.value}
        options={[
          {
            key: "true",
            value: "true",
            label: "Yes",
          },
          {
            key: "false",
            value: "false",
            label: "No",
          },
        ]}
        enforceRequiredFields={props.enforceRequiredFields}
      />
    </>
  );
}

function WorksheetContentItemQuestionOptionList(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <Label id={id} contentItem={props.contentItem} />
      <DropDownField
        id={id}
        contentItem={props.contentItem}
        onChange={props.onChange}
        value={props.value}
        options={props.contentItem.optionListOptions.map((o) => ({
          key: o.id,
          label: o.label,
          value: o.label,
        }))}
        enforceRequiredFields={props.enforceRequiredFields}
      />
    </>
  );
}

function WorksheetContentItemQuestionCheckbox(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          required={props.enforceRequiredFields && props.contentItem.required}
          checked={props.value === "true"}
          onChange={(e) => {
            const value = e.currentTarget.checked ? "true" : "false";
            props.onChange(value);
          }}
        />
        <label className="custom-control-label" htmlFor={id}>
          {props.contentItem.text}
        </label>
      </div>
    </>
  );
}

function WorksheetContentItemQuestionShortAnswer(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <Label id={id} contentItem={props.contentItem} />
      <input
        type="text"
        className="form-control"
        id={id}
        required={props.enforceRequiredFields && props.contentItem.required}
        value={props.value}
        onChange={(e) => {
          const newValue = e.currentTarget.value;

          props.onChange(newValue);
        }}
        maxLength={100}
      />
    </>
  );
}

function WorksheetContentItemQuestionNumber(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <Label id={id} contentItem={props.contentItem} />
      <input
        type="number"
        className="form-control"
        id={id}
        required={props.enforceRequiredFields && props.contentItem.required}
        value={props.value}
        onChange={(e) => {
          const newValue = e.currentTarget.value;

          props.onChange(newValue);
        }}
      />
    </>
  );
}

function WorksheetContentItemQuestionDate(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <Label id={id} contentItem={props.contentItem} />
      <DayPicker
        preventMobileView={true}
        onDayPickerHide={() => null}
        dayPickerProps={{}}
        value={!!props.value ? format(props.value, dayPickerFormat) : ""}
        required={props.enforceRequiredFields && props.contentItem.required}
        inputId={id}
        onDaySelected={(day: Date) => {
          if (!!day) {
            props.onChange(dateService.formatAsIso(day));
          }
        }}
      />
    </>
  );
}

function WorksheetContentItemQuestionFileUpload(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;

  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);

  const arraySchema = z.array(formDataFileSchema);

  const valueToParse =
    props.value === "" || props.value === null ? "[]" : props.value;
  const { data, success, error } = arraySchema.safeParse(
    JSON.parse(valueToParse)
  );
  if (!success) {
    logError(`unable to parse files in WorksheetContentItem. Error: ${error}`);
  }

  const parsedValue = data ?? [];

  return (
    <>
      <Label id={id} contentItem={props.contentItem} />

      <Files
        files={parsedValue}
        imagePrefix={imagePrefix ?? ""}
        header={""}
        tenantId={tenantId ?? ""}
        onFileAdded={(newFile) => {
          props.onChange(
            JSON.stringify([
              ...parsedValue,
              {
                ...newFile,
                thumbnails: newFile.thumbnails.map((t) => {
                  return {
                    ...t,
                    // Clear out properties that aren't needed to send to server
                    sourceImage: undefined,
                    blob: undefined,
                    buffer: undefined,
                    profiler: undefined,
                  };
                }),
                id: uuidv4(),
              },
            ])
          );
        }}
        onFileRemoved={(fileId, imagePath) => {
          props.onChange(
            JSON.stringify(
              parsedValue.filter((f) => !isPhoto(fileId, imagePath, f))
            )
          );
        }}
        onFileUpdated={(fileId, imagePath, caption) => {
          props.onChange(
            JSON.stringify(
              parsedValue.map((f) => {
                if (isPhoto(fileId, imagePath, f)) {
                  return {
                    ...f,
                    caption,
                  };
                } else {
                  return f;
                }
              })
            )
          );
        }}
        onFileUploadingStatusChange={() => {}}
        requireAtLeastOneFile={
          props.enforceRequiredFields && props.contentItem.required
        }
        turnOffIndention
      />
    </>
  );
}

function WorksheetContentItemQuestionParagraph(props: ContentItemProps) {
  const id = `contentItem_${props.contentItem.id}`;
  return (
    <>
      <Label id={id} contentItem={props.contentItem} />
      <TextareaAutosize
        className="form-control"
        id={id}
        required={props.enforceRequiredFields && props.contentItem.required}
        value={props.value}
        onChange={(e) => {
          const newValue = e.currentTarget.value;

          props.onChange(newValue);
        }}
      />
    </>
  );
}

function Label({
  id,
  contentItem,
}: {
  id: string;
  contentItem: IJobInstanceWorksheetContentItem;
}) {
  return (
    <label htmlFor={id} className={`${contentItem.required ? "required" : ""}`}>
      {contentItem.text}
    </label>
  );
}

function DropDownField({
  id,
  value,
  contentItem,
  onChange,
  options,
  enforceRequiredFields,
}: {
  id: string;
  value: string;
  contentItem: IJobInstanceWorksheetContentItem;
  onChange: (newValue: string) => void;
  options: Array<{ key: string; value: string; label: string }>;
  enforceRequiredFields: boolean;
}) {
  return (
    <select
      className="form-control"
      id={id}
      required={enforceRequiredFields && contentItem.required}
      value={value}
      onChange={(e) => {
        const newValue = e.currentTarget.value;

        onChange(newValue);
      }}
    >
      <option value=""></option>
      {options.map((o) => (
        <option key={o.key} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
}
