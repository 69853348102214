export function moveArrayElement<T>(
  lineItems: T[],
  elementToMove: number,
  offset: number
) {
  const newItems = [...lineItems];
  const element = newItems[elementToMove];
  newItems.splice(elementToMove, 1);
  newItems.splice(elementToMove + offset, 0, element);
  return newItems;
}
