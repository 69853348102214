import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { IGetStartedContentState } from "../models/IGetStartedContentState";

const getStartedContentStateDataProvider = {
  updatetGetStartedContentState: (
    id: string,
    body: Partial<Omit<IGetStartedContentState, "id">>
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(`getStartedContentState/${id}`), body, headers)
    );
  },

  getGetStartedContentState: () => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`getStartedContentState`), headers)
        .pipe(
          map(
            (result) =>
              result.response
                .getStartedContentState as Array<IGetStartedContentState>
          )
        )
    );
  },
};

export default getStartedContentStateDataProvider;
