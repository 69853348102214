export function PageFooter() {
  return (
    <div
      className="bg-light p-3"
      style={{ position: "absolute", bottom: 0, width: "100%" }}
    >
      <small>
        <div
          className="d-flex justify-content-center"
          style={{ columnGap: "10px" }}
        >
          <a
            href="https://www.youraspire.com/legal/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of service
          </a>

          <a
            href="https://www.youraspire.com/privacy-statement"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>

          <a
            href="https://aspire-extensions.document360.io/crew-control/docs/fees"
            target="_blank"
            rel="noopener noreferrer"
          >
            Processing fees
          </a>
        </div>
      </small>
    </div>
  );
}
