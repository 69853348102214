import { useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { Modal, ModalBody, ModalFooter } from "reactstrap/lib";
import { ModalHeader } from "reactstrap";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { IWorksheetView } from "../models/IWorksheet";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IJobWorksheetSelectionValueItem } from "./JobWorksheetsSelection.types";
import { useForm } from "react-hook-form";
import uuidv4 from "uuid/v4";
import { isStringSet } from "../../../services/stringService";
import Prompt from "../../../containers/app/components/Prompt";
import { Flags, isFeatureFlagSet } from "../../../services/featureFlagService";

export function JobWorksheetsSelection({
  value,
  onChange,
  locked,
}: {
  value: Array<IJobWorksheetSelectionValueItem>;
  onChange(newValue: Array<IJobWorksheetSelectionValueItem>): void;
  locked: boolean;
}) {
  const [showModal, setShowModal] = useState(false);

  const sourceWorksheets = useApplicationStateSelector(
    (s) => s.worksheet.worksheets
  );

  const worksheets = sourceWorksheets.filter(
    (w) => !w.inactive || value.some((v) => v.worksheetId === w.id)
  );

  if (worksheets.length === 0 || !isFeatureFlagSet(Flags.worksheets)) {
    return null;
  }

  const sortedValue = getSortedItemsV2(value, [
    (v) => getWorksheetTitle({ worksheets, worksheetOnJob: v }),
  ]);

  return (
    <>
      <h5>Worksheets for crew</h5>
      <div className="form-section mb-3">
        <div className="mb-2">
          {locked ? (
            <div className="text-warning mb-2">
              This job has started so the worksheets cannot be changed.
            </div>
          ) : null}

          <ul className="pl-4">
            {sortedValue.map((worksheetOnJob) => (
              <React.Fragment key={worksheetOnJob.id}>
                <li>
                  <WorksheetRow
                    locked={locked}
                    worksheets={worksheets}
                    worksheetOnJob={worksheetOnJob}
                    onChange={(newValues) => {
                      onChange(
                        value.map((v) => {
                          if (v.id === worksheetOnJob.id) {
                            return {
                              ...v,
                              ...newValues,
                            };
                          } else {
                            return v;
                          }
                        })
                      );
                    }}
                    onDelete={() => {
                      onChange(value.filter((v) => v.id !== worksheetOnJob.id));
                    }}
                  />
                </li>
              </React.Fragment>
            ))}
          </ul>

          {value.length === 0 ? (
            <small className="text-muted">
              No worksheets are set for this job.
            </small>
          ) : null}
        </div>

        {!locked ? (
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-testid="addWorksheet"
            onClick={() => setShowModal(true)}
          >
            Add worksheet
          </button>
        ) : null}

        {showModal ? (
          <JobWorksheetForm
            worksheets={worksheets}
            saveButtonText="Add"
            onSave={(data) => {
              onChange([
                ...value,
                {
                  ...data,
                  serverId: null,
                  id: uuidv4(),
                },
              ]);
              setShowModal(false);
            }}
            onCancel={() => {
              setShowModal(false);
            }}
            defaultValues={{
              title: "",
              worksheetId: "",
            }}
            jobWorksheetId=""
          />
        ) : null}
      </div>
    </>
  );
}

function WorksheetRow({
  worksheets,
  worksheetOnJob,
  onChange,
  onDelete,
  locked,
}: {
  worksheets: Array<IWorksheetView>;
  worksheetOnJob: IJobWorksheetSelectionValueItem;
  onChange: (newValue: IJobWorksheetSelectionValueItem) => void;
  onDelete: () => void;
  locked: boolean;
}) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const worksheetTitle = getWorksheetTitle({
    worksheets,
    worksheetOnJob,
  });

  return (
    <>
      <span data-testid="worksheetTitle">{worksheetTitle}</span>
      {!locked ? (
        <>
          <button
            type="button"
            className="btn btn-link text-dark"
            style={{ verticalAlign: "baseline" }}
            data-testid="editWorksheet"
            onClick={() => {
              setShowEditForm(true);
            }}
            title="Edit worksheet"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>

          <button
            type="button"
            className="btn btn-link text-dark px-0"
            style={{ verticalAlign: "baseline" }}
            data-testid="deleteWorksheet"
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} title="Delete worksheet" />
          </button>
        </>
      ) : null}

      {showEditForm ? (
        <JobWorksheetForm
          defaultValues={{
            worksheetId: worksheetOnJob.worksheetId,
            title: worksheetOnJob.title ?? "",
          }}
          onSave={(newValues) => {
            onChange({
              ...worksheetOnJob,
              ...newValues,
            });
            setShowEditForm(false);
          }}
          saveButtonText="Update"
          worksheets={worksheets}
          onCancel={() => {
            setShowEditForm(false);
          }}
          jobWorksheetId={worksheetOnJob.id}
        />
      ) : null}

      {showDeleteConfirmation ? (
        <Prompt
          promptMessage={
            <>
              Are you sure you want to delete <strong>{worksheetTitle}</strong>?
            </>
          }
          showPrompt
          onConfirm={() => {
            setShowDeleteConfirmation(false);
            onDelete();
          }}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
        />
      ) : null}
    </>
  );
}

function getWorksheetTitle({
  worksheets,
  worksheetOnJob,
}: {
  worksheets: IWorksheetView[];
  worksheetOnJob: IJobWorksheetSelectionValueItem;
}) {
  if (isStringSet(worksheetOnJob.title)) {
    return worksheetOnJob.title;
  } else {
    const worksheet = worksheets.find(
      (w) => w.id === worksheetOnJob.worksheetId
    );
    return worksheet?.title ?? "";
  }
}

type WorksheetFormData = { worksheetId: string; title: string };
function JobWorksheetForm({
  jobWorksheetId,
  worksheets,
  defaultValues,
  onSave,
  onCancel,
  saveButtonText,
}: {
  jobWorksheetId: string;
  worksheets: Array<IWorksheetView>;
  defaultValues: WorksheetFormData;
  onSave: (data: WorksheetFormData) => void;
  onCancel: () => void;
  saveButtonText: string;
}) {
  const { register, watch, getValues } = useForm<WorksheetFormData>({
    defaultValues,
  });

  const worksheetId = watch("worksheetId");
  const defaultTitle =
    worksheets.find((w) => w.id === worksheetId)?.title ?? "";

  const formId = `jobWorksheetForm_${jobWorksheetId}`;

  return (
    <Modal isOpen scrollable>
      <ModalHeader>Add worksheet</ModalHeader>

      <ModalBody>
        <form
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onSave(getValues());
          }}
        >
          <div className="form-group">
            <label htmlFor="worksheetId" className="required">
              Worksheet
            </label>
            <select
              id="worksheetId"
              required
              className="form-control"
              {...register("worksheetId")}
            >
              <option value=""></option>
              {worksheets.map((worksheet) => (
                <option key={worksheet.id} value={worksheet.id}>
                  {worksheet.title}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="form-control"
              maxLength={150}
              placeholder={defaultTitle}
              {...register("title")}
            />
          </div>
        </form>
      </ModalBody>

      <ModalFooter>
        <button
          type="submit"
          form={formId}
          className="btn btn-primary"
          data-testid="saveJobWorksheetForm"
        >
          {saveButtonText}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
}
