import configuration from "./configuration";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import uuidv4 from "uuid/v4";
import { fullStoryLogInfo } from "./fullStoryService";

let applicationInsights: ApplicationInsights | null = null;

let sessionId = uuidv4();
fullStoryLogInfo(`app insights sessionId: ${sessionId}`);

export function initializeAppInsights() {
  if (configuration.appInsightsConnectionString) {
    applicationInsights = new ApplicationInsights({
      config: {
        connectionString: configuration.appInsightsConnectionString,
        disableAjaxTracking: true,
        // enableAjaxPerfTracking: true,
        // enableCorsCorrelation: true,
        // enableRequestHeaderTracking: true,
        // enableResponseHeaderTracking: true,
        // correlationHeaderDomains: [
        //   "localhost:44350",
        //   "greenerpastures-staging-api.azurewebsites.net",
        //   "api.crewcontrol.us",
        // ],
      },
    });
    applicationInsights.loadAppInsights();

    applicationInsights.addTelemetryInitializer((envelope) => {
      if (envelope.data) {
        envelope.data.sessionId = sessionId;

        // If we don't have a line number, this alert isn't actionable.
        // Some browsers generate extremely large numbers of these errors
        // but we're unable to determine if it's even our scripts or marketing
        // or a browser extension script.
        if (
          envelope.data.message === "ErrorEvent: Script error." &&
          !envelope.data.lineNumber
        ) {
          return false;
        }
      }
    });
  }
}

export function identifyApplicationInsightsUser(
  tenantName: string,
  tenantId: string
) {
  if (applicationInsights) {
    applicationInsights.setAuthenticatedUserContext(tenantName);

    applicationInsights.addTelemetryInitializer((envelope) => {
      if (envelope.data) {
        envelope.data.tenantName = tenantName;
        envelope.data.tenantId = tenantId;
      }
    });
  }
}

export function trackPageView(path: string) {
  // if (applicationInsights) {
  //   applicationInsights.trackPageView({
  //     name: path,
  //   });
  // }
}

export function trackTrace(errorMessage: string) {
  if (applicationInsights) {
    applicationInsights.trackTrace({
      message: errorMessage,
      severityLevel: SeverityLevel.Error,
    });
  }
}

export function trackException(error: Error) {
  if (applicationInsights) {
    applicationInsights.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
}

export function trackInformation(message: string) {
  if (applicationInsights) {
    applicationInsights.trackTrace({
      message: message,
      severityLevel: SeverityLevel.Information,
    });
  }
}
