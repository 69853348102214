import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IJobInstanceWorksheet } from "../../../slices/worksheets/models/IJobInstanceWorksheet";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { JobInstanceWorksheetForm } from "../../../slices/worksheets/components/JobInstanceWorksheetForm";

export function CompleteFormWorksheets({
  jobInstanceId,
  worksheets,
  onCompletedChange,
}: {
  jobInstanceId: string;
  worksheets: Array<IJobInstanceWorksheet>;
  onCompletedChange: (args: {
    worksheetForJobInstanceId: string;
    newCompletedValue: boolean;
  }) => void;
}) {
  if (worksheets.length === 0) {
    return null;
  }

  return (
    <div className="mb-3">
      Worksheets
      {worksheets.map((worksheet) => (
        <div key={worksheet.id}>
          <WorksheetRow
            jobInstanceId={jobInstanceId}
            worksheet={worksheet}
            onCompletedChange={(newCompletedValue) =>
              onCompletedChange({
                worksheetForJobInstanceId: worksheet.id,
                newCompletedValue,
              })
            }
          />
        </div>
      ))}
    </div>
  );
}

function WorksheetRow({
  jobInstanceId,
  worksheet,
  onCompletedChange,
}: {
  jobInstanceId: string;
  worksheet: IJobInstanceWorksheet;
  onCompletedChange: (newCompletedValue: boolean) => void;
}) {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <>
      {worksheet.completed ? (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-success"
          fixedWidth
          data-testid="worksheetCompletedIcon"
        />
      ) : (
        <FontAwesomeIcon
          icon={faFile}
          fixedWidth
          data-testid="worksheetNotCompletedIcon"
        />
      )}

      <span>{worksheet.title}</span>

      <button
        type="button"
        className="btn btn-link text-dark py-0"
        style={{ verticalAlign: "baseline" }}
        data-testid="editWorksheet"
        onClick={() => {
          setShowEdit(true);
        }}
        title="Edit worksheet"
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>

      {showEdit ? (
        <JobInstanceWorksheetForm
          jobInstanceId={jobInstanceId}
          worksheetForJobInstanceId={worksheet.id}
          onClose={() => setShowEdit(false)}
          onCompleteChanged={onCompletedChange}
        />
      ) : null}
    </>
  );
}
