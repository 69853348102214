import { actionTypes } from "./actionTypes";
import uuidv4 from "uuid/v4";
import { actionCreators as formActionCreators } from "../formGenerator";
import { IDistance } from "../models/IDistance";
import { IDayToLoad } from "../services/dayScheduleLoader";
import { IInitialLoad } from "../models/IInitialLoad";
import { JobType } from "../models/IJob";
import { IJobInstance } from "../models/IJobInstance";
import { IOneTimeJob } from "../models/IOneTimeJob";
import { IMaintenanceJob } from "../models/IMaintenanceJob";
import { ICustomer } from "../models/ICustomer";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import {
  ICustomerSearchResult,
  IGroupSearchResult,
} from "../models/ICustomerSearchResult";
import { IBillingReportCustomer } from "../models/IBillingReport";
import { IDaySchedule } from "../models/IDaySchedule";
import {
  IUnscheduledMaintenanceJobsWeekResult,
  IShiftResult,
  IClearJobsResponse,
} from "../services/remoteDataProvider";
import { IPermanentDropJobs } from "../models/IPermanentDropJobs";
import { IRemoteScheduleChanged } from "../models/IRemoteScheduleChanged";
import { IRemoteJobChanged } from "../models/IRemoteJobChanged";
import { ICrewCategory } from "../models/ICrewCategory";
import { IManageCustomerSearchResult } from "../models/IManageCustomersSearch";
import { IUnscheduledJobCategoryFilter } from "./scheduleUi";
import { IRemoteCustomerNotificationAdded } from "../models/IRemoteCustomerNotificationAdded";
import { ICrew } from "../models/ICrew";
import { ICommonState } from "./common";
import { ICustomerCommunicationTemplate } from "../models/ICustomerCommunicationTemplate";
import { KeyOfType } from "../keyofType";
import { IInvoiceSaveJobInstanceUpdate } from "../models/IInvoiceSaveJobInstanceUpdate";
import { BillingReportJobStatusFilter } from "../enums/billingReportJobStatusFilter";
import { UnscheduledJobsDisplayMode } from "../enums/unscheduledJobsDisplayMode";
import {
  IUnscheduledJobsCategoryFilterSet,
  IUnscheduledJobsSearchTextFilterSet,
  IJobInstanceDragStart,
  IJobInstanceDragEnd,
  IJobInstanceToggleSelected,
  IJobInstanceToggleSkippedStart,
  IJobInstanceToggleSkippedComplete,
  IJobInstanceToggleSkippedError,
  IJobInstanceToggleCompletedStart,
  IJobInstanceToggleCompletedComplete,
  IJobInstanceToggleCompletedError,
  IManageUiShowMessage,
  IManageUiHideMessage,
  IManageUiHideError,
  IManageUiShowError,
  ICrewDeleteStart,
  ICrewDeleteComplete,
  ICrewDeleteError,
  ICrewMemberDeleteStart,
  ICrewUpdate,
  ICrewMemberDeleteComplete,
  ICrewMemberDeleteError,
  IJobDeleteStart,
  IJobDeleteComplete,
  IJobDeleteError,
  ICustomerAdditionalLocationDeleteStart,
  ICustomerAdditionalLocationDeleteComplete,
  ICustomerAdditionalLocationDeleteError,
  ICustomerDeleteStart,
  ICustomerDeleteComplete,
  ICustomerDeleteError,
  IJobInstanceDeleteStart,
  IJobInstanceDeleteComplete,
  IJobInstanceDeleteError,
  ITodoTemplateDeleteStart,
  ITodoTemplateDeleteComplete,
  ITodoTemplateDeleteError,
  ILoadDistancesStartingAction,
  ILoadDistancesCompletedAction,
  ILoadDaySchedulesStartingAction,
  ILoadDaySchedulesCompletedAction,
  ILoadDaySchedulesErrorAction,
  ILoadDaySchedulesErrorClearAction,
  IInitialLoadCompletedAction,
  IDropJobAction,
  IDropJobCompleteAction,
  IAutoRouteJobsStart,
  IAutoRouteJobsCompleted,
  IAction,
  IAutoRouteJobsPermanentCompleted,
  ICustomerPaymentConfigurationClear,
  IAutoRouteJobsError,
  IRemoteJobInstanceUpdated,
  IRemoteJobInstanceFieldUpdated,
  IRemoteDayScheduleFieldUpdated,
  IRemoteDayScheduleChanged,
  IRemoteJobChangedAction,
  IRemoteCustomerNotificationAddedAction,
  IOneTimeJobLoadStart,
  IOneTimeJobLoadComplete,
  IShiftScheduleCompleteAggregate,
  ICustomerLoadStart,
  ICustomerLoadComplete,
  ICustomerLoadError,
  ICustomerLoadClearError,
  ICustomerPaymentMethodClear,
  IMaintenanceJobLoadStart,
  IMaintenanceJobLoadComplete,
  IMerchantOnboardingSubmitted,
  ICustomerSelectionSearchInput,
  ICustomerSelectionSearchStart,
  ICustomerSelectionSearchComplete,
  ICustomerSelectionSearchEmpty,
  ICustomerSelectionSearchError,
  IBillingReportStartLoad,
  IBillingReportErrorLoad,
  IPermanentJobsSetMessage,
  IPermanentJobsClearMessage,
  IPermanentJobsSaveStart,
  IPermanentJobsSaveError,
  IPermanentJobsSaveComplete,
  ICrewCategoryAdd,
  IManageCustomersSearchStart,
  IManageCustomersSearchComplete,
  IManageCustomersSearchError,
  IJobInstanceBulkUpdated,
  IProjectSaved,
  ICustomerCommunicationTemplateUpdated,
  IInvoiceBatchEmailComplete,
  ICustomerClearJobsComplete,
  IUnscheduledJobsSetPage,
  IUnscheduledJobsSetDisplayModel,
  ICustomerQuickBooksLinkUpdated,
  IRemoteCustomerChangedAction,
  IInvoiceConfigurationUpdated,
  IJobInstanceTimeUpdated,
  IRemoteCrewChangedAction,
  ICustomerCommunicationTemplateDefaultsUpdated,
  IReminderConfigurationUpdated,
  IProposalEmailConfigurationUpdated,
} from "./actionTypeDefinitions";
import { IInvoiceConfiguration } from "../models/IInvoiceConfiguration";
import { ICustomerTextTemplateDefaults } from "../models/ICustomerTextTemplateDefaults";
import { IReminderConfiguration } from "../models/IReminderConfiguration";
import { IProposalEmailConfiguration } from "../models/IProposalEmailConfiguration";

const actionCreators = {
  unscheduledJobsCategoryFilterSet: (
    categoryFilter: IUnscheduledJobCategoryFilter | null
  ): IUnscheduledJobsCategoryFilterSet => ({
    type: actionTypes.SET_UNSCHEDULEDJOBS_CATEGORYFILTER,
    categoryFilter,
  }),

  unscheduledJobsSearchTextFilterSet: (
    searchText: string
  ): IUnscheduledJobsSearchTextFilterSet => ({
    type: actionTypes.SET_UNSCHEDULEDJOBS_SEARCHTEXTFILTER,
    searchText,
  }),

  jobInstanceDragStart: (jobInstanceId: string): IJobInstanceDragStart => ({
    type: actionTypes.JOB_INSTANCE_DRAG_START,
    jobInstanceId,
  }),

  jobInstanceDragEnd: (): IJobInstanceDragEnd => ({
    type: actionTypes.JOB_INSTANCE_DRAG_END,
  }),

  jobInstanceToggleSelected: (
    jobInstanceIds: Array<string>,
    selected?: boolean,
    singleJobInstanceToggle?: boolean
  ): IJobInstanceToggleSelected => {
    return {
      type: actionTypes.JOB_INSTANCE_TOGGLE_SELECTED,
      jobInstanceIds,
      selected,
      singleJobInstanceToggle: singleJobInstanceToggle ?? false,
    };
  },

  jobInstanceToggleSkippedStart: (
    jobInstanceIds: Array<string>,
    skipped: boolean,
    clearSelected: boolean
  ): IJobInstanceToggleSkippedStart => ({
    type: actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_START,
    jobInstanceIds,
    skipped,
    clearSelected,
  }),

  jobInstanceToggleSkippedComplete: (
    jobInstanceIds: Array<string>,
    skipped: boolean,
    clearSelected: boolean
  ): IJobInstanceToggleSkippedComplete => ({
    type: actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_COMPLETE,
    jobInstanceIds,
    skipped,
    clearSelected,
  }),

  jobInstanceToggleSkippedError: (
    jobInstanceIds: Array<string>,
    error: string
  ): IJobInstanceToggleSkippedError => ({
    type: actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_ERROR,
    jobInstanceIds,
    error,
  }),

  jobInstanceToggleCompletedStart: (
    jobInstanceIds: Array<string>
  ): IJobInstanceToggleCompletedStart => ({
    type: actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_START,
    jobInstanceIds,
  }),

  jobInstanceToggleCompletedComplete: (
    jobInstanceIds: Array<string>
  ): IJobInstanceToggleCompletedComplete => ({
    type: actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_COMPLETE,
    jobInstanceIds,
  }),

  jobInstanceToggleCompletedError: (
    jobInstanceIds: Array<string>,
    error: string
  ): IJobInstanceToggleCompletedError => ({
    type: actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_ERROR,
    jobInstanceIds,
    error,
  }),

  showManageUiMessage: (message: string): IManageUiShowMessage => ({
    type: actionTypes.MANGEUI_SHOW_MESSAGE,
    message,
    messageId: uuidv4(),
  }),

  hideManageUiMessage: (
    messageId?: string | undefined
  ): IManageUiHideMessage => ({
    type: actionTypes.MANGEUI_HIDE_MESSAGE,
    messageId,
  }),

  hideManageUiError: (errorId: string): IManageUiHideError => ({
    type: actionTypes.MANGEUI_HIDE_ERROR,
    errorId,
  }),

  showManageUiError: (
    errorId: string,
    message: string
  ): IManageUiShowError => ({
    type: actionTypes.MANGEUI_SHOW_ERROR,
    errorId,
    message,
  }),

  crewDeleteStart: (crewId: string): ICrewDeleteStart => ({
    type: actionTypes.CREW_DELETE_START,
    crewId,
  }),

  crewDeleteComplete: (crewId: string): ICrewDeleteComplete => ({
    type: actionTypes.CREW_DELETE_COMPLETE,
    crewId,
  }),

  crewDeleteError: (crewId: string, error: string): ICrewDeleteError => ({
    type: actionTypes.CREW_DELETE_ERROR,
    crewId,
    error,
  }),

  crewMemberDeleteStart: (crewMemberId: string): ICrewMemberDeleteStart => ({
    type: actionTypes.CREW_MEMBER_DELETE_START,
    crewMemberId,
  }),

  crewUpdate: (crewId: string, crewChanges: Partial<ICrew>): ICrewUpdate => ({
    type: actionTypes.CREW_UPDATE,
    crewId,
    crewChanges,
  }),

  crewMemberDeleteComplete: (
    crewMemberId: string
  ): ICrewMemberDeleteComplete => ({
    type: actionTypes.CREW_MEMBER_DELETE_COMPLETE,
    crewMemberId,
  }),

  crewMemberDeleteError: (
    crewMemberId: string,
    error: string
  ): ICrewMemberDeleteError => ({
    type: actionTypes.CREW_MEMBER_DELETE_ERROR,
    crewMemberId,
    error,
  }),

  jobDeleteStart: (
    jobId: string,
    jobType: JobType,
    contextId?: string
  ): IJobDeleteStart => ({
    type: actionTypes.JOB_DELETE_START,
    jobId,
    jobType,
    contextId,
  }),

  jobDeleteComplete: (jobId: string): IJobDeleteComplete => ({
    type: actionTypes.JOB_DELETE_COMPLETE,
    jobId,
  }),

  jobDeleteError: (
    jobId: string,
    error: string,
    contextId?: string
  ): IJobDeleteError => ({
    type: actionTypes.JOB_DELETE_ERROR,
    jobId,
    error,
    contextId,
  }),

  customerAdditionalLocationDeleteStart: (
    customerAdditionalLocationId: string,
    contextId?: string
  ): ICustomerAdditionalLocationDeleteStart => ({
    type: actionTypes.CUSTOMER_ADDITIONAL_LOCATION_DELETE_START,
    customerAdditionalLocationId,
    contextId,
  }),

  customerAdditionalLocationDeleteComplete: (
    customerAdditionalLocationId: string
  ): ICustomerAdditionalLocationDeleteComplete => ({
    type: actionTypes.CUSTOMER_ADDITIONAL_LOCATION_DELETE_COMPLETE,
    customerAdditionalLocationId,
  }),

  customerAdditionalLocationDeleteError: (
    customerAdditionalLocationId: string,
    error: string,
    contextId?: string
  ): ICustomerAdditionalLocationDeleteError => ({
    type: actionTypes.CUSTOMER_ADDITIONAL_LOCATION_DELETE_ERROR,
    customerAdditionalLocationId,
    error,
    contextId,
  }),

  customerDeleteStart: (customerId: string): ICustomerDeleteStart => ({
    type: actionTypes.CUSTOMER_DELETE_START,
    customerId,
  }),

  customerDeleteComplete: (customerId: string): ICustomerDeleteComplete => ({
    type: actionTypes.CUSTOMER_DELETE_COMPLETE,
    customerId,
  }),

  customerDeleteError: (
    customerId: string,
    error: string
  ): ICustomerDeleteError => ({
    type: actionTypes.CUSTOMER_DELETE_ERROR,
    customerId,
    error,
  }),

  jobInstanceDeleteStart: (
    jobInstanceIds: Array<string>,
    markMaintenanceJobsInactive: boolean
  ): IJobInstanceDeleteStart => ({
    type: actionTypes.JOB_INSTANCE_DELETE_START,
    jobInstanceIds,
    markMaintenanceJobsInactive,
  }),

  jobInstanceDeleteComplete: (
    jobInstanceIds: Array<string>,
    updatedDaySchedules: Array<string>,
    updatedFlexibleJobWeeks: Array<string>
  ): IJobInstanceDeleteComplete => ({
    type: actionTypes.JOB_INSTANCE_DELETE_COMPLETE,
    jobInstanceIds,
    updatedDaySchedules,
    updatedFlexibleJobWeeks,
  }),

  jobInstanceDeleteError: (
    jobInstanceIds: Array<string>,
    error: string
  ): IJobInstanceDeleteError => ({
    type: actionTypes.JOB_INSTANCE_DELETE_ERROR,
    jobInstanceIds,
    error,
  }),

  todoTemplateDeleteStart: (
    todoTemplateId: string
  ): ITodoTemplateDeleteStart => ({
    type: actionTypes.TODO_TEMPLATE_DELETE_START,
    todoTemplateId,
  }),

  todoTemplateDeleteComplete: (
    todoTemplateId: string
  ): ITodoTemplateDeleteComplete => ({
    type: actionTypes.TODO_TEMPLATE_DELETE_COMPLETE,
    todoTemplateId,
  }),

  todoTemplateDeleteError: (
    todoTemplateId: string,
    error: string
  ): ITodoTemplateDeleteError => ({
    type: actionTypes.TODO_TEMPLATE_DELETE_ERROR,
    todoTemplateId,
    error,
  }),

  loadDistancesStarting: (
    distancesToLoad: Array<IDistance>
  ): ILoadDistancesStartingAction => {
    return {
      type: actionTypes.LOAD_DISTANCES_STARTING,
      distancesToLoad,
    };
  },

  loadDistancesCompleted: (
    distancesLoaded: Array<IDistance>
  ): ILoadDistancesCompletedAction => {
    return {
      type: actionTypes.LOAD_DISTANCES_COMPLETED,
      distancesLoaded,
    };
  },

  loadDaySchedulesStarting: (
    daySchedulesToLoad: Array<IDayToLoad>,
    weeksUnscheduledMaintenanceJobsToLoad: Array<Date | string>,
    maxUnassignedWeekAlreadyLoaded: string | null
  ): ILoadDaySchedulesStartingAction => {
    return {
      type: actionTypes.LOAD_DAY_SCHEDULES_STARTING,
      daySchedulesToLoad,
      weeksUnscheduledMaintenanceJobsToLoad,
      maxUnassignedWeekAlreadyLoaded,
    };
  },

  loadDaySchedulesCompleted: (
    daySchedules: Array<IDaySchedule>,
    weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJobsWeekResult>
  ): ILoadDaySchedulesCompletedAction => {
    return {
      type: actionTypes.LOAD_DAY_SCHEDULES_COMPLETED,
      daySchedules,
      weeksUnscheduledMaintenanceJobs,
    };
  },

  loadDaySchedulesError: (
    daySchedulesWithError: Array<IDayToLoad>,
    weeksUnscheduledMaintenanceJobsWithError: Array<string | Date>
  ): ILoadDaySchedulesErrorAction => {
    return {
      type: actionTypes.LOAD_DAY_SCHEDULES_ERROR,
      daySchedulesWithError,
      weeksUnscheduledMaintenanceJobsWithError,
    };
  },

  loadDaySchedulesErrorClear: (): ILoadDaySchedulesErrorClearAction => {
    return {
      type: actionTypes.LOAD_DAY_SCHEDULES_ERROR_CLEAR,
    };
  },

  startInitialLoad: () => ({
    type: actionTypes.INITIAL_LOAD_STARTING,
  }),

  updateInitialLoad: () => ({
    type: actionTypes.INITIAL_LOAD_UPDATE,
  }),

  completeInitialLoad: (
    initialLoad: IInitialLoad
  ): IInitialLoadCompletedAction => ({
    ...initialLoad,
    type: actionTypes.INITIAL_LOAD_COMPLETED,
  }),

  errorOnInitialLoad: () => {
    return {
      type: actionTypes.INITIAL_LOAD_ERROR,
    };
  },

  dropJob: (
    jobInstanceIds: Array<string>,
    destinationDayScheduleId: string | null,
    destinationFlexibleJob: boolean,
    destinationFlexibleJobWeek: Date | null,
    destinationPrecedingJobInstanceId: string | null,
    destinationDayScheduleDate: Date | null,
    destinationDayCrewId: string | null,
    daySchedules: Array<IDaySchedule>,
    jobInstanceUpdates?: Array<{
      jobInstanceId: string;
      startTime: string | null;
      endTime: string | null;
    }>
  ): IDropJobAction => {
    const daySchedulesForMovedJobInstances = daySchedules
      .filter((ds) =>
        ds.jobInstances.some((ji) => jobInstanceIds.includes(ji.id))
      )
      .map((ds) => ds.id);
    return {
      type: actionTypes.DROP_JOB,
      jobInstanceIds,
      destinationDayScheduleId,
      destinationFlexibleJob,
      destinationFlexibleJobWeek,
      destinationPrecedingJobInstanceId,
      destinationDayScheduleDate,
      destinationDayCrewId,
      daySchedulesForMovedJobInstances,
      jobInstanceUpdates,
    };
  },

  dropJobComplete: (
    jobInstanceIds: Array<string>,
    destinationDayScheduleId: string
  ): IDropJobCompleteAction => ({
    type: actionTypes.DROP_JOB_COMPLETED,
    jobInstanceIds,
    destinationDayScheduleId,
  }),

  errorOnDropJob: (jobInstanceIds: Array<string>) => {
    return {
      type: actionTypes.DROP_JOB_ERROR,
      jobInstanceIds,
    };
  },

  closePageLevelMessage: (messageKey?: string) => ({
    type: actionTypes.CLOSE_PAGE_LEVEL_MESSAGE,
    messageKey,
  }),

  clearCustomerNotificationResults: () => ({
    type: actionTypes.CLEAR_CUSTOMER_NOTIFICATION_RESULTS,
  }),

  autoRouteJobsStarting: (dayScheduleId: string): IAutoRouteJobsStart => ({
    type: actionTypes.AUTO_ROUTE_JOBS_STARTING,
    dayScheduleId,
  }),

  autoRouteJobsCompleted: (
    dayScheduleId: string,
    jobInstanceIds: Array<string>,
    maintenanceJobIds: Array<string>,
    allowReverse: boolean
  ): IAutoRouteJobsCompleted => ({
    type: actionTypes.AUTO_ROUTE_JOBS_COMPLETED,
    dayScheduleId,
    jobInstanceIds,
    maintenanceJobIds,
    allowReverse,
  }),

  autoRouteJobsPermanentStarting: (): IAction => ({
    type: actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_STARTING,
  }),

  autoRouteJobsPermanentCompleted: (
    updatedDaySchedules: Array<string>,
    updatedFlexibleJobWeeks: Array<string>,
    maintenanceJobIds: Array<string>
  ): IAutoRouteJobsPermanentCompleted => ({
    type: actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_COMPLETED,
    updatedDaySchedules,
    updatedFlexibleJobWeeks,
    maintenanceJobIds,
  }),

  autoRouteJobsPermanentError: (): IAction => ({
    type: actionTypes.AUTO_ROUTE_JOBS_PROMPT_SAVE_ERROR,
  }),

  autoRouteJobsPermanentClear: (): IAction => ({
    type: actionTypes.AUTO_ROUTE_JOBS_PROMPT_CLEAR,
  }),

  customerPaymentConfigurationClear: (
    customerId: string
  ): ICustomerPaymentConfigurationClear => ({
    type: actionTypes.CUSTOMER_PAYMENT_CONFIGURATION_CLEAR,
    customerId,
  }),

  autoRouteJobsError: (dayScheduleId: string): IAutoRouteJobsError => ({
    type: actionTypes.AUTO_ROUTE_JOBS_ERROR,
    dayScheduleId,
    pageLevelMessageKey: uuidv4(),
  }),

  toggleHeaderVisibility: (
    dayScheduleId: string,
    mode: string,
    visible: boolean
  ) => ({
    type: actionTypes.TOGGLE_HEADER_VISIBILITY,
    dayScheduleId,
    mode,
    visible,
  }),

  forms: formActionCreators,

  remoteJobInstanceUpdated: (
    jobInstance: IJobInstance
  ): IRemoteJobInstanceUpdated => {
    return {
      type: actionTypes.REMOTE_JOBINSTANCE_UPDATED,
      id: jobInstance.id,
      updatedJobInstance: jobInstance,
    };
  },

  remoteJobInstanceFieldUpdated: (
    jobInstanceId: string,
    fieldName: keyof IJobInstance,
    value: any
  ): IRemoteJobInstanceFieldUpdated => {
    return {
      type: actionTypes.REMOTE_JOBINSTANCE_FIELD_UPDATED,
      jobInstanceId,
      fieldName,
      value,
    };
  },

  remoteDayScheduleFieldUpdated: (
    dayScheduleId: string,
    fieldName: keyof IDaySchedule,
    value: any
  ): IRemoteDayScheduleFieldUpdated => {
    return {
      type: actionTypes.REMOTE_DAYSCHEDULE_FIELD_UPDATED,
      dayScheduleId,
      fieldName,
      value,
    };
  },

  remoteScheduleChanged: (
    changedSchedule: IRemoteScheduleChanged
  ): IRemoteDayScheduleChanged => {
    return {
      type: actionTypes.REMOTE_DAYSCHEDULE_CHANGED,
      changedSchedules: changedSchedule,
    };
  },

  remoteJobChanged: (
    jobChanged: IRemoteJobChanged
  ): IRemoteJobChangedAction => {
    return {
      type: actionTypes.REMOTE_JOB_CHANGED,
      jobId: jobChanged.jobId,
      jobType: jobChanged.jobType,
      tenantId: jobChanged.tenantId,
    };
  },

  remoteCustomerChanged: (customerId: string): IRemoteCustomerChangedAction => {
    return {
      type: actionTypes.REMOTE_CUSTOMER_CHANGED,
      customerId,
    };
  },

  remoteCrewChanged: (crewId: string): IRemoteCrewChangedAction => {
    return {
      type: actionTypes.REMOTE_CREW_CHANGED,
      crewId,
    };
  },

  remoteCustomerNotificationAdded: (
    customerNotificationAdded: IRemoteCustomerNotificationAdded
  ): IRemoteCustomerNotificationAddedAction => {
    return {
      type: actionTypes.REMOTE_CUSTOMER_NOTIFICATION_ADDED,
      jobInstanceId: customerNotificationAdded.jobInstanceId,
      customerNotification: customerNotificationAdded.customerNotification,
    };
  },

  loadOneTimeJobsStart: (jobIds: Array<string>): IOneTimeJobLoadStart => {
    return {
      type: actionTypes.ONE_TIME_JOB_LOAD_START,
      jobIds,
    };
  },

  loadOneTimeJobsComplete: (
    oneTimeJobs: Array<IOneTimeJob>,
    customers: Array<ICustomer>,
    customerAdditionalLocations: Array<ICustomerAdditionalLocation>
  ): IOneTimeJobLoadComplete => ({
    type: actionTypes.ONE_TIME_JOB_LOAD_COMPLETE,
    oneTimeJobs,
    customers,
    customerAdditionalLocations,
  }),

  shiftScheduleCompleteAggregate: (
    oneTimeJobIds: Array<string>,
    shiftResult: IShiftResult
  ): IShiftScheduleCompleteAggregate => ({
    type: actionTypes.SHIFT_SCHEDULE_COMPLETE_AGGREGATE,
    oneTimeJobIds,
    shiftResult,
  }),

  loadCustomersStart: (customerIds: Array<string>): ICustomerLoadStart => ({
    type: actionTypes.CUSTOMER_LOAD_START,
    customerIds,
  }),

  loadCustomersComplete: (
    customers: Array<ICustomer>,
    customerAdditionalLocations: Array<ICustomerAdditionalLocation>
  ): ICustomerLoadComplete => ({
    type: actionTypes.CUSTOMER_LOAD_COMPLETE,
    customers,
    customerAdditionalLocations,
  }),

  loadCustomersError: (customerIds: Array<string>): ICustomerLoadError => ({
    type: actionTypes.CUSTOMER_LOAD_ERROR,
    customerIds,
  }),

  loadCustomersNotFound: (customerIds: Array<string>): ICustomerLoadError => ({
    type: actionTypes.CUSTOMER_LOAD_NOT_FOUND,
    customerIds,
  }),

  loadCustomersErrorClear: (
    customerIds: Array<string>
  ): ICustomerLoadClearError => ({
    type: actionTypes.CUSTOMER_LOAD_ERROR_CLEAR,
    customerIds,
  }),

  customerPaymentMethodClear: (
    customerId: string
  ): ICustomerPaymentMethodClear => ({
    type: actionTypes.CUSTOMER_PAYMENT_METHOD_CLEAR,
    customerId,
  }),

  loadMaintenanceJobsStart: (
    jobIds: Array<string>
  ): IMaintenanceJobLoadStart => {
    return {
      type: actionTypes.MAINTENANCE_JOB_LOAD_START,
      jobIds,
    };
  },

  loadMaintenanceJobsComplete: (
    maintenanceJobs: Array<IMaintenanceJob>,
    customers: Array<ICustomer>,
    customerAdditionalLocations: Array<ICustomerAdditionalLocation>
  ): IMaintenanceJobLoadComplete => ({
    type: actionTypes.MAINTENANCE_JOB_LOAD_COMPLETE,
    maintenanceJobs,
    customers,
    customerAdditionalLocations,
  }),

  setQuickBooksNotEnabled: (): IAction => {
    return {
      type: actionTypes.QUICKBOOKS_SET_NOT_ENABLED,
    };
  },

  merchantOnboardingSubmitted: (args: {
    daytimePhone: string;
  }): IMerchantOnboardingSubmitted => {
    return {
      type: actionTypes.MERCHANT_ONBOARDING_SUBMITTED,
      ...args,
    };
  },

  customerSelectionSearchInput: (
    searchTerm: string,
    includeQuickBooksRecords?: boolean,
    includeGroups?: boolean
  ): ICustomerSelectionSearchInput => ({
    type: actionTypes.CUSTOMER_SELECTION_SEARCH_INPUT,
    searchTerm,
    includeQuickBooksRecords,
    includeGroups,
  }),

  customerSelectionSearchStart: (
    searchTerm: string,
    includeQuickBooksRecords?: boolean,
    includeGroups?: boolean
  ): ICustomerSelectionSearchStart => ({
    type: actionTypes.CUSTOMER_SELECTION_SEARCH_START,
    searchTerm,
    includeQuickBooksRecords,
    includeGroups,
  }),

  customerSelectionSearchComplete: (
    matchedCustomers: Array<ICustomerSearchResult>,
    matchedGroups: Array<IGroupSearchResult>
  ): ICustomerSelectionSearchComplete => ({
    type: actionTypes.CUSTOMER_SELECTION_SEARCH_COMPLETE,
    matchedCustomers: matchedCustomers,
    matchedGroups,
  }),

  customerSelectionSearchEmpty: (): ICustomerSelectionSearchEmpty => ({
    type: actionTypes.CUSTOMER_SELECTION_SEARCH_EMPTY,
  }),

  customerSelectionSearchError: (): ICustomerSelectionSearchError => ({
    type: actionTypes.CUSTOMER_SELECTION_SEARCH_ERROR,
  }),

  customerQuickBooksLinkUpdated: (
    customerId: string,
    quickBooksId: string
  ): ICustomerQuickBooksLinkUpdated => ({
    type: actionTypes.CUSTOMER_QUICKBOOKS_LINK_UPDATED,
    customerId,
    quickBooksId,
  }),

  billingReportStartLoad: (
    startDate: string,
    endDate: string,
    category: string,
    customerName: string,
    crewIds: Array<string>,
    jobStatus: BillingReportJobStatusFilter,
    customerCategoryId: string
  ): IBillingReportStartLoad => ({
    type: actionTypes.BILLING_REPORT_START_LOAD,
    startDate,
    endDate,
    category,
    customerName,
    crewIds,
    jobStatus,
    customerCategoryId,
  }),

  billingReportCompleteLoad: (customers: Array<IBillingReportCustomer>) => ({
    type: actionTypes.BILLING_REPORT_COMPLETE_LOAD,
    customers,
  }),

  billingReportErrorLoad: (errorMessage: string): IBillingReportErrorLoad => ({
    type: actionTypes.BILLING_REPORT_ERROR_LOAD,
    errorMessage,
  }),

  clearCurrentGuide: (): IAction => ({
    type: actionTypes.CLEAR_CURRENT_GUIDE,
  }),

  setPermanentJobsMessage: (
    actionParameters: IPermanentDropJobs
  ): IPermanentJobsSetMessage => ({
    ...actionParameters,

    type: actionTypes.PERMANENT_JOBS_SET_MESSAGE,
  }),

  clearPermanentJobsMessage: (): IPermanentJobsClearMessage => ({
    type: actionTypes.PERMANENT_JOBS_CLEAR_MESSAGE,
  }),

  startSavePermanentJobs: (
    actionParameters: IPermanentDropJobs
  ): IPermanentJobsSaveStart => {
    return {
      ...actionParameters,
      type: actionTypes.PERMANENT_JOBS_SAVE_START,
    };
  },

  errorSavePermanentJobs: (args: {
    validationError: boolean;
    validationErrorMessage: string | null;
  }): IPermanentJobsSaveError => {
    return {
      type: actionTypes.PERMANENT_JOBS_SAVE_ERROR,
      ...args,
    };
  },

  completeSavePermanentJobs: (
    updatedDaySchedules: Array<string>,
    updatedFlexibleJobWeeks: Array<string>,
    movedMaintenanceJobIds: Array<string>
  ): IPermanentJobsSaveComplete => ({
    type: actionTypes.PERMANENT_JOBS_SAVE_COMPLETE,
    updatedDaySchedules,
    updatedFlexibleJobWeeks,
    movedMaintenanceJobIds,
  }),

  addCrewCategory: (
    categoriesToAdd: Array<ICrewCategory>
  ): ICrewCategoryAdd => ({
    type: actionTypes.CREW_CATEGORY_ADD,
    categoriesToAdd,
  }),

  startManageCustomersSearch: (
    search: string,
    append: boolean,
    searchingAllCustomers: boolean,
    page: number,
    pageSize: number,
    includeInactive: boolean
  ): IManageCustomersSearchStart => {
    return {
      type: actionTypes.MANAGE_CUSTOMERS_SEARCH_START,
      search,
      append,
      searchingAllCustomers,
      page,
      pageSize,
      includeInactive,
    };
  },

  completeManageCustomersSearch: (
    matchingCustomers: Array<IManageCustomerSearchResult>,
    append: boolean,
    additionalCustomers: number,
    searchText: string
  ): IManageCustomersSearchComplete => {
    return {
      type: actionTypes.MANAGE_CUSTOMERS_SEARCH_COMPLETE,
      matchingCustomers,
      append,
      additionalCustomers,
      searchText,
    };
  },

  errorManageCustomersSearch: (): IManageCustomersSearchError => {
    return {
      type: actionTypes.MANAGE_CUSTOMERS_SEARCH_ERROR,
    };
  },

  cancelSubscription: (): IAction => {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION,
    };
  },

  jobInstanceBulkUpdated: (
    updates: Array<{ id: string } & Partial<IJobInstance>>
  ): IJobInstanceBulkUpdated => {
    return {
      type: actionTypes.JOB_INSTANCE_BULK_UPDATED,
      updates,
    };
  },

  projectSave: ({
    updatedDaySchedules,
    updatedFlexibleJobWeeks,
    savedJobs,
    opportunityId,
    mode,
  }: {
    updatedDaySchedules: Array<string>;
    updatedFlexibleJobWeeks: Array<string>;
    savedJobs: Array<{ createdId: string; projectId: string | null }>;
    opportunityId: string | null;
    mode: "add" | "edit";
  }): IProjectSaved => {
    return {
      type: actionTypes.PROJECT_SAVE,
      updatedDaySchedules,
      updatedFlexibleJobWeeks,
      savedJobs,
      opportunityId,
      mode,
    };
  },

  updateCustomerCommunicationTemplate: (
    template: KeyOfType<ICommonState, ICustomerCommunicationTemplate | null>,
    property: keyof ICustomerCommunicationTemplate,
    value: string
  ): ICustomerCommunicationTemplateUpdated => {
    return {
      type: actionTypes.CUSTOMER_COMMUNICATION_TEMPLATE_UPDATED,
      template,
      property,
      value,
    };
  },

  invoiceBatchEmailCompleted: (
    jobInstanceUpdates: Array<IInvoiceSaveJobInstanceUpdate>
  ): IInvoiceBatchEmailComplete => {
    return {
      type: actionTypes.INVOICE_BATCH_EMAIL_COMPLETED,
      jobInstanceUpdates,
    };
  },

  customerClearJobsComplete: (
    args: { customerId: string } & IClearJobsResponse
  ): ICustomerClearJobsComplete => {
    return {
      type: actionTypes.CUSTOMER_CLEAR_JOBS_COMPLETE,
      ...args,
    };
  },

  setUnscheduledJobsPage: (page: number): IUnscheduledJobsSetPage => {
    return {
      type: actionTypes.UNSCHEDULED_JOBS_SET_PAGE,
      page,
    };
  },

  setUnscheduledJobsDisplayMode: (
    displayMode: UnscheduledJobsDisplayMode
  ): IUnscheduledJobsSetDisplayModel => {
    return {
      type: actionTypes.UNSCHEDULED_JOBS_SET_DISPLAY_MODE,
      displayMode,
    };
  },

  reminderConfigurationUpdated: (
    proposalBeforeReminderConfiguration: IReminderConfiguration,
    proposalAfterReminderConfiguration: IReminderConfiguration,
    invoiceBeforeReminderConfiguration: IReminderConfiguration,
    invoiceAfterReminderConfiguration: IReminderConfiguration
  ): IReminderConfigurationUpdated => ({
    type: actionTypes.REMINDER_CONFIGURATION_UPDATED,
    proposalBeforeReminderConfiguration,
    proposalAfterReminderConfiguration,
    invoiceBeforeReminderConfiguration,
    invoiceAfterReminderConfiguration,
  }),

  proposalEmailConfigurationUpdated: (
    configuration: IProposalEmailConfiguration
  ): IProposalEmailConfigurationUpdated => ({
    type: actionTypes.PROPOSAL_EMAIL_CONFIGURATION_UPDATED,
    configuration,
  }),

  invoiceConfigurationUpdated: (
    invoiceConfiguration: IInvoiceConfiguration & {
      updateContractBilling: boolean;
    }
  ): IInvoiceConfigurationUpdated => ({
    type: actionTypes.INVOICE_CONFIGURATION_UPDATED,
    invoiceConfiguration,
  }),

  jobInstanceTimeUpdated: (
    jobInstanceId: string,
    startTime: string | null,
    endTime: string | null,
    arrivalWindowDurationMinutes: number | null
  ): IJobInstanceTimeUpdated => ({
    type: actionTypes.JOB_INSTANCE_TIME_UPDATED,
    jobInstanceId,
    startTime,
    endTime,
    arrivalWindowDurationMinutes,
  }),

  jobInstanceUpdateCompletionInfo: (
    jobInstanceId: string,
    payload: any
  ): any => {
    return {
      type: actionTypes.JOB_INSTANCE_COMPLETION_INFO_UPDATED,
      jobInstanceId,
      payload,
    };
  },

  customerNotificationTemplateDefaultsUpdated: (
    templateId: string,
    defaultValues: ICustomerTextTemplateDefaults
  ): ICustomerCommunicationTemplateDefaultsUpdated => ({
    type: actionTypes.CUSTOMER_NOTIFICTAION_TEMPLATE_DEFAULTS_UPDATED,
    templateId,
    defaultValues,
  }),

  tollFreeVerificationSubmitted: () => {
    return {
      type: actionTypes.TOLL_FREE_VERIFICATION_SUBMITTED,
    };
  },

  initialDispatchSent: () => {
    return {
      type: actionTypes.INITIAL_DISPATCH_SENT,
    };
  },

  null: () => ({
    type: actionTypes.NULL_ACTION,
  }),

  markReduxStoreCacheStale: () => ({
    type: actionTypes.MARK_LOCAL_CACHE_STALE,
  }),
};

export { actionTypes, actionCreators };
