import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { LoadScriptNext } from "@react-google-maps/api";
import TagManager from "react-gtm-module";
import {
  getGoogelAnalyticsSignupCategory,
  googleMapLibraries,
} from "../../../services/googleService";
import { parseTimeZone } from "../../../models/TimeZone";
import constants from "../../../constants";
import RegisterTermsOfServiceAndPrivacy from "./RegisterTermsOfServiceAndPrivacy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import AddressSimple, {
  ISimpleAddressComponents,
} from "../../../containers/app/components/AddressSimple";
import IndustrySelection from "../../../containers/app/components/IndustrySelection";
import PhoneNumberField from "../../../containers/app/components/PhoneNumberField";
import { INewCompanyFormData } from "./Register.types";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import auth from "../../../services/Auth";
import { getSavePayload } from "./Register.functions";
import { fullStoryTrack } from "../../../services/fullStoryService";
import accountManagementDataProvider from "../services/accountManagementDataProvider";
import Spinner from "../../../containers/app/components/Spinner";

let googleTagManagerEventRaised = false;

export function RegisterForm() {
  const newCompanyErrorMessage = useApplicationStateSelector(
    (s) => s.forms.registerNewCompany.errorMessage
  );
  const newCompanySaving = useApplicationStateSelector(
    (s) => s.forms.registerNewCompany.saving
  );
  const [loaded, setLoaded] = useState(false);
  const [hasExistingAccountMessage, setHasExistingAccountMessage] =
    useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (loaded === false) {
      accountManagementDataProvider.hasExistingAccount().subscribe((result) => {
        if (result.hasExistingAccount) {
          fullStoryTrack("Trial Signup Duplicate User Error");
          setHasExistingAccountMessage(result.message ?? "");
        }
      });

      setLoaded(true);
    }
  }, [loaded]);

  const [newCompanyFormData, setNewCompanyFormData] =
    useState<INewCompanyFormData>({
      companyName: "",
      phoneNumber: "",
      typicalCrewSize: "1",
      acceptTermsOfServiceAndPrivacyPolicy: false,
      addressSelected: false,
      timeZone: parseTimeZone(
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone
      ),
      addressForSave: getEmptyAddress(),
      addressForDisplay: getEmptyAddress(),
      country: "",
      industry: "",
      firstName: "",
      lastName: "",
    });

  const { setRef: setCompanyNameRef } =
    useCompanyNameRefWithFocusOnInitialize();
  const { ref: streetAndNumberRef } = useStreetAndNumberRef();

  const onNewCompanySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newCompanyFormData.addressSelected) {
      dispatch(
        actionCreators.forms.registerNewCompany.setErrorMessage(
          "An address must be selected before getting started"
        )
      );
      return;
    }

    if (!newCompanySaving) {
      fullStoryTrack("Trial Signup");

      dispatch(
        actionCreators.forms.registerNewCompany.startSaving(
          getSavePayload(newCompanyFormData)
        )
      );
    }
  };

  function handleAddressChange(
    address: ISimpleAddressComponents,
    raisedByAutoComplete: boolean
  ) {
    const formDataToUpdate = { ...newCompanyFormData };
    formDataToUpdate.addressForDisplay = address;

    if (raisedByAutoComplete) {
      formDataToUpdate.addressForSave = address;
      formDataToUpdate.addressSelected = true;
      formDataToUpdate.country = address.country;

      setStreetAndNumberValidity({
        streetAndNumberRef,
        isValid: true,
      });

      dispatch(actionCreators.forms.registerNewCompany.clearErrorMessage());
    } else if (address.addressShown.trim() === "") {
      formDataToUpdate.addressForSave = getEmptyAddress();
      formDataToUpdate.addressSelected = false;

      setStreetAndNumberValidity({
        streetAndNumberRef,
        isValid: false,
      });
    }

    setNewCompanyFormData(formDataToUpdate);
  }

  function handleNewCompanyChange(
    key:
      | "companyName"
      | "phoneNumber"
      | "typicalCrewSize"
      | "firstName"
      | "lastName",
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const formDataToUpdate = { ...newCompanyFormData };
    formDataToUpdate[key] = event.target.value;
    setNewCompanyFormData(formDataToUpdate);
  }

  if (!googleTagManagerEventRaised) {
    TagManager.dataLayer({
      dataLayer: {
        category: getGoogelAnalyticsSignupCategory(),
        event: "registrationStarted",
      },
    });

    googleTagManagerEventRaised = true;
  }

  return (
    <>
      <LoadScriptNext
        loadingElement={<div></div>}
        id="script-loader"
        libraries={googleMapLibraries as any}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY as string}
        onError={() => {
          console.log("Unable to load google maps script");
        }}
      >
        <React.Fragment>
          {newCompanySaving ? <Spinner /> : null}

          <form onSubmit={onNewCompanySubmit}>
            {hasExistingAccountMessage.length > 0 ? (
              <div
                data-testid="existingAccountAlert"
                className="form-group alert alert-danger"
              >
                <FontAwesomeIcon icon={faWarning} className="mr-2" />
                {hasExistingAccountMessage}
                <div className="text-center">
                  <LinkButton2
                    className="ml-1 text-dark "
                    buttonContents={<u>Logout</u>}
                    style={{ verticalAlign: "baseline" }}
                    onClick={() => {
                      auth.logout();
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="companyName" className="required">
                Company name
              </label>
              <input
                ref={setCompanyNameRef}
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={newCompanyFormData.companyName}
                onChange={(e) => handleNewCompanyChange("companyName", e)}
                required={true}
                maxLength={constants.companyNameMaxLength}
                data-testid="companyName"
              />
            </div>
            <div className="form-group">
              <label htmlFor="industry" className="required">
                Industry
              </label>
              <IndustrySelection
                id="industry"
                className="form-control"
                value={newCompanyFormData.industry}
                onChange={(e) => {
                  setNewCompanyFormData({
                    ...newCompanyFormData,
                    industry: e.currentTarget.value,
                  });
                }}
                required
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName" className="required">
                  First name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={newCompanyFormData.firstName}
                  onChange={(e) => handleNewCompanyChange("firstName", e)}
                  required={true}
                  maxLength={constants.firstNameMaxLength}
                  data-testid="firstName"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastName" className="required">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={newCompanyFormData.lastName}
                  onChange={(e) => handleNewCompanyChange("lastName", e)}
                  required={true}
                  maxLength={constants.lastNameMaxLength}
                  data-testid="lastName"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber" className="required">
                Phone number
              </label>
              <PhoneNumberField
                id="phoneNumber"
                name="phoneNumber"
                value={newCompanyFormData.phoneNumber}
                className="form-control"
                onChange={(e) => handleNewCompanyChange("phoneNumber", e)}
                required={true}
                dataTestId="phoneNumber"
              />
            </div>
            <AddressSimple
              streetAndNumberId="registerLocation"
              streetAndNumberName="registerLocation"
              value={newCompanyFormData.addressForDisplay}
              onChange={(a, raisedByAutoComplete) => {
                handleAddressChange(a, raisedByAutoComplete);
              }}
              streetAndNumberRef={streetAndNumberRef}
              required={true}
              addressFieldLabel="Where do your crew(s) start the day?  (we use this for routing)"
              errorMessage={null}
            />
            <div className="form-group">
              <label htmlFor="typical-crew-size">
                How many people are typically on a crew? (you can always change
                this later)
              </label>
              <div>
                <FontAwesomeIcon
                  icon={faMinus}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let newCrewSize: number;
                    const parsedCurrentValue = parseInt(
                      newCompanyFormData.typicalCrewSize
                    );
                    if (isNaN(parsedCurrentValue)) {
                      newCrewSize = 1;
                    } else if (parsedCurrentValue > 0) {
                      newCrewSize = parsedCurrentValue - 1;
                    } else {
                      newCrewSize = parsedCurrentValue;
                    }
                    setNewCompanyFormData({
                      ...newCompanyFormData,
                      typicalCrewSize: newCrewSize.toString(),
                    });
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  id="typical-crew-size"
                  name="typicalCrewSize"
                  data-testid="typicalCrewSize"
                  value={newCompanyFormData.typicalCrewSize}
                  maxLength={2}
                  required={true}
                  inputMode="numeric"
                  onChange={(e) => {
                    const value = e.currentTarget.value.trim();

                    if (value === "" || !isNaN(parseInt(value, 10))) {
                      setNewCompanyFormData({
                        ...newCompanyFormData,
                        typicalCrewSize: value,
                      });
                    }
                  }}
                  style={{
                    width: "50px",
                    display: "inline",
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                ></input>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let newCrewSize: number;
                    const parsedCurrentValue = parseInt(
                      newCompanyFormData.typicalCrewSize
                    );
                    if (isNaN(parsedCurrentValue)) {
                      newCrewSize = 1;
                    } else if (parsedCurrentValue < 99) {
                      newCrewSize = parsedCurrentValue + 1;
                    } else {
                      newCrewSize = parsedCurrentValue;
                    }

                    setNewCompanyFormData({
                      ...newCompanyFormData,
                      typicalCrewSize: newCrewSize.toString(),
                    });
                  }}
                />
              </div>
            </div>
            <RegisterTermsOfServiceAndPrivacy
              checked={newCompanyFormData.acceptTermsOfServiceAndPrivacyPolicy}
              onChecked={(checked) =>
                setNewCompanyFormData({
                  ...newCompanyFormData,
                  acceptTermsOfServiceAndPrivacyPolicy: checked,
                })
              }
              id="newCompanyTermsOfServiceAndPrivacy"
              linkButtonStyle={{ color: "#e2a443" }}
            />
            {!!newCompanyErrorMessage ? (
              <div className="text-danger">{newCompanyErrorMessage}</div>
            ) : null}

            <button
              type="submit"
              data-testid="getStartedButton"
              className="btn btn-block btn-primary branded-public-buttons"
              disabled={newCompanySaving}
            >
              Get started
            </button>

            <div className="mt-2 text-center">
              <small>
                Not wanting to create a new company?
                <LinkButton2
                  className="ml-1 branded-public-link"
                  buttonContents={
                    <small className="branded-public-link">Logout</small>
                  }
                  style={{ verticalAlign: "baseline" }}
                  onClick={() => {
                    auth.logout();
                  }}
                />
              </small>
            </div>
          </form>
        </React.Fragment>
      </LoadScriptNext>
    </>
  );
}

function getEmptyAddress(): ISimpleAddressComponents {
  return {
    country: "",
    addressShown: "",
    streetAndNumber: "",
    city: "",
    state: "",
    zip: "",
    latitude: "",
    longitude: "",
  };
}

function useCompanyNameRefWithFocusOnInitialize() {
  const ref = useRef<HTMLInputElement | null>(null);
  const hasInitialized = useRef(false);
  const setRef = useCallback((node) => {
    if (node && !hasInitialized.current) {
      node.focus();

      hasInitialized.current = true;
    }

    ref.current = node;
  }, []);

  return { ref, setRef };
}

function useStreetAndNumberRef() {
  const ref = useRef<HTMLInputElement | null>(null);
  const hasInitialized = useRef<boolean>(false);
  useEffect(() => {
    if (!hasInitialized.current) {
      setTimeout(() => {
        if (ref.current) {
          setStreetAndNumberValidity({
            streetAndNumberRef: ref,
            isValid: false,
          });

          hasInitialized.current = true;
        }
      });
    }
  });

  return { ref };
}

function setStreetAndNumberValidity({
  streetAndNumberRef,
  isValid,
}: {
  streetAndNumberRef: React.MutableRefObject<HTMLInputElement | null>;
  isValid: boolean;
}) {
  if (streetAndNumberRef.current) {
    streetAndNumberRef.current.setCustomValidity(
      isValid ? "" : "Please select an address here."
    );
  }
}
