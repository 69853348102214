import { convertFromRaw } from "draft-js";
import uuidv4 from "uuid/v4";
import { IInvoiceJobInstance } from "../containers/app/forms/InvoiceForm.types";
import dateService from "./dateService";
import remoteDataProvider from "./remoteDataProvider";
import { IProposalLineItem } from "../slices/sales/models/IProposal";
import { ILineItem } from "../containers/app/components/InvoiceLineItem";
import { IInvoiceItem } from "../models/IInvoiceItem";
import modelConversion from "./modelConversion";
import { getLineItemName } from "./lineItemService";
import { isStringSet } from "./stringService";
import { IProposalTemplateLineItem } from "../slices/sales/models/IProposalTemplate";
import {
  IWorkNotInvoicedCustomer,
  IWorkNotInvoicedJob,
  IWorkNotInvoicedProject,
  IWorkNotInvoicedProjectJobInstance,
} from "../models/IWorkNotInvoicedCustomer";
import { ICustomer } from "../models/ICustomer";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import billingReportService from "./billingReportService";
import { getTotalHoursForJobInstances } from "./invoiceService";

export function createNewLineItem(
  quantity: number | string,
  serviceDate?: string
) {
  return {
    id: uuidv4(),
    amountPerItem: "",
    name: "",
    description: "",
    itemId: "",
    quantity: typeof quantity === "number" ? quantity.toString() : quantity,
    serviceDate: serviceDate,
    optional: false,
    selected: false,
  };
}

export function mapToProposalLineItems(
  invoiceLineItems: Array<ILineItem> | null,
  isHideOptionAvailable: boolean,
  invoiceItems: Array<IInvoiceItem>
): Array<IProposalLineItem> | null {
  return (
    invoiceLineItems &&
    invoiceLineItems.map((li) => ({
      id: li.id,
      itemId: li.itemId,
      amount: modelConversion.convertStringToNumber(li.amountPerItem),
      description: li.description,
      quantity: modelConversion.convertStringToNumber(li.quantity),
      taxable: li.taxable ?? false,
      hide: isHideOptionAvailable && !li.optional ? li.hide ?? false : false,
      optional: li.optional ?? false,
      selected: li.selected ?? false,
      name: getLineItemName(invoiceItems, li),
    }))
  );
}

export function mapToProposalTemplateLineItems(
  invoiceLineItems: Array<ILineItem> | null,
  isHideOptionAvailable: boolean,
  invoiceItems: Array<IInvoiceItem>
): Array<IProposalTemplateLineItem> | null {
  return (
    invoiceLineItems &&
    invoiceLineItems.map((li) => ({
      id: li.id,
      itemId: li.itemId,
      amount: modelConversion.convertStringToNumberOrNull(li.amountPerItem),
      description: li.description,
      quantity: modelConversion.convertStringToNumberOrNull(li.quantity),
      taxable: li.taxable ?? false,
      hide: isHideOptionAvailable ? li.hide ?? false : false,
      optional: li.optional ?? false,
      selected: li.selected ?? false,
      name: getLineItemName(invoiceItems, li),
    }))
  );
}

export function getInvoiceItems(isQuickBooksEnabled: boolean) {
  return isQuickBooksEnabled
    ? remoteDataProvider.getQuickBooksItems()
    : remoteDataProvider.getNonQuickBooksInvoiceItems();
}

interface GetGrossRevenueSumResult {
  hasSingleAmount: boolean;
  amount: number | null;
}

export function getGrossRevenueAmount(
  selectedJobInstances: Array<string>,
  jobInstances: Array<IInvoiceJobInstance> | undefined
): GetGrossRevenueSumResult {
  if (!jobInstances) {
    return { hasSingleAmount: true, amount: null };
  }

  return jobInstances
    .filter((ji) => selectedJobInstances.some((sji) => sji === ji.id))
    .reduce(
      (acc, current) => {
        if (
          acc.hasSingleAmount &&
          typeof current.grossRevenuePerVisit === "number"
        ) {
          if (acc.amount === null) {
            return {
              amount: current.grossRevenuePerVisit as number,
              hasSingleAmount: true,
            };
          } else if (acc.amount !== current.grossRevenuePerVisit) {
            return {
              amount: null,
              hasSingleAmount: false,
            };
          }
        }

        return acc;
      },
      { hasSingleAmount: true, amount: null } as GetGrossRevenueSumResult
    );
}

export function getDefaultDescription({
  selectedJobInstances,
  jobInstances,
}: {
  selectedJobInstances: string[];
  jobInstances: IInvoiceJobInstance[] | undefined;
}): string {
  const isDefaultServiceDateSet = isStringSet(
    getDefaultServiceDate({
      jobInstances,
      selectedJobInstances,
    }) ?? null
  );

  return selectedJobInstances
    .map((ji) => {
      if (jobInstances) {
        const foundJobInstance = jobInstances.find(
          (jobInstance) => ji === jobInstance.id
        );
        if (foundJobInstance) {
          let components = [];
          if (!isDefaultServiceDateSet) {
            components.push(
              dateService.formatDateForDisplay(foundJobInstance.date)
            );
          }

          if (isStringSet(foundJobInstance.address)) {
            components.push(foundJobInstance.address);
          }

          return components.join(" - ");
        }
      }
      return "";
    })
    .filter((l) => !!l)
    .join("\n");
}

export function getDefaultServiceDate({
  jobInstances,
  selectedJobInstances,
}: {
  jobInstances: IInvoiceJobInstance[] | undefined;
  selectedJobInstances: string[] | undefined;
}) {
  if (!jobInstances || !selectedJobInstances) {
    return undefined;
  }

  const selectedJobDates = jobInstances
    .filter((ji) => selectedJobInstances.includes(ji.id))
    .map((ji) => ji.date);
  if (selectedJobDates.length <= 0) {
    return undefined;
  }

  const hasMultipleDates = selectedJobDates.some((dateA) =>
    selectedJobDates.some((dateB) => dateA !== dateB)
  );

  if (hasMultipleDates) {
    return undefined;
  }

  return selectedJobDates[0];
}

export function mapProjectNotInvoicedJobToInvoiceJobInstance({
  customer,
  project,
  customers,
  customerAdditionalLocations,
}: {
  customer: IWorkNotInvoicedCustomer;
  project: IWorkNotInvoicedProject;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
}) {
  return {
    id: project.id,
    services: project.services,
    address: getLocationName(
      customer,
      project,
      customers,
      customerAdditionalLocations
    ),
    amount: project.amount,
    totalHours: getTotalHoursForJobInstances(project.jobInstances),
    jobInstances: project.jobInstances.map((ji) => ({
      id: ji.id,
      projectId: project.id,
      jobId: null,
      jobType: null,
      date: ji.date,
      timeRanges: ji.timeRanges,
      customerAdditionalLocationId: project.customerAdditionalLocationId,
      actualManHours: ji.actualManHours,
      address: getLocationName(
        customer,
        ji,
        customers,
        customerAdditionalLocations
      ),
      services: ji.services,
      grossRevenuePerVisit: undefined,
      totalHours: ji.actualManHours ?? 0,
      purchaseOrderNumber: ji.purchaseOrderNumber,
    })),
  };
}

export function mapWorkNotInvoicedJobToInvoiceJobInstance({
  customer,
  job,
  customers,
  customerAdditionalLocations,
}: {
  customer: IWorkNotInvoicedCustomer;
  job: IWorkNotInvoicedJob;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
}) {
  return job.jobInstances.map((jobInstance) => ({
    id: jobInstance.id,
    projectId: null,
    jobId: job.id,
    jobType: job.jobType,
    date: jobInstance.date,
    grossRevenuePerVisit: job.grossRevenuePerVisit ?? 0,
    customerAdditionalLocationId: job.customerAdditionalLocationId,
    address: getLocationName(
      customer,
      job,
      customers,
      customerAdditionalLocations
    ),
    totalHours: jobInstance.actualManHours ?? 0,
    services: job.services,
    timeRanges: jobInstance.timeRanges,
    purchaseOrderNumber: jobInstance.purchaseOrderNumber,
  }));
}

export function getLocationName(
  customer: IWorkNotInvoicedCustomer,
  jobOrProject:
    | IWorkNotInvoicedJob
    | IWorkNotInvoicedProject
    | IWorkNotInvoicedProjectJobInstance,
  customers: ICustomer[],
  customerAdditionalLocations: ICustomerAdditionalLocation[]
) {
  return billingReportService.getLocationName(
    {
      id: customer.id,
      customerStreetAndNumber: customer.streetAndNumber,
      customerApartmentSuite: customer.apartmentSuite,
      customerLatitude: customer.latitude,
      customerLongitude: customer.longitude,
      customerAdditionalLocationId: jobOrProject.customerAdditionalLocationId,
      customerAdditionalLocationName:
        jobOrProject.customerAdditionalLocationName,
      customerAdditionalLocationsStreetAndNumber:
        jobOrProject.customerAdditionalLocationStreetAndNumber,
      customerAdditionalLocationsLatitude:
        jobOrProject.customerAdditionalLocationLatitude,
      customerAdditionalLocationsLongitude:
        jobOrProject.customerAdditionalLocationLongitude,
    },
    customers,
    customerAdditionalLocations
  );
}

export function getDefaultIntroText(tenantName: string | null) {
  return convertFromRaw({
    blocks: [
      {
        key: "ehlc4",
        text: "Dear <Customer name>,",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "6cjrp",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "bt6nf",
        text: "Here is your invoice! We appreciate your prompt payment.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "alk0q",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "e3lqk",
        text: "Thank you,",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "489b6",
        text: tenantName ?? "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
}

export function getDefaultFooterText() {
  return convertFromRaw({
    blocks: [
      {
        key: "ahlc4",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
}
