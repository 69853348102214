import React, { useState, useEffect, useRef } from "react";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import AddressComponents2, { IAddressComponents } from "./AddressComponents2";
import dataProvider from "../../../services/dataProvider";
import modalConversion from "../../../services/modelConversion";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import PhoneNumberField from "./PhoneNumberField";
import { ISaveCustomerRequest } from "../../../services/remoteDataProvider";
import uuidv4 from "uuid/v4";
import { EmailAddresses, IEmailAddressRecord } from "./EmailAddresses";

interface IProps {
  quickBooksCustomer: IQuickBooksCustomer;
  previouslySaved: boolean;
}

function createBlankAddress() {
  return {
    streetAndNumber: "",
    apartmentSuite: "",
    city: "",
    latitude: "",
    latitudeSignificantDigits: null,
    longitude: "",
    longitudeSignificantDigits: null,
    state: "",
    zip: "",
  };
}

export const QuickBooksCustomerImportCustomer: React.FunctionComponent<
  IProps
> = ({ quickBooksCustomer, previouslySaved }) => {
  const [expanded, setExpanded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taxExempt, setTaxExempt] = useState(false);
  const [alternativePhoneNumber, setAlternativePhoneNumber] = useState("");
  const [emailAddresses, setEmailAddresses] = useState<
    Array<IEmailAddressRecord>
  >([]);
  const [address, setAddress] = useState<IAddressComponents>(
    createBlankAddress()
  );

  const streetAndNumberRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    let address: IAddressComponents;
    if (!quickBooksCustomer.address) {
      address = createBlankAddress();
    } else {
      address = {
        streetAndNumber: quickBooksCustomer.address.streetAndNumber,
        apartmentSuite: quickBooksCustomer.address.apartmentSuite ?? "",
        city: quickBooksCustomer.address.city,
        latitude: quickBooksCustomer.address.latitude
          ? quickBooksCustomer.address.latitude.toString()
          : "",
        latitudeSignificantDigits: null,
        longitude: quickBooksCustomer.address.longitude
          ? quickBooksCustomer.address.longitude.toString()
          : "",
        longitudeSignificantDigits: null,
        state: quickBooksCustomer.address.state,
        zip: quickBooksCustomer.address.zip,
      };
    }

    setName(quickBooksCustomer.displayName);
    setPhoneNumber(quickBooksCustomer.phoneNumber || "");
    setAlternativePhoneNumber(quickBooksCustomer.alternativePhoneNumber || "");
    setAddress(address);
    setTaxExempt(quickBooksCustomer.taxExempt);

    const emailAddresses = (quickBooksCustomer.emailAddress || "").split(",");
    setEmailAddresses(
      emailAddresses.length === 0
        ? [
            {
              id: uuidv4(),
              value: "",
            },
          ]
        : emailAddresses.map((e: string) => ({
            id: uuidv4(),
            value: e,
          }))
    );
  }, [
    quickBooksCustomer,
    setName,
    setPhoneNumber,
    setAlternativePhoneNumber,
    setEmailAddresses,
    setAddress,
  ]);

  return (
    <div className="card" style={{ margin: "25px" }}>
      <div className="card-body">
        <button
          className="btn btn-link"
          onClick={() => setExpanded(!expanded)}
          style={{ padding: "0" }}
        >
          <h5 className="card-title text-left">
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              style={{ marginRight: "10px" }}
            />
            {quickBooksCustomer.displayName}
            {saved || previouslySaved ? (
              <FontAwesomeIcon
                icon={faCheck}
                className="text-success"
                aria-hidden="true"
                style={{ marginLeft: "10px" }}
                title="Customer Added!"
              />
            ) : null}
          </h5>
        </button>
        {expanded ? (
          <React.Fragment>
            <h6 className="card-subtitle mb-2 text-muted">
              QuickBooks Customer Data
            </h6>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSaving(true);
                setErrorMessage("");

                const customer: ISaveCustomerRequest = {
                  id: "",
                  ...address,
                  name: name,
                  emailAddresses: emailAddresses.map((e) => e.value),
                  phoneNumber: phoneNumber,
                  alternativePhoneNumber: alternativePhoneNumber,
                  latitude: modalConversion.convertStringToNumberOrNull(
                    address.latitude
                  ),
                  longitude: modalConversion.convertStringToNumberOrNull(
                    address.longitude
                  ),
                  quickBooksId: quickBooksCustomer.id,
                  inactive: false,
                  categories: [],
                  originalInactive: null,
                  taxExempt,
                };
                dataProvider.saveCustomer(customer, null).subscribe(
                  () => {
                    setSaved(true);
                    setSaving(false);
                  },
                  (err) => {
                    console.error(err);
                    console.error("error import customer");

                    setSaving(false);

                    const errorMessage = getErrorMessageFromError(
                      err,
                      "An unknown error occurred while saving."
                    );
                    setErrorMessage(errorMessage);
                  }
                );
              }}
            >
              <div className="form-group">
                <label htmlFor="name" className="required">
                  Name
                </label>
                <input
                  disabled={previouslySaved || saved}
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  className="form-control"
                  required={true}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </div>
              <AddressComponents2
                disabled={previouslySaved || saved}
                streetAndNumberId={`qbcustomerid_${quickBooksCustomer.id}`}
                streetAndNumberName={`qbcustomerid_${quickBooksCustomer.id}`}
                value={address}
                onChange={(address) => {
                  setAddress(address);
                }}
                streetAndNumberRef={streetAndNumberRef}
                showApartmentSuite
              />

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="taxExempt"
                    checked={taxExempt}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setTaxExempt(value);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="taxExempt">
                    Tax-exempt
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Phone</label>
                <PhoneNumberField
                  disabled={previouslySaved || saved}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  className="form-control"
                  onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="alternativePhoneNumber">
                  Alternative phone
                </label>
                <PhoneNumberField
                  disabled={previouslySaved || saved}
                  id="alternativePhoneNumber"
                  name="alternativePhoneNumber"
                  value={alternativePhoneNumber}
                  className="form-control"
                  onChange={(e) =>
                    setAlternativePhoneNumber(e.currentTarget.value)
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="emailAddress">Email addresses</label>

                <EmailAddresses
                  label={"Email address"}
                  emailAddresses={emailAddresses}
                  onChange={(newValue) => setEmailAddresses(newValue)}
                  required={false}
                  disabled={previouslySaved || saved}
                />
              </div>

              {!!errorMessage ? (
                <div className="text-danger" style={{ whiteSpace: "pre-line" }}>
                  {errorMessage}
                </div>
              ) : null}
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={saving || saved || previouslySaved}
                >
                  {previouslySaved
                    ? "Previously Saved"
                    : saved
                    ? "Saved"
                    : "Add Customer"}
                </button>
                {saving ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    fixedWidth={true}
                    size="2x"
                    className="text-primary"
                  />
                ) : null}
              </div>
            </form>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};
