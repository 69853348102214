import React, { useCallback, useState } from "react";
import SubscriptionWarning from "../../../slices/tenantSubscription/components/SubscriptionWarning";
import { ITodoItemResponse } from "../../../models/ITodoItemResponse";
import { ICustomCrewQuestionResponse } from "../../../models/ICustomCrewQuestionResponse";
import { IFormDataFile } from "../components/files/ExistingFile";
import { ITimeRange } from "../../../models/ITimeRange";
import FormContainerWithoutRedux from "../components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { TimeRanges } from "../components/TimeRanges";
import TextareaAutosize from "react-autosize-textarea/lib";
import Files from "../components/files/Index";
import remoteDataProvider from "../../../services/remoteDataProvider";
import {
  Controller,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { isPhoto } from "../../../services/fileService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { getTodoItems } from "../../../services/todoService";
import uuidv4 from "uuid/v4";
import { ICrew } from "../../../models/ICrew";
import jobFinder, { IFoundJob } from "../../../services/jobFinder";
import { ICrewCategory } from "../../../models/ICrewCategory";
import { ITodoTemplate } from "../../../models/ITodoTemplate";
import { ITodoItem } from "../../../models/ITodoItem";
import { ICustomCrewQuestion } from "../../../models/ICustomCrewQuestion";
import { map } from "rxjs/operators";
import ModalDataLoader from "../components/ModalDataLoader";
import { actionCreators } from "../../../modules/actionCreators";
import { useDispatch } from "react-redux";
import { ErrorMessageType } from "../components/FormContainer";
import { forkJoin } from "rxjs";
import jobInstanceWorksheetsDataProvider from "../../../slices/worksheets/services/jobInstanceWorksheetsDataProvider";
import { CompleteFormWorksheets } from "./CompleteFormWorksheets";
import { IJobInstanceWorksheet } from "../../../slices/worksheets/models/IJobInstanceWorksheet";

interface ILoadData {
  todoItems: Array<ITodoItem>;
  todoTemplate: ITodoTemplate | null;
  customCrewQuestions: {
    id: string | null;
    text: string;
  }[];
  formData: IFormData;
  defaultActualCrewMembers: number;
  worksheets: Array<IJobInstanceWorksheet>;
}

export interface IProps {
  jobId: string;
  onSaveComplete: () => void;
  onCancel: () => void;
}

interface IFormData {
  notesFromCrew: string;
  todoItemResponses: Array<ITodoItemResponse>;
  customCrewQuestionResponses: Array<ICustomCrewQuestionResponse>;
  photosFromCrew: Array<IFormDataFile>;
  completed: boolean;
  skipped: boolean;
  timeRanges: Array<ITimeRange>;
  actualCrewMembers: number | null;
}

const CompleteForm: React.FunctionComponent<IProps> = ({
  jobId,
  onSaveComplete,
  onCancel,
}) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const [fileUploading, setFileUploading] = useState(false);
  const [todoItems, setTodoItems] = useState<Array<ITodoItem>>([]);
  const [customCrewQuestions, setCustomCrewQuestions] = useState<
    {
      id: string | null;
      text: string;
    }[]
  >([]);
  const [todoTemplate, setTodoTemplate] = useState<ITodoTemplate | null>(null);
  const [defaultActualCrewMembers, setDefaultActualCrewMembers] = useState(0);
  const [worksheets, setWorksheets] = useState<Array<IJobInstanceWorksheet>>(
    []
  );
  const [originalFormData, setOriginalFormData] = useState<IFormData | null>(
    null
  );

  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);

  const dispatch = useDispatch();

  const { control, setValue, getValues, watch } = useForm<IFormData>({
    defaultValues: {
      notesFromCrew: "",
      todoItemResponses: [],
      customCrewQuestionResponses: [],
      photosFromCrew: [],
      completed: false,
      skipped: false,
      timeRanges: [],
      actualCrewMembers: null,
    },
  });

  const customCrewQuestionResponses = watch("customCrewQuestionResponses");
  const todoItemResponses = watch("todoItemResponses");
  const completed = watch("completed");
  const skipped = watch("skipped");

  const loadData = useGetLoadDataFunction({ jobId });

  const handleLoadedData = useGetHandleLoadedDataFunction({
    setValue,
    setCustomCrewQuestions,
    setTodoItems,
    setTodoTemplate,
    setDefaultActualCrewMembers,
    setOriginalFormData,
    getValues,
    setWorksheets,
  });

  return (
    <>
      <ModalDataLoader<ILoadData>
        errorMessage={
          "The data could not be loaded for this form. Please check your Internet connection and try again."
        }
        onErrorAlertClose={onCancel}
        loadData={loadData}
        onDataLoaded={handleLoadedData}
      >
        <SubscriptionWarning
          mode="alwaysLockedWhenNotSubscribed"
          onCancel={() => {
            onCancel();
          }}
        >
          <FormContainerWithoutRedux
            size="lg"
            formHeader="Update Completion Information"
            formType={FormTypesV2.updateJobCompletionInformation}
            save={() => {
              return remoteDataProvider.markComplete(getFormData(getValues()), {
                jobInstanceId: jobId,
              });
            }}
            onSaveComplete={(result) => {
              dispatch(
                actionCreators.jobInstanceUpdateCompletionInfo(jobId, result)
              );
              onSaveComplete();
            }}
            onCancel={onCancel}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            validate={() => {
              const result = { valid: true, errorMessage: "" };

              if (fileUploading) {
                result.valid = false;
                result.errorMessage =
                  "Please wait until all photos are uploaded";
              }

              return result;
            }}
            hasFormDataChanged={() => {
              return (
                JSON.stringify(getValues()) !== JSON.stringify(originalFormData)
              );
            }}
          >
            <h5>Time Worked</h5>
            <div className="form-section">
              <Controller
                name={`timeRanges`}
                control={control}
                render={({ field }) => (
                  <TimeRanges
                    timeRanges={field.value}
                    defaultActualCrewMembers={defaultActualCrewMembers}
                    onTimeRangesChanged={(timeRanges) => {
                      field.onChange(timeRanges);
                    }}
                  />
                )}
              />
              <div className="form-group d-flex">
                <div className="custom-control custom-checkbox mr-5">
                  <Controller
                    name={`completed`}
                    control={control}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="completed"
                        name="completed"
                        checked={field.value}
                        onChange={(e) => {
                          const value = e.target.checked;
                          field.onChange(value);
                          setValue("skipped", value ? false : skipped);
                        }}
                      />
                    )}
                  />
                  <label className="custom-control-label" htmlFor="completed">
                    Completed
                  </label>
                </div>

                <div className="custom-control custom-checkbox">
                  <Controller
                    name={`skipped`}
                    control={control}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="skipped"
                        name="skipped"
                        checked={field.value}
                        disabled={completed}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                  <label className="custom-control-label" htmlFor="skipped">
                    Skipped
                  </label>
                </div>
              </div>
            </div>
            <h5>Job Details</h5>
            <div className="form-section">
              <CompleteFormWorksheets
                jobInstanceId={jobId}
                worksheets={worksheets}
                onCompletedChange={({
                  newCompletedValue,
                  worksheetForJobInstanceId,
                }) => {
                  setWorksheets((worksheetsForUpdate) =>
                    worksheetsForUpdate.map((worksheet) => {
                      if (worksheet.id === worksheetForJobInstanceId) {
                        return {
                          ...worksheet,
                          completed: newCompletedValue,
                        };
                      } else {
                        return worksheet;
                      }
                    })
                  );
                }}
              />

              {todoItems.length > 0 ? (
                <div className="form-group">
                  <label>
                    Checklist
                    {!!todoTemplate ? (
                      <React.Fragment> - {todoTemplate.name}</React.Fragment>
                    ) : null}
                  </label>
                  {todoItems.map((todoItem) => (
                    <div
                      className="custom-control custom-checkbox"
                      key={todoItem.id as string}
                    >
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={todoItem.id + "_CompleteForm_cb"}
                        checked={isItemChecked(
                          todoItem.id as string,
                          todoItemResponses
                        )}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const newResponses = todoItemResponses.map((t) => {
                            if (t.id === todoItem.id) {
                              return {
                                ...t,
                                completed: checked,
                              };
                            } else {
                              return t;
                            }
                          });

                          const foundResponse = todoItemResponses.find(
                            (r) => r.id === todoItem.id
                          );
                          if (!foundResponse) {
                            newResponses.push({
                              id: todoItem.id as string,
                              completed: checked,
                            });
                          }

                          setValue("todoItemResponses", newResponses);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={todoItem.id + "_CompleteForm_cb"}
                      >
                        {todoItem.text}
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="form-group">
                <label htmlFor="notesFromCrew">Notes</label>
                <Controller
                  name={`notesFromCrew`}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      maxRows={10}
                      className="form-control"
                      name="notesFromCrew"
                      id="notesFromCrew"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.currentTarget.value);
                      }}
                    />
                  )}
                />
              </div>

              {customCrewQuestions.map((q, index) => {
                const response = customCrewQuestionResponses.find(
                  (r) => r.customCrewQuestionId === q.id
                );
                const value = response ? response.answer : "";
                const questionText =
                  response && response.questionText
                    ? response.questionText
                    : q.text;
                return (
                  <div key={q.id || index} className="form-group">
                    <label htmlFor={q.id || index.toString()}>
                      {questionText}
                    </label>
                    <TextareaAutosize
                      maxRows={10}
                      className="form-control"
                      name={q.id || index.toString()}
                      id={q.id || index.toString()}
                      value={value}
                      onChange={(e) => {
                        const questionId = q.id as string;
                        const newAnswer = e.currentTarget.value;
                        const updatedResponses =
                          customCrewQuestionResponses.map((r) => ({ ...r }));

                        const existingResponse = updatedResponses.find(
                          (r) => r.customCrewQuestionId === questionId
                        );

                        if (existingResponse) {
                          existingResponse.questionText = questionText;
                          existingResponse.answer = newAnswer;
                        } else {
                          updatedResponses.push({
                            questionText,
                            answer: newAnswer,
                            customCrewQuestionId: questionId,
                          });
                        }

                        setValue(
                          "customCrewQuestionResponses",
                          updatedResponses
                        );
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <div className="form-group">
              <Controller
                name={`photosFromCrew`}
                control={control}
                render={({ field }) => (
                  <Files
                    header="Attachments"
                    showHeader
                    files={field.value}
                    tenantId={tenantId ?? ""}
                    imagePrefix={imagePrefix ?? ""}
                    onFileUploadingStatusChange={(hasPendingPhotoAdd) => {
                      setFileUploading(hasPendingPhotoAdd);
                    }}
                    onFileAdded={(photo) => {
                      field.onChange([...field.value, photo]);
                    }}
                    onFileRemoved={(photoId, imagePath) => {
                      field.onChange(
                        field.value.filter(
                          (p) => !isPhoto(photoId, imagePath, p)
                        )
                      );
                    }}
                    onFileUpdated={(photoId, imagePath, caption) => {
                      field.onChange(
                        field.value.map((p) => {
                          if (isPhoto(photoId, imagePath, p)) {
                            return {
                              ...p,
                              caption,
                            };
                          } else {
                            return p;
                          }
                        })
                      );
                    }}
                  />
                )}
              />
            </div>
          </FormContainerWithoutRedux>
        </SubscriptionWarning>
      </ModalDataLoader>
    </>
  );
};

function useGetLoadDataFunction({ jobId }: { jobId: string }) {
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const todoTemplates = useApplicationStateSelector(
    (s) => s.common.todoTemplates
  );
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const crewQuestions = useApplicationStateSelector(
    (s) => s.common.crewViewConfiguration.customCrewQuestions
  );

  return useCallback(() => {
    return forkJoin({
      jobInstanceResult: remoteDataProvider.getJobInstance(jobId),
      worksheets: jobInstanceWorksheetsDataProvider.getWorksheetsForJobInstance(
        {
          jobInstanceId: jobId,
        }
      ),
    }).pipe(
      map(({ jobInstanceResult, worksheets }) => {
        const { jobInstance, crewId } = jobInstanceResult;

        let defaultActualCrewMembers: number | null;

        let crewDefaultCrewMembers: number | null = null;
        const crew =
          crewId !== null ? crews.find((c) => c.id === crewId) : null;
        if (crew) {
          crewDefaultCrewMembers = crew.typicalCrewSize
            ? crew.typicalCrewSize
            : null;
        }

        defaultActualCrewMembers = jobInstance.actualCrewMembers
          ? jobInstance.actualCrewMembers
          : crewDefaultCrewMembers;

        let formData = {
          actualCrewMembers: defaultActualCrewMembers,
          completed: jobInstance.complete,
          skipped: jobInstance.skipped,
          notesFromCrew: jobInstance.notesFromCrew || "",
          todoItemResponses: jobInstance.todoItemResponses.map((t) => ({
            ...t,
          })),
          customCrewQuestionResponses:
            jobInstance.customCrewQuestionResponses.map((r) => ({
              ...r,
            })),
          photosFromCrew: jobInstance.photosFromCrew.map((p) => ({
            ...p,
            contentType: p.contentType,
            imagePath: p.imagePath,
            actualWidth: null,
            actualHeight: null,
          })),
          timeRanges: jobInstance.timeRanges.map((tr) => ({
            ...tr,
            actualCrewMembers: tr.actualCrewMembers ?? defaultActualCrewMembers,
          })),
        };

        if (formData.timeRanges.length === 0) {
          formData.timeRanges = [
            {
              id: uuidv4(),
              startTime: null,
              endTime: null,
              actualCrewMembers: defaultActualCrewMembers,
            },
          ];
        }

        const getTodoItemResult = getTodoItems(
          jobInstance,
          todoTemplates,
          maintenanceJobs,
          oneTimeJobs
        );

        const foundJob = jobFinder.getJobForDayScheduleV2(
          maintenanceJobs,
          oneTimeJobs,
          jobInstance
        );

        const baseCustomCrewQuestions = !!crew
          ? getApplicableCustomCrewQuestions(crew, foundJob, crewQuestions)
          : [];
        const questions = [
          ...baseCustomCrewQuestions.map((q) => ({
            id: q.id,
            text: q.question,
          })),
          ...jobInstance.customCrewQuestionResponses
            .filter(
              (r) =>
                !baseCustomCrewQuestions.some(
                  (q) => q.id === r.customCrewQuestionId
                )
            )
            .map((r) => ({
              id: r.customCrewQuestionId,
              text: r.questionText,
            })),
        ];

        return {
          todoItems: getTodoItemResult.todoItems,
          todoTemplate: getTodoItemResult.todoTemplate,
          customCrewQuestions: questions,
          formData: formData,
          defaultActualCrewMembers: defaultActualCrewMembers,
          worksheets,
        } as ILoadData;
      })
    );
  }, [
    jobId,
    oneTimeJobs,
    maintenanceJobs,
    crewQuestions,
    crews,
    todoTemplates,
  ]);
}

function useGetHandleLoadedDataFunction({
  setValue,
  setCustomCrewQuestions,
  setTodoItems,
  setTodoTemplate,
  setDefaultActualCrewMembers,
  setOriginalFormData,
  setWorksheets,
  getValues,
}: {
  setValue: UseFormSetValue<IFormData>;
  setCustomCrewQuestions: React.Dispatch<
    React.SetStateAction<{ id: string | null; text: string }[]>
  >;
  setTodoItems: React.Dispatch<React.SetStateAction<ITodoItem[]>>;
  setTodoTemplate: React.Dispatch<React.SetStateAction<ITodoTemplate | null>>;
  setDefaultActualCrewMembers: React.Dispatch<React.SetStateAction<number>>;
  setOriginalFormData: React.Dispatch<React.SetStateAction<IFormData | null>>;
  setWorksheets: React.Dispatch<
    React.SetStateAction<Array<IJobInstanceWorksheet>>
  >;
  getValues: UseFormGetValues<IFormData>;
}) {
  return useCallback(
    (result: ILoadData) => {
      setValue("actualCrewMembers", result.formData.actualCrewMembers);
      setValue("completed", result.formData.completed);
      setValue(
        "customCrewQuestionResponses",
        result.formData.customCrewQuestionResponses
      );
      setValue("notesFromCrew", result.formData.notesFromCrew);
      setValue("photosFromCrew", result.formData.photosFromCrew);
      setValue("skipped", result.formData.skipped);
      setValue("timeRanges", result.formData.timeRanges);
      setValue("todoItemResponses", result.formData.todoItemResponses);

      setCustomCrewQuestions(result.customCrewQuestions);
      setTodoItems(result.todoItems);
      setTodoTemplate(result.todoTemplate);
      setDefaultActualCrewMembers(result.defaultActualCrewMembers);
      setOriginalFormData(getValues());
      setWorksheets(result.worksheets);
    },
    [
      setValue,
      getValues,
      setCustomCrewQuestions,
      setTodoItems,
      setTodoTemplate,
      setDefaultActualCrewMembers,
      setOriginalFormData,
      setWorksheets,
    ]
  );
}

function isItemChecked(
  todoItemId: string,
  todoResponses: Array<ITodoItemResponse>
) {
  const response = todoResponses?.find((r) => r.id === todoItemId);
  if (response) {
    return response.completed;
  }

  return false;
}

function getFormData(formData: IFormData) {
  return {
    ...formData,
    timeRanges: formData.timeRanges.filter(
      (tr) => tr.startTime || tr.endTime || tr.actualCrewMembers
    ),
  };
}

export default CompleteForm;

function getApplicableCustomCrewQuestions(
  crew: ICrew,
  foundJob: IFoundJob | null,
  customCrewQuestions: ICustomCrewQuestion[]
) {
  return customCrewQuestions.filter((q) => {
    if (q.crewCategory) {
      const questionCategory = q.crewCategory as ICrewCategory;

      const doesCrewHaveCategory = crew.crewCategories.some(
        (c) => c.id === questionCategory.id
      );

      let doesJobHaveCategory = false;
      if (foundJob) {
        doesJobHaveCategory = foundJob.categories.some(
          (c) => c.id === questionCategory.id
        );
      }

      return doesCrewHaveCategory || doesJobHaveCategory;
    } else {
      return true;
    }
  });
}
