import { Modal, ModalBody } from "reactstrap/lib";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useEffect } from "react";
import accountManagementDataProvider from "../services/accountManagementDataProvider";
import { useDispatch } from "react-redux";
import { commonUiActionCreators } from "../../../modules/commonUi";

export function TermsOfServiceUpdatedModal() {
  const showTermsOfServiceUpdatedModal = useApplicationStateSelector(
    (s) => s.commonUi.showTermsOfServiceUpdatedModal
  );

  return showTermsOfServiceUpdatedModal ? (
    <TermsOfServiceUpdatedModalBody />
  ) : null;
}

function TermsOfServiceUpdatedModalBody() {
  useLogUpdatedTermsOfServiceViewed();

  const dispatch = useDispatch();

  return (
    <Modal isOpen size="lg">
      <ModalBody>
        <div className="d-flex justify-content-center">
          <img src="/homepage_logo_sm.png" alt="Crew Control logo" />
        </div>

        <div className="my-3 d-flex justify-content-center bg-warning text-white p-2">
          <h5>The Terms of Service and Privacy Policy has changed!</h5>
        </div>

        <div className="my-2">
          Please review the updated links below. These links are accessible at
          the bottom of Crew Control pages.
        </div>

        <ul>
          <li>
            Updated Crew Control{" "}
            <a
              href="https://www.youraspire.com/legal/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </li>
          <li>
            Updated Crew Control{" "}
            <a
              href="https://www.youraspire.com/privacy-statement"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </li>
        </ul>

        <button
          type="button"
          data-testid="getStartedButton"
          className="btn btn-block btn-primary branded-public-buttons"
          onClick={() => {
            dispatch(commonUiActionCreators.closeTermsOfServiceUpdatedModal());
          }}
        >
          Close
        </button>
      </ModalBody>
    </Modal>
  );
}
function useLogUpdatedTermsOfServiceViewed() {
  useEffect(() => {
    accountManagementDataProvider.logUpdatedTermsOfServiceViewed().subscribe({
      next: () => {},

      error: () => {},
    });
  }, []);
}
