import React, { useEffect, useRef, useState } from "react";
import { DateFilterOptions } from "../../../enums/dateFilterOptions";
import { SortDirection } from "../../../enums/sortDirection";
import { useSortColumn } from "../../../hooks/useSortColumn";
import { IInvoiceView } from "../../../models/IInvoice";
import dateService from "../../../services/dateService";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { getSortedItemsV2 } from "../../../services/sortingService";
import {
  getDefaultFilters as getInvoiceDateFilters,
  updateDatesOnFilter,
} from "../../../services/dateFilterService";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import BillingInvoiceListActionButtons from "./BillingInvoiceListActionButtons";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { debounceTime, finalize, timeout } from "rxjs/operators";
import { downloadBlob } from "../../../services/csvDownloadService";
import { Subject, Subscription } from "rxjs";
import BillingInvoiceListPaymentsDetails from "./BillingInvoiceListPaymentsDetails";
import BillingInvoicesListDate from "./BillingInvoicesListDate";
import ServerLoadedList from "../../../libraries/tableLayout/ServerLoadedList";
import { formatCurrency } from "../../../services/currencyFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DateFilterHeadless, {
  CustomDateElements,
} from "../../../containers/app/components/DateFilterHeadless";
import BillingHeaderField from "./BillingHeaderField";
import useSalesEnabled from "../../sales/hooks/useSalesEnabled";
import BillingListCustomerPopover from "./BillingListCustomerPopover";
import { InvoiceListSortColumns } from "../../../enums/invoiceListSortColumns";
import { InvoiceListType } from "../../../enums/invoiceListType";
import { TableColumns } from "../../../libraries/tableLayout/TableColumn";
import constants from "../../../constants";
import { useIsResolution } from "../../../hooks/useIsResolution";
import { isStringSet } from "../../../services/stringService";

interface IProps {
  listType: InvoiceListType;
}

interface IDateFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

const BillingInvoicesList: React.FunctionComponent<IProps> = ({ listType }) => {
  const settingsType = getUserSettingsType(listType);
  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );

  const { getUserSettings } = useUserSettings();
  const [errorLoading, setErrorLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [invoiceDateFilters, setInvoiceDateFilters] = useState<IDateFilters>(
    settingsType
      ? getInvoiceDateFilters(getUserSettings, settingsType)
      : ({} as IDateFilters)
  );
  const [datePaidFilters, setDatePaidFilters] = useState<IDateFilters>({
    frequency: DateFilterOptions.all,
    startingDate: null,
    endingDate: null,
  });
  const {
    immediateState: searchFilter,
    setter: setSearchFilter,
    debouncedState: debouncedSearchFilter,
  } = useDebounceState("");
  const [showPastDueOnly, setShowPastDueOnly] = useState(false);
  const [invoices, setInvoices] = useState<Array<IInvoiceView> | null>(null);
  const [filterError, setFilterError] = useState<string>("");
  const formRef = useRef<HTMLFormElement>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(false);

  const { currentSortColumn, currentSortDirection, getSortColumn } =
    useSortColumn<InvoiceListSortColumns>(
      InvoiceListSortColumns.date,
      SortDirection.Descending
    );

  const tableBreakpoint = listType === InvoiceListType.paid ? "lg" : "xl";
  const isDesktopView = useIsResolution(tableBreakpoint);

  let sortColumn = currentSortColumn;
  let sortDirection = currentSortDirection;
  if (!isDesktopView) {
    sortColumn = InvoiceListSortColumns.date;
    sortDirection = SortDirection.Descending;
  }

  // Refresh data after invoice payment saves
  useRefreshListOnFormSave({
    listType,
    setLoadingData,
    setErrorLoading,
    invoiceDateFilters,
    datePaidFilters,
    searchFilter,
    showPastDueOnly,
    setInvoices,
    setTotalAmount,
    setHasMoreResults,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
  });

  useEffect(() => {
    let subscription = loadData({
      listType,
      setLoadingData,
      setErrorLoading,
      invoiceDateFilters,
      datePaidFilters,
      searchFilter: debouncedSearchFilter,
      showPastDueOnly,
      setInvoices,
      setTotalAmount,
      setHasMoreResults,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [
    listType,
    debouncedSearchFilter,
    invoiceDateFilters,
    datePaidFilters,
    sortColumn,
    sortDirection,
    showPastDueOnly,
  ]);
  const columns = useGetColumns({
    listType,
    getSortColumn,
    setLoadingData,
    setErrorLoading,
    invoiceDateFilters,
    datePaidFilters,
    searchFilter,
    showPastDueOnly,
    setInvoices,
    setTotalAmount,
    setHasMoreResults,
    sortColumn,
    sortDirection,
    customerUrlRoot,
  });
  const { setUserSettings } = useUserSettings();

  const filterBreakpoint = "sm";
  const hasFilterError = filterError !== "";

  return (
    <>
      {listType !== InvoiceListType.notSynced &&
      typeof totalAmount === "number" ? (
        <div className={`my-2`}>
          <BillingHeaderField
            label="Total amount"
            value={formatCurrency(totalAmount)}
            valueTestId="totalAmountValue"
          />
        </div>
      ) : null}
      <ServerLoadedList<IInvoiceView>
        header={null}
        dataType="invoices"
        breakpoint={tableBreakpoint}
        tableTestId="BillingInvoicesList"
        errorLoading={errorLoading}
        loadingData={loadingData}
        data={invoices}
        showContentWhileRefreshing={true}
        refreshData={() =>
          loadData({
            listType,
            setLoadingData,
            setErrorLoading,
            invoiceDateFilters,
            datePaidFilters,
            searchFilter,
            showPastDueOnly,
            setInvoices,
            setTotalAmount,
            setHasMoreResults,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          })
        }
        hasFilterError={hasFilterError}
        responsiveTableContainerStyle={{ paddingBottom: "75px" }}
        filter={
          <>
            {listType !== InvoiceListType.notSynced ? (
              <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                <div
                  className={`d-block d-${filterBreakpoint}-flex justify-content-between align-items-baseline`}
                  style={{ columnGap: constants.listFilterGap }}
                >
                  <div
                    className={`d-flex flex-fill align-items-baseline flex-wrap`}
                    style={{ columnGap: constants.listFilterGap }}
                  >
                    <DateFilterHeadless
                      label="Invoice date range"
                      filters={invoiceDateFilters}
                      onFiltersChanged={(newFilters) => {
                        newFilters = updateDatesOnFilter(newFilters);
                        if (settingsType) {
                          setUserSettings(settingsType, newFilters);
                        }

                        setInvoiceDateFilters({
                          ...invoiceDateFilters,
                          ...newFilters,
                        });
                      }}
                      showAllDates={true}
                      setErrorMessage={(m) => setFilterError(m)}
                      idPrefix="invoiceDate"
                    >
                      {({ dateRangeElements, customDateElements }) => (
                        <DateRangePresentation
                          dateRangeElements={dateRangeElements}
                          customDateElements={customDateElements}
                        />
                      )}
                    </DateFilterHeadless>

                    {listType === InvoiceListType.paid ? (
                      <DateFilterHeadless
                        label="Date paid range"
                        filters={datePaidFilters}
                        onFiltersChanged={(newFilters) => {
                          newFilters = updateDatesOnFilter(newFilters);

                          setDatePaidFilters({
                            ...invoiceDateFilters,
                            ...newFilters,
                          });
                        }}
                        showAllDates={true}
                        setErrorMessage={(m) => setFilterError(m)}
                        idPrefix="datePaid"
                      >
                        {({ dateRangeElements, customDateElements }) => (
                          <DateRangePresentation
                            dateRangeElements={dateRangeElements}
                            customDateElements={customDateElements}
                          />
                        )}
                      </DateFilterHeadless>
                    ) : null}

                    <div
                      className="flex-fill"
                      style={{ maxWidth: constants.listFilterMaxWidth }}
                    >
                      <div style={{ display: "inline-block", width: "100%" }}>
                        <label htmlFor={`billingWorkInvoicesSearchField`}>
                          Search
                        </label>
                        <div className="input-group">
                          <input
                            value={searchFilter}
                            onChange={(e) =>
                              setSearchFilter(e.currentTarget.value)
                            }
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            id={"billingWorkInvoicesSearchField"}
                          />
                          <button
                            className="btn bg-transparent"
                            type="button"
                            style={{ marginLeft: "-40px", zIndex: 3 }}
                            onClick={() => setSearchFilter("")}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    </div>

                    {listType === InvoiceListType.unpaid ? (
                      <div className="custom-control custom-checkbox mt-2 mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="showPastDueOnly"
                          checked={showPastDueOnly}
                          onChange={(e) => setShowPastDueOnly(e.target.checked)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="showPastDueOnly"
                        >
                          Show past due only
                        </label>
                      </div>
                    ) : null}
                  </div>
                  <div className={`text-nowrap mt-3 mt-${filterBreakpoint}-0`}>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => {
                        if (!isFormValid(formRef, filterError)) {
                          return;
                        }

                        setLoadingData(true);
                        setErrorLoading(false);
                        remoteDataProvider
                          .downloadInvoiceCsv(
                            invoiceDateFilters.startingDate,
                            invoiceDateFilters.endingDate,
                            getPaidFilterValue(listType) ?? false,
                            searchFilter,
                            showPastDueOnly,
                            datePaidFilters.startingDate,
                            datePaidFilters.endingDate
                          )
                          .pipe(
                            timeout(20000),
                            finalize(() => setLoadingData(false))
                          )
                          .subscribe(
                            (blob) => {
                              downloadBlob(blob, "BillingInvoice.csv");
                            },
                            () => setErrorLoading(true)
                          );
                      }}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
                {filterError && (
                  <div>
                    <div className="text-danger">{filterError}</div>
                  </div>
                )}
              </form>
            ) : null}
          </>
        }
        columns={columns}
        hasMoreResults={hasMoreResults}
      />
    </>
  );
};

export default BillingInvoicesList;

function DateRangePresentation({
  dateRangeElements,
  customDateElements,
}: {
  dateRangeElements: React.ReactNode;
  customDateElements: CustomDateElements;
}) {
  return (
    <React.Fragment>
      <div
        style={{ maxWidth: constants.listFilterMaxWidth }}
        className="flex-fill"
      >
        <div
          style={{
            display: "inline-block",
            width: "100%",
          }}
        >
          <div className="form-group">{dateRangeElements}</div>
        </div>
        {customDateElements !== null ? (
          <div
            className="d-flex"
            style={{ columnGap: constants.listFilterGap }}
          >
            <div className="form-group flex-fill">
              {customDateElements.startingDateElement}
            </div>
            <div className="form-group flex-fill">
              {customDateElements.endingDateElement}
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

function getUserSettingsType(listType: InvoiceListType) {
  switch (listType) {
    case InvoiceListType.paid:
      return UserSettingsType.paidInvoiceFilters;
    case InvoiceListType.unpaid:
      return UserSettingsType.openInvoiceFilters;
  }
}

function getPaidFilterValue(listType: InvoiceListType) {
  switch (listType) {
    case InvoiceListType.paid:
      return true;
    case InvoiceListType.unpaid:
      return false;
    case InvoiceListType.notSynced:
      return null;
  }
}

function useGetColumns({
  listType,
  getSortColumn,
  setLoadingData,
  setErrorLoading,
  invoiceDateFilters,
  datePaidFilters,
  searchFilter,
  showPastDueOnly,
  setInvoices,
  setTotalAmount,
  setHasMoreResults,
  sortColumn,
  sortDirection,
  customerUrlRoot,
}: {
  listType: InvoiceListType;
  getSortColumn: (
    displayName: string,
    sortColumn: InvoiceListSortColumns
  ) => JSX.Element;
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceDateFilters: IDateFilters;
  datePaidFilters: IDateFilters;
  searchFilter: string;
  showPastDueOnly: boolean;
  setInvoices: React.Dispatch<React.SetStateAction<IInvoiceView[] | null>>;
  setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
  setHasMoreResults: React.Dispatch<React.SetStateAction<boolean>>;
  sortColumn: InvoiceListSortColumns | null;
  sortDirection: SortDirection;
  customerUrlRoot: string;
}) {
  const isSalesEnabled = useSalesEnabled();

  const columns: TableColumns<IInvoiceView> = [
    {
      key: "date",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Invoice date", InvoiceListSortColumns.date)
          : "Invoice date",
      cell: ({ row: invoice }) => (
        <div className="pr-4">
          <BillingInvoicesListDate invoice={invoice} />
        </div>
      ),
      testId: "invoiceDate",
      width: "0",
      headerClassName: "text-nowrap",
      cellClassName: "text-nowrap",
    },
    {
      key: "customer",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Customer", InvoiceListSortColumns.customer)
          : "Customer",
      cell: ({ row: invoice, displayType }) => (
        <BillingListCustomerPopover
          recordId={invoice.id}
          customerId={invoice.customerId}
          customerName={invoice.customerName}
          customerAddress={invoice.customerAddress}
          customerPhoneNumber={invoice.customerPhoneNumber}
          customerAlternativePhoneNumber={
            invoice.customerAlternativePhoneNumber
          }
          idPrefix={displayType}
        />
      ),
      testId: "invoiceCustomer",
    },
  ];

  columns.push({
    key: "invoiceNumber",
    header: ({ displayType }) =>
      displayType === "desktop"
        ? getSortColumn("Invoice #", InvoiceListSortColumns.invoiceNumber)
        : "Invoice #",
    cell: ({ row: invoice }) => invoice.invoiceNumber,
    testId: "invoiceNumber",
  });

  if (isSalesEnabled && listType !== InvoiceListType.notSynced) {
    columns.push({
      key: "proposalNumbers",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Estimate #", InvoiceListSortColumns.proposalNumbers)
          : "Estimate #",
      cell: ({ row: t }) => {
        return t.proposalNumbersV3 &&
          t.proposalNumbersV3.filter((n) => isStringSet(n.proposalNumber))
            .length > 0
          ? t.proposalNumbersV3.map((details, index) => {
              const number = details.proposalNumber.trim();
              let proposalLookupId = details.proposalLookupId ?? null;
              const initialSpan = <>{index > 0 ? <span>, </span> : null}</>;
              if (proposalLookupId) {
                return (
                  <React.Fragment key={details.proposalLookupId}>
                    {initialSpan}
                    <a
                      id={`${t.invoiceNumber}_${number}`}
                      data-testid={`${t.invoiceNumber}_${number}`}
                      href={`${customerUrlRoot}/proposal/${proposalLookupId}?ref=admin`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      {number ?? ""}
                    </a>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={details.proposalLookupId}>
                    {initialSpan} <span>{number}</span>
                  </React.Fragment>
                );
              }
            })
          : null;
      },
      testId: "proposalNumbers",
    });
  }

  if (listType === InvoiceListType.unpaid) {
    columns.push({
      key: "dueDate",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Due date", InvoiceListSortColumns.dueDate)
          : "Due date",
      cell: ({ row: t }) =>
        t.dueDate ? dateService.formatDateForDisplay(t.dueDate) : "",
      testId: "invoiceDueDate",
    });

    columns.push({
      key: "lastSentDateTime",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Last sent", InvoiceListSortColumns.lastSentDateTime)
          : "Last sent",
      cell: ({ row: t }) =>
        t.lastSentDateTime || t.sent ? (
          <div className="pr-4">
            {t.lastSentDateTime
              ? dateService.formatDateTimeForDateTimeDisplay(t.lastSentDateTime)
              : "Sent before 10/25/2022"}
          </div>
        ) : null,
      testId: "lastSentDateTime",
      width: "0",
      headerClassName: "text-nowrap",
      cellClassName: "text-nowrap",
    });

    columns.push({
      key: "lastViewedDateTime",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn(
              "Last viewed",
              InvoiceListSortColumns.lastViewedDateTime
            )
          : "Last viewed",
      cell: ({ row: t }) =>
        t.lastViewedDateTime ? (
          <div className="pr-4">
            {dateService.formatDateTimeForDateTimeDisplay(t.lastViewedDateTime)}
          </div>
        ) : null,
      testId: "lastViewedDateTime",
      width: "0",
      headerClassName: "text-nowrap",
      cellClassName: "text-nowrap",
    });
  }

  if (
    listType === InvoiceListType.paid ||
    listType === InvoiceListType.notSynced
  ) {
    columns.push({
      key: "datePaid",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Date paid", InvoiceListSortColumns.datePaid)
          : "Date paid",
      cell: ({ row: t }) =>
        t.datePaid ? dateService.formatDateForDisplay(t.datePaid) : "",
      testId: "invoiceDatePaid",
    });

    columns.push({
      key: "paymentDetails",
      header: "Payment details",
      cell: ({ row: invoice }) =>
        invoice.payments.length > 0 ? (
          <BillingInvoiceListPaymentsDetails invoice={invoice} />
        ) : null,
      testId: "paymentDetails",
    });
  }

  columns.push({
    key: "totalAmount",
    header: ({ displayType }) =>
      displayType === "desktop"
        ? getSortColumn("Total amount", InvoiceListSortColumns.totalAmount)
        : "Total amount",
    cell: ({ row: t }) => formatCurrency(t.totalAmount),
    headerClassName: "text-right",
    cellClassName: "text-right",
    testId: "invoiceTotalAmount",
  });

  if (listType === InvoiceListType.unpaid) {
    columns.push({
      key: "balance",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Balance", InvoiceListSortColumns.balance)
          : "Balance",
      cell: ({ row: t }) => formatCurrency(t.balance),
      headerClassName: "text-right",
      cellClassName: "text-right",
      testId: "invoiceBalance",
    });
  }

  columns.push({
    key: "actionButtons",
    header: <React.Fragment />,
    isButtonCell: true,
    cell: ({ row: invoice, displayType }) => (
      <div className={displayType === "desktop" ? "text-right" : undefined}>
        <BillingInvoiceListActionButtons
          invoice={invoice}
          listType={listType}
          onDataChanged={() => {
            loadData({
              listType,
              setLoadingData,
              setErrorLoading,
              invoiceDateFilters,
              datePaidFilters,
              searchFilter,
              setInvoices,
              setTotalAmount,
              setHasMoreResults,
              sortColumn: sortColumn,
              sortDirection: sortDirection,
              showPastDueOnly,
            });
          }}
        />
      </div>
    ),
  });
  return columns;
}

function useRefreshListOnFormSave({
  listType,
  setLoadingData,
  setErrorLoading,
  invoiceDateFilters,
  datePaidFilters,
  searchFilter,
  showPastDueOnly,
  setInvoices,
  setTotalAmount,
  setHasMoreResults,
  sortColumn,
  sortDirection,
}: {
  listType: InvoiceListType;
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceDateFilters: IDateFilters;
  datePaidFilters: IDateFilters;
  searchFilter: string;
  showPastDueOnly: boolean;
  setInvoices: React.Dispatch<React.SetStateAction<IInvoiceView[] | null>>;
  setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
  setHasMoreResults: React.Dispatch<React.SetStateAction<boolean>>;
  sortColumn: InvoiceListSortColumns | null;
  sortDirection: SortDirection;
}) {
  const invoiceRetryChargeFormSaveCount = useApplicationStateSelector(
    (s) => s.forms.invoiceRetryCharge.saveCount
  );
  const lastInvoiceRetryChargeFormSaveCount = useRef(
    invoiceRetryChargeFormSaveCount
  );

  const invoiceSaveCount = useApplicationStateSelector(
    (s) => s.forms.invoice.saveCount
  );
  const lastInvoiceFormSaveCount = useRef(invoiceSaveCount);

  useEffect(() => {
    let subscription: Subscription | null;

    if (
      invoiceRetryChargeFormSaveCount !==
        lastInvoiceRetryChargeFormSaveCount.current ||
      invoiceSaveCount !== lastInvoiceFormSaveCount.current
    ) {
      subscription = loadData({
        listType,
        setLoadingData,
        setErrorLoading,
        invoiceDateFilters,
        datePaidFilters,
        searchFilter,
        showPastDueOnly,
        setInvoices,
        setTotalAmount,
        setHasMoreResults,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      });

      lastInvoiceRetryChargeFormSaveCount.current =
        invoiceRetryChargeFormSaveCount;
      lastInvoiceFormSaveCount.current = invoiceSaveCount;
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [
    invoiceRetryChargeFormSaveCount,
    invoiceSaveCount,
    invoiceDateFilters,
    datePaidFilters,
    searchFilter,
    showPastDueOnly,
    listType,
    setErrorLoading,
    setInvoices,
    setLoadingData,
    setTotalAmount,
    setHasMoreResults,
    sortColumn,
    sortDirection,
  ]);
}

function loadData({
  listType,
  setLoadingData,
  setErrorLoading,
  invoiceDateFilters,
  datePaidFilters,
  searchFilter,
  showPastDueOnly,
  setInvoices,
  setTotalAmount,
  setHasMoreResults,
  sortColumn,
  sortDirection,
}: {
  listType: InvoiceListType;
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceDateFilters: IDateFilters;
  datePaidFilters: IDateFilters;
  searchFilter: string;
  showPastDueOnly: boolean;
  setInvoices: React.Dispatch<React.SetStateAction<IInvoiceView[] | null>>;
  setTotalAmount: React.Dispatch<React.SetStateAction<number>>;
  setHasMoreResults: React.Dispatch<React.SetStateAction<boolean>>;
  sortColumn: InvoiceListSortColumns | null;
  sortDirection: SortDirection;
}) {
  setLoadingData(true);
  setErrorLoading(false);

  let paidFilter = null;

  switch (listType) {
    case InvoiceListType.paid:
      paidFilter = true;
      break;
    case InvoiceListType.unpaid:
      paidFilter = false;
      break;
  }

  let result = remoteDataProvider
    .getInvoices({
      top: 200,
      startingDate: invoiceDateFilters.startingDate,
      endingDate: invoiceDateFilters.endingDate,
      datePaidStartingDate: datePaidFilters.startingDate,
      datePaidEndingDate: datePaidFilters.endingDate,
      paid: paidFilter,
      searchTerm: searchFilter,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      notSyncedToQuickBooks: listType === InvoiceListType.notSynced,
      showPastDueOnly,
    })
    .subscribe(
      (r) => {
        setInvoices(r.list);
        setTotalAmount(r.totalAmount);
        setHasMoreResults(r.hasMoreResults);
        setLoadingData(false);
      },
      () => {
        setErrorLoading(true);
        setLoadingData(false);
      }
    );

  return result;
}

function isFormValid(
  formRef: React.RefObject<HTMLFormElement>,
  filterError: string | null
) {
  if (formRef.current) {
    formRef.current.reportValidity();
    if (!formRef.current.checkValidity()) {
      return false;
    }
  }

  if (filterError) {
    return false;
  }

  return true;
}

export function getSortedInvoices(
  invoices: Array<IInvoiceView>,
  sortColumn: InvoiceListSortColumns | null,
  sortDirection: SortDirection
) {
  if (sortColumn === null) {
    return invoices;
  }

  let sortProperties: Array<
    keyof IInvoiceView | ((i: IInvoiceView) => string | number)
  >;
  switch (sortColumn) {
    case InvoiceListSortColumns.totalAmount:
      sortProperties = ["totalAmount"];
      break;
    case InvoiceListSortColumns.taxAmount:
      sortProperties = ["taxAmount"];
      break;
    case InvoiceListSortColumns.customer:
      sortProperties = ["customerName"];
      break;
    case InvoiceListSortColumns.date:
      sortProperties = ["date"];
      break;
    case InvoiceListSortColumns.lastSentDateTime:
      sortProperties = ["lastSentDateTime"];
      break;
    case InvoiceListSortColumns.lastViewedDateTime:
      sortProperties = ["lastViewedDateTime"];
      break;
    case InvoiceListSortColumns.invoiceNumber:
      sortProperties = ["invoiceNumber"];
      break;
    case InvoiceListSortColumns.datePaid:
      sortProperties = ["datePaid"];
      break;
    case InvoiceListSortColumns.proposalNumbers:
      sortProperties = ["proposalNumbersV3"];
      break;
    case InvoiceListSortColumns.balance:
      sortProperties = ["balance"];
      break;
    case InvoiceListSortColumns.dueDate:
      sortProperties = ["dueDate"];
      break;
  }

  return getSortedItemsV2(
    invoices,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}

function useDebounceState<T>(initialState: T) {
  const [immediateState, setImmediateState] = useState(initialState);
  const [debouncedState, setDebouncedState] = useState(initialState);
  const subject = useRef(new Subject<T>());

  useEffect(() => {
    const sub = subject.current.pipe(debounceTime(150)).subscribe((input) => {
      setDebouncedState(input);
    });

    return function cleanup() {
      sub.unsubscribe();
    };
  }, []);

  const setter = (newValue: T) => {
    setImmediateState(newValue);
    subject.current.next(newValue);
  };

  return {
    setter,
    immediateState,
    debouncedState,
  };
}
