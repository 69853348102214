import { ajax } from "rxjs/ajax";
import { map, timeout } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { IWorksheet, IWorksheetView } from "../models/IWorksheet";

const worksheetsDataProvider = {
  getWorksheets: ({ worksheetIds }: { worksheetIds?: Array<string> }) => {
    const queryStringParams: Array<string> = [];
    if (worksheetIds) {
      worksheetIds.forEach((worksheetId) =>
        queryStringParams.push(`worksheetIds=${worksheetId}`)
      );
    }

    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`Worksheets?${queryStringParams.join("&")}`), headers)
        .pipe(
          timeout(10000),
          map((result) => result.response.worksheets as Array<IWorksheetView>)
        )
    );
  },

  createWorksheet: (worksheet: IWorksheet) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`Worksheets`), worksheet, headers).pipe(
        timeout(10000),
        map((result) => result.response as IWorksheetView)
      )
    );
  },

  updateWorksheet: (worksheetId: string, worksheet: IWorksheet) => {
    return executeWithHeaders((headers) =>
      ajax
        .patch(buildUrl(`Worksheets/${worksheetId}`), worksheet, headers)
        .pipe(
          timeout(10000),
          map((result) => result.response as IWorksheetView)
        )
    );
  },

  deleteWorksheet: (id: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`Worksheets/${id}`), headers).pipe(timeout(10000))
    );
  },
};

export default worksheetsDataProvider;
