import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CompletedWorkReportColumn } from "../../../../enums/completedWorkReportColumn";

export default function ColumnSelector({
  visibleColumns: defaultVisibleColumns,
  onVisibleColumnsChange,
}: {
  visibleColumns: Array<CompletedWorkReportColumn>;
  onVisibleColumnsChange(newValue: Array<CompletedWorkReportColumn>): void;
}) {
  const [showModal, setShowModal] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<
    Array<CompletedWorkReportColumn>
  >(defaultVisibleColumns);

  const onClose = () => {
    setShowModal(false);
    onVisibleColumnsChange(visibleColumns);
  };

  return (
    <>
      <button
        className="btn btn-secondary text-nowrap"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faColumns} />
        <span className="ml-1">Columns</span>
      </button>
      {showModal ? (
        <Modal toggle={() => onClose()} isOpen>
          <ModalHeader toggle={() => onClose()}>Choose Columns</ModalHeader>
          <ModalBody>
            <ColumnCheckbox
              column={CompletedWorkReportColumn.SpecificVisits}
              label="Specific visits"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.VisitCount}
              label="Visit count"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Frequency}
              label="Frequency"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Notes}
              label="Notes"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Billing}
              label="Billing"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Services}
              label="Services"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.CustomerCategories}
              label="Customer tags"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Variance}
              label="Variance"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Hours}
              label="Hours"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.Revenue}
              label="Revenue"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />

            <ColumnCheckbox
              column={CompletedWorkReportColumn.RevenuePerManHour}
              label="$ / Man hour"
              visibleColumns={visibleColumns}
              onVisibleColumnsChange={setVisibleColumns}
            />
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => onClose()}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}

function ColumnCheckbox({
  column,
  label,
  visibleColumns,
  onVisibleColumnsChange,
}: {
  column: CompletedWorkReportColumn;
  label: string;
  visibleColumns: Array<CompletedWorkReportColumn>;
  onVisibleColumnsChange(newValue: Array<CompletedWorkReportColumn>): void;
}) {
  const inputElementId = `CompletedWorkColumn_${column}`;

  return (
    <div className="custom-control custom-checkbox mr-sm-2 ml-2">
      <input
        id={inputElementId}
        type="checkbox"
        className="custom-control-input"
        checked={visibleColumns.includes(column)}
        onChange={(e) => {
          if (e.target.checked) {
            onVisibleColumnsChange([...visibleColumns, column]);
          } else {
            onVisibleColumnsChange(visibleColumns.filter((c) => c !== column));
          }
        }}
      />
      <label htmlFor={inputElementId} className="custom-control-label">
        {label}
      </label>
    </div>
  );
}
