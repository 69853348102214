import React, { useEffect } from "react";

const TermsOfServicePage: React.FunctionComponent<{}> = () => {
  useEffect(() => {
    window.location.href = "https://www.youraspire.com/legal/terms-of-service";
  }, []);

  return null;
};

export default TermsOfServicePage;
