import React, { useCallback, useEffect, useRef, useState } from "react";
import FormContainer2 from "../components/FormContainer2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import conditionalRenderer from "../components/conditionalRenderer";
import ModalDataLoader from "../components/ModalDataLoader";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { logError } from "../../../services/errorLogger";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { ICrewCategory } from "../../../models/ICrewCategory";
import FormContainer, { ErrorMessageType } from "../components/FormContainer";
import uuidv4 from "uuid/v4";
import { crewCategoriesFormPageSize } from "./CrewCategoriesForm.const";

interface ICrewCategoryForForm {
  id: string;
  name: string;
  color: string;
  inactive: boolean;
  isNewItem: boolean;
}

const CrewCategoriesForm: React.FunctionComponent<{}> = () => {
  const [availableColors, setAvailableColors] = useState<Array<string>>([]);
  const dispatch = useDispatch();

  const loadAvailableColors = useCallback(
    () => remoteDataProvider.getAvailableCrewCategoryColors(),
    []
  );
  const handleColorsLoaded = useCallback(
    (newAvailableColors: Array<string>) => {
      if (newAvailableColors) {
        setAvailableColors(
          newAvailableColors
            .map((c) => convertRgbToHex(c) as string)
            .filter((c) => typeof c === "string")
        );
      }
    },
    []
  );

  return (
    <ModalDataLoader<Array<string>>
      errorMessage="The service categories form was unable to open. Please check your Internet connection and try again."
      onErrorAlertClose={() =>
        dispatch(actionCreators.forms.crewCategories.cancelForm())
      }
      loadData={loadAvailableColors}
      onDataLoaded={handleColorsLoaded}
    >
      <FormBody availableColors={availableColors} />
    </ModalDataLoader>
  );
};

function FormBody({ availableColors }: { availableColors: Array<string> }) {
  const sourceCrewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );

  const [showInactive, setShowInactive] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const originalCrewCategories = getOriginalCrewCategories({
    sourceCrewCategories,
    availableColors,
  });
  const [crewCategories, setCrewCategories] = useState<
    Array<ICrewCategoryForForm>
  >(originalCrewCategories);

  const visibleCategories = crewCategories.filter((crewCategoryField) =>
    isCategoryVisible(crewCategoryField, showInactive)
  );

  return (
    <FormContainer2
      formHeader={"Edit Service Categories"}
      formType={"crewCategories"}
      getFormData={() => {
        return crewCategories.map((c) => ({
          ...c,
          color: convertHexToRgb(c.color),
          id: c.isNewItem ? null : c.id,
          isNewItem: undefined,
        }));
      }}
      size="lg"
      showForm={true}
      hasFormDataChanged={() => {
        const currentValues = JSON.stringify(crewCategories);
        const originalValues = JSON.stringify(originalCrewCategories);

        return currentValues !== originalValues;
      }}
    >
      <div className="d-flex justify-content-between mb-3">
        <div>
          <AddCategoryLinkButton
            availableColors={availableColors}
            crewCategories={visibleCategories}
            onAdd={(newCrewCategory) =>
              setCrewCategories([...crewCategories, newCrewCategory])
            }
          />
        </div>

        <div>
          <ShowInactiveCheckbox
            showInactive={showInactive}
            onChange={(newValue) => {
              setShowInactive(newValue);
              setCurrentPage(0);
            }}
          />
        </div>
      </div>

      {visibleCategories.length > 0 ? (
        <>
          <PagedCrewCategories
            crewCategories={visibleCategories}
            currentPage={currentPage}
            onCurrentPageChanged={(newValue) => setCurrentPage(newValue)}
            pageSize={crewCategoriesFormPageSize}
            renderCurrentPage={(categories) => {
              return (
                <>
                  <div className="form-row">
                    <div className="col-7">
                      <label htmlFor="crewCategoryName">
                        <strong>Name</strong>
                      </label>
                    </div>

                    <div className="col-3">
                      <label htmlFor="crewCategoryColor">
                        <strong>Color</strong>
                      </label>
                    </div>

                    <div className="col-2">
                      <label htmlFor="crewCategoryColor">
                        <strong>Inactive?</strong>
                      </label>
                    </div>
                  </div>
                  {categories.map((crewCategory) => (
                    <CrewCategoryFormRow
                      key={crewCategory.id}
                      availableColors={availableColors}
                      crewCategoryField={crewCategory}
                      onChange={(updatedValues) =>
                        setCrewCategories((categories) =>
                          categories.map((category) => {
                            if (category.id === crewCategory.id) {
                              return {
                                ...category,
                                ...updatedValues,
                              };
                            } else {
                              return category;
                            }
                          })
                        )
                      }
                    />
                  ))}
                </>
              );
            }}
          />
        </>
      ) : (
        <div className="text-center">
          <strong>No categories to display</strong>
        </div>
      )}
    </FormContainer2>
  );
}

function PagedCrewCategories({
  crewCategories,
  pageSize,
  currentPage,
  onCurrentPageChanged,
  renderCurrentPage,
}: {
  crewCategories: Array<ICrewCategoryForForm>;
  pageSize: number;
  currentPage: number;
  onCurrentPageChanged: (newValue: number) => void;
  renderCurrentPage: (
    currentPageCategories: Array<ICrewCategoryForForm>
  ) => React.ReactNode;
}) {
  const currentPageCategories = crewCategories.filter((category, index) => {
    const bottomCutoff = currentPage * pageSize;
    const topCutoff = (currentPage + 1) * pageSize;

    return index >= bottomCutoff && index < topCutoff;
  });

  const showPreviousButton = currentPage > 0;
  const showNextButton = pageSize * (currentPage + 1) < crewCategories.length;

  let nextPageCount = crewCategories.length - pageSize * (currentPage + 1);
  if (nextPageCount > pageSize) {
    nextPageCount = pageSize;
  }

  const pagingRow = (
    <div className="d-flex" style={{ columnGap: "10px" }}>
      {showPreviousButton ? (
        <div data-testid="previousPageButton">
          <PagingButton
            contents={`Previous ${pageSize}`}
            onClick={() => onCurrentPageChanged(currentPage - 1)}
          />
        </div>
      ) : null}

      {showPreviousButton && showNextButton ? <div>|</div> : null}

      {showNextButton ? (
        <div data-testid="nextPageButton">
          <PagingButton
            contents={`Next ${nextPageCount}`}
            onClick={() => {
              onCurrentPageChanged(currentPage + 1);
            }}
          />
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      {pagingRow}

      {renderCurrentPage(currentPageCategories)}

      {pagingRow}
    </>
  );
}

function ShowInactiveCheckbox({
  showInactive,
  onChange,
}: {
  showInactive: boolean;
  onChange: (newValue: boolean) => void;
}) {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        id="crew-categories-include-inactive"
        checked={showInactive}
        onChange={(e) => onChange(e.currentTarget.checked)}
      />
      <label
        className="custom-control-label"
        htmlFor="crew-categories-include-inactive"
      >
        Show inactive
      </label>
    </div>
  );
}

function CrewCategoryFormRow({
  crewCategoryField: crewCategory,
  onChange,
  availableColors,
}: {
  crewCategoryField: ICrewCategoryForForm;
  onChange: (newValues: Partial<ICrewCategoryForForm>) => void;
  availableColors: Array<string>;
}) {
  return (
    <div className={"form-row"}>
      <div className="form-group col-7">
        <NameInputField crewCategory={crewCategory} onChange={onChange} />
      </div>
      <div className="form-group col-3">
        <ColorInputField
          availableColors={availableColors}
          crewCategory={crewCategory}
          onChange={onChange}
        />
      </div>
      <div className="form-group col-2">
        <div className="custom-control custom-checkbox">
          <input
            id={getCheckboxId(crewCategory.id)}
            type="checkbox"
            className="custom-control-input"
            checked={crewCategory.inactive}
            onChange={(e) =>
              onChange({
                inactive: e.currentTarget.checked,
              })
            }
          ></input>
          <label
            className="custom-control-label mt-1"
            htmlFor={getCheckboxId(crewCategory.id)}
          >
            <span className="sr-only">
              {crewCategory.inactive ? "Inactive" : "Active"}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

function NameInputField({
  crewCategory,
  onChange,
  id,
  inputRef,
}: {
  crewCategory: ICrewCategoryForForm;
  onChange: (newValues: Partial<ICrewCategoryForForm>) => void;
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}) {
  return (
    <input
      id={id}
      ref={inputRef}
      aria-label="Name"
      type="text"
      className="form-control"
      required
      value={crewCategory.name}
      onChange={(e) =>
        onChange({
          name: e.currentTarget.value,
        })
      }
    />
  );
}

function ColorInputField({
  availableColors,
  crewCategory,
  onChange,
  id,
}: {
  availableColors: Array<string>;
  crewCategory: ICrewCategoryForForm;
  onChange: (newValues: Partial<ICrewCategoryForForm>) => void;
  id?: string;
}) {
  return (
    <>
      <input
        id={id}
        aria-label="Color"
        className="form-control"
        type="color"
        list="presetColors"
        required
        value={crewCategory.color}
        onChange={(e) =>
          onChange({
            color: e.currentTarget.value,
          })
        }
      ></input>
      <datalist id="presetColors">
        {availableColors !== null
          ? availableColors.map((availableColor) => (
              <option
                key={availableColor}
                value={availableColor}
                data-testid="availableColor"
              />
            ))
          : null}
      </datalist>
    </>
  );
}

function AddCategoryLinkButton({
  onAdd,
  availableColors,
  crewCategories,
}: {
  onAdd: (newCrewCategory: ICrewCategoryForForm) => void;
  availableColors: Array<string>;
  crewCategories: Array<ICrewCategoryForForm>;
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary btn-sm"
        onClick={() => setShowModal(true)}
      >
        Add new category
      </button>

      {showModal ? (
        <AddCategoryForm
          onAdd={onAdd}
          availableColors={availableColors}
          onClose={() => setShowModal(false)}
          crewCategories={crewCategories}
        />
      ) : null}
    </>
  );
}

function AddCategoryForm({
  onAdd,
  availableColors,
  onClose,
  crewCategories,
}: {
  onAdd: (newCrewCategory: ICrewCategoryForForm) => void;
  availableColors: Array<string>;
  onClose: () => void;
  crewCategories: Array<ICrewCategoryForForm>;
}) {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null);
  const [formData, setFormData] = useState<ICrewCategoryForForm>({
    id: uuidv4(),
    name: "",
    color: availableColors[crewCategories.length % availableColors.length],
    inactive: false,
    isNewItem: true,
  });

  const nameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    });
  }, []);

  return (
    <FormContainer
      cancel={onClose}
      showForm={true}
      saving={false}
      startSave={() => {
        onAdd(formData);
        onClose();
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      formHeader={"Add New Category"}
      formKey="crewCategoriesAdd"
    >
      <div className="form-group">
        <label htmlFor="addCategoryName">Name</label>
        <NameInputField
          inputRef={nameRef}
          id="addCategoryName"
          crewCategory={formData}
          onChange={(newValues) =>
            setFormData({
              ...formData,
              ...newValues,
            })
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="addCategoryColor">Color</label>
        <ColorInputField
          id="addCategoryColor"
          availableColors={availableColors}
          crewCategory={formData}
          onChange={(newValues) =>
            setFormData({
              ...formData,
              ...newValues,
            })
          }
        />
      </div>
    </FormContainer>
  );
}

function getOriginalCrewCategories({
  sourceCrewCategories,
  availableColors,
}: {
  sourceCrewCategories: ICrewCategory[];
  availableColors: string[];
}): Array<ICrewCategoryForForm> {
  return getSortedItemsV2(sourceCrewCategories, ["name"])
    .map((c) => ({
      ...c,
      // If color isn't set, default to first available color
      color: !c.color
        ? availableColors[0]
        : (convertRgbToHex(c.color) as string),
      inactive: c.inactive ?? false,
      isNewItem: false,
    }))
    .filter((c) => typeof c.color === "string");
}

function PagingButton({
  contents,
  onClick,
}: {
  contents: string;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className="link-button text-primary px-0"
      onClick={() => {
        onClick();
      }}
      style={{
        width: "100%",
      }}
    >
      {contents}
    </button>
  );
}

function isCategoryVisible(
  crewCategoryFieldToCheck: ICrewCategoryForForm,
  showInactiveWatch: boolean
) {
  if (showInactiveWatch) {
    return true;
  }

  return !crewCategoryFieldToCheck.inactive;
}

function convertRgbToHex(input: string): string | null {
  const rgbComponents = (input ?? "").match(/(\d{1,3})/g);
  if (rgbComponents?.length !== 3) {
    logError(`invalid value passed to convertRgbToHex: ${input}`);
    return null;
  }

  const r = parseInt(rgbComponents[0]);
  const g = parseInt(rgbComponents[1]);
  const b = parseInt(rgbComponents[2]);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

function convertHexToRgb(input: string) {
  var hexComponents = input.replace("#", "").match(/.{1,2}/g);

  if (hexComponents?.length !== 3) {
    logError(`invalid value passed to convertHexToRgb: ${input}`);
    return null;
  }

  var [r, g, b] = [
    parseInt(hexComponents[0], 16),
    parseInt(hexComponents[1], 16),
    parseInt(hexComponents[2], 16),
  ];

  return `rgb(${r}, ${g}, ${b})`;
}

function getCheckboxId(id: string): string {
  return `inactiveCheckbox${id}`;
}

export default conditionalRenderer(
  CrewCategoriesForm,
  (s) => s.forms.crewCategories.showForm
);
