import constants from "../../../constants";
import { getQueryVariable } from "../../../services/getQueryVariable";
import { INewCompanyFormData } from "../../../slices/accountManagement/components/Register.types";

export function getSavePayload(newCompanyFormData: INewCompanyFormData) {
  return {
    ...newCompanyFormData,
    isTestAccount: window.sessionStorage.getItem("testrun") === "1",
    landingPageUrl: window.sessionStorage.getItem(
      constants.signUpReferralSessionStorageKey
    ),
    tenantPlan: window.sessionStorage.getItem(constants.planSessionStorageKey),
    trialUnavailable:
      window.sessionStorage.getItem(
        constants.trialUnavailableSessionStorageKey
      ) === "1" ||
      getQueryVariable(constants.trialUnavailableQueryStringKey) === "1",
    streetAndNumber: newCompanyFormData.addressForSave.streetAndNumber,
    city: newCompanyFormData.addressForSave.city,
    state: newCompanyFormData.addressForSave.state,
    zip: newCompanyFormData.addressForSave.zip,
    latitude: newCompanyFormData.addressForSave.latitude,
    longitude: newCompanyFormData.addressForSave.longitude,
    mode: 0,
  };
}
