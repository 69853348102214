import React, { useRef, useEffect, useState } from "react";
import uuidv4 from "uuid/v4";
import TextareaAutosize from "react-autosize-textarea/lib";
import { ReorderButtonsForListItem } from "./ReorderButtonsForListItem";
import { moveArrayElement } from "./ReorderButtonsForListItem.functions";

export interface ITodoItem {
  id: string | null;
  text: string;
  required: boolean;
  tempId: string;
}

interface IProps {
  value: Array<ITodoItem>;
  onChange(newValue: Array<ITodoItem>): void;
  locked?: boolean;
  noItemsText?: string;
  readOnly?: boolean;
}

const ManageTodoItems: React.FunctionComponent<IProps> = ({
  value,
  onChange,
  locked,
  noItemsText,
  readOnly,
}) => {
  const previousValue = useRef<Array<ITodoItem>>(value);
  const lastItemRef = useRef<HTMLTextAreaElement>(null);
  const [addInProgress, setAddInProgress] = useState(false);
  useEffect(() => {
    if (addInProgress && value.length > previousValue.current.length) {
      if (lastItemRef.current) {
        lastItemRef.current.focus();
      }

      setAddInProgress(false);
    }

    previousValue.current = value;
  }, [value, addInProgress, setAddInProgress]);

  return (
    <React.Fragment>
      <div className="form-group">
        {value.length === 0 ? (
          <div>
            <small className="text-muted no-todo-items">
              {!!noItemsText
                ? noItemsText
                : "No checklist items are set for this job."}
            </small>
          </div>
        ) : null}

        {value.map((t, index) => (
          <div className="mb-3" key={t.tempId}>
            <div className="d-flex">
              {value.length > 1 && !readOnly && !locked ? (
                <div className="mt-2 mr-2" data-testid="reorderButtonContainer">
                  <ReorderButtonsForListItem
                    moveDownDisabled={index === value.length - 1}
                    moveUpDisabled={index === 0}
                    onMoveUp={() => {
                      onChange(moveArrayElement(value, index, -1));
                    }}
                    onMoveDown={() => {
                      onChange(moveArrayElement(value, index, 1));
                    }}
                  />
                </div>
              ) : null}
              <div style={{ flexGrow: 1 }}>
                <div className="input-group mb-2">
                  <TextareaAutosize
                    className="form-control todo-text"
                    value={t.text}
                    readOnly={locked || readOnly}
                    onChange={(e) => {
                      const newItems = [...value];
                      newItems[index] = {
                        ...newItems[index],
                        text: e.currentTarget.value,
                      };
                      onChange(newItems);
                    }}
                    ref={index === value.length - 1 ? lastItemRef : null}
                    aria-label="Checklist item"
                  />
                  {!locked && !readOnly ? (
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-danger todo-remove-item btn-sm"
                        onClick={() => {
                          const newItems = [...value];
                          newItems.splice(index, 1);
                          onChange(newItems);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className="input-group ml-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={locked || readOnly}
                      id={"required" + t.tempId}
                      checked={t.required}
                      onChange={(e) => {
                        const newItems = [...value];
                        newItems[index] = {
                          ...newItems[index],
                          required: e.currentTarget.checked,
                        };
                        onChange(newItems);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"required" + t.tempId}
                    >
                      Required
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {!locked && !readOnly ? (
          <button
            type="button"
            className="btn btn-secondary btn-sm todo-add-item"
            onClick={() => {
              setAddInProgress(true);
              onChange([
                ...value,
                { id: null, required: false, text: "", tempId: uuidv4() },
              ]);
            }}
            style={{ marginTop: "15px" }}
          >
            Add New Checklist Item
          </button>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default ManageTodoItems;
