import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import modalConversion from "../../../services/modelConversion";
import { IRootState } from "../../../store";
import addressFormatter from "../../../services/addressFormatter";
import Spinner from "../components/Spinner";
import AddressComponents2, {
  IAddressComponents,
} from "../components/AddressComponents2";
import { ICustomer } from "../../../models/ICustomer";
import {
  validateLatitudeOptional,
  validateLongitudeOptional,
} from "../../../services/latLngService";
import { IAddress } from "../../../models/IAddress";
import { getDefaultProp } from "../../../services/formService";
import CategorySelection from "../components/CategorySelection";
import { ICustomerCategory } from "../../../models/ICustomerCategory";
import { ICategoryIdentifierForSave } from "../../../models/ICategoryIdentifierForSave";
import { getCategories } from "../../../services/crewCategoryService";
import PhoneNumberField from "../components/PhoneNumberField";
import { ISaveCustomerRequest } from "../../../services/remoteDataProvider";
import { ICategoryIdentifier } from "../../../models/ICategoryIdentifier";
import TextareaAutosize from "react-autosize-textarea";
import SmsOptInCheckbox from "../components/SmsOptInCheckbox";
import {
  EmailAddresses,
  IEmailAddressRecord,
} from "../components/EmailAddresses";
import {
  createEmailAddressRecord,
  createEmailAddressRecordsForCustomer,
  getEmailAddressesForSave,
} from "../components/EmailAddresses.functions";
import { getOptInStatusAfterNumberChange } from "../../../services/phoneNumberService";
import uuidv4 from "uuid/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import customerDataProvider from "../../../slices/customer/services/customerDataProvider";
import { isStringSet } from "../../../services/stringService";

interface IProps {
  customerId: string | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(payload: any): void;
  cancel(): void;
  setErrorMessage(message: string): void;
  loadCustomersStart(ids: Array<string>): void;
  customers: Array<ICustomer>;
  customersLoading: Array<string>;
  defaultName: string | undefined;
  defaultPhoneNumber: string | undefined;
  defaultAlternativePhoneNumber: string | undefined;
  defaultEmailAddress: string | undefined;
  defaultEmailAddresses: Array<string> | undefined;
  defaultAddress: IAddress | undefined;
  defaultQuickBooksCustomerId: string | undefined;
  defaultTaxExempt: boolean | undefined;
  formInstanceKey: string | undefined | null;
  customerCategories: Array<ICustomerCategory>;
  customerTextingAllowed: boolean;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
  loadingCustomer: boolean;
  phoneNumberOptedIntoSmsDirty: boolean;
  showAdditionalPhoneNumber: boolean;
}

interface IFormData {
  id?: string;
  emailAddresses: Array<IEmailAddressRecord>;
  customerName: string;
  phoneNumber: string;
  phoneNumberOptedIntoSms: boolean;
  alternativePhoneNumber: string;
  address: IAddressComponents;
  quickBooksId: string | null;
  inactive: boolean;
  categories: Array<ICategoryIdentifierForSave>;
  administratorOnlyNotes: string;
  notesForCrew: string;
  taxExempt: boolean;
}

class CustomerForm extends React.Component<IProps, IState> {
  private nameElement: React.RefObject<HTMLInputElement>;
  private streetAndNumberRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    const formData = this.getEmptyFormData();
    this.state = {
      formData: formData,
      originalFormData: formData,
      loadingCustomer: false,
      phoneNumberOptedIntoSmsDirty: false,
      showAdditionalPhoneNumber: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.validate = this.validate.bind(this);
    this.getFormData = this.getFormData.bind(this);

    this.nameElement = React.createRef();
    this.streetAndNumberRef = React.createRef();
  }

  getEmptyAddress(): IAddressComponents {
    return {
      city: "",
      latitude: "",
      longitude: "",
      state: "",
      streetAndNumber: "",
      apartmentSuite: "",
      zip: "",
      latitudeSignificantDigits: null,
      longitudeSignificantDigits: null,
      placeId: null,
    };
  }

  getEmptyFormData(): IFormData {
    return {
      customerName: "",
      phoneNumber: "",
      phoneNumberOptedIntoSms: false,
      alternativePhoneNumber: "",
      emailAddresses: [createEmailAddressRecord("")],
      address: this.getEmptyAddress(),
      quickBooksId: null,
      inactive: false,
      categories: [],
      administratorOnlyNotes: "",
      notesForCrew: "",
      taxExempt: false,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;

    if (
      props.showForm &&
      (prevProps.showForm !== props.showForm ||
        (prevProps.customers !== props.customers &&
          props.customerId &&
          !prevProps.customers.find((j) => j.id === props.customerId) &&
          !!props.customers.find((j) => j.id === props.customerId)))
    ) {
      let formData: IFormData;
      if (!props.customerId) {
        formData = this.getEmptyFormData();

        if (props.defaultName) {
          formData.customerName = props.defaultName;
        }

        if (props.defaultEmailAddresses) {
          if (props.defaultEmailAddresses.length > 0) {
            formData.emailAddresses = props.defaultEmailAddresses.map((e) => ({
              id: uuidv4(),
              value: e,
            }));
          }
        } else if (props.defaultEmailAddress) {
          formData.emailAddresses = [
            createEmailAddressRecord(props.defaultEmailAddress),
          ];
        }

        if (props.defaultPhoneNumber) {
          formData.phoneNumber = props.defaultPhoneNumber;
        }

        if (props.defaultAlternativePhoneNumber) {
          formData.alternativePhoneNumber = props.defaultAlternativePhoneNumber;
        }

        if (props.defaultQuickBooksCustomerId) {
          formData.quickBooksId = props.defaultQuickBooksCustomerId;
        }

        if (typeof props.defaultTaxExempt === "boolean") {
          formData.taxExempt = props.defaultTaxExempt;
        }

        if (props.defaultAddress) {
          formData.address.streetAndNumber =
            props.defaultAddress.streetAndNumber;
          formData.address.apartmentSuite =
            props.defaultAddress.apartmentSuite ?? "";
          formData.address.city = props.defaultAddress.city;
          formData.address.state = props.defaultAddress.state;
          formData.address.zip = props.defaultAddress.zip;
          formData.address.latitude = props.defaultAddress.latitude
            ? props.defaultAddress.latitude.toString()
            : "";
          formData.address.longitude = props.defaultAddress.longitude
            ? props.defaultAddress.longitude.toString()
            : "";
        }

        this.setState({
          formData,
          originalFormData: formData,
          phoneNumberOptedIntoSmsDirty: false,
          showAdditionalPhoneNumber:
            isStringSet(formData.phoneNumber) ||
            isStringSet(formData.alternativePhoneNumber),
        });

        setTimeout(() => {
          if (!this.state.formData.customerName) {
            if (this.nameElement.current) {
              this.nameElement.current.focus();
            }
          } else if (
            !this.state.formData.address.streetAndNumber ||
            (this.state.formData.address.streetAndNumber &&
              !this.state.formData.address.latitude &&
              !this.state.formData.address.longitude)
          ) {
            if (this.streetAndNumberRef.current) {
              this.streetAndNumberRef.current.focus();
            }
          }
        });
      } else {
        const customer = props.customers.find((c) => c.id === props.customerId);
        if (customer) {
          formData = {
            customerName: customer.name,
            phoneNumber: customer.phoneNumber,
            phoneNumberOptedIntoSms: customer.phoneNumberOptedIntoSms,
            alternativePhoneNumber: customer.alternativePhoneNumber ?? "",
            emailAddresses: createEmailAddressRecordsForCustomer(customer),
            quickBooksId: customer.quickBooksId,
            inactive: customer.inactive,
            administratorOnlyNotes: customer.administratorOnlyNotes ?? "",
            notesForCrew: customer.notesForCrew ?? "",
            taxExempt: customer.taxExempt,
            categories: getCategories(
              customer.categories,
              this.props.customerCategories
            ),
            address: {
              city: customer.city || "",
              streetAndNumber: customer.streetAndNumber || "",
              apartmentSuite: customer.apartmentSuite || "",
              state: customer.state || "",
              latitude: customer.latitude
                ? addressFormatter.formatLatLng(
                    customer.latitude,
                    customer.latitudeSignificantDigits
                  )
                : "",
              longitude: customer.longitude
                ? addressFormatter.formatLatLng(
                    customer.longitude,
                    customer.longitudeSignificantDigits
                  )
                : "",
              latitudeSignificantDigits: customer.latitudeSignificantDigits,
              longitudeSignificantDigits: customer.longitudeSignificantDigits,
              zip: customer.zip || "",
              placeId: customer.placeId,
            },
          };
          this.setState({
            formData,
            originalFormData: formData,
            loadingCustomer: false,
            phoneNumberOptedIntoSmsDirty: false,
            showAdditionalPhoneNumber:
              isStringSet(formData.phoneNumber) ||
              isStringSet(formData.alternativePhoneNumber),
          });
        } else {
          if (
            !this.props.customersLoading.find(
              (customerLoading) => customerLoading === props.customerId
            )
          ) {
            this.props.loadCustomersStart([props.customerId]);
          }
          this.setState({ loadingCustomer: true });
        }
      }
    }
  }

  handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const fieldName = event.target.name;
    this.handleChangeWithFieldName(fieldName, event.target.value);
  }

  handleChangeWithFieldName(fieldName: string, value: any) {
    const formDataToUpdate = { ...this.state.formData };
    const formDataKey = fieldName as keyof IFormData;
    (formDataToUpdate as any)[formDataKey] = value;

    let { showAdditionalPhoneNumber } = this.state;

    if (formDataKey === "phoneNumber") {
      formDataToUpdate.phoneNumberOptedIntoSms =
        getOptInStatusAfterNumberChange({
          currentOptInStatus: formDataToUpdate.phoneNumberOptedIntoSms,
          newNumber: value,
          originalNumber: this.state.originalFormData.phoneNumber,
          originalOptInStatus:
            this.state.originalFormData.phoneNumberOptedIntoSms,
          phoneNumberOptInDirty: this.state.phoneNumberOptedIntoSmsDirty,
        });

      if (isStringSet(value)) {
        showAdditionalPhoneNumber = true;
      }
    }

    this.setState({
      formData: formDataToUpdate,
      showAdditionalPhoneNumber,
    });
  }

  handleAddressChange(newAddressValue: IAddressComponents) {
    this.setState({
      formData: {
        ...this.state.formData,
        address: newAddressValue,
      },
    });
  }

  getFormData() {
    const formData: ISaveCustomerRequest = {
      emailAddresses: getEmailAddressesForSave(
        this.state.formData.emailAddresses
      ),
      name: this.state.formData.customerName,
      phoneNumber: this.state.formData.phoneNumber,
      phoneNumberOptedIntoSms: this.state.formData.phoneNumberOptedIntoSms,
      alternativePhoneNumber: this.state.formData.alternativePhoneNumber,
      city: this.state.formData.address.city,
      latitude: modalConversion.convertStringToNumberOrNull(
        this.state.formData.address.latitude
      ),
      longitude: modalConversion.convertStringToNumberOrNull(
        this.state.formData.address.longitude
      ),
      latitudeSignificantDigits:
        this.state.formData.address.latitudeSignificantDigits,
      longitudeSignificantDigits:
        this.state.formData.address.longitudeSignificantDigits,
      placeId: this.state.formData.address.placeId,
      state: this.state.formData.address.state,
      streetAndNumber: this.state.formData.address.streetAndNumber,
      apartmentSuite: this.state.formData.address.apartmentSuite,
      zip: this.state.formData.address.zip,
      id: this.state.formData.id ? this.state.formData.id : "",
      quickBooksId: this.state.formData.quickBooksId,
      inactive: this.state.formData.inactive,
      categories: this.state.formData.categories.map(
        (c) => ({ ...c } as ICategoryIdentifier)
      ),
      originalInactive: this.props.customerId
        ? this.props.customers.find((c) => c.id === this.props.customerId)
            ?.inactive ?? null
        : null,
      notesForCrew: this.state.formData.notesForCrew,
      administratorOnlyNotes: this.state.formData.administratorOnlyNotes,
      taxExempt: this.state.formData.taxExempt,
    };

    return formData;
  }

  validate() {
    const result = { valid: true, errorMessage: "" };

    const latitudeValidationResult = validateLatitudeOptional(
      this.state.formData.address.latitude
    );
    if (!latitudeValidationResult.valid) {
      return {
        valid: false,
        errorMessage: latitudeValidationResult.error,
      };
    }

    const longitudeValidationResult = validateLongitudeOptional(
      this.state.formData.address.longitude
    );
    if (!longitudeValidationResult.valid) {
      return {
        valid: false,
        errorMessage: longitudeValidationResult.error,
      };
    }

    return result;
  }

  render() {
    const {
      customers,
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      customerId,
    } = this.props;
    const { formData } = this.state;

    let formHeader = "Add Customer";
    let isAdd = true;
    let customer: ICustomer | null = null;
    if (customerId) {
      customer = customers.find((j) => j.id === customerId) ?? null;
      if (customer) {
        formHeader = `Update ${customer.name}`;
      }
      isAdd = false;
    }

    const isSubForm = typeof this.props.formInstanceKey === "string";

    return (
      <React.Fragment>
        <FormContainer
          setErrorMessage={setErrorMessage}
          validate={this.validate}
          getFormData={this.getFormData}
          formHeader={formHeader}
          showForm={showForm && !this.state.loadingCustomer}
          errorMessage={errorMessage}
          saving={saving}
          startSave={startSave}
          cancel={cancel}
          formKey="customer"
          disableFade={true}
          saveButtonText={isSubForm ? "Add customer" : undefined}
          hasFormDataChanged={() => {
            return (
              JSON.stringify(this.state.formData) !==
              JSON.stringify(this.state.originalFormData)
            );
          }}
          addSaveAndNewButton={!customerId && !isSubForm}
        >
          <div className="form-group">
            <label htmlFor="customerName" className="required">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              name="customerName"
              id="customerName"
              required={true}
              value={formData.customerName}
              onChange={this.handleChange}
              ref={this.nameElement}
              autoComplete="off"
              maxLength={260}
            />
          </div>
          <AddressComponents2
            streetAndNumberId="inputAddress"
            streetAndNumberName="inputAddress"
            value={formData.address}
            onChange={this.handleAddressChange}
            streetAndNumberRef={this.streetAndNumberRef}
            showMap={true}
            showApartmentSuite
          />
          <div className="form-group">
            <label htmlFor="categories">Tags</label>
            <CategorySelection
              categories={this.props.customerCategories}
              placeholderText="Select customer tags"
              noOptionsMessage="No tags found"
              value={formData.categories}
              onChange={(newValue) => {
                this.setState({
                  formData: {
                    ...formData,
                    categories: newValue,
                  },
                });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Primary phone</label>
            <PhoneNumberField
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>

          {this.props.customerTextingAllowed ? (
            <SmsOptInCheckbox
              checked={formData.phoneNumberOptedIntoSms}
              onCheckedChange={(newChecked) => {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    phoneNumberOptedIntoSms: newChecked,
                  },
                  phoneNumberOptedIntoSmsDirty: true,
                });
              }}
              phoneNumber={formData.phoneNumber}
            />
          ) : null}

          {this.state.showAdditionalPhoneNumber ? (
            <div className="form-group">
              <label htmlFor="alternativePhoneNumber">Alternative phone</label>
              <PhoneNumberField
                id="alternativePhoneNumber"
                name="alternativePhoneNumber"
                value={formData.alternativePhoneNumber}
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
          ) : null}

          <div className="form-group">
            <label htmlFor="emailAddress">Email addresses</label>
            <EmailAddresses
              label="Email address"
              emailAddresses={formData.emailAddresses}
              onChange={(newValue) =>
                this.handleChangeWithFieldName("emailAddresses", newValue)
              }
              required={false}
            />
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="taxExempt"
                checked={formData.taxExempt}
                onChange={(e) => {
                  const value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      taxExempt: value,
                    },
                  });
                }}
              />
              <label className="custom-control-label" htmlFor="taxExempt">
                Tax-exempt
              </label>
            </div>
            {customer && !customer.taxExempt && formData.taxExempt ? (
              <TaxRateInUseWarning customerId={customer.id} />
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="administratorOnlyNotes">
              Administrator only notes
            </label>
            <TextareaAutosize
              maxRows={10}
              id="administratorOnlyNotes"
              className="form-control"
              name="administratorOnlyNotes"
              value={formData.administratorOnlyNotes}
              onChange={(e) => {
                this.handleChangeWithFieldName(
                  "administratorOnlyNotes",
                  e.currentTarget.value
                );
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="notesForCrew">Crew notes</label>
            <TextareaAutosize
              maxRows={10}
              id="notesForCrew"
              className="form-control"
              name="notesForCrew"
              value={formData.notesForCrew}
              onChange={(e) => {
                this.handleChangeWithFieldName(
                  "notesForCrew",
                  e.currentTarget.value
                );
              }}
            />
          </div>
          {!isAdd ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="inactive"
                  name="inactive"
                  checked={formData.inactive}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({
                      formData: {
                        ...formData,
                        inactive: value,
                      },
                    });
                  }}
                />
                <label className="custom-control-label" htmlFor="inactive">
                  Inactive
                </label>
              </div>
            </div>
          ) : null}
        </FormContainer>
        {this.state.loadingCustomer ? <Spinner /> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  customerId: state.forms.customer.parameters
    ? (state.forms.customer.parameters.customerId as string)
    : null,
  defaultName: getDefaultProp<string>(
    state.forms.customer.parameters,
    "defaultName"
  ),
  defaultQuickBooksCustomerId: getDefaultProp<string>(
    state.forms.customer.parameters,
    "defaultQuickBooksCustomerId"
  ),
  defaultEmailAddress: getDefaultProp<string>(
    state.forms.customer.parameters,
    "defaultEmailAddress"
  ),
  defaultEmailAddresses: getDefaultProp<Array<string>>(
    state.forms.customer.parameters,
    "defaultEmailAddresses"
  ),
  defaultPhoneNumber: getDefaultProp<string>(
    state.forms.customer.parameters,
    "defaultPhoneNumber"
  ),
  defaultAlternativePhoneNumber: getDefaultProp<string>(
    state.forms.customer.parameters,
    "defaultAlternativePhoneNumber"
  ),
  defaultAddress: getDefaultProp<IAddress>(
    state.forms.customer.parameters,
    "defaultAddress"
  ),
  defaultTaxExempt: getDefaultProp<boolean>(
    state.forms.customer.parameters,
    "defaultTaxExempt"
  ),
  formInstanceKey: state.forms.customer.parameters
    ? (state.forms.customer.parameters.formInstanceKey as string | undefined)
    : null,
  showForm: state.forms.customer.showForm,
  errorMessage: state.forms.customer.errorMessage,
  saving: state.forms.customer.saving,
  customers: state.customer.customers,
  customersLoading: state.customer.customersLoading,
  customerCategories: state.common.customerCategories,
  customerTextingAllowed:
    state.common.optionalCapabilitiesAllowed.customerTexting,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.customer.startSaving,
  cancel: actionCreators.forms.customer.cancelForm,
  setErrorMessage: actionCreators.forms.customer.setErrorMessage,
  loadCustomersStart: actionCreators.loadCustomersStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);

function TaxRateInUseWarning({ customerId }: { customerId: string }) {
  const [taxRateInUse, setTaxRateInUse] = useState(false);
  useEffect(() => {
    const subscription = customerDataProvider
      .getTaxRateUsage(customerId)
      .subscribe({
        next: (result) => setTaxRateInUse(result.hasTaxRateInUse),

        error: () => {
          // Swallow error
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [customerId]);

  if (!taxRateInUse) {
    return null;
  }

  return (
    <div className="alert alert-warning p-2" data-testid="taxRateInUseWarning">
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
      <small>
        This customer has an open proposal, job, contract billing schedule or
        invoice that includes taxes. Existing items will not be updated to
        exclude taxes.
      </small>
    </div>
  );
}
