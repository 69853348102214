import { IAddress } from "../../../models/IAddress";
import {
  IWorkNotInvoicedCustomer,
  IWorkNotInvoicedJobInstance,
  IWorkNotInvoicedProjectJobInstance,
} from "../../../models/IWorkNotInvoicedCustomer";
import { getUniqueItems } from "../../../services/arrayService";
import dateService from "../../../services/dateService";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { isStringSet } from "../../../services/stringService";

export function buildCustomerAddress(
  customer: IWorkNotInvoicedCustomer
): IAddress | null {
  if (
    !isStringSet(customer.streetAndNumber) &&
    !isStringSet(customer.apartmentSuite) &&
    !isStringSet(customer.city) &&
    !isStringSet(customer.state) &&
    !isStringSet(customer.zip) &&
    customer.latitude === null &&
    customer.longitude === null
  ) {
    return null;
  }

  return {
    streetAndNumber: customer.streetAndNumber,
    apartmentSuite: customer.apartmentSuite,
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    latitude: customer.latitude,
    longitude: customer.longitude,
    latitudeSignificantDigits: null,
    longitudeSignificantDigits: null,
  };
}

export function getJobInstanceDates(
  jobInstances:
    | Array<IWorkNotInvoicedProjectJobInstance>
    | Array<IWorkNotInvoicedJobInstance>
): React.ReactNode {
  let dates: Array<string> = [];
  if (jobInstances.length === 0) {
    dates = [];
  } else if (jobInstances.length === 1) {
    dates = [dateService.formatDateForDisplay(jobInstances[0].date)];
  } else {
    dates = getSortedItemsV2(
      getUniqueItems(jobInstances.map((ji) => ji.date)),
      [(d) => d]
    );
  }

  if (dates.length === 0) {
    return "";
  } else if (dates.length === 1) {
    return dateService.formatDateForDisplay(dates[0]);
  } else {
    return `${dateService.formatDateForDisplay(
      dates[0]
    )} - ${dateService.formatDateForDisplay(dates[dates.length - 1])}`;
  }
}
