import { useEffect, useState } from "react";
import { IJobInstanceWorksheet } from "../models/IJobInstanceWorksheet";
import jobInstanceWorksheetsDataProvider from "../services/jobInstanceWorksheetsDataProvider";
import { logError } from "../../../services/errorLogger";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { ModalDataLoaderStateless } from "../../../containers/app/components/ModalDataLoaderStateless";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";
import React from "react";
import { JobInstanceWorksheetFormContentItem } from "./JobInstanceWorksheetFormContentItem";
import { map } from "rxjs/operators";

type CompleteChanged = (newCompleteValue: boolean) => void;

export function JobInstanceWorksheetForm({
  jobInstanceId,
  worksheetForJobInstanceId,
  onClose,
  onCompleteChanged,
}: {
  jobInstanceId: string;
  worksheetForJobInstanceId: string;
  onClose: () => void;
  onCompleteChanged: CompleteChanged;
}) {
  const { errorMessage, loading, worksheetForJobInstance } = useLoadWorksheet({
    jobInstanceId,
    worksheetForJobInstanceId,
  });

  return (
    <>
      <ModalDataLoaderStateless
        errorLoadingData={!!errorMessage}
        errorMessage={errorMessage ?? ""}
        loadingData={loading}
        onErrorAlertClose={onClose}
      >
        {worksheetForJobInstance !== null ? (
          <JobInstanceWorksheetFormBody
            jobInstanceId={jobInstanceId}
            worksheetForJobInstance={worksheetForJobInstance}
            onClose={onClose}
            onCompleteChanged={onCompleteChanged}
          />
        ) : (
          <></>
        )}
      </ModalDataLoaderStateless>
    </>
  );
}

type ContentValues = {
  [key: string]: string;
};

function JobInstanceWorksheetFormBody({
  jobInstanceId,
  worksheetForJobInstance,
  onClose,
  onCompleteChanged,
}: {
  jobInstanceId: string;
  worksheetForJobInstance: IJobInstanceWorksheet;
  onClose: () => void;
  onCompleteChanged: CompleteChanged;
}) {
  const [formErrorMessage, setFormErrorMessage] =
    useState<ErrorMessageType>(null);

  const [completed, setCompleted] = useState(worksheetForJobInstance.completed);

  const defaultFormValues: ContentValues = {};
  worksheetForJobInstance.contentItems.forEach((contentItem) => {
    defaultFormValues[contentItem.id] = contentItem.response;
  });
  const [contentValues, setContentValues] =
    useState<ContentValues>(defaultFormValues);

  const completedCheckboxId = `completed_${worksheetForJobInstance.id}`;

  return (
    <FormContainerWithoutRedux
      formType={FormTypesV2.worksheetForJobInstance}
      save={() => {
        const payload = {
          completed,
          contentItemResponses: Object.entries(contentValues).map(
            ([key, value]) => ({
              id: key,
              response: value,
            })
          ),
        };

        return jobInstanceWorksheetsDataProvider
          .updateWorksheetResponses({
            jobInstanceId,
            worksheetForJobInstanceId: worksheetForJobInstance.id,
            payload: payload,
          })
          .pipe(map(() => payload));
      }}
      formHeader={`Update ${worksheetForJobInstance.title}`}
      onCancel={onClose}
      onSaveComplete={(result) => {
        if (result.completed !== worksheetForJobInstance.completed) {
          onCompleteChanged(result.completed);
        }

        onClose();
      }}
      errorMessage={formErrorMessage}
      setErrorMessage={setFormErrorMessage}
    >
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={completedCheckboxId}
            checked={completed}
            onChange={(e) => setCompleted(e.currentTarget.checked)}
            data-testid="worksheetCompleted"
          />
          <label className="custom-control-label" htmlFor={completedCheckboxId}>
            Completed
          </label>
        </div>
      </div>

      {worksheetForJobInstance.contentItems.map((contentItem) => (
        <React.Fragment key={contentItem.id}>
          <JobInstanceWorksheetFormContentItem
            contentItem={contentItem}
            onChange={(newValue) => {
              setContentValues((v) => ({
                ...v,
                [contentItem.id]: newValue,
              }));
            }}
            value={contentValues[contentItem.id] ?? ""}
            enforceRequiredFields={completed}
          />
        </React.Fragment>
      ))}
    </FormContainerWithoutRedux>
  );
}

function useLoadWorksheet({
  jobInstanceId,
  worksheetForJobInstanceId,
}: {
  jobInstanceId: string;
  worksheetForJobInstanceId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [worksheetForJobInstance, setWorksheetForJobInstance] =
    useState<IJobInstanceWorksheet | null>(null);

  useEffect(() => {
    setLoading(true);
    jobInstanceWorksheetsDataProvider
      .getWorksheetsForJobInstance({
        jobInstanceId,
      })
      .subscribe({
        next: (worksheets) => {
          setLoading(false);

          const worksheetFromResult = worksheets.find(
            (w) =>
              w.id === worksheetForJobInstanceId ||
              w.sourceWorksheetForMaintenanceJobId === worksheetForJobInstanceId
          );
          if (worksheetFromResult) {
            setWorksheetForJobInstance(worksheetFromResult);
          } else {
            logError(
              `worksheet '${worksheetForJobInstanceId}' for job instance '${jobInstanceId}' was no returned`
            );
            setErrorMessage(
              "The worksheet could not be found. Please reload your browser and try again."
            );
          }
        },

        error: (err) => {
          setLoading(false);
          setErrorMessage(getErrorMessageFromError(err));
        },
      });
  }, [jobInstanceId, worksheetForJobInstanceId]);

  return { loading, errorMessage, worksheetForJobInstance };
}
