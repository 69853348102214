export enum InvoiceListSortColumns {
  date,
  customer,
  datePaid,
  taxAmount,
  totalAmount,
  invoiceNumber,
  lastViewedDateTime,
  lastSentDateTime,
  proposalNumbers,
  balance,
  dueDate,
}
