import React from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import Container from "./Container";
import { ITodoTemplate } from "../../../../models/ITodoTemplate";
import { getSortedTodoTemplates } from "../../../../services/sortingService";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { promptActionCreators } from "../../../../modules/prompt";

const TodoTemplates: React.FunctionComponent<{}> = () => {
  const todoTemplates = useApplicationStateSelector(
    (s) => s.common.todoTemplates
  );
  const dispatch = useDispatch();

  const sortedTodoTemplates = getSortedTodoTemplates(todoTemplates);

  const addButton = (
    <button
      className="btn btn-primary"
      onClick={() => dispatch(actionCreators.forms.todoTemplate.showForm({}))}
    >
      Add Checklist Template
    </button>
  );

  return (
    <Container<ITodoTemplate>
      entityNameForDelete="checklist template"
      getFields={() => []}
      getTitle={(todoTemplate) => todoTemplate.name}
      items={sortedTodoTemplates}
      addButton={addButton}
      pageHeader="Job Checklist Templates"
      deleteItem={(todoTemplate) =>
        actionCreators.todoTemplateDeleteStart(todoTemplate.id)
      }
      showEditForm={(todoTemplate) => {
        dispatch(
          actionCreators.forms.todoTemplate.showForm({
            todoTemplateId: todoTemplate.id,
            skipRedirect: true,
          })
        );
      }}
      showPrompt={(
        promptMessage,
        promptSaveText,
        promptSubMessage,
        confirmationAction
      ) =>
        dispatch(
          promptActionCreators.showPrompt({
            promptMessage,
            promptSaveText,
            promptSubMessage,
            confirmationAction,
            promptCancelText: null,
            promptSubMessageClassName: undefined,
          })
        )
      }
      messageToShow="No checklist templates exist"
      additionalButton={{
        text: "Copy checklist template",
        onClick: (item) =>
          dispatch(
            actionCreators.forms.todoTemplate.showForm({
              defaultName: `Copy of ${item.name}`,
              defaultTodoItems: item.todoItems.map((i) => ({ ...i })),
            })
          ),
      }}
    />
  );
};

export default TodoTemplates;
