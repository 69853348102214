import React from "react";
import { useDispatch } from "react-redux";
import CardContextMenu from "../../../containers/app/components/CardContextMenu";
import CardContextMenuButtonLink from "../../../containers/app/components/CardContextMenuButtonLink";
import CardContextMenuEditSubMenu from "../../../containers/app/components/CardContextMenuEditSubMenu";
import { ICustomer } from "../../../models/ICustomer";
import { IOpportunity } from "../models/IOpportunity";
import { IProposalExisting } from "../models/IProposal";
import { opportunitiesActionCreators } from "../modules/opportunity";
import OpportunityActions from "./OpportunityActions";

interface IProps {
  opportunity: IOpportunity;
  proposal: IProposalExisting | null;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUnarchiving: React.Dispatch<React.SetStateAction<boolean>>;
  customer: ICustomer;
  isProposalLane: boolean;
}

const OpportunityCardContextMenu: React.FunctionComponent<IProps> = ({
  opportunity,
  proposal,
  setIsDeleting,
  setIsUnarchiving,
  customer,
  isProposalLane,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <OpportunityActions<{ skipContextMenuCloseClass: string }>
        opportunity={opportunity}
        proposal={proposal}
        setIsDeleting={setIsDeleting}
        setIsUnarchiving={setIsUnarchiving}
        customer={customer}
        isProposalLane={isProposalLane}
        setErrorMessage={(errorMessage) =>
          dispatch(
            opportunitiesActionCreators.setError({
              pageErrorMessage: errorMessage,
            })
          )
        }
        renderDropDownContainer={({
          children,
          isContextMenuOpen,
          setIsContextMenuOpen,
        }) => {
          return (
            <CardContextMenu
              width={120}
              isContextMenuOpen={isContextMenuOpen}
              setIsContextMenuOpen={setIsContextMenuOpen}
            >
              {(renderProps) => (
                <>
                  <div className="context-menu-buttons">
                    {children(renderProps)}
                  </div>
                </>
              )}
            </CardContextMenu>
          );
        }}
        renderDropDownItem={({ text, onClick }) => (
          <p>
            <CardContextMenuButtonLink
              buttonContents={text}
              onClick={onClick}
            />
          </p>
        )}
        renderSubMenuParent={({
          text,
          children,
          showChildren,
          setShowChildren,
          containerRenderProps,
        }) => (
          <CardContextMenuEditSubMenu
            text={text}
            skipContextMenuCloseClass={
              containerRenderProps.skipContextMenuCloseClass
            }
            showChildren={showChildren}
            setShowChildren={setShowChildren}
          >
            <div className="context-menu-buttons">{children}</div>
          </CardContextMenuEditSubMenu>
        )}
        renderLink={({ text, url, target, rel }) => (
          <p>
            <a
              style={{ marginLeft: "1px" }}
              href={url}
              target={target}
              rel={rel}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {text}
            </a>
          </p>
        )}
      />
    </>
  );
};

export default OpportunityCardContextMenu;
