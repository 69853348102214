import { IPhotoThumbnail } from "./IPhoto";
import { MaintenanceJobFrequency, ISeasonalDate } from "./IMaintenanceJob";

export interface IReport {
  customers: Array<IBillingReportCustomer>;
}

export interface IBillingReportCustomer {
  id: string;
  quickBooksCustomerId: string | null;
  type: BillingReportJobType;
  jobId: string;
  name: string;
  services: string;
  customerCategories: string;
  customerStreetAndNumber: string;
  customerApartmentSuite: string | null;
  customerLatitude: number | null;
  customerLongitude: number | null;

  frequency: MaintenanceJobFrequency;
  seasonalScheduleFrequency: MaintenanceJobFrequency | null;
  seasonalScheduleStart: ISeasonalDate | null;
  seasonalScheduleEnd: ISeasonalDate | null;

  projectEstimatedManHours: number | null;
  projectGrossRevenue: number | null;

  customerAdditionalLocationId: string;
  customerAdditionalLocationName: string | null;
  customerAdditionalLocationsStreetAndNumber: string;
  customerAdditionalLocationsLatitude: number | null;
  customerAdditionalLocationsLongitude: number | null;
  jobInstances: Array<IBillingReportJobInstance>;
}

export interface IBillingReportJobInstance {
  id: string;
  date: string;
  estimatedManHours: number | undefined;
  actualManHours?: number;
  grossRevenuePerVisit?: number;

  showNotesLink: boolean;

  purchaseOrderNumber: string;
  invoiceNumber: string;
  invoiceSent: boolean;
}

export interface IBillingReportJobInstanceDetails {
  crewId: string | null;
  jobInstanceId: string;
  todoItems: Array<ITodoItem>;
  notesFromCrew: string;
  notesForCrew: string;
  administratorOnlyNotes: string;
  signatureImagePath: string;
  photos: Array<IPhoto>;
  customCrewQuestionResponses: Array<CustomCrewQuestionResponse>;
}

export enum BillingReportJobType {
  MaintenanceJob = 0,
  OneTimeJob = 1,
  Project = 2,
}

export interface ITodoItem {
  text: string;
  completed: boolean;
}

export interface IPhoto {
  id: string;
  caption: string;
  imagePath: string;
  contentType: string;
  thumbnails: Array<IPhotoThumbnail>;
  timestamp: string | null;
}

export interface CustomCrewQuestionResponse {
  questionText: string;
  answer: string;
}
