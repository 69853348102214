import constants from "../../../constants";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { getQueryVariable } from "../../../services/getQueryVariable";
import { isStringSet } from "../../../services/stringService";

export function setSessionProperties() {
  window.sessionStorage.setItem("isSignUp", "true");

  if (isExistingReferralSet()) {
    if (isUtmQueryStringSet() && window.location.href) {
      window.sessionStorage.setItem(
        constants.signUpReferralSessionStorageKey,
        window.location.href
      );
    } else {
      const decodedReferral = getReferralCodeFromQueryString();

      if (decodedReferral) {
        window.sessionStorage.setItem(
          constants.signUpReferralSessionStorageKey,
          decodedReferral
        );
      }
    }
  }

  let plan = getQueryVariable("plan");
  if (plan) {
    window.sessionStorage.setItem(constants.planSessionStorageKey, plan);
  }

  const trialUnavailable = getQueryVariable(
    constants.trialUnavailableQueryStringKey
  );
  if (trialUnavailable) {
    window.sessionStorage.setItem(
      constants.trialUnavailableSessionStorageKey,
      trialUnavailable
    );
  }
}

function isExistingReferralSet() {
  try {
    const existingReferral = window.sessionStorage.getItem(
      constants.signUpReferralSessionStorageKey
    );
    return (
      !existingReferral ||
      (existingReferral.toLowerCase().indexOf("utm") === -1 &&
        isUtmQueryStringSet())
    );
  } catch {
    return false;
  }
}

function getReferralCodeFromQueryString() {
  let encodedReferral = getQueryVariable("ref");
  let decodedReferral = "";
  if (encodedReferral) {
    try {
      decodedReferral = atob(encodedReferral);
    } catch {
      decodedReferral = "https://youraspire.com/InvalidRefSupplied";
    }
  } else if (document.referrer) {
    decodedReferral = document.referrer;
    fullStoryTrack("Referral Code Not Supplied");
  }
  return decodedReferral;
}

function isUtmQueryStringSet() {
  const utmFields = [
    "utm_campaign",
    "utm_source",
    "utm_medium",
    "utm_content",
    "utm_term",
  ];
  return utmFields.some((field) =>
    isStringSet(getQueryVariable(field) ?? null)
  );
}
