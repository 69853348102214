import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import constants from "../../../constants";
import Prompt from "../../../containers/app/components/Prompt";
import Spinner from "../../../containers/app/components/Spinner";
import { actionCreators } from "../../../modules/actionCreators";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import remoteDataProvider from "../../../services/remoteDataProvider";
import invoiceDataProvider from "../services/invoiceDataProvider";

interface IProps {
  customerName: string;
  jobInstanceIds?: Array<string>;
  contractBillingHistoryItemId?: string;
  onCreateInvoiceClick(): void;
  onDoNotInvoiceSaveComplete(): void;
}

const BillingWorkNotInvoicedActionButtons: React.FunctionComponent<IProps> = ({
  customerName,
  jobInstanceIds,
  contractBillingHistoryItemId,
  onCreateInvoiceClick,
  onDoNotInvoiceSaveComplete,
}) => {
  const [saving, setSaving] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDoNotInvoicePrompt, setShowDoNotInvoicePrompt] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      {saving ? <Spinner /> : null}

      <ButtonDropdown
        direction="down"
        isOpen={showDropDown}
        toggle={() => setShowDropDown(!showDropDown)}
      >
        <button
          className="btn btn-secondary btn-sm"
          onClick={onCreateInvoiceClick}
        >
          Create invoice
        </button>
        <DropdownToggle split color="secondary" size="sm" />
        <DropdownMenu positionFixed={true}>
          <DropdownItem
            onClick={() => {
              setShowDoNotInvoicePrompt(true);
            }}
          >
            Do not invoice
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <Prompt
        showPrompt={showDoNotInvoicePrompt}
        promptMessage={
          (jobInstanceIds?.length ?? 0) > 1
            ? `Are you sure you want to mark these jobs for ${customerName} as 'Do not invoice'?`
            : `Are you sure you want to mark this job for ${customerName} as 'Do not invoice'?`
        }
        onCancel={() => setShowDoNotInvoicePrompt(false)}
        onConfirm={() => {
          setShowDoNotInvoicePrompt(false);
          setSaving(true);

          if (jobInstanceIds) {
            const updates = jobInstanceIds.map((jobInstanceId) => ({
              id: jobInstanceId,
              doNotInvoice: true,
            }));

            remoteDataProvider.saveJobInstances(updates).subscribe({
              next: () => {
                setSaving(false);
                onDoNotInvoiceSaveComplete();
                dispatch(actionCreators.jobInstanceBulkUpdated(updates));
              },

              error: (err) => {
                setSaving(false);
                setErrorMessage(
                  getErrorMessageFromError(err, constants.unknownErrorMessage)
                );
              },
            });
          } else if (contractBillingHistoryItemId) {
            invoiceDataProvider
              .updateContractBillingHistoryItem({
                contractBillingHistoryItemId,
                changes: {
                  doNotInvoice: true,
                },
              })
              .subscribe({
                next: () => {
                  setSaving(false);
                  onDoNotInvoiceSaveComplete();
                },

                error: (err) => {
                  setSaving(false);
                  setErrorMessage(
                    getErrorMessageFromError(err, constants.unknownErrorMessage)
                  );
                },
              });
          }
        }}
      />

      {errorMessage ? (
        <Modal isOpen={true}>
          <ModalBody>
            <div className="text-danger">{errorMessage}</div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setErrorMessage("")}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};

export default BillingWorkNotInvoicedActionButtons;
