import {
  Control,
  useFieldArray,
  UseFieldArrayRemove,
  UseFieldArraySwap,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormData } from "./WorksheetForm.types";
import {
  faChevronUp,
  faChevronDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuidv4 from "uuid/v4";
import { WorksheetContentType } from "../enums/WorksheetContentType";
import { getEmptyContentItem, getEmptyOption } from "./WorksheetForm.functions";

export function WorksheetFormQuestions({
  control,
  register,
  watch,
  getValues,
  setValue,
}: {
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
  watch: UseFormWatch<FormData>;
  getValues: UseFormGetValues<FormData>;
  setValue: UseFormSetValue<FormData>;
}) {
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: `contentItems`,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="bg-light">
          <div className="border p-3 my-4">
            <WorksheetFormQuestion
              questionIndex={index}
              questionLength={fields.length}
              register={register}
              remove={remove}
              swap={swap}
              watch={watch}
              control={control}
              getValues={getValues}
              setValue={setValue}
            />
          </div>
        </div>
      ))}

      <button
        className="btn btn-secondary btn-sm"
        type="button"
        onClick={() => {
          append(getEmptyContentItem());
        }}
      >
        Add question
      </button>
    </>
  );
}

function WorksheetFormQuestion({
  questionIndex,
  register,
  questionLength,
  remove,
  swap,
  watch,
  control,
  getValues,
  setValue,
}: {
  questionIndex: number;
  register: UseFormRegister<FormData>;
  questionLength: number;
  remove: UseFieldArrayRemove;
  swap: UseFieldArraySwap;
  watch: UseFormWatch<FormData>;
  control: Control<FormData>;
  getValues: UseFormGetValues<FormData>;
  setValue: UseFormSetValue<FormData>;
}) {
  const questionType = watch(`contentItems.${questionIndex}.type` as const);

  return (
    <>
      <div className="form-row">
        <div className="col-6 col-sm-5 col-lg-3 form-group">
          <select
            className="form-control"
            aria-label="Question type"
            {...register(`contentItems.${questionIndex}.type` as const, {
              onChange: () => {
                const optionsKey =
                  `contentItems.${questionIndex}.optionListOptions` as const;
                const existingOptions = getValues(optionsKey);
                if (existingOptions.length === 0) {
                  setValue(optionsKey, [getEmptyOption()]);
                }
              },
            })}
          >
            <option value={WorksheetContentType.QuestionShortAnswer.toString()}>
              Short answer
            </option>
            <option value={WorksheetContentType.QuestionLongAnswer.toString()}>
              Paragraph
            </option>
            <option value={WorksheetContentType.QuestionCheckbox.toString()}>
              Checkbox
            </option>
            <option value={WorksheetContentType.QuestionFileUpload.toString()}>
              File upload
            </option>
            <option value={WorksheetContentType.QuestionBoolean.toString()}>
              Yes/No
            </option>
            <option value={WorksheetContentType.QuestionDate.toString()}>
              Date
            </option>
            <option value={WorksheetContentType.QuestionOptionList.toString()}>
              Drop-down
            </option>
            <option value={WorksheetContentType.QuestionNumber.toString()}>
              Number
            </option>
          </select>
        </div>
        <div className="col-1 col-lg-3 order-last order-sm-0">
          <div className="custom-control custom-checkbox form-group">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`questionrequired_${questionIndex}`}
              {...register(`contentItems.${questionIndex}.required` as const)}
            />
            <label
              className="custom-control-label"
              htmlFor={`questionrequired_${questionIndex}`}
            >
              Required
            </label>
          </div>
        </div>
        {questionLength > 1 ? (
          <div
            className="col-6 form-group d-flex flex-nowrap justify-content-end align-self-start"
            style={{ columnGap: "10px" }}
          >
            {questionIndex > 0 ? (
              <button
                className="btn btn-link text-dark p-0"
                type="button"
                onClick={() => {
                  swap(questionIndex, questionIndex - 1);
                }}
              >
                <FontAwesomeIcon icon={faChevronUp} title="Move question up" />
              </button>
            ) : null}

            {questionIndex < questionLength - 1 ? (
              <button
                className="btn btn-link text-dark p-0"
                type="button"
                onClick={() => {
                  swap(questionIndex, questionIndex + 1);
                }}
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  title="Move question down"
                />
              </button>
            ) : null}

            <button
              className="btn btn-link text-dark p-0"
              type="button"
              onClick={() => {
                remove(questionIndex);
              }}
            >
              <FontAwesomeIcon icon={faTrash} title="Delete question" />
            </button>
          </div>
        ) : null}
      </div>
      <div className="form-group">
        <input
          type="text"
          id={`questionrequired_${questionIndex}`}
          className="form-control"
          placeholder="Question"
          aria-label="Question"
          maxLength={150}
          required
          {...register(`contentItems.${questionIndex}.text` as const)}
        />
      </div>

      {questionType === WorksheetContentType.QuestionOptionList.toString() ? (
        <>
          <OptionList
            control={control}
            questionIndex={questionIndex}
            register={register}
          />
        </>
      ) : null}
    </>
  );
}

function OptionList({
  control,
  questionIndex,
  register,
}: {
  control: Control<FormData>;
  questionIndex: number;
  register: UseFormRegister<FormData>;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `contentItems.${questionIndex}.optionListOptions` as const,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="form-row">
          <div className="col-12 col-xl-6 form-group input-group">
            <input
              type="text"
              required
              className="form-control"
              placeholder="Drop-down option"
              aria-label="Drop-down option"
              {...register(
                `contentItems.${questionIndex}.optionListOptions.${index}.label` as const
              )}
            />

            {fields.length > 1 ? (
              <div className="input-group-append">
                <button
                  className="btn btn-link text-dark p-0 pl-2"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon icon={faTrash} title="Delete option" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ))}

      <div>
        <button
          className="btn btn-link p-0"
          type="button"
          onClick={() => {
            append({
              id: uuidv4(),
              label: ``,
            });
          }}
        >
          Add another
        </button>
      </div>
    </>
  );
}
