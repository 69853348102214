import { useState } from "react";
import PhoneNumberField from "../../../containers/app/components/PhoneNumberField";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import onboardingDataProvider from "../services/onboardingDataProvider";
import Spinner from "../../../containers/app/components/Spinner";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";

const closedStickySettingValue = "true";
export function InitialDispatchPrompt() {
  const showDispatchPrompt = useApplicationStateSelector(
    (s) => s.common.showDispatchPrompt
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [closed, setClosed] = useState(false);

  const dispatch = useDispatch();

  const { getUserSettings, setUserSettings } = useUserSettings();

  const initialDispatchPromptClosed = getUserSettings(
    UserSettingsType.initialDispatchPromptClosed
  );

  if (
    // Even if showDispatchPrompt is false, still show if
    // closed so confirmation appears
    (!showDispatchPrompt && !showConfirmation) ||
    closed ||
    initialDispatchPromptClosed === closedStickySettingValue
  ) {
    return null;
  }

  return (
    <>
      {saving ? <Spinner /> : null}

      <div data-testid="dispatchPrompt" className="mt-3">
        <div>
          <div className="d-flex justify-content-center align-items-center">
            <div
              data-testid="billingInfoPrompt"
              className={"alert alert-primary"}
              role="alert"
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                data-testid="close"
                onClick={() => {
                  setClosed(true);
                  setUserSettings(
                    UserSettingsType.initialDispatchPromptClosed,
                    "true"
                  );
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {!showConfirmation ? (
                <>
                  <div className="d-flex justify-content-center">
                    <div>
                      <FontAwesomeIcon
                        style={{ background: "white", borderRadius: "20%" }}
                        icon={faMobile}
                        color="text-info"
                        size="3x"
                      ></FontAwesomeIcon>
                    </div>
                    <div className="ml-3">
                      <div>
                        <h5 className="mr-3">
                          Try out our crew member mobile experience!
                        </h5>
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          fullStoryTrack("Dispatch Prompt Submitted");

                          setSaving(true);

                          onboardingDataProvider
                            .publishExampleSchedule({
                              phoneNumber,
                            })
                            .subscribe({
                              next: () => {
                                setSaving(false);
                                setShowConfirmation(true);
                                dispatch(actionCreators.initialDispatchSent());
                              },

                              error: (err) => {
                                setSaving(false);
                                setErrorMessage(getErrorMessageFromError(err));
                              },
                            });
                        }}
                      >
                        <div
                          className="d-flex align-items-stretch justify-content-center"
                          style={{
                            gap: "10px",
                          }}
                        >
                          <div style={{ maxWidth: "125px" }}>
                            <PhoneNumberField
                              id={"dispatchPhoneNumber"}
                              dataTestId="dispatchPhoneNumber"
                              onChange={(e) =>
                                setPhoneNumber(e.currentTarget.value)
                              }
                              value={phoneNumber}
                              className={"form-control form-control"}
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-secondary btn-sm"
                            data-testid="send"
                          >
                            Send Schedule
                          </button>
                        </div>
                        {errorMessage ? (
                          <div
                            className="text-danger"
                            data-testid="errorMessage"
                          >
                            {errorMessage}
                          </div>
                        ) : null}
                      </form>
                    </div>
                  </div>
                  <div className="text-center">
                    <small>
                      <a
                        href="https://aspire-extensions.document360.io/crew-control/docs/mobile-app"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more about our mobile applications
                      </a>
                    </small>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center"
                    data-testid="scheduleSentConfirmation"
                  >
                    <div style={{ maxWidth: "700px" }}>
                      <div>
                        <strong>Schedule sent!</strong>
                      </div>
                      <div>
                        To send automatically every day, go to Settings &gt;
                        Dispatch Scheduling.
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
