import { SizeProp, IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";

export function ReorderButtonsForListItem({
  onMoveUp,
  onMoveDown,
  moveUpDisabled,
  moveDownDisabled,
}: {
  onMoveUp: () => void;
  onMoveDown: () => void;
  moveUpDisabled: boolean;
  moveDownDisabled: boolean;
}) {
  return (
    <>
      <MoveUpButtonForListItem
        onMoveUp={onMoveUp}
        disabled={moveUpDisabled}
        size="sm"
      />
      <MoveDownButtonForListItem
        onMoveDown={onMoveDown}
        disabled={moveDownDisabled}
        size="sm"
      />
    </>
  );
}

export function MoveUpButtonForListItem({
  size,
  className,
  onMoveUp,
  buttonStyle,
  disabled,
}: {
  size?: SizeProp;
  className?: string;
  onClick?: () => void;
  buttonStyle?: Partial<CSSProperties>;
  onMoveUp: () => void;
  disabled: boolean;
}) {
  return (
    <ReorderButton
      disabled={disabled}
      icon={faChevronUp}
      title="Move up"
      onClick={() => {
        if (!disabled) {
          onMoveUp();
        }
      }}
      size={size}
      className={className}
      buttonStyle={buttonStyle}
      testId="moveUpButton"
    />
  );
}

export function MoveDownButtonForListItem({
  size,
  className,
  onMoveDown,
  buttonStyle,
  disabled,
}: {
  size?: SizeProp;
  className?: string;
  onMoveDown: () => void;
  buttonStyle?: Partial<CSSProperties>;
  disabled: boolean;
}) {
  return (
    <ReorderButton
      disabled={disabled}
      icon={faChevronDown}
      title="Move down"
      onClick={() => {
        if (!disabled) {
          onMoveDown();
        }
      }}
      size={size}
      className={className}
      buttonStyle={buttonStyle}
      testId="moveDownButton"
    />
  );
}

function ReorderButton({
  disabled,
  icon,
  title,
  onClick,
  size,
  className,
  buttonStyle,
  testId,
}: {
  disabled: boolean;
  icon: IconProp;
  title: string;
  onClick: () => void;
  size?: SizeProp;
  className?: string;
  buttonStyle?: Partial<CSSProperties>;
  testId?: string;
}) {
  return (
    <button
      className={`btn btn-secondary btn-sm ${className ?? ""}`}
      style={{
        border: 0,
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        lineHeight: 0.5,
        display: "block",
        ...(buttonStyle ?? {}),
      }}
      type="button"
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      <FontAwesomeIcon icon={icon} size={size} title={title} />
    </button>
  );
}
