import constants, { updatedPricingCosts } from "../../../constants";
import { SubscriptionFrequency } from "../../../enums/subscriptionFrequency";
import { SubscriptionType } from "../../../enums/subscriptionType";
import { TenantPlan } from "../../../enums/tenantPlan";
import { formatCurrency } from "../../../services/currencyFormatter";
import { ISubscriptionPriceChange } from "../models/ISubscriptionPriceChange";

export function getPriceChangeMessage({
  priceChangeDetails,
}: {
  priceChangeDetails: ISubscriptionPriceChange;
}) {
  if (priceChangeDetails.newPlanAmount === null) {
    return <></>;
  }

  return (
    <>
      <div data-testid="newPlanAmountDetails">
        Your subscription will be updated to{" "}
        <span className="font-weight-bold">
          {formatCurrency(priceChangeDetails.newPlanAmount)} per{" "}
          {priceChangeDetails.subscriptionFrequency ===
          SubscriptionFrequency.annual
            ? "year"
            : "month"}
          .
        </span>
      </div>
      {typeof priceChangeDetails.prorationAmount === "number" &&
      priceChangeDetails.prorationAmount !== 0 ? (
        <div data-testid="prorationAmountDetails">
          {priceChangeDetails.prorationAmount > 0 ? (
            <>
              A charge of{" "}
              <span className="font-weight-bold">
                {formatCurrency(priceChangeDetails.prorationAmount)}
              </span>{" "}
              will be made now for the remainder of your billing period.
            </>
          ) : (
            "A credit will be added to your next payment for the unused time."
          )}
        </div>
      ) : null}
    </>
  );
}

export function getSubscriptionPrice({
  tenantPlan,
  subscriptionType,
  subscriptionFrequency,
  useUpdatedPricing,
  numberOfCrews,
}: {
  tenantPlan: TenantPlan | null;
  subscriptionType: SubscriptionType;
  subscriptionFrequency: SubscriptionFrequency;
  useUpdatedPricing: boolean;
  numberOfCrews: number;
}): GetSubscriptionPriceResult {
  let basePricePerMonth: number | null;
  let additionalUnitPricePerMonth: number | null;
  let basePriceCrewCountCutOff: number | null;

  let totalCostPerMonth: number;
  if (subscriptionType === SubscriptionType.crew) {
    if (!useUpdatedPricing) {
      additionalUnitPricePerMonth =
        tenantPlan === TenantPlan.plus
          ? constants.crewPlusCostPerMonth
          : constants.crewBasicCostPerMonth;
      totalCostPerMonth = additionalUnitPricePerMonth * numberOfCrews;
      basePricePerMonth = null;
      basePriceCrewCountCutOff = null;
    } else {
      if (
        tenantPlan === TenantPlan.basic &&
        subscriptionFrequency === SubscriptionFrequency.month
      ) {
        additionalUnitPricePerMonth =
          updatedPricingCosts.crewBasicMonthlyCostPerMonth;
      } else if (
        tenantPlan === TenantPlan.plus &&
        subscriptionFrequency === SubscriptionFrequency.month
      ) {
        additionalUnitPricePerMonth =
          updatedPricingCosts.crewPlusMonthlyCostPerMonth;
      } else if (
        tenantPlan === TenantPlan.basic &&
        subscriptionFrequency === SubscriptionFrequency.annual
      ) {
        additionalUnitPricePerMonth =
          updatedPricingCosts.crewBasicAnnuallyCostPerMonth;
      } else {
        additionalUnitPricePerMonth =
          updatedPricingCosts.crewPlusAnnuallyCostPerMonth;
      }

      totalCostPerMonth = additionalUnitPricePerMonth * numberOfCrews;
      basePricePerMonth = null;
      basePriceCrewCountCutOff = null;
    }
  } else {
    if (
      tenantPlan === TenantPlan.basic &&
      subscriptionFrequency === SubscriptionFrequency.month
    ) {
      basePricePerMonth = updatedPricingCosts.techBasicMonthlyFixedCostPerMonth;
      additionalUnitPricePerMonth =
        updatedPricingCosts.techBasicMonthlyAdditionalCostPerMonth;
    } else if (
      tenantPlan === TenantPlan.plus &&
      subscriptionFrequency === SubscriptionFrequency.month
    ) {
      basePricePerMonth = updatedPricingCosts.techPlusMonthlyFixedCostPerMonth;
      additionalUnitPricePerMonth =
        updatedPricingCosts.techPlusMonthlyAdditionalCostPerMonth;
    } else if (
      tenantPlan === TenantPlan.basic &&
      subscriptionFrequency === SubscriptionFrequency.annual
    ) {
      basePricePerMonth =
        updatedPricingCosts.techBasicAnnuallyFixedCostPerMonth;
      additionalUnitPricePerMonth =
        updatedPricingCosts.techBasicAnnuallyAdditionalCostPerMonth;
    } else {
      basePricePerMonth = updatedPricingCosts.techPlusAnnuallyFixedCostPerMonth;
      additionalUnitPricePerMonth =
        updatedPricingCosts.techPlusAnnuallyAdditionalCostPerMonth;
    }

    basePriceCrewCountCutOff = 2;
    if (numberOfCrews <= basePriceCrewCountCutOff) {
      totalCostPerMonth = basePricePerMonth;
    } else {
      totalCostPerMonth =
        basePricePerMonth + (numberOfCrews - 2) * additionalUnitPricePerMonth;
    }
  }

  const chargedAmount =
    subscriptionFrequency === SubscriptionFrequency.annual
      ? totalCostPerMonth * 12
      : totalCostPerMonth;

  return {
    basePricePerMonth,
    additionalUnitPricePerMonth,
    basePriceCrewCountCutOff,
    totalCostPerMonth,
    chargedAmount,
    firstTimeChargeAmount: chargedAmount,
  };
}

export type GetSubscriptionPriceResult = {
  basePricePerMonth: number | null;
  additionalUnitPricePerMonth: number;
  basePriceCrewCountCutOff: number | null;
  totalCostPerMonth: number;
  chargedAmount: number;
  firstTimeChargeAmount: number;
};
