import FormContainerWithoutRedux from "../../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../../formGenerator/formTypes";
import {
  Controller,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import React, { useState } from "react";
import InvoiceLineItems from "../../../../containers/app/components/InvoiceLineItems";
import { mapToProposalLineItems } from "../../../../services/invoiceFormService";
import { EditorState } from "draft-js";
import InvoiceFormAmounts from "../../../../containers/app/forms/InvoiceFormAmounts";
import { IInvoiceItem } from "../../../../models/IInvoiceItem";
import { useGetCustomerFromStore } from "../../../../hooks/useGetCustomerFromStore";
import { useGetOpportunityFromStore } from "../../hooks/useGetOpportunityFromStore";
import { forkJoin, of } from "rxjs";
import proposalDataProvider, {
  ProposalSaveRequest,
  ProposalSaveResponse,
} from "../../services/proposalDataProvider";
import {
  useUserSettings,
  SetUserSettingsType,
  GetUserSettingsType,
} from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { serializeTextState } from "../../../../services/richTextService";
import { IProposalTemplate } from "../../models/IProposalTemplate";
import ProposalTemplateSelection from "./ProposalTemplateSelection";
import { IFormData } from "./IFormData";
import { DateField } from "./DateField";
import DeliveryFields from "./DeliveryFields";
import FilesField from "./FilesField";
import DepositFields from "./DepositFields";
import useSaveCompleteHandler from "./useSaveCompleteHandler";
import getFormDataForExistingProposal from "./getFormDataForExistingProposal";
import getFormDataForNewProposal from "./getFormDataForNewProposal";
import TextField from "./TextField";
import {
  ErrorMessageType,
  IAdditionalSaveButton,
  IValidateResult,
} from "../../../../containers/app/components/FormContainer";
import { ICustomer } from "../../../../models/ICustomer";
import getDefaultValuesFromTemplate from "./getDefaultValuesFromTemplate";
import { IQuickBooksCustomer } from "../../../../models/IQuickBooksCustomer";
import { getEmailAddressesForSave } from "../../../../containers/app/components/EmailAddresses.functions";
import { SendType } from "../../../../enums/sendType";
import { useGetReplyToEmailAddress } from "../../../../hooks/useGetReplyToEmailAddress";
import { fullStoryTrack } from "../../../../services/fullStoryService";
import CaptureCustomerPaymentMethod from "./CaptureCustomerPaymentMethod";
import dateService from "../../../../services/dateService";
import { differenceInCalendarDays } from "date-fns";
import ProposalTemplateForm from "../ProposalTemplateForm";
import useGetDefaultTaxRate from "../../hooks/useGetDefaultTaxRate";
import TextareaAutosize from "react-autosize-textarea";
import getProposal from "./getProposal";
import { IProposal } from "../../models/IProposal";
import { isTaxRateSet } from "../../../../containers/app/forms/InvoiceFormAmounts.functions";
import { isDepositRequired } from "../../services/proposalService";

export default function ProposalFormBody({
  proposalId,
  opportunityId,
  customerId,
  invoiceItems,
  setInvoiceItems,
  proposalTemplates,
  quickBooksCustomers,
  setQuickBooksCustomers,
  defaultValues,
  hideTemplateField,
  setProposalTemplates,
  onSaveComplete,
  onCancel,
}: {
  proposalId: string | null;
  opportunityId: string | null;
  customerId: string | null;
  onSaveComplete: () => void;
  onCancel: () => void;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  proposalTemplates: Array<IProposalTemplate>;
  setProposalTemplates: React.Dispatch<
    React.SetStateAction<Array<IProposalTemplate>>
  >;
  quickBooksCustomers: Array<IQuickBooksCustomer>;
  setQuickBooksCustomers: React.Dispatch<
    React.SetStateAction<Array<IQuickBooksCustomer>>
  >;
  defaultValues?: Partial<IFormData>;
  hideTemplateField?: boolean;
}) {
  const [fileUploading, setFileUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const [showUpdateDaysValid, setShowUpdateDaysValid] = useState(false);
  const [defaultDaysValid, setDefaultDaysValid] = useState(0);
  const [showProposalTemplateForm, setShowProposalTemplateForm] =
    useState(false);

  const opportunity = useGetOpportunityFromStore(opportunityId);
  const customer = useGetCustomerFromStore(
    opportunity?.customerId ?? customerId ?? null
  );

  const [selectedTemplate, setSelectedTemplate] =
    useState<Partial<IProposalTemplate> | null>(
      proposalTemplates.find((t) => t.isPrimaryTemplate) ?? null
    );

  const defaultTaxRate = useGetDefaultTaxRate(customer);

  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    originalFormData,
    proposal,
  } = useInitializeForm({
    proposalId,
    customer,
    proposalTemplates,
    invoiceItems,
    defaultValues,
    defaultTaxRate,
  });

  const saveCompleteHandler = useSaveCompleteHandler();

  const { getUserSettings, setUserSettings } = useUserSettings();

  const showLineItemPrices = watch("showLineItemPrices");
  const lineItems = watch("lineItems");
  const validUntilDate = watch("validUntilDate");
  const proposalDate = watch("proposalDate");

  const captureCustomerPaymentMethod = watch("captureCustomerPaymentMethod");
  const captureCustomerPaymentMethodOptional = watch(
    "captureCustomerPaymentMethodOptional"
  );
  const showUpdateButton =
    showUpdateDaysValid &&
    validUntilDate.length > 0 &&
    proposalDate.length > 0 &&
    defaultDaysValid !== selectedTemplate?.daysValid &&
    !isUpdateForm(proposalId);

  if ((!customerId && opportunity === null) || customer === null) {
    return null;
  }

  return (
    <FormContainerWithoutRedux<
      {
        saveResult: ProposalSaveResponse;
        initialPayload: ProposalSaveRequest;
      },
      ProposalSaveRequest
    >
      formHeader={getFormHeader(proposalId, customer)}
      formType={FormTypesV2.proposal}
      size="xl"
      disableSubmitOnEnterPress={!isUpdateForm(proposalId)}
      additionalPrimaryButtons={getAdditionalPrimarySaveButtons(
        isUpdateForm(proposalId)
      )}
      validate={() => {
        return validateForm(fileUploading, getValues);
      }}
      save={(enrichFormData) => {
        let savePayload: ProposalSaveRequest = getSavePayload({
          getValues,
          isUpdate: isUpdateForm(proposalId),
          proposalId,
          opportunityId,
          customerId,
          invoiceItems,
          enrichFormData,
          setUserSettings,
        });

        return forkJoin({
          saveResult: proposalDataProvider.saveProposal(savePayload),
          initialPayload: of(savePayload),
        });
      }}
      onSaveComplete={({ initialPayload, saveResult }) => {
        if (initialPayload?.depositSettings !== null) {
          fullStoryTrack("Deposit Created");
        }
        if (initialPayload?.captureCustomerPaymentMethod) {
          fullStoryTrack("Save Payment Method Required");
        }
        saveCompleteHandler({
          initialPayload,
          customer,
          onSaveComplete,
          proposalId,
          saveResult,
        });
      }}
      hasFormDataChanged={() => {
        return JSON.stringify(getValues()) !== JSON.stringify(originalFormData);
      }}
      onCancel={onCancel}
      setErrorMessage={setErrorMessage}
      errorMessage={errorMessage}
      saveButtonText={getSaveButtonText(proposalId)}
    >
      {!isUpdateForm(proposalId) && !hideTemplateField ? (
        <ProposalTemplateSelection
          templates={proposalTemplates}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          onSaveComplete={(createdId) =>
            proposalTemplateSave(
              createdId,
              setProposalTemplates,
              setValue,
              getUserSettings
            )
          }
          onTemplateSelection={(template) => {
            setValuesFromTemplate(template, setValue, getUserSettings);
            setShowUpdateDaysValid(false);
          }}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      ) : null}

      <div className="form-row">
        <div className="col-12 col-lg-6 form-group">
          <DateField
            control={control}
            label="Proposal date"
            fieldId="proposalDate"
            onDaySelected={(fromDate: Date) => {
              let toDate = getValues("validUntilDate");
              if (fromDate && toDate.length > 0) {
                updateDefaultDaysValid(
                  dateService.formatAsIso(fromDate),
                  toDate,
                  setShowUpdateDaysValid,
                  setDefaultDaysValid
                );
              } else {
                setShowUpdateDaysValid(false);
              }
            }}
          />
        </div>
        <div
          className={
            showUpdateButton ? "col-12 col-lg-6" : "col-12 col-lg-6 form-group"
          }
        >
          <DateField
            control={control}
            label="Valid until"
            fieldId="validUntilDate"
            onDaySelected={(toDate: Date) => {
              let fromDate = getValues("proposalDate");
              if (toDate && fromDate.length > 0) {
                updateDefaultDaysValid(
                  fromDate,
                  dateService.formatAsIso(toDate),
                  setShowUpdateDaysValid,
                  setDefaultDaysValid
                );
              } else {
                setShowUpdateDaysValid(false);
              }
            }}
          />
          {showUpdateButton ? (
            <button
              type="button"
              className="btn btn-sm btn-link"
              data-testid="updateDefaultDaysValid"
              onClick={() => {
                setShowProposalTemplateForm(true);
              }}
            >
              Update default number of days valid
            </button>
          ) : null}
          {showProposalTemplateForm ? (
            <ProposalTemplateForm
              template={
                selectedTemplate?.id
                  ? {
                      ...proposalTemplates.find(
                        (t) => t.id === selectedTemplate.id
                      ),
                      daysValid: defaultDaysValid,
                    } ?? getDefaultTemplate(defaultDaysValid)
                  : getDefaultTemplate(defaultDaysValid)
              }
              invoiceItems={invoiceItems}
              setInvoiceItems={setInvoiceItems}
              onSaveComplete={(id) => {
                proposalTemplateSave(
                  id,
                  setProposalTemplates,
                  setValue,
                  getUserSettings
                );
                setSelectedTemplate({ id: id, ...selectedTemplate });
                setShowProposalTemplateForm(false);
                setShowUpdateDaysValid(false);
              }}
              onCancel={() => setShowProposalTemplateForm(false)}
            />
          ) : null}
        </div>
      </div>

      {!isUpdateForm(proposalId) ? (
        <DeliveryFields
          register={register}
          watch={watch}
          control={control}
          setValue={setValue}
          originalPhoneNumber={originalFormData.customerPhoneNumber}
          originalPhoneNumberOptedIntoSms={
            originalFormData.customerPhoneNumberOptedIntoSms
          }
        />
      ) : null}

      <TextField
        control={control}
        field="introText"
        label="Intro text"
        testId="introTextContainer"
      />

      <div className="form-group" data-testid="summaryContainer">
        <label htmlFor="summary">Job summary</label>
        <Controller
          name="summary"
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              maxRows={10}
              className="form-control"
              id="summary"
              value={field.value}
              onChange={(e) => {
                field.onChange(e.currentTarget.value);
              }}
            />
          )}
        />
      </div>

      <CaptureCustomerPaymentMethod
        idPrefix="Proposal"
        captureCustomerPaymentMethod={captureCustomerPaymentMethod}
        setCaptureCustomerPaymentMethod={(newValue: boolean) => {
          setValue("captureCustomerPaymentMethod", newValue);
        }}
        captureCustomerPaymentMethodOptional={
          captureCustomerPaymentMethodOptional
        }
        setCaptureCustomerPaymentMethodOptional={(newValue: boolean) => {
          setValue("captureCustomerPaymentMethodOptional", newValue);
        }}
      />

      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            id="showLineItemPricesTemplate"
            type="checkbox"
            className="custom-control-input"
            {...register("showLineItemPrices")}
          />
          <label
            htmlFor="showLineItemPricesTemplate"
            className="custom-control-label"
          >
            Show line item prices
          </label>
        </div>
      </div>

      <div className="form-group">
        <Controller
          name={`lineItems`}
          control={control}
          render={({ field }) => (
            <InvoiceLineItems
              elementIdPrefix="proposal"
              lineItems={field.value}
              invoiceItems={invoiceItems}
              setInvoiceItems={setInvoiceItems}
              setLineItems={(newValue) => field.onChange(newValue)}
              showHideOption={isHideOptionAvailable(showLineItemPrices)}
              customerTaxExempt={customer.taxExempt}
              taxRateAlreadySet={isTaxRateSet(proposal)}
              onClearErrorMessage={() => {
                setErrorMessage("");
              }}
              allowOptionalItems={true}
            />
          )}
        />
      </div>

      <div className="form-group">
        <Controller
          name={`amountAdjustments`}
          control={control}
          render={({ field }) => (
            <InvoiceFormAmounts
              lineItems={lineItems}
              customerTaxExempt={customer.taxExempt}
              taxRateAlreadySet={isTaxRateSet(proposal)}
              taxRate={field.value.taxRate}
              onTaxRateChange={(newTaxRate) =>
                field.onChange({
                  ...field.value,
                  taxRate: newTaxRate,
                })
              }
              discountFields={{
                discount: field.value.discount,
                onChange: (newDiscount) => {
                  field.onChange({
                    ...field.value,
                    discount: newDiscount,
                  });
                },
              }}
              alwaysShowTaxRate={true}
            />
          )}
        />

        <DepositFields
          control={control}
          register={register}
          setValue={setValue}
          watch={watch}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          quickBooksCustomers={quickBooksCustomers}
          setQuickBooksCustomers={setQuickBooksCustomers}
          customer={customer}
        />
      </div>

      <FilesField control={control} setFileUploading={setFileUploading} />

      <TextField
        control={control}
        field="footerText"
        label="Footer text"
        testId="footerTextContainer"
      />
    </FormContainerWithoutRedux>
  );
}

function getDefaultTemplate(defaultDaysValid: number) {
  return {
    name: "Default template",
    daysValid: defaultDaysValid,
    isPrimaryTemplate: true,
    depositSettings: null,
  };
}

function proposalTemplateSave(
  createdId: string,
  setProposalTemplates: React.Dispatch<
    React.SetStateAction<Array<IProposalTemplate>>
  >,
  setValue: UseFormSetValue<IFormData>,
  getUserSettings: GetUserSettingsType
) {
  proposalDataProvider.getProposalTemplates().subscribe((templates) => {
    setProposalTemplates(templates);
    // Update proposal when adding a template but not editing.
    if (createdId) {
      let createdTemplate = templates.find((t) => t.id === createdId);

      if (createdTemplate) {
        setValuesFromTemplate(createdTemplate, setValue, getUserSettings);
      }
    }
  });
}

function useInitializeForm({
  proposalId,
  customer,
  proposalTemplates,
  invoiceItems,
  defaultValues,
  defaultTaxRate,
}: {
  proposalId: string | null;
  customer: ICustomer | null;
  proposalTemplates: IProposalTemplate[];
  invoiceItems: IInvoiceItem[];
  defaultValues: Partial<IFormData> | undefined;
  defaultTaxRate: number | null;
}) {
  const { getUserSettings } = useUserSettings();
  const proposalConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalConfiguration
  );

  const getOriginalFormData = () =>
    proposalId
      ? getFormDataForExistingProposal(
          proposals,
          proposalId,
          customer,
          proposalTemplates,
          invoiceItems,
          defaultTaxRate,
          getUserSettings,
          proposalConfiguration
        )
      : getFormDataForNewProposal({
          customer,
          replyToEmailAddress,
          proposalTemplates,
          defaultValues,
          invoiceItems,
          defaultTaxRate,
          getUserSettings,
          proposalConfiguration,
        });

  const proposals = useApplicationStateSelector((s) => s.proposal.proposals);
  const proposal: IProposal | null = proposalId
    ? getProposal(proposals, proposalId) ?? null
    : null;
  const replyToEmailAddress = useGetReplyToEmailAddress();
  const [originalFormData] = useState<IFormData>(getOriginalFormData());

  const useFormResult = useForm<IFormData>({
    defaultValues: getOriginalFormData(),
  });

  return {
    control: useFormResult.control,
    getValues: useFormResult.getValues,
    setValue: useFormResult.setValue,
    register: useFormResult.register,
    watch: useFormResult.watch,
    originalFormData,
    proposal,
  };
}

function updateDefaultDaysValid(
  fromDate: string,
  toDate: string,
  setShowUpdateDaysValid: React.Dispatch<React.SetStateAction<boolean>>,
  setDefaultDaysValid: React.Dispatch<React.SetStateAction<number>>
) {
  var daydiff = differenceInCalendarDays(toDate, fromDate);

  if (daydiff > 0) {
    setDefaultDaysValid(daydiff);
    setShowUpdateDaysValid(true);
  } else {
    setShowUpdateDaysValid(false);
  }
}

function getFormHeader(proposalId: string | null, customer: ICustomer) {
  let formHeader: string;
  if (!isUpdateForm(proposalId)) {
    formHeader = `Send Proposal to ${customer?.name}`;
  } else {
    formHeader = "Edit Proposal";
  }
  return formHeader;
}

function getSaveButtonText(proposalId: string | null) {
  let saveButtonText: string;
  if (!isUpdateForm(proposalId)) {
    saveButtonText = "Send";
  } else {
    saveButtonText = "Save";
  }
  return saveButtonText;
}

function getAdditionalPrimarySaveButtons(
  isUpdate: boolean
): IAdditionalSaveButton[] {
  return !isUpdate
    ? [
        {
          label: "Save Without Sending",
          enrichFormData: (data: ProposalSaveRequest) => ({
            ...data,
            skipSend: true,
          }),
        },
      ]
    : [];
}

function validateForm(
  fileUploading: boolean,
  getValues: UseFormGetValues<IFormData>
) {
  let validateResult: IValidateResult = {
    valid: true,
  };

  if (fileUploading) {
    validateResult = {
      valid: false,
      errorMessage: "Please wait until all files are uploaded",
    };
  }

  if (getValues().lineItems.some((li) => !li.itemId)) {
    validateResult = {
      valid: false,
      errorMessage: "Line Item must be selected",
    };
  }

  return validateResult;
}

function isUpdateForm(proposalId: string | null): proposalId is string {
  return proposalId !== null;
}

function getSavePayload({
  getValues,
  isUpdate,
  proposalId,
  customerId,
  opportunityId,
  invoiceItems,
  enrichFormData,
  setUserSettings,
}: {
  getValues: UseFormGetValues<IFormData>;
  isUpdate: boolean;
  proposalId: string | null;
  opportunityId: string | null;
  customerId: string | null;
  invoiceItems: IInvoiceItem[];
  enrichFormData:
    | ((data: ProposalSaveRequest) => ProposalSaveRequest)
    | undefined;
  setUserSettings: SetUserSettingsType;
}) {
  const values = getValues();

  setUserSettings(
    UserSettingsType.taxRate,
    getValues("amountAdjustments").taxRate
  );

  setUserSettings(
    UserSettingsType.replyToEmailAddress,
    getValues("emailFields.replyToEmailAddress")
  );

  let depositSettingsForSave = values.depositRequired
    ? values.depositSettings
    : null;

  if (!isDepositRequired(depositSettingsForSave)) {
    depositSettingsForSave = null;
  }

  let savePayload: ProposalSaveRequest = {
    id: isUpdate ? (proposalId as string) : undefined,
    proposalDate: values.proposalDate,
    validUntilDate: values.validUntilDate,
    deliveryMethod: values.deliveryMethod,
    opportunityId,
    customerId,
    lineItems: mapToProposalLineItems(
      values.lineItems,
      isHideOptionAvailable(values.showLineItemPrices),
      invoiceItems
    ),
    introText: serializeTextState(values.introText as EditorState),
    footerText: serializeTextState(values.footerText as EditorState),
    hideLineItemPrices: !values.showLineItemPrices,
    replyToEmailAddress: !isUpdate
      ? values.emailFields.replyToEmailAddress
      : null,
    skipSend: false,
    depositSettings: values.depositRequired ? values.depositSettings : null,
    addConvenienceFee: values.depositRequired
      ? values.addConvenienceFee
      : false,
    taxRate: values.amountAdjustments.taxRate,
    discount: values.amountAdjustments.discount,
    files: values.files,
    customerEmailAddresses: getEmailAddressesForSave(
      values.emailFields.customerEmailAddresses
    ),
    customerPhoneNumber: values.customerPhoneNumber,
    customerPhoneNumberOptedIntoSms:
      values.deliveryMethod === SendType.text
        ? values.customerPhoneNumberOptedIntoSms
        : null,
    depositItemId: values.depositItem.itemId,
    depositItemName: values.depositItem.name,
    depositItemDescription: values.depositItem.description,
    quickBooksCustomerId: values.quickBooksCustomerId ?? undefined,
    captureCustomerPaymentMethod: values.captureCustomerPaymentMethod ?? false,
    captureCustomerPaymentMethodOptional:
      values.captureCustomerPaymentMethodOptional ?? false,
    summary: values.summary,
    subject: values.subject,
  };

  // Remove showLineItemPrices coming from "...values"
  delete (savePayload as any).showLineItemPrices;

  // Remove depositRequired coming from "...values" since isn't needed
  // server only needs depositSettings
  delete (savePayload as any).depositRequired;

  if (enrichFormData) {
    savePayload = enrichFormData(savePayload);
  }
  return savePayload;
}

function isHideOptionAvailable(showLineItemPrices: boolean): boolean {
  return !showLineItemPrices;
}

function setValuesFromTemplate(
  selectedTemplate: IProposalTemplate,
  setValue: UseFormSetValue<IFormData>,
  getUserSettings: GetUserSettingsType
) {
  const defaultValues = getDefaultValuesFromTemplate(
    selectedTemplate,
    getUserSettings
  );

  setValue("introText", defaultValues.introText);
  setValue("footerText", defaultValues.footerText);
  setValue("showLineItemPrices", defaultValues.showLineItemPrices);
  setValue("lineItems", defaultValues.lineItems);
  setValue("depositRequired", defaultValues.depositRequired);
  setValue("depositSettings", defaultValues.depositSettings);
  setValue("addConvenienceFee", defaultValues.addConvenienceFee);
  setValue(
    "captureCustomerPaymentMethod",
    defaultValues.captureCustomerPaymentMethod
  );
  setValue(
    "captureCustomerPaymentMethodOptional",
    defaultValues.captureCustomerPaymentMethodOptional
  );
  setValue("subject", defaultValues.subject);

  if (defaultValues.validUntilDate.length > 0) {
    setValue("validUntilDate", defaultValues.validUntilDate);
  }
}
