import * as React from "react";
import photoDisplayService, {
  IPhotoProperiesForImgSrc,
  isImageFile,
  getFileComponents,
} from "../../../../services/fileService";
import { photoThumbnailSchema } from "../../../../models/IPhoto";
import TextareaAutosize from "react-autosize-textarea/lib";
import ImageWithDeleteOverlay from "../ImageWithDeleteOverlay";
import Prompt from "../Prompt";
import { isStringSet } from "../../../../services/stringService";
import dateService from "../../../../services/dateService";
import { z } from "zod";

interface IProps {
  tenantId: string;
  imagePrefix: string;
  file: IFormDataFile;
  onFileRemoved: (fileId: string | null, imagePath: string | null) => void;
  onFileUpdated: (
    fileId: string | null,
    imagePath: string | null,
    caption: string
  ) => void;
  onPhotoClicked: () => void;
}

interface IState {
  showDeleteConfirmation: boolean;
}

export const formDataFileSchema = z.object({
  id: z.string().nullable(),
  caption: z.string(),
  contentType: z.string(),
  imagePath: z.string(),

  // Only for existing items
  thumbnails: z.array(photoThumbnailSchema),

  // Only applicable for new items
  actualWidth: z.nullable(z.number()),
  actualHeight: z.nullable(z.number()),
  timestamp: z.nullable(z.string()),
});

export type IFormDataFile = z.infer<typeof formDataFileSchema>;

class ExistingForForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showDeleteConfirmation: false,
    };

    this.deleteFile = this.deleteFile.bind(this);
  }

  public render() {
    const { file } = this.props;

    let isImage: boolean;
    let imageElement: JSX.Element | null;
    if (isImageFile(file)) {
      const thumbnailWidth = 350;
      let photoProps: IPhotoProperiesForImgSrc | null;
      if (file.id) {
        photoProps = photoDisplayService.getPhotoPropertiesForImgSrc(
          this.props.imagePrefix,
          file.id,
          file.thumbnails,
          thumbnailWidth
        );
      } else if (this.props.tenantId && file.imagePath && file.contentType) {
        photoProps =
          photoDisplayService.getPhotoPropertiesForImgSrcWithoutPhotoId(
            this.props.tenantId,
            this.props.imagePrefix,
            file.imagePath,
            file.contentType,
            file.thumbnails,
            thumbnailWidth
          );
      } else {
        throw new Error("image missing id OR path + content type + tenant ID");
      }

      if (!photoProps) {
        return null;
      }

      const imageThumbnailSource = photoProps.url;
      const height = photoProps.height;
      const width = photoProps.width;

      imageElement = (
        <ImageWithDeleteOverlay
          alt="Thumbnail"
          src={imageThumbnailSource}
          width={width}
          height={height}
          onDelete={() => {
            this.deleteFile();
          }}
        />
      );
      isImage = true;
    } else {
      imageElement = null;
      isImage = false;
    }

    const fileComponents = getFileComponents(file.imagePath);

    let path: string;
    let name: string;
    if (fileComponents.success) {
      path = fileComponents.linkUrl as string;
      name = fileComponents.name as string;
    } else {
      path = file.imagePath;
      name = file.imagePath;
    }

    return (
      <React.Fragment>
        <div className="form-group" style={{ textAlign: "center" }}>
          {isImage ? (
            imageElement
          ) : file.imagePath ? (
            <React.Fragment>
              <a
                href={`${this.props.imagePrefix}/${path}`}
                className="btn btn-link"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  margin: "0",
                  padding: "0",
                }}
              >
                {name}
              </a>{" "}
              -{" "}
              <button
                type="button"
                className="btn btn-link"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    showDeleteConfirmation: true,
                  });
                }}
                style={{ margin: "0", padding: "0" }}
              >
                <span
                  className="text-danger"
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Delete
                </span>
              </button>
            </React.Fragment>
          ) : null}
          {isStringSet(file.timestamp) ? (
            <div className="mt-2" data-testid="timestampContainer">
              {dateService.formatDateTimeForDateTimeDisplay(file.timestamp)}
            </div>
          ) : null}
          <div className="form-group mt-2">
            <TextareaAutosize
              maxRows={10}
              className="form-control"
              value={file.caption}
              placeholder="Caption"
              data-testid="caption"
              onChange={(e) => {
                this.props.onFileUpdated(
                  this.props.file.id,
                  this.props.file.imagePath,
                  e.currentTarget.value
                );
              }}
            />
          </div>
          <Prompt
            promptMessage="Delete file?"
            promptSaveText="Delete"
            onCancel={() =>
              this.setState({
                showDeleteConfirmation: false,
              })
            }
            onConfirm={() => {
              this.deleteFile();
              this.setState({
                showDeleteConfirmation: false,
              });
            }}
            showPrompt={this.state.showDeleteConfirmation}
          />
        </div>
      </React.Fragment>
    );
  }

  private deleteFile() {
    this.props.onFileRemoved(this.props.file.id, this.props.file.imagePath);
  }
}

export default ExistingForForm;
