export function HelpIcon() {
  return (
    <div
      style={{ position: "fixed", bottom: "15px", right: "15px", zIndex: 2 }}
    >
      <a
        href="https://youraspire.atlassian.net/servicedesk/customer/portal/9"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/help.svg" alt="Help" />
      </a>
    </div>
  );
}
