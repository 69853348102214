export type Audience = "all" | "crew" | "technician";

export type Content = {
  id: string;
  header: string;
  details: string;
  lengthInMinutes: number;
  loomEmbeddedUrl: string;
  audience: Audience;
};

export function getWalkthroughs(): Content[] {
  return [
    {
      id: "2E83D607-0949-49DC-AE2C-86EFD07974D6",
      header: "Scheduling your jobs with ease",
      details: "Learn how to get up and running scheduling your one-time jobs.",
      lengthInMinutes: 2,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/2cbc21e15c7e412eb39d97e2c913c33f?sid=3ea10889-4e84-446b-9d0d-12d48560dafe",
      audience: "technician",
    },
    {
      id: "16BBA9DC-BD86-460E-8E4E-A7F97028AE87",
      header: "Setting Up Your Recurring Maintenance Jobs",
      details:
        "By adding recurring jobs you can easily schedule all your customers' maintenance work.",
      lengthInMinutes: 2,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/1223d1d7763a4c68b9c8ee3a0e9add37?sid=0ad2b484-1f64-48f8-8a56-2fbdcdead679",
      audience: "technician",
    },
    {
      id: "2E47F13C-E12C-4D1D-BEEB-6A74A976C6AD",
      header: "Flexible job rescheduling with Crew Control",
      details:
        "See how you can quickly make adjustments to your crew's schedule.",
      lengthInMinutes: 1,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/eb79e31a681041e787128c3ed39b07da?sid=63d72dae-5739-4716-b3fc-d66fab294dab",
      audience: "technician",
    },
    {
      id: "1124741C-3B9C-4C78-9D5F-FF61A7F72092",
      header: "Manage Your Team's On-Site Experience",
      details:
        "Learn how your crew leads can use the Crew Schedule mobile application and keep you up to date with their progress.",
      lengthInMinutes: 5,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/1fc829e3f1fe4cb2948e87276b3a9152?sid=c225d5c6-db62-4d9b-847e-2c17c268253f",
      audience: "technician",
    },
    {
      id: "AC3FACB6-AEDD-46C1-A58B-0FE3CB4C873B",
      header: "Make scheduling adjustments with ease",
      details:
        "See how you can quickly make adjustments to your crew's schedule.",
      lengthInMinutes: 1,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/eb79e31a681041e787128c3ed39b07da?sid=be092384-0f22-4cc3-8f1a-64c42208e4d2",
      audience: "crew",
    },
    {
      id: "A19925FF-AD3B-4049-BABB-8FCB0B2CD15B",
      header: "Rescheduling for rain delays",
      details: "Learn how to shift your teams schedule to react to rainouts.",
      lengthInMinutes: 1,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/6581d38245f44a139f02add0f8ad9677?sid=3b00b51a-1f31-4d2d-ac8e-2d3392c0f6cf",
      audience: "crew",
    },
    {
      id: "A92EB345-0034-47B2-99BA-BF43B77C840E",
      header: "Easily schedule your recurring services",
      details:
        "By adding recurring jobs you can easily schedule all your customers maintenance work. ",
      lengthInMinutes: 2,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/5ec3d38b302b418aa7bf7dd506615110?sid=c2b063d4-8ea6-4b6a-ad4a-1846f3ad31c6",
      audience: "crew",
    },
    {
      id: "A16BF172-309B-40EE-9FA4-25F4F0A58828",
      header: "Know the numbers that grow your business",
      details:
        "Keep track of your crew's job times and understand your profitability by time variance.",
      lengthInMinutes: 2,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/8ac5da3d9b1449b28b4f2d2170ff49d2?sid=70a95cad-cc91-455f-b5c9-75dfa6be6ac0",
      audience: "crew",
    },
    {
      id: "A57CEFA7-9198-4A26-9FC9-2BC129D3E1E5",
      header: "Automated customer notifications",
      details:
        "Learn how to keep your customers informed every step of the way.",
      lengthInMinutes: 2,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/3d47c33f940841b7817889aa184306b8?sid=66e5c250-71f6-4e3c-a9bb-95aebcb9a512",
      audience: "all",
    },
    {
      id: "AF58DDD3-A0B4-4A7B-B027-0DD234512AD0",
      header: "Managing your crews progress",
      details:
        "Learn how your crews can used dispatched schedules to keep you up to date with their progress.",
      lengthInMinutes: 3,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/0cfc029f5b424d239eb811ca96e9e48f?sid=2778858d-9fa8-47ee-9629-38c2c30f5265",
      audience: "crew",
    },
    {
      id: "A3B428DC-1A83-4186-9B86-0350396FB78A",
      header: "Save on drivetime by optimizing your route",
      details: "Learn how you can automatically optimize your route.",
      lengthInMinutes: 1,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/a76386c6b94e476d9125faf2c8981817?sid=fc32d6ac-ff6c-4133-ad16-aa57b8a670e4",
      audience: "crew",
    },
    {
      id: "F01D2124-FF68-4F64-AA4E-AE7D1059A9F6",
      header: "End-of-month billing with Crew Control",
      details:
        "Learn how you can effortlessly invoice your jobs at the end of each month.",
      lengthInMinutes: 5,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/f783fd1fe7d745d384ce9191cfaff21d?sid=d8aba893-03a4-4a7b-8d18-86b49aa5b1e7",
      audience: "all",
    },
    {
      id: "F01D2124-FF68-4F64-AA4E-AE7D1059A9F7",
      header: "Accelerate your sales with electronic proposals",
      details:
        "Learn how to manage all of your opportunities and offers from an easy-to-use dashboard.",
      lengthInMinutes: 4,
      loomEmbeddedUrl:
        "https://www.loom.com/embed/70549e6a4c9249c79f620b2e27ec3bc6?sid=128ae0c5-082c-49bb-97ef-543309f59271",
      audience: "all",
    },
  ];
}
