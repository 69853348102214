import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWorksheetView } from "../models/IWorksheet";
import { actionTypes } from "../../../modules/actionTypes";
import {
  IAction,
  IInitialLoadCompletedAction,
} from "../../../modules/actionTypeDefinitions";

export interface IWorksheetState {
  worksheets: Array<IWorksheetView>;
}

const initialState: IWorksheetState = {
  worksheets: [],
};

const { reducer, actions } = createSlice({
  initialState,
  name: "worksheet",
  reducers: {
    loadSpecificWorksheets: (
      state,
      action: PayloadAction<{ worksheets: Array<IWorksheetView> }>
    ) => {
      state.worksheets = [
        ...state.worksheets.filter(
          (existingWorksheet) =>
            !action.payload.worksheets.some(
              (newOpportunity) => existingWorksheet.id === newOpportunity.id
            )
        ),
        ...action.payload.worksheets,
      ];
    },

    deleteWorksheet: (
      state,
      action: PayloadAction<{ worksheetId: string }>
    ) => {
      state.worksheets = state.worksheets.filter(
        (o) => o.id !== action.payload.worksheetId
      );
    },

    // Just used for actions that are fed to epic
    reloadWorksheet: (
      state,
      action: PayloadAction<{ worksheetId: string }>
    ) => {},
  },

  extraReducers: (builder) => {
    builder.addMatcher(isInitialLoadCompletedAction, (state, action) => {
      state.worksheets = action.worksheets;
    });
  },
});

export const worksheetsActionCreators = actions;

export default reducer;

function isInitialLoadCompletedAction(
  action: IAction
): action is IInitialLoadCompletedAction {
  return action.type === actionTypes.INITIAL_LOAD_COMPLETED;
}
