import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { ICrewMember } from "../../../models/ICrewMember";
import { ScheduleDispatchDelivery } from "../../../enums/scheduleDispatchDelivery";
import PhoneNumberField from "../components/PhoneNumberField";
import SmsOptInCheckbox from "../components/SmsOptInCheckbox";
import { getOptInStatusAfterNumberChange } from "../../../services/phoneNumberService";
import TextareaAutosize from "react-autosize-textarea";
import { areCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import remoteDataProvider, {
  ISaveCrewMemberRequest,
} from "../../../services/remoteDataProvider";
import { timeout } from "rxjs/operators";
import { ICrew } from "../../../models/ICrew";
import CrewMultiSelect from "../components/CrewMultiSelect";
import { getSortedCrews } from "../../../services/sortingService";
import { ITenantFeatureFlags } from "../../../models/ITenantFeatureFlags";
import InfoToolTip from "../components/InfoToolTip";

interface IProps {
  crews: Array<ICrew>;
  crewMembers: Array<ICrewMember>;
  crewMemberId: string | null;
  defaultName: string | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  formInstanceKey: string | undefined | null;
  payrixMerchantAccountId: string;
  salesEnabled: boolean;
  isQuickBooksEnabled: boolean;
  customerTextingAllowed: boolean;
  featureFlags: ITenantFeatureFlags | null;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
  phoneNumberOptedIntoSmsDirty: boolean;
  hasProductsServices: boolean | null;
}

interface IFormData {
  crewMemberName: string;
  emailAddress: string;
  phoneNumber: string;
  phoneNumberOptedIntoSms: boolean;
  inactive: boolean;
  scheduleDispatchDelivery: number;
  notes: string;
  allowOnsiteInvoicing: boolean;
  allowOnsiteEstimating: boolean;
  allowOnTheWayNotification: boolean;
  allowMobileApplication: boolean;
  mobileApplicationAllowedCrews: Array<string>;
}

class CrewMemberForm extends React.Component<IProps, IState> {
  private crewMemberNameRef: React.RefObject<HTMLInputElement>;
  private phoneNumberRef: React.MutableRefObject<HTMLInputElement | null>;

  constructor(props: IProps) {
    super(props);

    const formData = this.getEmptyFormData();
    this.state = {
      formData: formData,
      originalFormData: formData,
      phoneNumberOptedIntoSmsDirty: false,
      hasProductsServices: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.validate = this.validate.bind(this);

    this.crewMemberNameRef = React.createRef();
    this.phoneNumberRef = React.createRef();
  }

  getEmptyFormData(): IFormData {
    return {
      crewMemberName: "",
      emailAddress: "",
      phoneNumber: "",
      phoneNumberOptedIntoSms: false,
      inactive: false,
      scheduleDispatchDelivery: ScheduleDispatchDelivery.both,
      notes: "",
      allowOnsiteInvoicing: false,
      allowOnsiteEstimating: false,
      allowOnTheWayNotification: false,
      allowMobileApplication: false,
      mobileApplicationAllowedCrews: [],
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      if (!this.props.isQuickBooksEnabled) {
        remoteDataProvider
          .getNonQuickBooksInvoiceItems()
          .pipe(timeout(30000))
          .subscribe(
            (result) => {
              this.setState({
                hasProductsServices:
                  result.filter((r) => !r.inactive).length > 0,
              });
            },
            () => {}
          );
      }

      let formData: IFormData;
      if (!props.crewMemberId) {
        formData = this.getEmptyFormData();

        if (props.defaultName) {
          formData.crewMemberName = props.defaultName;
        }

        setTimeout(() => {
          if (!props.defaultName) {
            if (this.crewMemberNameRef.current) {
              this.crewMemberNameRef.current.focus();
            }
          } else {
            if (this.phoneNumberRef.current) {
              this.phoneNumberRef.current.focus();
            }
          }
        });
      } else {
        const crewMember = this.getCrewMember(props);
        formData = {
          crewMemberName: crewMember.name,
          emailAddress: crewMember.emailAddress || "",
          phoneNumber: crewMember.phoneNumber || "",
          phoneNumberOptedIntoSms: crewMember.phoneNumberOptedIntoSms,
          inactive: crewMember.inactive,
          scheduleDispatchDelivery: crewMember.scheduleDispatchDelivery,
          notes: crewMember.notes ?? "",
          allowOnsiteInvoicing: crewMember.allowOnsiteInvoicing,
          allowOnsiteEstimating: crewMember.allowOnsiteEstimating,
          allowOnTheWayNotification: crewMember.allowOnTheWayNotification,
          allowMobileApplication: crewMember.allowMobileApplication,
          mobileApplicationAllowedCrews:
            crewMember.mobileApplicationAllowedCrews,
        };
      }
      this.setState({
        formData,
        originalFormData: formData,
        phoneNumberOptedIntoSmsDirty: false,
      });
    }
  }

  private getCrewMember(
    props: Readonly<IProps> & Readonly<{ children?: React.ReactNode }>
  ) {
    const crewMember = props.crewMembers.find(
      (c) => c.id === props.crewMemberId
    );
    if (!crewMember) {
      throw new Error(`crew member not found ${props.crewMemberId}`);
    }
    return crewMember;
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const formDataToUpdate = { ...this.state.formData };
    const formDataKey = event.target.name as keyof IFormData;
    const formDataValue = event.target.value as any;
    (formDataToUpdate[formDataKey] as any) = formDataValue;

    if (formDataKey === "phoneNumber") {
      formDataToUpdate.phoneNumberOptedIntoSms =
        getOptInStatusAfterNumberChange({
          currentOptInStatus: formDataToUpdate.phoneNumberOptedIntoSms,
          newNumber: formDataValue,
          originalNumber: this.state.originalFormData.phoneNumber,
          originalOptInStatus:
            this.state.originalFormData.phoneNumberOptedIntoSms,
          phoneNumberOptInDirty: this.state.phoneNumberOptedIntoSmsDirty,
        });
    }

    this.setState({
      formData: formDataToUpdate,
    });
  }

  getFormData(): ISaveCrewMemberRequest {
    const result: ISaveCrewMemberRequest = {
      ...this.state.formData,
      originalAllowMobileApplication:
        this.state.originalFormData.allowMobileApplication,
      name: this.state.formData.crewMemberName,
    };

    return result;
  }

  validate() {
    return { valid: true, errorMessage: "" };
  }

  render() {
    const { formData } = this.state;
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      crewMemberId,
    } = this.props;

    let formHeader = "Add Crew Member";
    if (crewMemberId) {
      const crewMember = this.getCrewMember(this.props);
      formHeader = `Update ${crewMember.name}`;
    }

    const isSubForm = typeof this.props.formInstanceKey === "string";

    return (
      <FormContainer
        setErrorMessage={setErrorMessage}
        validate={this.validate}
        getFormData={this.getFormData}
        formHeader={formHeader}
        showForm={showForm}
        errorMessage={errorMessage}
        saving={saving}
        startSave={startSave}
        cancel={cancel}
        formKey="crewMember"
        hasFormDataChanged={() => {
          return (
            JSON.stringify(this.state.formData) !==
            JSON.stringify(this.state.originalFormData)
          );
        }}
        addSaveAndNewButton={!crewMemberId && !isSubForm}
      >
        <div className="form-section">
          <div className="form-group">
            <label htmlFor="crewMemberName" className="required">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              name="crewMemberName"
              id="crewMemberName"
              required={true}
              value={formData.crewMemberName}
              onChange={this.handleChange}
              ref={this.crewMemberNameRef}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone</label>
            <PhoneNumberField
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              className="form-control"
              onChange={this.handleChange}
              ref={this.phoneNumberRef}
            />
          </div>
          <SmsOptInCheckbox
            checked={formData.phoneNumberOptedIntoSms}
            required={
              formData.scheduleDispatchDelivery ===
                ScheduleDispatchDelivery.sms ||
              formData.scheduleDispatchDelivery ===
                ScheduleDispatchDelivery.both
            }
            onCheckedChange={(newChecked) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  phoneNumberOptedIntoSms: newChecked,
                },
                phoneNumberOptedIntoSmsDirty: true,
              });
            }}
            phoneNumber={formData.phoneNumber}
          />
          <div className="form-group">
            <label htmlFor="emailAddress">Email address</label>
            <input
              type="email"
              id="emailAddress"
              className="form-control"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="scheduleDispatchDelivery">
              Receive schedule dispatch by
            </label>
            <select
              id="scheduleDispatchDelivery"
              className="form-control"
              name="scheduleDispatchDelivery"
              value={formData.scheduleDispatchDelivery}
              onChange={(e) => {
                this.setState({
                  formData: {
                    ...formData,
                    scheduleDispatchDelivery: parseInt(e.currentTarget.value),
                  },
                });
              }}
            >
              <option value={ScheduleDispatchDelivery.sms}>Text message</option>
              <option value={ScheduleDispatchDelivery.email}>Email</option>
              <option value={ScheduleDispatchDelivery.both}>
                Both email and text message
              </option>
            </select>
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="allowMobileApplication"
                checked={formData.allowMobileApplication}
                onChange={(e) => {
                  const value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      allowMobileApplication: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="allowMobileApplication"
              >
                Allow mobile application login
              </label>
              <InfoToolTip
                id="allowMobileApplicationExplanation"
                text="Enabling this allows crew members to log in and access a full week's schedule using their phone number and a one-time code.  No username/password necessary!"
              />
            </div>
          </div>
          <div className="form-group">
            <CrewMultiSelect
              inputId="mobileApplicationAllowedCrews"
              placeholder="Select allowed crews"
              isDisabled={!formData.allowMobileApplication}
              required={formData.allowMobileApplication}
              crews={getSortedCrews(this.props.crews).filter(
                (c) => !c.inactive
              )}
              selectedCrewIds={formData.mobileApplicationAllowedCrews}
              setSelectedCrewIds={(newCrewIds) =>
                this.setState({
                  formData: {
                    ...formData,
                    mobileApplicationAllowedCrews: newCrewIds,
                  },
                })
              }
            />
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="allowOnsiteInvoicing"
                checked={formData.allowOnsiteInvoicing}
                onChange={(e) => {
                  const value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      allowOnsiteInvoicing: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="allowOnsiteInvoicing"
              >
                Allow on-site invoicing
              </label>
            </div>
          </div>
          <div className="alert alert-info px-2 py-2">
            <div className="d-flex align-items-center">
              <div>
                <a
                  href="https://app.teamwalnut.com/player/?demoId=8488a887-d67a-4800-b2d9-bec3b94ed4c5&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app"
                  target="_blank"
                  rel="noreferrer"
                  className="text-underline text-dark"
                  style={{ textDecoration: "underline" }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                  See how easy it is to invoice your customer on-site!
                </a>
              </div>
            </div>

            {this.state.formData.allowOnsiteInvoicing ? (
              <small>
                <ul className="mb-0">
                  {this.state.hasProductsServices === false ? (
                    <li data-testid="productServiceWarning">
                      You have no products &amp; services to be invoiced. Make
                      sure you define your products &amp; services found under
                      Settings. Your crew members can only use the products &
                      services you have already added.
                    </li>
                  ) : null}
                  {!areCreditCardsEnabled(
                    this.props.payrixMerchantAccountId
                  ) ? (
                    <li data-testid="allowTechInvoicingWarning">
                      Your account will have the ability to record a payment as
                      cash, check or other method. Once onboarded onto payments,
                      you will have access to process credit card and ACH
                      transactions!
                    </li>
                  ) : null}
                </ul>
              </small>
            ) : null}
          </div>
          {this.props.salesEnabled ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="allowOnsiteEstimating"
                  checked={formData.allowOnsiteEstimating}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({
                      formData: {
                        ...formData,
                        allowOnsiteEstimating: value,
                      },
                    });
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="allowOnsiteEstimating"
                >
                  Allow on-site estimating
                </label>
              </div>
            </div>
          ) : null}
          {this.props.customerTextingAllowed ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="allowOnTheWayNotification"
                  checked={formData.allowOnTheWayNotification}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({
                      formData: {
                        ...formData,
                        allowOnTheWayNotification: value,
                      },
                    });
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="allowOnTheWayNotification"
                >
                  Allow on-the-way notification
                </label>
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <TextareaAutosize
              maxRows={10}
              id="notes"
              className="form-control"
              name="notes"
              value={formData.notes}
              onChange={(e) =>
                this.setState({
                  formData: {
                    ...formData,
                    notes: e.currentTarget.value,
                  },
                })
              }
            />
          </div>
          {crewMemberId ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="inactive"
                  name="inactive"
                  checked={formData.inactive}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({
                      formData: {
                        ...formData,
                        inactive: value,
                      },
                    });
                  }}
                />
                <label className="custom-control-label" htmlFor="inactive">
                  Inactive
                </label>
              </div>
            </div>
          ) : null}
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  crewMembers: state.crew.crewMembers,
  crewMemberId: state.forms.crewMember.parameters
    ? state.forms.crewMember.parameters.crewMemberId
    : null,
  defaultName: state.forms.crewMember.parameters
    ? state.forms.crewMember.parameters.defaultName
    : null,
  showForm: state.forms.crewMember.showForm,
  errorMessage: state.forms.crewMember.errorMessage,
  saving: state.forms.crewMember.saving,
  formInstanceKey: state.forms.crewMember.parameters
    ? (state.forms.crewMember.parameters.formInstanceKey as string | undefined)
    : null,
  payrixMerchantAccountId: state.common.payrixMerchantAccountId,
  salesEnabled: state.common.optionalCapabilitiesAllowed?.sales ?? false,
  isQuickBooksEnabled: state.common.isQuickBooksEnabled,
  customerTextingAllowed:
    state.common.optionalCapabilitiesAllowed.customerTexting,
  featureFlags: state.common.featureFlags,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.crewMember.startSaving,
  cancel: actionCreators.forms.crewMember.cancelForm,
  setErrorMessage: actionCreators.forms.crewMember.setErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewMemberForm);
