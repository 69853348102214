import { formTypes } from "../formGenerator/formTypes";
import {
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator/actionTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAction } from "./actionTypeDefinitions";

export enum QuickBooksModalMode {
  configuration,
  confirmation,
  error,
  reconnect,
}

export interface ICommonUiState {
  showQuickBooksModal: boolean;
  quickBooksModalMode: QuickBooksModalMode;
  quickBooksModalExplanationText: string | null;

  showAdminUsersModal: boolean;

  showMerchantOnboardingModal: boolean;

  parametersForCustomerClearJobsModal: {
    customerId: string;
    customerName: string;
  } | null;

  parametersForCrewMemberInvitationModal: {
    crewMemberId: string;
    crewMemberName: string;
  } | null;

  alertErrorMessage: string | null;

  idForInvoicePrintContainer: string | null;

  showTermsOfServiceUpdatedModal: boolean;
}

const initialState: ICommonUiState = {
  showQuickBooksModal: false,
  quickBooksModalMode: QuickBooksModalMode.configuration,
  quickBooksModalExplanationText: null,

  showAdminUsersModal: false,

  showMerchantOnboardingModal: false,

  parametersForCustomerClearJobsModal: null,

  parametersForCrewMemberInvitationModal: null,

  alertErrorMessage: null,

  idForInvoicePrintContainer: null,

  showTermsOfServiceUpdatedModal: false,
};

const { reducer, actions } = createSlice({
  name: "commonUi",
  initialState: initialState,
  reducers: {
    showQuickBooksModal(state, action: PayloadAction<IQuickBooksModalShow>) {
      return {
        ...state,
        showQuickBooksModal: true,
        quickBooksModalMode: action.payload.quickBooksModalMode,
        quickBooksModalExplanationText:
          action.payload.quickBooksModalExplanationText ?? null,
      };
    },

    hideQuickBooksModal(state) {
      return {
        ...state,
        showQuickBooksModal: false,
        quickBooksModalMode: QuickBooksModalMode.configuration,
      };
    },

    showAdminUsersManageModal(state) {
      return {
        ...state,
        showAdminUsersModal: true,
      };
    },

    hideAdminUsersManageModal(state) {
      return {
        ...state,
        showAdminUsersModal: false,
      };
    },

    showMerchantOnboardingModal(state) {
      return {
        ...state,
        showMerchantOnboardingModal: true,
      };
    },

    hideMerchantOnboardingModal(state) {
      return {
        ...state,
        showMerchantOnboardingModal: false,
      };
    },

    showAlertModal(
      state,
      action: PayloadAction<{ alertErrorMessage: string }>
    ) {
      state.alertErrorMessage = action.payload.alertErrorMessage;
    },

    dismissCommonAlertModal(state) {
      return {
        ...state,
        alertErrorMessage: null,
      };
    },

    showInvoicePrintContainer(
      state,
      action: PayloadAction<{ idForInvoicePrintContainer: string }>
    ) {
      return {
        ...state,
        idForInvoicePrintContainer: action.payload.idForInvoicePrintContainer,
      };
    },

    dismissInvoicePrintContainer(state) {
      return {
        ...state,
        idForInvoicePrintContainer: null,
      };
    },

    showCustomerClearJobsModal(
      state,
      action: PayloadAction<{ customerId: string; customerName: string }>
    ) {
      return {
        ...state,
        parametersForCustomerClearJobsModal: action.payload,
      };
    },

    dismissCustomerClearJobsModal(state) {
      return {
        ...state,
        parametersForCustomerClearJobsModal: null,
      };
    },

    showCrewMemberInvitationModal(
      state,
      action: PayloadAction<{ crewMemberId: string; crewMemberName: string }>
    ) {
      return {
        ...state,
        parametersForCrewMemberInvitationModal: action.payload,
      };
    },

    dismissCrewMemberInvitationModal(state) {
      return {
        ...state,
        parametersForCrewMemberInvitationModal: null,
      };
    },

    showTermsOfServiceUpdatedModal(state) {
      return {
        ...state,
        showTermsOfServiceUpdatedModal: true,
      };
    },

    closeTermsOfServiceUpdatedModal(state) {
      return {
        ...state,
        showTermsOfServiceUpdatedModal: false,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(isInvoiceCompleteSavingAction, (state, action) => {
      let saveErrorMessage: string | null = null;
      if (
        action.payload.emailFailed &&
        action.payload.crewControlUpdatesFailed
      ) {
        saveErrorMessage =
          "The invoice was created in QuickBooks Online but the email was not able to be set and the Crew Control records were not able to be updated.";
      } else if (action.payload.emailFailed) {
        saveErrorMessage =
          "The invoice was created in QuickBooks Online but the email was not able to be set.";
      } else if (action.payload.crewControlUpdatesFailed) {
        saveErrorMessage =
          "The invoice was created in QuickBooks Online but the Crew Control records were not able to be updated.";
      } else if (action.payload.textFailed) {
        const textErrorMessage = action.payload.textErrorMessage;
        if (textErrorMessage) {
          saveErrorMessage =
            "The invoice was created but there was a failure texting the customer:\n" +
            textErrorMessage +
            "\n\nYou can resend the invoice from the Open Invoices area.";
        } else {
          saveErrorMessage =
            "The invoice was created but there was a failure texting the customer.\n\nYou can resend the invoice from the Open Invoices area.";
        }
      }
      return {
        ...state,
        alertErrorMessage: saveErrorMessage,
        idForInvoicePrintContainer:
          action.payload.printOnSave && !action.payload.draft
            ? action.payload.crewControlInvoiceId
            : null,
      };
    });
  },
});

export default reducer;

export const commonUiActionCreators = actions;

function isInvoiceCompleteSavingAction(action: IAction) {
  return (
    action.type ===
    createSpecificActionTypeName(
      formTypes.invoice,
      formActionTypes.completeSaving
    )
  );
}

interface IQuickBooksModalShow {
  quickBooksModalMode: QuickBooksModalMode;
  quickBooksModalExplanationText?: string;
}
