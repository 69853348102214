import { useState } from "react";
import { TollFreeVerificationForm } from "./TollFreeVerificationForm";
import useIsAdmin from "../../../hooks/useIsAdmin";

export function TollFreeVerificationPrompt({
  footerContents,
}: {
  footerContents?: React.ReactNode;
}) {
  const [showForm, setShowForm] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <>
      <div
        data-testid="tollFreeVerificationAlert"
        className={"alert alert-info"}
      >
        <div>
          {isAdmin ? (
            <>
              Our phone carriers require that you submit your information before
              you send text messages.
            </>
          ) : (
            <>
              Our phone carriers require that you submit your information before
              you send text messages. Please ask an administrator user to open
              this form and submit your business information.
            </>
          )}
        </div>
        <div className="mt-2">
          This information is required by phone carriers to help reduce spam.
        </div>

        {footerContents}
      </div>

      <div className="mt-2">
        <button
          type="button"
          className="btn btn-primary btn-block"
          onClick={() => {
            setShowForm(true);
          }}
          data-testid="hereButton"
        >
          Register for texting
        </button>
      </div>

      {showForm ? (
        <TollFreeVerificationForm
          onSaveComplete={() => setShowForm(false)}
          onCancel={() => setShowForm(false)}
        />
      ) : null}
    </>
  );
}
