import { ICrew } from "../models/ICrew";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import { ICustomer } from "../models/ICustomer";
import { IAddress } from "../models/IAddress";

function formatAddressInternal(
  streetAndNumber: string,
  apartmentSuite: string,
  city: string,
  state: string,
  zip: string
) {
  let value = "";
  if (streetAndNumber) {
    value = streetAndNumber;
  }

  if (apartmentSuite) {
    value += " " + apartmentSuite;
  }

  if (city) {
    value += " " + city;
  }

  if (state) {
    value += " " + state;
  }

  if (zip) {
    value += " " + zip;
  }

  return value.trim() || "";
}

function getAddressForJob(
  job: IJobAddressComponents,
  customers: Array<ICustomer>,
  locations: Array<ICustomerAdditionalLocation>
): IGetAddressForJobResult {
  let addressEntity: IAddress;
  let customerId = job.customerId;
  let customerAdditionalLocationId = job.customerAdditionalLocationId;
  if (job.customerAdditionalLocationId) {
    const location = locations.find(
      (l) => l.id === job.customerAdditionalLocationId
    );
    if (!location) {
      throw new Error(
        `could not find location '${job.customerAdditionalLocationId}' for job '${job.id}'`
      );
    } else {
      addressEntity = location;
    }
  } else {
    const customer = customers.find((l) => l.id === job.customerId);
    if (!customer) {
      throw new Error(
        `could not find customer '${job.customerId}' for job '${job.id}'`
      );
    } else {
      addressEntity = customer;
    }
  }

  return {
    ...addressEntity,
    customerId,
    customerAdditionalLocationId,
  };
}

function formatAddressForJob(
  job: IJobAddressComponents,
  customers: Array<ICustomer>,
  locations: Array<ICustomerAdditionalLocation>
) {
  const addressEntity = getAddressForJob(job, customers, locations);
  return formatAddressEntity(addressEntity);
}

export function formatAddressEntity(addressEntity: IAddress) {
  let value = "";

  value = formatAddressInternal(
    addressEntity.streetAndNumber || "",
    addressEntity.apartmentSuite || "",
    addressEntity.city || "",
    addressEntity.state || "",
    addressEntity.zip || ""
  );

  const firstAttempt = value.trim() || "";
  if (firstAttempt) {
    return firstAttempt;
  }

  if (addressEntity.latitude && addressEntity.longitude) {
    return `${formatLatLng(
      addressEntity.latitude,
      addressEntity.latitudeSignificantDigits
    )}, ${formatLatLng(
      addressEntity.longitude,
      addressEntity.longitudeSignificantDigits
    )}`;
  } else if (addressEntity.latitude) {
    return formatLatLng(
      addressEntity.latitude,
      addressEntity.latitudeSignificantDigits
    );
  } else if (addressEntity.longitude) {
    return formatLatLng(
      addressEntity.longitude,
      addressEntity.longitudeSignificantDigits
    );
  } else {
    return "";
  }
}

function formatCrewAddress(crew: ICrew) {
  return formatAddressInternal(
    crew.streetAndNumber,
    "",
    crew.city,
    crew.state,
    crew.zip
  );
}

function formatLatLng(val: number, significantDigits: number | null): string {
  if (significantDigits === null) {
    return typeof val === "number" ? val.toString() : "";
  } else {
    const parts = val.toString().split(".");
    if (parts.length !== 2) {
      return val.toFixed(significantDigits);
    }

    if (parts[1].length < significantDigits) {
      return val.toFixed(significantDigits);
    }

    return val.toString();
  }
}

function parseStreetAndNumber(streetAndNumber: string | undefined | null) {
  let number = "";
  let street = "";

  const parts = (streetAndNumber ?? "").split(" ");
  if (parts.length > 0) {
    if (/^\d+$/.test(parts[0])) {
      number = parts[0];

      parts.shift();
    }
  }

  street = parts.join(" ");

  return { number, street };
}

function getGoogleMapsAddressLink(address: IAddress) {
  const formattedAddress = formatAddressEntity(address);
  const missingLatOrLng = !address.latitude || !address.longitude;

  return !missingLatOrLng
    ? `https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`
    : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        formattedAddress
      )}`;
}

export default {
  formatAddressEntity,
  formatAddressForJob,
  formatCrewAddress,
  formatLatLng,
  getAddressForJob,
  parseStreetAndNumber,
  getGoogleMapsAddressLink,
};

interface IJobAddressComponents {
  id: string;
  customerId: string | null;
  customerAdditionalLocationId: string | null;
}

export interface IGetAddressForJobResult extends IAddress {
  customerId: string | null;
  customerAdditionalLocationId: string | null;
}
