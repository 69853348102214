export function getIntroText(subscriptionMode: boolean) {
  let contactSupportFragment = (
    <>
      If you have any questions or concerns, please{" "}
      <a
        href="https://youraspire.atlassian.net/servicedesk/customer/portal/9"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact our support team
      </a>
      .
    </>
  );

  let result: React.ReactNode;
  if (subscriptionMode) {
    result = (
      <>
        <div>
          <strong>
            When subscribing to Crew Control, you also sign up for payment
            processing.
          </strong>
        </div>
        <div>{contactSupportFragment}</div>
      </>
    );
  } else {
    result = (
      <>
        <div>
          <strong>Why are we asking for this information?</strong>
        </div>
        <div>
          This information is required by regulations to sign up for payment
          processing. {contactSupportFragment}
        </div>
        <div className="mt-2">
          <strong>Why use Crew Control payments?</strong>
        </div>
        <div>
          Text invoices, setup recurring billing, and more with our billing
          workcenter! You can learn more by watching{" "}
          <a
            href="https://www.youraspire.com/hubfs/Videos/Release%20videos/Crew%20Control%20Payments%20release.mp4"
            target="_blank"
            rel="noopener noreferrer"
          >
            this video
          </a>
          .
        </div>
      </>
    );
  }

  return result;
}
