import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useEffect, useRef, useState } from "react";
import OriginalDayPicker from "react-day-picker";
import { isMobileOnly } from "react-device-detect";
import DropJobsPermanentPrompt from "../../../containers/app/components/schedule/DropJobsPermanentPrompt";
import AutoRoutePrompt from "../../../containers/app/components/schedule/AutoRoutePrompt";
import { actionCreators } from "../../../modules/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import parse from "date-fns/parse";

export function ScheduleHeader({
  pageHeaderText,
  previousSchedule,
  nextSchedule,
  unscheduledJobsElement,
  changeScheduleDate,
  baseDate,
}: {
  pageHeaderText: JSX.Element;
  previousSchedule: () => void;
  nextSchedule: () => void;
  changeScheduleDate(newDate: Date): any;
  baseDate: string;
  unscheduledJobsElement: JSX.Element;
}) {
  const pageLevelMessage = useApplicationStateSelector(
    (s) => s.scheduleUi.pageLevelMessage
  );
  const pageLevelAlertClass = useApplicationStateSelector(
    (s) => s.scheduleUi.pageLevelAlertClass
  );

  const dispatch = useDispatch();
  const datePickerElement = useRef<HTMLDivElement>(null);

  const [datePickerVisible, setDatePickerVisible] = useState(false);

  useEffect(() => {
    function handleMouseDownOutsideDatePicker(event: MouseEvent) {
      // Clear date picker
      if (datePickerElement.current && event.target) {
        if (!datePickerElement.current.contains(event.target as Node)) {
          setDatePickerVisible(false);
        }
      }
    }

    document.addEventListener("mousedown", handleMouseDownOutsideDatePicker);

    return function cleanup() {
      document.removeEventListener(
        "mousedown",
        handleMouseDownOutsideDatePicker
      );
    };
  }, []);

  const styles = {
    header: {
      display: "inline",
      textDecoration: isMobileOnly ? "underline" : undefined,
    },
    headerContainer: {
      display: "flex",
      justifyContent: datePickerVisible ? "center" : "space-between",
    },
  };

  return (
    <>
      <DropJobsPermanentPrompt />
      <AutoRoutePrompt />

      {!!pageLevelMessage ? (
        <div className="board-sticky-banner" role="alert">
          <div
            className={
              "alert alert-dismissible fade show " + pageLevelAlertClass
            }
          >
            {pageLevelMessage}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => dispatch(actionCreators.closePageLevelMessage())}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      ) : null}

      <div style={styles.headerContainer}>
        {!datePickerVisible ? (
          <button
            type="button"
            className="link-button"
            onClick={() => {
              previousSchedule();
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="2x" />
          </button>
        ) : null}

        {!datePickerVisible ? (
          wrapInMobileContent(
            <h1 className={"text-center schedule-header"} style={styles.header}>
              {pageHeaderText}
            </h1>,
            (content) => (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => setDatePickerVisible(true)}
              >
                {content}
              </button>
            )
          )
        ) : (
          <div ref={datePickerElement}>
            <OriginalDayPicker
              initialMonth={parse(baseDate)}
              selectedDays={parse(baseDate)}
              onDayClick={(day) => {
                setDatePickerVisible(false);
                changeScheduleDate(day);
              }}
            />
          </div>
        )}

        {!datePickerVisible ? (
          <button
            type="button"
            className="link-button"
            onClick={() => {
              nextSchedule();
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} size="2x" />
          </button>
        ) : null}
      </div>

      {unscheduledJobsElement}
    </>
  );
}

function wrapInMobileContent(
  baseContent: JSX.Element,
  mobileWrapper: (content: JSX.Element) => JSX.Element
): JSX.Element {
  return isMobileOnly ? mobileWrapper(baseContent) : baseContent;
}
