import { ajax, AjaxResponse } from "rxjs/ajax";
import { map, catchError, mergeMap } from "rxjs/operators";
import format from "date-fns/format";
import Auth from "./Auth";
import { throwError, Observable, of } from "rxjs";
import { ICrew } from "../models/ICrew";
import { IInitialLoad } from "../models/IInitialLoad";
import { IMaintenanceJob } from "../models/IMaintenanceJob";
import {
  IReport,
  IBillingReportJobInstanceDetails,
} from "../models/IBillingReport";
import { IJobInstance } from "../models/IJobInstance";
import { IOneTimeJob } from "../models/IOneTimeJob";
import dateService from "./dateService";
import { parse } from "date-fns";
import { ICrewViewConfiguration } from "../models/ICrewViewConfiguration";
import IFileUploadProperties from "../models/IFileUploadProperties";
import { ITodoTemplate } from "../models/ITodoTemplate";
import { IDaySchedule } from "../models/IDaySchedule";
import { ICustomer } from "../models/ICustomer";
import { IJobsForOrdering } from "../models/IJobForOrdering";
import { IQuickBooksCustomer } from "../models/IQuickBooksCustomer";
import { IInvoiceItem } from "../models/IInvoiceItem";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import { ICrewMember } from "../models/ICrewMember";
import { ICustomersSearchResult } from "../models/ICustomerSearchResult";
import { IUnscheduledJobInstance } from "../models/IUnscheduledMaintenanceJob";
import { IAdminViewConfiguration } from "../models/IAdminViewConfiguration";
import { IScheduledDispatchSettings } from "../models/IScheduledDispatchSettings";
import { IQuickBooksInvoiceSettings } from "../models/IQuickBooksInvoiceSettings";
import { IPermanentDropJobs } from "../models/IPermanentDropJobs";
import { IManageCustomersSearchResult } from "../models/IManageCustomersSearch";
import { IJobHistory } from "../models/IJobHistory";
import { IMaintenanceJobScheduleProperties } from "../models/IMaintenanceJobScheduleProperties";
import { ICustomerPaymentConfiguration } from "../models/ICustomerPaymentConfiguration";
import { ICreditCardTransaction } from "../models/ICreditCardTransaction";
import { IPayrollReportCrewMember } from "../models/IPayrollReportCrewMember";
import { IPayrollTimeRange } from "../models/IPayrollTimeRange";
import { IWorkNotInvoicedCustomer } from "../models/IWorkNotInvoicedCustomer";
import { IJobsSearchResult } from "../models/IJobSearchResult";
import { IInvoice, IInvoiceView } from "../models/IInvoice";
import { ICompanyProfile } from "../models/ICompanyProfile";
import { IRouteJobsResult } from "./jobRouter";
import { ICustomerNotificationsConfiguration } from "../models/ICustomerNotificationsConfiguration";
import { IOnboardingFormData } from "../containers/app/components/merchantOnboarding/IOnboardingFormData";
import { ICrewCategory } from "../models/ICrewCategory";
import { ICustomerPaymentMethod } from "../models/ICustomerPaymentMethod";
import { SendType } from "../enums/sendType";
import { IUserAccount } from "../models/IUserAccount";
import { IInvitation } from "../models/IInvitation";
import { ICustomerCommunicationTemplate } from "../models/ICustomerCommunicationTemplate";
import { IBankAccount } from "../models/IBankAccount";
import { UserAccountRole } from "../enums/userAccountRole";
import { CustomerCommunicationTemplateType } from "../enums/customerCommunicationTemplateType";
import { InvoiceDeliveryMethod } from "../models/InvoiceDeliveryMethod";
import { TenantPlan } from "../enums/tenantPlan";
import CustomerNotFoundError from "../errors/customerNotFoundError";
import { BillingReportJobStatusFilter } from "../enums/billingReportJobStatusFilter";
import { IUserNotificationsSettings } from "../models/IUserNotificationsSettings";
import { fullStoryLogError, fullStoryLogInfo } from "./fullStoryService";
import { IInvoiceConfiguration } from "../models/IInvoiceConfiguration";
import { InvoiceListSortColumns } from "../enums/invoiceListSortColumns";
import { SortDirection } from "../enums/sortDirection";
import { ICustomerTextTemplateDefaults } from "../models/ICustomerTextTemplateDefaults";
import { IReminderConfiguration } from "../models/IReminderConfiguration";
import { IInvoiceProposalDetail } from "../models/IInvoiceProposalDetail";
import { IUserSetting } from "../models/IUserSetting";
import { IInvoiceItemCrewControl } from "../models/IInvoiceItemCrewControl";
import { SubscriptionFrequency } from "../enums/subscriptionFrequency";
import { JobInstanceChangeType } from "../enums/jobInstanceChangeType";
import { MaintenanceJobChangeType } from "../enums/maintenanceJobChangeType";

const errorCodesToNotLog = [
  "PublishingWithoutContactInfo",
  "PublishingWithInvalidEmailAddress",
  "PublishingWithInvalidPhoneNumber",
  "CustomerDeleteWithMaintenanceJobs",
  "CustomerDeleteWithOneTimeJobs",
];

const getAuthorizedHeaders = () => {
  if (!Auth.isTokenExpired()) {
    return of(createAuthorizedHeaders(Auth.getAuthorizationHeaderValue()));
  } else {
    fullStoryLogInfo("Refresshing session");
    return Auth.renewTokenObservable().pipe(
      map((authorizationHeaderValue) =>
        createAuthorizedHeaders(authorizationHeaderValue)
      )
    );
  }
};

// WARNING: FullStory network capture settings need to be updated with any changes to these paths!
const merchantOnboardingFormPath = "submitOnboardingForm";
const achTokenizationFormPath = "achToken";

let signalrConnectionId = "";
export function setSignalrConnectionId(input: string) {
  signalrConnectionId = input;
}

export function createCommonHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

function createAuthorizedHeaders(authorizationHeaderValue: string) {
  let headers: any = {
    ...createCommonHeaders(),
    Authorization: authorizationHeaderValue,
  };

  if (signalrConnectionId) {
    headers["X-SignalRConnectionId"] = signalrConnectionId;
  }

  return headers;
}

export function executeWithHeaders<T>(
  action: (headers: any) => Observable<T>,
  headersFnOverride?: () => Observable<any>
): Observable<T> {
  const headersFn = headersFnOverride ?? (() => getAuthorizedHeaders());
  return headersFn().pipe(
    mergeMap((headers) => {
      return action(headers).pipe(
        catchError((err) => {
          const isIgnoredError =
            err.status === 400 &&
            err.response &&
            !!errorCodesToNotLog.find((c) => c === err.response.errorCode);

          if (!isIgnoredError) {
            fullStoryLogError(err);
          }

          return throwError(err);
        })
      );
    }),
    catchError((err) => {
      fullStoryLogError(err);

      return throwError(err);
    })
  );
}

const hostnameLowercase = window.location.hostname.toLowerCase();

let urlBase: string;
if (
  hostnameLowercase === "localhost" ||
  hostnameLowercase === "greenerpastures-local-frontend.azurewebsites.net"
) {
  urlBase = "https://localhost:44350";
} else if (
  hostnameLowercase === "greenerpastures-staging-frontend.azurewebsites.net" ||
  hostnameLowercase === "greenerpastures-staging-frontend.azureedge.net"
) {
  urlBase = "https://greenerpastures-staging-api.azurewebsites.net";
} else {
  urlBase = "https://api.crewcontrol.us";
}

export const buildUrl = (path: string) => `${urlBase}/api/${path}`;

const formatIso8601 = (input: Date) =>
  !!input ? format(input, "YYYY-MM-DD") : "";

interface IJobInstanceCrewNotesPayload {
  todoItems: Array<IJobInstanceCrewNotesTodoItem>;
}

interface IJobInstanceCrewNotesTodoItem {
  id: string;
  text: string;
}

export interface ILoadDaySchedulesResult {
  daySchedules: Array<IDaySchedule>;
  unscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJobsWeekResult>;
}

export interface IUnscheduledMaintenanceJobsWeekResult {
  date: string;
  jobInstances: Array<IUnscheduledJobInstance>;
  partialDay: boolean;
  partialDayEvaluatedAsOf: string | null;
}

export interface IGetOneTimeJobsResult {
  oneTimeJobs: Array<IOneTimeJob>;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
}

export interface IGetMaintenanceJobsResult {
  maintenanceJobs: Array<IMaintenanceJob>;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
}

export interface IGetCustomersResult {
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  oneTimeJobIds: Array<string>;
  maintenanceJobIds: Array<string>;
  projectIds: Array<string>;
}

export interface ISavedQuickBooksCustomers {
  id: string;
  quickBooksId: string;
}

export interface IShiftRequest {
  dates: Array<Date>;
  crewIds: Array<string>;
}

export interface IShiftResult {
  updatedDaySchedules: Array<string>;
}

export interface IDriveTimeRequest {
  legs: Array<IDriveTimeLeg>;
}

export interface IDriveTimeResult {
  legResults: Array<IDriveTimeLegResult>;
}
export interface IDriveTimeLeg {
  start: IDriveTimePoint;
  end: IDriveTimePoint;
}

export interface IDriveTimeLegResult extends IDriveTimeLeg {
  driveTimeInSeconds: number | null;
  error: boolean;
}

export interface IDriveTimePoint {
  latitude: string;
  longitude: string;
}

export interface IAutoRouteJobsRequest {
  dayScheduleId: string;
  rendezvousLocation: IDriveTimePoint;
  jobInstances: Array<IAutoRouteJobsRequestJobInstance>;
}

export interface ICustomerNotificationResult {
  customerResults: Array<ICustomerNotificationResultForSingleCustomer>;
}

export interface ICustomerNotificationResultForSingleCustomer {
  customerId: string;
  jobInstanceId: string;
  customerNotificationId: string | null;
  success: boolean;
  error: string | null;
  text: string;
}

export interface IGetOrderedJobsRequest
  extends IMaintenanceJobScheduleProperties {
  crewId: string;
  maintenanceJobId: string | null;
}

export interface IAutoRouteJobsRequestJobInstance {
  jobInstanceId: string;
  location: IDriveTimePoint;
}

export interface ISubscriptionCreateResult {
  clientSecret: string | null;
  alreadyPaid: boolean;
}

export interface IInvitationRegistrationDetails {
  tenantName: string;
  emailAddress: string;
  used: boolean;
  expired: boolean;
  hasExistingAuthUser: boolean;
}

export interface ISendInvoiceRequest {
  sendType: SendType;
  emailAddresses: Array<string>;
  replyToEmailAddress: string;
  phoneNumber: string;
  phoneNumberOptedIntoSms: boolean;
  invoiceId: string;
}

export interface IMerchantOnboardingDefaults {
  emailAddress: string;
  phoneNumber: string;
}

export interface IInvoiceSaveRequest extends IInvoice {
  jobInstanceIds: Array<string>;
  projectIds: Array<string>;
  customerEmailAddresses: Array<string>;
  customerPhoneNumber: string;
  allowOnlineCreditCardPayment: boolean;
  allowOnlineAchPayment: boolean;
  addConvenienceFee: boolean;
  contractBillingHistoryItemId: string;
  printOnSave: boolean;
  deliveryMethod: InvoiceDeliveryMethod;
  quickBooksDepositItemIdOverride: string | null;
  hideLineItemPrices: boolean;
  customerPhoneNumberOptedIntoSms: boolean;
}

export interface IQuickBooksCustomerRequest {
  displayName: string;
  streetAndNumber: string;
  apartmentSuite: string;
  city: string;
  state: string;
  zip: string;
  latitude: string;
  longitude: string;
  emailAddress: string;
  phoneNumber: string;
  alternativePhoneNumber: string;
  taxExempt: boolean;
}

export interface IQuickBooksCustomerResponse {
  id: string;
  displayName: string;
}

export type SaveUserAccountRequest = Partial<
  Omit<IUserAccount, "id" | "emailAddress">
>;

export type ISaveCustomerRequest = Partial<ICustomer> & {
  originalInactive: boolean | null;
};
export type ISaveCustomerResponse = ISaveCustomerRequest & { id: string };

export type ISaveCrewMemberRequest = Partial<ICrewMember> & {
  originalAllowMobileApplication: boolean | null;
};
export type ISaveCrewMemberResponse = ISaveCrewMemberRequest & { id: string };

export interface IClearJobsResponse {
  updatedMaintenanceJobIds: Array<string>;
  removedJobInstances: Array<string>;
  removedProjects: Array<string>;
  updatedDaySchedules: Array<string>;
  updatedFlexibleJobWeeks: Array<string>;
}

export interface IInvoicesResponse {
  list: Array<IInvoiceView>;
  totalAmount: number;
  hasMoreResults: boolean;
}

export interface IMoveJobInstanceRequest {
  jobInstanceIds: Array<string>;

  destinationCrewId: string | null;

  destinationFlexibleJob: boolean | null;

  destinationDate: string;

  destinationPrecedingJobInstanceId: string | null;

  permanentMaintenanceJobsMove: boolean;

  jobInstanceChangeType: JobInstanceChangeType | null;

  maintenanceJobChangeType: MaintenanceJobChangeType | null;

  jobInstanceUpdates: Array<{
    jobInstanceId: string;
    startTime: string | null;
    endTime: string | null;
  }> | null;
}

const remoteDataProvider = {
  getInitialLoad: (): Observable<IInitialLoad> => {
    const route = "initialload/v2";
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(route), headers)
    ).pipe(map((d) => d.response as IInitialLoad));
  },

  searchJobs: (seachText: string): Observable<IJobsSearchResult> => {
    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(`job/search?searchText=${encodeURIComponent(seachText)}`),
        headers
      )
    ).pipe(map((d) => d.response as IJobsSearchResult));
  },

  searchCustomers: (
    seachText: string,
    showAllCustomers?: boolean,
    page?: number,
    pageSize?: number,
    includeQuickBooksRecords?: boolean,
    includeAdditionalLocations?: boolean,
    includeInactive?: boolean,
    includeGroups?: boolean
  ): Observable<ICustomersSearchResult> => {
    let url = `customer/search?searchText=${encodeURIComponent(
      seachText
    )}&showAllCustomers=${showAllCustomers || false}&page=${
      page || 0
    }&pageSize=${pageSize || 20}&includeQuickBooksRecords=${
      includeQuickBooksRecords || false
    }&includeAdditionalLocations=${
      includeAdditionalLocations || false
    }&includeInactive=${includeInactive || false}&includeGroups=${
      includeGroups || false
    }`;

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(url), headers)
    ).pipe(map((d) => d.response as ICustomersSearchResult));
  },

  manageCustomersSearch: (
    seachText: string,
    showAllCustomers?: boolean,
    page?: number,
    pageSize?: number,
    includeAdditionalLocations?: boolean,
    includeInactive?: boolean
  ): Observable<IManageCustomersSearchResult> => {
    let url = `customer/managecustomerssearch?searchText=${encodeURIComponent(
      seachText
    )}&showAllCustomers=${showAllCustomers || false}&page=${
      page || 0
    }&pageSize=${pageSize || 20}&includeAdditionalLocations=${
      includeAdditionalLocations || false
    }&includeInactive=${includeInactive || false}`;

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(url), headers)
    ).pipe(map((d) => d.response as IManageCustomersSearchResult));
  },

  getOrderedJobs: (
    request: IGetOrderedJobsRequest
  ): Observable<IJobsForOrdering> => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("maintenancejob/getorderedjobsv2"), request, headers)
    ).pipe(map((d) => d.response as IJobsForOrdering));
  },

  getOneTimeJobs: (
    jobIds: Array<string>
  ): Observable<IGetOneTimeJobsResult> => {
    const route = "onetimejob/getbyids";
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(route), JSON.stringify(jobIds), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((d) => {
        const result = d.response as IGetOneTimeJobsResult;
        return result;
      })
    );
  },

  getMaintenanceJobs: (
    jobIds: Array<string>
  ): Observable<IGetMaintenanceJobsResult> => {
    const route = "maintenancejob/getbyids";
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(route), JSON.stringify(jobIds), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((d) => {
        const result = d.response as IGetMaintenanceJobsResult;
        return result;
      })
    );
  },

  getCustomers: ({
    customerIds,
  }: {
    customerIds: Array<string>;
  }): Observable<IGetCustomersResult> => {
    const route = "customer/getbyidsv2";
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(route),
        {
          customerIds,
          includeJobs: false,
        },
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((d) => {
        const result = d.response as IGetCustomersResult;

        const missingCustomers = customerIds.filter(
          (customerId) => !result.customers.some((c) => c.id === customerId)
        );
        if (missingCustomers.length > 0) {
          throw new CustomerNotFoundError(
            `customer not returned - ${missingCustomers[0]}`
          );
        }

        return result;
      })
    );
  },

  downloadCustomersCsv: (
    seachText: string,
    includeInactive?: boolean
  ): Observable<Blob> => {
    return executeWithHeaders((headers) =>
      ajax({
        method: "GET",
        responseType: "blob",
        url: buildUrl(
          `customer/export?searchText=${encodeURIComponent(
            seachText
          )}&includeInactive=${includeInactive || false}`
        ),
        headers,
      })
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as Blob;
      })
    );
  },

  loadBillingReport: (
    startDate: Date,
    endDate: Date,
    category: string,
    customerName: string,
    crewIds: Array<string>,
    jobStatus: BillingReportJobStatusFilter,
    customerCategoryId: string
  ): Observable<IReport> => {
    let baseUrl = `billingreport/v3?startdate=${formatIso8601(
      startDate
    )}&enddate=${formatIso8601(endDate)}`;

    if (category && category.trim()) {
      baseUrl += `&categories=${encodeURIComponent(category)}`;
    }

    if (customerCategoryId) {
      baseUrl += `&customerCategories=${encodeURIComponent(
        customerCategoryId
      )}`;
    }

    if (customerName && customerName.trim()) {
      baseUrl += `&customerName=${encodeURIComponent(customerName)}`;
    }

    if (crewIds && crewIds.length > 0) {
      crewIds.forEach(
        (crewId) => (baseUrl += `&crewIds=${encodeURIComponent(crewId)}`)
      );
    }

    baseUrl += `&jobStatus=${jobStatus}`;

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(baseUrl), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as IReport;
      })
    );
  },

  downloadJobInstancesCsv: (
    startDate: Date,
    endDate: Date,
    customerName: string,
    category: string,
    crewIds: Array<string>,
    jobStatus: BillingReportJobStatusFilter,
    customerCategoryId: string
  ): Observable<Blob> => {
    let baseUrl = `billingreport/completedjobs?startdate=${formatIso8601(
      startDate
    )}&enddate=${formatIso8601(endDate)}&customerName=${encodeURIComponent(
      customerName
    )}`;

    if (category && category.trim()) {
      baseUrl += `&categories=${encodeURIComponent(category)}`;
    }

    if (customerCategoryId) {
      baseUrl += `&customerCategories=${encodeURIComponent(
        customerCategoryId
      )}`;
    }

    if (crewIds && crewIds.length > 0) {
      crewIds.forEach(
        (crewId) => (baseUrl += `&crewIds=${encodeURIComponent(crewId)}`)
      );
    }

    baseUrl += `&jobStatus=${jobStatus}`;

    return executeWithHeaders((headers) =>
      ajax({
        method: "GET",
        responseType: "blob",
        url: buildUrl(baseUrl),
        headers,
      })
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as Blob;
      })
    );
  },

  getBillingReportJobInstanceDetails: (
    jobInstanceIds: Array<string>,
    projectIds: Array<string>
  ): Observable<Array<IBillingReportJobInstanceDetails>> => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`billingreport/jobInstanceDetails`),
        {
          jobInstanceIds,
          projectIds,
        },
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response
          .jobInstanceResults as Array<IBillingReportJobInstanceDetails>;
      })
    );
  },

  loadDaySchedules: (
    daySchedulesToLoad: any,
    weeksUnscheduledMaintenanceJobsToLoad: any,
    maxUnassignedWeekAlreadyLoaded: string | null
  ): Observable<ILoadDaySchedulesResult> => {
    const request = {
      daySchedulesToLoad,
      weeksToLoadUnscheduledMaintenanceJobsFor:
        weeksUnscheduledMaintenanceJobsToLoad.map((x: any) => formatIso8601(x)),
      maxUnassignedWeekAlreadyLoaded,
    };

    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("dayschedule/query/v2"),
        JSON.stringify(request),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as ILoadDaySchedulesResult;
      })
    );
  },

  getJobInstance: (
    jobInstanceId: string
  ): Observable<{ jobInstance: IJobInstance; crewId: string | null }> => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`jobInstance/${jobInstanceId}`), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          jobInstance: result.response.jobInstance as IJobInstance,
          crewId: result.response.crewId as string,
        };
      })
    );
  },

  saveTenant: (profile: ICompanyProfile) => {
    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(`Tenant`), profile, headers).pipe(
        catchError((err) => {
          fullStoryLogError(err);
          return throwError(err);
        }),
        map(() => {
          return {
            data: {
              ...profile,
            },
          };
        })
      )
    );
  },

  saveMaintenanceJob: (job: IMaintenanceJob, parameters: any) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    let isUpdate: boolean;
    if (parameters && parameters.maintenanceJobId) {
      saveFn = ajax.patch;
      urlSegment = `maintenancejob/${parameters.maintenanceJobId}`;
      isUpdate = true;
    } else {
      saveFn = ajax.post;
      urlSegment = "maintenancejob";
      isUpdate = false;
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(job), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...job,
            order: result.response.orderOfCreatedJob,
            parameters,
            id: isUpdate
              ? parameters.maintenanceJobId
              : result.response.createdId,
            updatedDaySchedules: result.response.updatedDaySchedules,
            updatedJobs: result.response.updatedJobs,
            // TODO: Why is when updatedFlexibleJobWeeks not set, the error is swallowed?
            updatedFlexibleJobWeeks: !result.response.updatedFlexibleJobWeeks
              ? []
              : result.response.updatedFlexibleJobWeeks.map((w: string) =>
                  parse(w)
                ),
            todoItems: result.response.todoItems,
            photos: result.response.photos,
            categories: result.response.categories,
            worksheets: result.response.worksheets,
            customerId: result.response.customerId,
            changedCategories: result.response.changedCategories,
            isAdd: !parameters?.maintenanceJobId,
            jobInstancesWithUpdatedTimes:
              result.response.jobInstancesWithUpdatedTimes,
          },
          success: true,
        };
      })
    );
  },

  moveMaintenanceJob: (request: IPermanentDropJobs) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("maintenancejob/move"),
        JSON.stringify(request),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            updatedDaySchedules: result.response
              .updatedDaySchedules as Array<string>,
            updatedFlexibleJobWeeks: result.response
              .updatedFlexibleJobWeeks as Array<string>,
          },
          success: true,
        };
      })
    );
  },

  saveOneTimeJob: (job: IOneTimeJob, parameters: any) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    let isUpdate: boolean;
    if (parameters && parameters.oneTimeJobId) {
      saveFn = ajax.patch;
      urlSegment = `onetimejob/${parameters.oneTimeJobId}`;
      isUpdate = true;
    } else {
      saveFn = ajax.post;
      urlSegment = "onetimejob";
      isUpdate = false;
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(job), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...job,
            id: isUpdate ? parameters.oneTimeJobId : job.id,
            updatedDaySchedules: result.response.updatedDaySchedules,
            updatedFlexibleJobWeeks: !result.response.updatedFlexibleJobWeeks
              ? []
              : result.response.updatedFlexibleJobWeeks.map((w: string) =>
                  parse(w)
                ),
            changedCategories: result.response.changedCategories,
            savedJobs: result.response.savedJobs,
            // If not an update, savedJobs has the categories
            categories: isUpdate ? result.response.categories : undefined,
            // If not an update, savedJobs has the categories
            worksheets: isUpdate ? result.response.worksheets : undefined,
            isAdd: !parameters?.oneTimeJob,
            todoItems: isUpdate ? result.response.todoItems : job.todoItems,
            photos: isUpdate ? result.response.photos : job.photos,
          },
          success: true,
        };
      })
    );
  },

  saveCrew: (crew: Partial<ICrew>, parameters: any) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    let isAddedCrew: boolean;
    if (parameters && parameters.crewId) {
      saveFn = ajax.patch;
      urlSegment = `crew/${parameters.crewId}`;
      isAddedCrew = false;
    } else {
      saveFn = ajax.post;
      urlSegment = "crew/v2";
      isAddedCrew = true;
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(crew), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...crew,
            parameters,
            id: result.response?.id ? result.response.id : parameters.crewId,
            changedCategories: result.response?.changedCategories
              ? result.response.changedCategories
              : [],
            crewCategories: result.response.crewCategories,
            isAddedCrew,
          },
          success: true,
        };
      })
    );
  },

  saveCrewMember: (
    crewMember: ISaveCrewMemberRequest,
    parameters: any
  ): Observable<{ data: ISaveCrewMemberResponse }> => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    let requestType: "post" | "patch";
    if (parameters && parameters.crewMemberId) {
      saveFn = ajax.patch;
      requestType = "patch";
      urlSegment = `crewMember/${parameters.crewMemberId}`;
    } else {
      saveFn = ajax.post;
      requestType = "post";
      urlSegment = "crewMember";
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(crewMember), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...crewMember,
            parameters,
            id:
              requestType === "patch"
                ? parameters.crewMemberId
                : result.response,
            inactivated:
              requestType === "patch" && result.response
                ? result.response.inactivated
                : null,
            inactivatedDateEqualOrGreater:
              requestType === "patch" && result.response
                ? result.response.inactivatedDateEqualOrGreater
                : null,
          },
          success: true,
        };
      })
    );
  },

  saveCustomer: (
    customer: ISaveCustomerRequest,
    parameters: any
  ): Observable<{ data: ISaveCustomerResponse }> => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    if (parameters && parameters.customerId) {
      saveFn = ajax.patch;
      urlSegment = `customer/${parameters.customerId}`;
    } else {
      saveFn = ajax.post;
      urlSegment = "customer";
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(customer), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...customer,
            parameters,
            id: result.response.createdId
              ? result.response.createdId
              : parameters.customerId,
            deletedCategories: result.response.deletedCategories ?? [],
            categories: result.response.categories,
          },
          success: true,
        };
      })
    );
  },

  sendAppInstructionsToCrewMember({ crewMemberId }: { crewMemberId: string }) {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`crewMember/${crewMemberId}/sendAppInstructions`),
        null,
        headers
      )
    );
  },

  saveCustomerPaymentConfiguration: (
    customerPaymentConfiguration: ICustomerPaymentConfiguration,
    parameters: any
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl(`customer/${parameters.customerId}/paymentConfiguration`),
        JSON.stringify(customerPaymentConfiguration),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          parameters,
          data: {
            ...customerPaymentConfiguration,
          },
          success: true,
        };
      })
    );
  },

  deleteCustomerPaymentConfiguration: (customerId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(
        buildUrl(`customer/${customerId}/paymentConfiguration`),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      })
    );
  },

  deleteCustomerPaymentMethod: (customerId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`customer/${customerId}/paymentMethod`), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      })
    );
  },

  saveCustomerAdditionalLocation: (
    customerAdditionalLocation: ICustomerAdditionalLocation,
    parameters: any
  ) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    if (parameters && parameters.customerAdditionalLocationId) {
      saveFn = ajax.patch;
      urlSegment = `customerAdditionalLocation/${parameters.customerAdditionalLocationId}`;
    } else {
      saveFn = ajax.post;
      urlSegment = "customerAdditionalLocation";
    }

    return executeWithHeaders((headers) =>
      saveFn(
        buildUrl(urlSegment),
        JSON.stringify(customerAdditionalLocation),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...customerAdditionalLocation,
            parameters,
            id: result.response.createdId
              ? result.response.createdId
              : parameters.customerAdditionalLocationId,
          },
          success: true,
        };
      })
    );
  },

  deleteCrew: (crewId: string) => {
    const urlSegment = `crew/${crewId}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  deleteCrewMember: (crewMemberId: string) => {
    const urlSegment = `crewMember/${crewMemberId}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  deleteMaintenanceJob: (maintenanceJobId: string) => {
    const urlSegment = `maintenanceJob/${maintenanceJobId}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  deleteCustomer: (customerId: string) => {
    const urlSegment = `customer/${customerId}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  clearCustomerJobs: (customerId: string) => {
    const urlSegment = `customer/${customerId}/clearjobs`;

    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(urlSegment), {}, headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as IClearJobsResponse;
      })
    );
  },

  deleteOneTimeJob: (oneTimeJobId: string) => {
    const urlSegment = `oneTimeJob/${oneTimeJobId}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  deleteJobInstance: (
    jobInstanceIds: Array<string>,
    markMaintenanceJobsInactive: boolean
  ) => {
    const urlSegment = `jobInstance/delete/v2`;

    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(urlSegment),
        { jobInstanceIds, markMaintenanceJobsInactive },
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          success: true,
          updatedMaintenanceJobIds: result.response.updatedMaintenanceJobIds,
          updatedDaySchedules: result.response.updatedDaySchedules,
          updatedFlexibleJobWeeks: result.response.updatedFlexibleJobWeeks,
        };
      })
    );
  },

  skipJobInstances: (jobInstanceIds: Array<string>, skipped: boolean) => {
    const urlSegment = `jobInstance/skip`;

    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(urlSegment), { jobInstanceIds, skipped }, headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  deleteCustomerAdditionalLocation: (customerAdditionalLocationId: string) => {
    const urlSegment = `customerAdditionalLocation/${customerAdditionalLocationId}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  moveJobInstance: (
    movePayload: Partial<IMoveJobInstanceRequest>,
    parameters: any
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("JobInstanceMove/v2"),
        JSON.stringify({
          ...movePayload,
          jobInstanceIds: parameters.jobInstanceIds,
        }),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...movePayload,
            ...result.response,
          },
          parameters,
          success: true,
        };
      })
    );
  },

  register: (registerPayload: any) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("useraccount"),
        JSON.stringify(registerPayload),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: registerPayload,
          success: true,
        };
      })
    );
  },

  saveUserAccount: (userAccountId: string, body: SaveUserAccountRequest) => {
    const urlSegment = `useraccount/${userAccountId}`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(body), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
          data: {
            userAccountId,
          },
        };
      })
    );
  },

  deleteUserAccount: (userId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`useraccount/${userId}`), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      })
    );
  },

  getInvitations: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`invitation`), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response.invitations as Array<IInvitation>;
      })
    );
  },

  deleteInvitation: (invitationId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`invitation/${invitationId}`), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      })
    );
  },

  publishSchedule: (payload: any, parameters: any) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("dayschedule/publish"),
        JSON.stringify({
          dayScheduleId: parameters.dayScheduleId,
          recipientEmailAddresses: payload.recipientEmailAddresses,
          crewMembers: payload.crewMembers,
          sendBetaLink: payload.sendBetaLink,
          savesDefaultRecipientsOnly: payload.savesDefaultRecipientsOnly,
        }),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: {
            ...payload,
            parameters,
          },
          success: true,
        };
      })
    );
  },

  markComplete: (markCompletePayload: any, parameters: any) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("JobInstanceCompletion"),
        JSON.stringify({
          ...markCompletePayload,
          timeRanges: markCompletePayload.timeRanges.map((tr: any) => ({
            ...tr,
            startTime: tr.startTime
              ? dateService.formatTimeForSerialization(tr.startTime)
              : null,
            endTime: tr.endTime
              ? dateService.formatTimeForSerialization(tr.endTime)
              : null,
          })),
          jobInstanceId: parameters.jobInstanceId,
        }),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          parameters,
          data: {
            ...markCompletePayload,
            ...result.response,
          },
          success: true,
        };
      })
    );
  },

  autoRouteJobs: (payload: IAutoRouteJobsRequest) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("AutoRouteDaySchedule/v2"),
        JSON.stringify(payload),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: payload,
          success: true,
        };
      })
    );
  },

  saveAutoRoutedJobs: (payload: IRouteJobsResult) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("AutoRouteDaySchedule"),
        JSON.stringify(payload),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: payload,
          success: true,
        };
      })
    );
  },

  isAuthenticated: () => {
    return Auth.isAuthenticated();
  },

  saveJobInstanceCrewNotes: (
    notes: IJobInstanceCrewNotesPayload,
    parameters: { jobInstanceId: string }
  ) => {
    const urlSegment = `JobInstanceCrewNotes/${parameters.jobInstanceId}`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(notes), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            response: result.response,
            parameters,
          },
          success: true,
        };
      })
    );
  },

  saveCrewViewConfiguration: (payload: ICrewViewConfiguration) => {
    const urlSegment = `TenantCrewViewConfiguration`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(payload), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            response: result.response as ICrewViewConfiguration,
          },
          success: true,
        };
      })
    );
  },

  saveAdminViewConfiguration: (payload: IAdminViewConfiguration) => {
    const urlSegment = `TenantAdminViewConfiguration`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(payload), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: payload,
          success: true,
        };
      })
    );
  },

  saveTenantInvoiceConfiguration: (
    payload: IInvoiceConfiguration & { updateContractBilling: boolean }
  ) => {
    const urlSegment = `TenantInvoiceConfiguration`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(payload), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: payload,
          success: true,
        };
      })
    );
  },

  getTenantInvoiceConfigurationUsage: () => {
    const urlSegment = `TenantInvoiceConfiguration/Usage`;

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(urlSegment), headers)
    ).pipe(
      map((result) => {
        return result.response as { customerContractBillingCount: number };
      })
    );
  },

  saveCustomerNotificationsConfiguration: (
    payload: ICustomerNotificationsConfiguration
  ) => {
    const urlSegment = `CustomerNotificationsConfiguration`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(payload), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: payload,
          success: true,
        };
      })
    );
  },

  sendCustomerNotificationsTestMessage: (body: {
    templateId: string | null;
    templateVariables: {
      [key: string]: string;
    };
    phoneNumber: string;
    phoneNumberOptedIntoSms: boolean;
  }) => {
    const urlSegment = `CustomerNotificationsConfiguration/SendTestMessage`;

    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(urlSegment), JSON.stringify(body), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      })
    );
  },

  saveJobInstance: (
    payload: Partial<IJobInstance>,
    parameters: { jobInstanceId: string }
  ) => {
    const urlSegment = `jobinstance/${parameters.jobInstanceId}`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(payload), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
          data: {
            ...payload,
            parameters,
          },
        };
      })
    );
  },

  saveJobInstances: (updates: Array<Partial<IJobInstance>>) => {
    const urlSegment = `jobinstance/BulkUpdate`;

    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(urlSegment), JSON.stringify({ updates }), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  saveDaySchedule: (
    payload: Partial<IDaySchedule>,
    parameters: { dayScheduleId: string }
  ) => {
    const urlSegment = `daySchedule/${parameters.dayScheduleId}`;

    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(urlSegment), JSON.stringify(payload), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
          data: {
            ...payload,
            parameters,
          },
        };
      })
    );
  },

  getPhotoImageWithoutIdSource: (
    tenantId: string,
    imagePath: string,
    contentType: string,
    width?: number
  ) => {
    return `${urlBase}/photos/NonSavedImage?tenantId=${tenantId}&imagePath=${imagePath}&contentType=${contentType}&width=${
      width || ""
    }`;
  },

  getFileUploadProperties: (): Observable<IFileUploadProperties> => {
    return executeWithHeaders((headers) =>
      ajax.get(`${urlBase}/photos/sastoken`, headers)
    ).pipe(map((r) => r.response as IFileUploadProperties));
  },

  saveTodoTemplate: (todoTemplate: ITodoTemplate, parameters: any) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    if (parameters && parameters.todoTemplateId) {
      saveFn = ajax.patch;
      urlSegment = `todoTemplate/${parameters.todoTemplateId}`;
    } else {
      saveFn = ajax.post;
      urlSegment = "todoTemplate";
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(todoTemplate), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            ...todoTemplate,
            parameters,
            id: result.response.id
              ? result.response.id
              : parameters.todoTemplateId,
            todoItems: result.response.todoItems,
          },
          success: true,
        };
      })
    );
  },

  deleteTodoTemplate: (id: string) => {
    const urlSegment = `todoTemplate/${id}`;

    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(urlSegment), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  setQuickBooksCookie: () => {
    return executeWithHeaders((headers) =>
      ajax({
        url: buildUrl("CookieAuthProvider/SetCookie"),
        method: "post",
        withCredentials: true,
        headers: headers,
      })
    ).pipe(
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  revokeQuickBooksAction: () => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("quickbooks/RevokeAccess"), null, headers)
    );
  },

  getQuickBooksCustomers: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl("quickbooks/customers"), headers)
    ).pipe(
      map((result) => {
        return result.response.customers as Array<IQuickBooksCustomer>;
      })
    );
  },

  getQuickBooksItems: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl("quickbooks/items"), headers)
    ).pipe(
      map((result) => {
        return result.response.items as Array<IInvoiceItem>;
      })
    );
  },

  getNonQuickBooksInvoiceItems: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl("invoiceitem"), headers)
    ).pipe(
      map((result) => {
        return result.response.items as Array<IInvoiceItemCrewControl>;
      })
    );
  },

  getNonQuickBooksInvoiceItem: (invoiceItemId: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`invoiceitem/${invoiceItemId}`), headers)
    ).pipe(
      map((result) => {
        return result.response as IInvoiceItemCrewControl;
      })
    );
  },

  addNonQuickBooksInvoiceItems: (
    payload: Partial<IInvoiceItemCrewControl>
  ): Observable<string> => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("invoiceitem"), payload, headers)
    ).pipe(
      map((result) => {
        return result.response.id as string;
      })
    );
  },

  updateNonQuickBooksInvoiceItems: (
    id: string,
    payload: Partial<IInvoiceItemCrewControl>
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(`invoiceitem/${id}`), payload, headers)
    );
  },

  getQuickBooksCustomersItems: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl("quickbooks/savedcustomers"), headers)
    ).pipe(
      map((result) => {
        return result.response.customers as Array<ISavedQuickBooksCustomers>;
      })
    );
  },

  getQuickBooksInvoiceSettings: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl("quickbooks/invoicesettings"), headers)
    ).pipe(
      map((result) => {
        return result.response as IQuickBooksInvoiceSettings;
      })
    );
  },

  saveInvoice: (invoice: IInvoiceSaveRequest) => {
    let saveFn: (
      url: string,
      body: any,
      headers: any
    ) => Observable<AjaxResponse>;
    let url: string;
    if (!invoice.id) {
      saveFn = ajax.post;
      url = "invoice";
    } else {
      saveFn = ajax.patch;
      url = `invoice/${invoice.id}`;
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(url), JSON.stringify(invoice), headers)
    ).pipe(
      map((result) => {
        return {
          data: {
            crewControlInvoiceId: result?.response?.id ?? null,
            jobInstanceUpdates: result?.response?.jobInstanceUpdates ?? [],
            sendEmail:
              invoice.deliveryMethod ===
                InvoiceDeliveryMethod.emailWithQuickBooks ||
              invoice.deliveryMethod ===
                InvoiceDeliveryMethod.emailWithCrewControl,
            jobInstanceIds: invoice.jobInstanceIds ?? [],
            customerUpdates: result?.response?.customerUpdates ?? [],
            emailFailed: result?.response?.emailFailed ?? false,
            textFailed: result?.response?.textFailed ?? false,
            textErrorMessage: result?.response?.textErrorMessage ?? null,
            crewControlUpdatesFailed:
              result?.response?.crewControlUpdatesFailed ?? false,
            customerId: invoice.customerId,
            customerEmailAddresses: invoice.customerEmailAddresses,
            customerPhoneNumber: invoice.customerPhoneNumber,
            printOnSave: invoice.printOnSave,
            draft: invoice.draft,
            customerPhoneNumberOptedIntoSms:
              invoice.customerPhoneNumberOptedIntoSms,
          },
          success: true,
        };
      })
    );
  },

  sendInvoice: (sendInvoicePayload: ISendInvoiceRequest) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("invoice/send"),
        JSON.stringify(sendInvoicePayload),
        headers
      )
    ).pipe(
      map(() => {
        return {
          success: true,
        };
      })
    );
  },

  deleteInvoice: (invoiceId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`invoice/${invoiceId}`), headers)
    );
  },

  shiftSchedules: (shiftRequest: IShiftRequest) => {
    const saveRequest = {
      ...shiftRequest,
      dates: shiftRequest.dates.map((d) => formatIso8601(d)),
    };

    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("dayschedule/shift"),
        JSON.stringify(saveRequest),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return { data: result.response as IShiftResult };
      })
    );
  },

  getDriveTimes: (getDriveTimesRequest: IDriveTimeRequest) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("map/getdrivetimes"),
        JSON.stringify(getDriveTimesRequest),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);

        // Return empty result
        return of({ response: { legResults: [] } as IDriveTimeResult });
      }),
      map((result) => {
        return result.response as IDriveTimeResult;
      })
    );
  },

  saveScheduledDispatchSettings: (request: IScheduledDispatchSettings) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl("scheduledDispatch/settings"),
        JSON.stringify(request),
        headers
      )
    ).pipe(
      map(() => {
        return { data: request };
      })
    );
  },

  getJobHistory: (
    customerId: string,
    startingDate: string,
    endingDate: string,
    category: string,
    locationId: string | null
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("customer/jobhistory"),
        JSON.stringify({
          customerId,
          startingDate,
          endingDate,
          categories: !category ? [] : [category],
          locationId,
        }),
        headers
      )
    ).pipe(map((result) => result.response as IJobHistory));
  },

  customerNotification: (request: any) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("customer/notify"), JSON.stringify(request), headers)
    ).pipe(
      map((result) => {
        return { data: result.response as ICustomerNotificationResult };
      }),
      map((result) => {
        return {
          ...result,
          data: {
            customerResults: result.data.customerResults.map((cr) => ({
              ...cr,
              text: request.text,
            })),
          },
        };
      })
    );
  },

  saveCustomerTextTemplateDefaults: (
    templateId: string,
    variableDefaults: ICustomerTextTemplateDefaults
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`customerNotificationsConfiguration/templateDefaultValues`),
        JSON.stringify({ templateId, variableDefaults }),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          data: {
            ...variableDefaults,
          },
          success: true,
        };
      })
    );
  },

  getCreditCardTransactions: (
    customerId: string | null,
    startDate: string | null,
    endDate: string | null
  ) => {
    const params: Array<string> = [];
    if (customerId !== null) {
      params.push(`customerId=${encodeURIComponent(customerId)}`);
    }
    if (startDate !== null) {
      params.push(`startDate=${encodeURIComponent(startDate)}`);
    }
    if (endDate !== null) {
      params.push(`endDate=${encodeURIComponent(endDate)}`);
    }

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`creditCardTransaction?${params.join("&")}`), headers)
    ).pipe(
      map(
        (result) =>
          result.response.transactions as Array<ICreditCardTransaction>
      )
    );
  },

  getPayrollReport: (
    startingDate: string,
    endingDate: string,
    breakTimeMinutes: number | null,
    breakThresholdMinutes: number | null
  ) => {
    const params: Array<string> = [];
    params.push(`startingDate=${encodeURIComponent(startingDate)}`);
    params.push(`endingDate=${encodeURIComponent(endingDate)}`);

    if (breakTimeMinutes !== null) {
      params.push(`breakTimeMinutes=${breakTimeMinutes}`);
    }

    if (breakThresholdMinutes !== null) {
      params.push(`breakThresholdMinutes=${breakThresholdMinutes}`);
    }

    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(`PayrollTimeTrackingReport?${params.join("&")}`),
        headers
      )
    ).pipe(
      map(
        (result) =>
          result.response.crewMembers as Array<IPayrollReportCrewMember>
      )
    );
  },

  downloadPayrollReportCsv: (
    startingDate: string,
    endingDate: string,
    breakTimeMinutes: number | null,
    breakThresholdMinutes: number | null
  ): Observable<Blob> => {
    const params: Array<string> = [];
    params.push(`startingDate=${encodeURIComponent(startingDate)}`);
    params.push(`endingDate=${encodeURIComponent(endingDate)}`);

    if (breakTimeMinutes !== null) {
      params.push(`breakTimeMinutes=${breakTimeMinutes}`);
    }

    if (breakThresholdMinutes !== null) {
      params.push(`breakThresholdMinutes=${breakThresholdMinutes}`);
    }

    return executeWithHeaders((headers) =>
      ajax({
        method: "GET",
        responseType: "blob",
        url: buildUrl(`PayrollTimeTrackingReport/download?${params.join("&")}`),
        headers,
      })
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as Blob;
      })
    );
  },

  getCrewMemberTimeRanges: (
    crewMemberId: string,
    startingDate: string | null,
    endingDate: string | null
  ) => {
    const params: Array<string> = [];
    if (startingDate !== null) {
      params.push(`startingDate=${encodeURIComponent(startingDate)}`);
    }
    if (endingDate !== null) {
      params.push(`endingDate=${encodeURIComponent(endingDate)}`);
    }

    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(
          `crewMember/${crewMemberId}/payrollTimeRanges?${params.join("&")}`
        ),
        headers
      )
    ).pipe(
      map(
        (result) =>
          result.response.payrollTimeRanges as Array<IPayrollTimeRange>
      )
    );
  },

  getPayrollTimeRange: (timeRangeId: string) => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`payrollTimeRange/${timeRangeId}`), headers)
        .pipe(map((result) => result.response as IPayrollTimeRange))
    );
  },

  savePayrollTimeRange: (timeRange: IPayrollTimeRange, parameters: any) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    if (parameters && parameters.timeRangeId) {
      saveFn = ajax.patch;
      urlSegment = `payrollTimeRange/${parameters.timeRangeId}`;
    } else {
      saveFn = ajax.post;
      urlSegment = "payrollTimeRange";
    }

    return executeWithHeaders((headers) =>
      saveFn(
        buildUrl(urlSegment),
        JSON.stringify({
          ...timeRange,
          crewMemberId: parameters.crewMemberId,
        }),
        headers
      )
    );
  },

  deletePayrollTimeRange: (timeRangeId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`payrollTimeRange/${timeRangeId}`), headers)
    );
  },

  getWorkNotInvoicedReport: (
    startingDate: string | null,
    endingDate: string | null,
    jobIds?: Array<string> | null,
    customerIds?: Array<string> | null
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`WorkNotInvoicedReport/get`),
        {
          startingDate,
          endingDate,
          jobIds: jobIds ?? [],
          customerIds: customerIds ?? null,
        },
        headers
      )
    ).pipe(
      map(
        (result) => result.response.customers as Array<IWorkNotInvoicedCustomer>
      )
    );
  },

  getInvoices: ({
    top,
    startingDate,
    endingDate,
    datePaidStartingDate,
    datePaidEndingDate,
    paid,
    customerId,
    invoiceIds,
    searchTerm,
    sortColumn,
    sortDirection,
    notSyncedToQuickBooks,
    showPastDueOnly,
  }: {
    top?: number | null;
    startingDate?: string | null;
    endingDate?: string | null;
    datePaidStartingDate?: string | null;
    datePaidEndingDate?: string | null;
    paid?: boolean | null;
    customerId?: string | null;
    invoiceIds?: Array<string> | null;
    searchTerm?: string;
    sortColumn?: InvoiceListSortColumns | null;
    sortDirection?: SortDirection | null;
    notSyncedToQuickBooks?: boolean | null;
    showPastDueOnly?: boolean | null;
  }): Observable<IInvoicesResponse> => {
    const params: Array<string> = [];

    if (typeof paid === "boolean") {
      params.push(`paid=${paid}`);
    }

    if (typeof showPastDueOnly === "boolean") {
      params.push(`showPastDueOnly=${showPastDueOnly}`);
    }

    if (top) {
      params.push(`top=${encodeURIComponent(top)}`);
    }

    if (startingDate) {
      params.push(`startingDate=${encodeURIComponent(startingDate)}`);
    }

    if (endingDate) {
      params.push(`endingDate=${encodeURIComponent(endingDate)}`);
    }

    if (datePaidStartingDate) {
      params.push(
        `datePaidStartingDate=${encodeURIComponent(datePaidStartingDate)}`
      );
    }

    if (datePaidEndingDate) {
      params.push(
        `datePaidEndingDate=${encodeURIComponent(datePaidEndingDate)}`
      );
    }

    if (customerId) {
      params.push(`customerId=${encodeURIComponent(customerId)}`);
    }

    if (invoiceIds) {
      invoiceIds.forEach((invoiceId) =>
        params.push(`invoiceIds=${encodeURIComponent(invoiceId)}`)
      );
    }

    if (searchTerm) {
      params.push(`searchTerm=${encodeURIComponent(searchTerm)}`);
    }

    if (typeof sortColumn === "number") {
      params.push(`sortColumn=${encodeURIComponent(sortColumn)}`);
    }

    if (typeof sortDirection === "number") {
      params.push(`sortDirection=${encodeURIComponent(sortDirection)}`);
    }

    if (typeof notSyncedToQuickBooks === "boolean") {
      params.push(`notSyncedToQuickBooks=${notSyncedToQuickBooks}`);
    }

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`Invoice?${params.join("&")}`), headers)
    ).pipe(
      map((result) => {
        return {
          list: result.response.invoices as Array<IInvoiceView>,
          invoiceProposalDetails: result.response
            .invoiceProposalDetails as Array<IInvoiceProposalDetail>,
          totalAmount: result.response.totalAmount,
          hasMoreResults: result.response.hasMoreResults,
        };
      })
    );
  },

  downloadInvoiceCsv: (
    startingDate: string | null,
    endingDate: string | null,
    paid: boolean,
    customerName: string | null,
    showPastDueOnly: boolean | null,
    datePaidStartingDate: string | null,
    datePaidEndingDate: string | null
  ): Observable<Blob> => {
    const params: Array<string> = [];
    params.push(`paid=${paid}`);

    if (startingDate) {
      params.push(`startingDate=${encodeURIComponent(startingDate)}`);
    }

    if (endingDate) {
      params.push(`endingDate=${encodeURIComponent(endingDate)}`);
    }

    if (customerName) {
      params.push(`customerName=${encodeURIComponent(customerName)}`);
    }

    if (showPastDueOnly) {
      params.push(`showPastDueOnly=${encodeURIComponent(showPastDueOnly)}`);
    }

    if (datePaidStartingDate) {
      params.push(
        `datePaidStartingDate=${encodeURIComponent(datePaidStartingDate)}`
      );
    }

    if (datePaidEndingDate) {
      params.push(
        `datePaidEndingDate=${encodeURIComponent(datePaidEndingDate)}`
      );
    }

    return executeWithHeaders((headers) =>
      ajax({
        method: "GET",
        responseType: "blob",
        url: buildUrl(`invoice/csv?${params.join("&")}`),
        headers,
      })
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response as Blob;
      })
    );
  },

  createSubscription: (body: {
    crewCount: number;
    tenantPlan: TenantPlan;
    subscriptionFrequency: SubscriptionFrequency;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`Subscription`), body, headers)
    ).pipe(map((result) => result.response as ISubscriptionCreateResult));
  },

  createSubscriptionPaymentRecord: (paymentMethodId: string | null) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`Subscription/paymentRecord`),
        { paymentMethodId },
        headers
      )
    );
  },

  cancelSubscription: () => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`Subscription`), headers)
    );
  },

  createInvitation: (body: {
    emailAddress: string;
    name: string;
    role: UserAccountRole;
    isTenantOwner: boolean;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`Invitation`), body, headers)
    );
  },

  getInvitationRegistrationDetails: (invitationId: string) => {
    return executeWithHeaders(
      (headers) =>
        ajax
          .get(
            buildUrl(`Invitation/${invitationId}/registrationDetails`),
            headers
          )
          .pipe(
            map((result) => result.response as IInvitationRegistrationDetails)
          ),
      () => of(createCommonHeaders())
    );
  },

  getMerchantOnboardingDefaults: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`MerchantOnboarding/onboardingDefaults`), headers)
    ).pipe(map((result) => result.response as IMerchantOnboardingDefaults));
  },

  submitMerchantOnboarding: (
    formData: IOnboardingFormData & { businessName: string }
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`MerchantOnboarding/${merchantOnboardingFormPath}`),
        formData,
        headers
      )
    );
  },

  getAvailableCrewCategoryColors: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl("crewCategories/availableColors"), headers)
    ).pipe(map((r) => r.response.colors as Array<string>));
  },

  saveCrewCategories: (updates: Array<Partial<ICrewCategory>>) => {
    const urlSegment = `crewCategories/BulkUpdate`;

    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(urlSegment), JSON.stringify({ updates }), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((r) => {
        return {
          data: {
            updates,
            addedCrewCategories: r.response.addedCrewCategories,
          },
          success: true,
        };
      })
    );
  },

  saveCustomerPaymentMethod: (
    customerPaymentMethod: Partial<ICustomerPaymentMethod & { token: string }>,
    parameters: any
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl(`customer/${parameters.customerId}/paymentMethod`),
        JSON.stringify(customerPaymentMethod),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map(() => {
        return {
          parameters,
          data: customerPaymentMethod,
          success: true,
        };
      })
    );
  },

  tokenizeAchAccount(
    account: IBankAccount
  ): Observable<{ token: string; partialNumber: string }> {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(achTokenizationFormPath),
        JSON.stringify(account),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return result.response;
      })
    );
  },

  saveQuickBooksCustomer: (request: IQuickBooksCustomerRequest) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`quickBooks/customer`),
        JSON.stringify(request),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          id: result.response.id,
          displayName: request.displayName,
        } as IQuickBooksCustomerResponse;
      })
    );
  },

  saveCustomerCommunicationTemplate: (
    type: CustomerCommunicationTemplateType,
    request: Partial<ICustomerCommunicationTemplate>
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl(`customerCommunicationTemplate/${type}`),
        JSON.stringify(request),
        headers
      )
    );
  },

  saveCustomerReminderConfiguration: (
    proposalBeforeReminderConfiguration: IReminderConfiguration,
    proposalAfterReminderConfiguration: IReminderConfiguration,
    invoiceBeforeReminderConfiguration: IReminderConfiguration,
    invoiceAfterReminderConfiguration: IReminderConfiguration
  ) => {
    return executeWithHeaders((headers) =>
      ajax
        .post(
          buildUrl(`customerReminderConfiguration`),
          JSON.stringify({
            proposalBeforeReminderConfiguration,
            proposalAfterReminderConfiguration,
            invoiceBeforeReminderConfiguration,
            invoiceAfterReminderConfiguration,
          }),
          headers
        )
        .pipe(
          catchError((err) => {
            fullStoryLogError(err);
            return throwError(err);
          }),
          map(() => {
            return {
              data: {
                proposalBeforeReminderConfiguration,
                proposalAfterReminderConfiguration,
                invoiceBeforeReminderConfiguration,
                invoiceAfterReminderConfiguration,
              },
              success: true,
            };
          })
        )
    );
  },

  customerImportPreview: (file: File): Observable<Array<ICustomer>> => {
    const formData = new FormData();
    formData.append("file", file);
    return executeWithHeaders((headers) => {
      delete headers["Content-Type"];
      return ajax
        .post(buildUrl(`customer/importPreview`), formData, headers)
        .pipe(map((r) => r.response.customers));
    });
  },

  customerImport: (customers: Array<ICustomer>) => {
    return executeWithHeaders((headers) => {
      return ajax.post(buildUrl(`customer/import`), { customers }, headers);
    });
  },

  getUserNotificationsSettings: (): Observable<IUserNotificationsSettings> => {
    return executeWithHeaders((headers) => {
      return ajax
        .get(buildUrl(`userNotificationsSettings`), headers)
        .pipe(map((r) => r.response));
    });
  },

  saveUserNotificationsSettings: (
    changes: Partial<IUserNotificationsSettings>
  ) => {
    return executeWithHeaders((headers) => {
      return ajax.patch(
        buildUrl(`userNotificationsSettings`),
        changes,
        headers
      );
    });
  },

  saveUserSettings: (userSettings: Array<IUserSetting>) => {
    return executeWithHeaders((headers) => {
      return ajax.post(
        buildUrl(`userSettings`),
        {
          userSettings,
        },
        headers
      );
    });
  },
};

export default remoteDataProvider;
export var url = urlBase;
