import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, timeout } from "rxjs/operators";
import { of } from "rxjs";
import { worksheetsActionCreators } from "../worksheet";
import worksheetsDataProvider from "../../services/worksheetsDataProvider";
import { actionCreators } from "../../../../modules/actionCreators";
import { IApplicationState } from "../../../../modules";

const reloadWorksheet: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(worksheetsActionCreators.reloadWorksheet.match),
    mergeMap((action) => {
      return worksheetsDataProvider
        .getWorksheets({ worksheetIds: [action.payload.worksheetId] })
        .pipe(
          map((worksheets) => {
            if (worksheets.length === 1) {
              return worksheetsActionCreators.loadSpecificWorksheets({
                worksheets,
              });
            } else {
              return actionCreators.null();
            }
          }),
          timeout(30000),
          catchError(() => of(actionCreators.null()))
        );
    })
  );

export default reloadWorksheet;
