import parse from "date-fns/parse";
import { RouterState } from "connected-react-router";
import { ICrew } from "../models/ICrew";
import { isMobileOnly } from "react-device-detect";
import dateService from "./dateService";
import { CrewScheduleType } from "../slices/schedule/enums/crewScheduleType";
import { GetUserSettingsType } from "./userSettingsService";
import { UserSettingsType } from "../enums/userSettingsType";
import { isStringSet } from "./stringService";
import constants from "../constants";

export const emptyCategoryCode = "8ce46749-08c3-4e78-8886-3dafcde51bd7";

const patterns = {
  registration: {
    // 'as const' needed for <Route />'s parameter inference
    acceptInvitation: "/usersignup/:invitationId" as const,
  },
  schedule: {
    default: "/schedule/week",
    map: "/schedule/map/:date?/:crewId?",
    sequenceWeek: "/schedule/week/:date?/:crewId?/:mapDate?",
    sequenceDay: "/schedule/day/:date?/:crewFilter?/:mapCrew?",
    month: "/schedule/month/:date?/:defaultCrewId?",
    print: "/schedule/print/:date/:crewId",
    timeWeek: "/calendar/week/:date?/:crewId?/:mapDate?",
    timeDay: "/calendar/day/:date?/:crewFilter?/:mapCrew?",
  },
  completedWork: {
    report: "/completedWork/report",
  },
  billing: {
    paymentHistory: "/billing/paymentHistory",
    index: "/billing/",
  },
  report: {
    daily: "/report/daily/:date?/:crewId?",
    employeeTimes: "/report/employeeTimes",
  },
  manage: {
    crews: "/manage/crews",
    crewsMember: "/manage/crewmembers",
    jobs: "/manage/jobs",
    todoTemplates: "/manage/checklists",
    customers: "/manage/customers/:search?",
    customerDetails: "/manage/customer/:customerId/:search?",
    crewMemberDetails: "/manage/crewMember/:crewMemberId",
    lineItems: "/manage/productsservices",
    customerCommunication: "/manage/customercommunication",
    proposalTemplates: "/manage/proposalTemplates",
  },
  setup: {
    quickBooksCustomerImport: "/setup/quickbookscustomerimport",
  },
  estimates: {
    index: "/sales/:opportunityId?",
  },
  clientSupport: {
    index: "/clientSupport",
  },
  userAccount: {
    login: "/login/",
    signup: "/signup",
  },
  print: {
    invoice: "/print/invoice/:invoiceId",
    proposal: "/print/proposal/:proposalId",
  },
  subscription: {
    getStarted: "/getStarted/",
  },
  worksheets: {
    jobWorksheetsPage: "/worksheets/",
  },
};

const builders = {
  registration: {
    acceptInvitation: (invitationId: string) => {
      return `/usersignup/${invitationId}`;
    },
  },
  schedule: {
    buildSequenceWeekRoute: (
      date?: string,
      crewId?: string,
      mapDate?: string
    ) => {
      let route = `/schedule/week`;
      if (typeof date === "string") {
        route += `/${date || ""}`;

        if (typeof crewId === "string") {
          route += `/${crewId || ""}`;

          if (typeof mapDate === "string") {
            route += `/${mapDate || ""}`;
          }
        }
      }

      return route;
    },
    buildWeekRoute: (
      getUserSettings: GetUserSettingsType,
      crews: ICrew[],
      date?: string,
      crewId?: string,
      mapDate?: string
    ) => {
      const activeCrews = crews.filter((c) => !c.inactive);
      const hasActiveScheduleBasedCrews = activeCrews.some(
        (c) => c.scheduleType !== CrewScheduleType.time
      );
      const hasActiveTimeBasedCrews = activeCrews.some(
        (c) => c.scheduleType === CrewScheduleType.time
      );

      let route = `/schedule/week`;
      if (typeof date === "string") {
        route += `/${date || ""}`;

        if (typeof crewId === "string") {
          route += `/${crewId || ""}`;

          if (typeof mapDate === "string") {
            route += `/${mapDate || ""}`;
          }
        }
      }

      const isTimeBased =
        !hasActiveScheduleBasedCrews ||
        (getUserSettings(UserSettingsType.defaultCrewScheduleType) ===
          CrewScheduleType.time &&
          hasActiveTimeBasedCrews) ||
        crews.some(
          (c) => c.id === crewId && c.scheduleType === CrewScheduleType.time
        );

      if (isTimeBased) {
        route = `/calendar/week`;
        if (typeof date === "string") {
          route += `/${date || ""}`;

          if (typeof crewId === "string") {
            route += `/${crewId || ""}`;

            if (typeof mapDate === "string") {
              route += `/${mapDate || ""}`;
            }
          }
        }
      }
      return route;
    },
    buildDayRoute: (
      getUserSettings: GetUserSettingsType,
      crews: ICrew[],
      date: string,
      mapCrew?: string,
      crewFilter?: string
    ) => {
      const activeCrews = crews.filter((c) => !c.inactive);
      const hasActiveScheduleBasedCrews = activeCrews.some(
        (c) => c.scheduleType !== CrewScheduleType.time
      );
      const hasActiveTimeBasedCrews = activeCrews.some(
        (c) => c.scheduleType === CrewScheduleType.time
      );

      let route = `/schedule/day/${date}/${encodeURIComponent(
        crewFilter || emptyCategoryCode
      )}/${mapCrew || ""}`;

      if (
        !hasActiveScheduleBasedCrews ||
        (getUserSettings(UserSettingsType.defaultCrewScheduleType) ===
          CrewScheduleType.time &&
          hasActiveTimeBasedCrews)
      ) {
        route = `/calendar/day/${date}/${encodeURIComponent(
          crewFilter || emptyCategoryCode
        )}/${mapCrew || ""}`;
      }

      return route;
    },
    buildSequenceDayRoute: (
      date: string,
      mapCrew?: string,
      crewFilter?: string
    ) =>
      `/schedule/day/${date}/${encodeURIComponent(
        crewFilter || emptyCategoryCode
      )}/${mapCrew || ""}`,
    buildPrintRoute: (date: string, crewId: string) =>
      `/schedule/print/${date}/${crewId}`,
    buildMapWeekRoute: (date?: string, crewId?: string) =>
      `/schedule/map/${date || ""}/${crewId || ""}`,
    buildMonthRoute: (date: string, defaultCrewId?: string) =>
      `/schedule/month/${date}/${defaultCrewId ?? ""}`,
    buildTimeWeekRoute: (date?: string, crewId?: string, mapDate?: string) => {
      let route = `/calendar/week`;
      if (typeof date === "string") {
        route += `/${date || ""}`;

        if (typeof crewId === "string") {
          route += `/${crewId || ""}`;

          if (typeof mapDate === "string") {
            route += `/${mapDate || ""}`;
          }
        }
      }

      return route;
    },
    buildTimeDayRoute: (date: string, mapCrew?: string, crewFilter?: string) =>
      `/calendar/day/${date}/${encodeURIComponent(
        crewFilter || emptyCategoryCode
      )}/${mapCrew || ""}`,
  },
  completedWork: {
    buildReportRoute: () => patterns.completedWork.report,
  },
  billing: {
    buildPaymentHistoryRoute: () => patterns.billing.paymentHistory,
    buildIndexRoute: () => patterns.billing.index,
  },
  report: {
    buildDailyRoute: (date: string | undefined, crewId: string | undefined) =>
      patterns.report.daily
        .replace(":date?", date || "")
        .replace(":crewId?", crewId || ""),
    buildEmployeeTimes: () => patterns.report.employeeTimes,
  },
  manage: {
    buildCrewsRoute: () => patterns.manage.crews,
    buildCrewMembersRoute: () => patterns.manage.crewsMember,
    buildJobsRoute: () => patterns.manage.jobs,
    buildTodoTemplatesRoute: () => patterns.manage.todoTemplates,
    buildLineItemsRoute: () => patterns.manage.lineItems,
    buildProposalTemplatesPage: () => patterns.manage.proposalTemplates,
    buildCustomerCommunicationRoute: () =>
      patterns.manage.customerCommunication,
    buildCustomersRoute: (search?: string) =>
      patterns.manage.customers.replace(
        ":search",
        encodeURIComponent(search || "")
      ),
    buildCustomerDetailsRoute: ({
      customerId,
      search,
      activeTab,
      searchText,
    }: IBuildCustomerDetailsRoute) => {
      const params = new URLSearchParams();

      if (activeTab) {
        params.append(constants.customerRecordTab, activeTab?.toString() ?? "");
      }

      if (searchText) {
        params.append(constants.customerRecordSearchText, searchText ?? "");
      }

      var queryString = isStringSet(params.toString())
        ? `?${params.toString()}`
        : "";

      return patterns.manage.customerDetails
        .replace(":customerId", customerId)
        .replace(":search?", encodeURIComponent(search || ""))
        .concat(queryString);
    },
    buildCrewMemberDetailsRoute: (crewMemberId: string) =>
      patterns.manage.crewMemberDetails.replace(":crewMemberId", crewMemberId),
  },
  setup: {
    buildQuickBooksCustomerImport: () =>
      patterns.setup.quickBooksCustomerImport,
  },
  sales: {
    buildSalesPage: () =>
      patterns.estimates.index.replace(":opportunityId?", ""),
  },
  userAccount: {
    buildLogin: () => patterns.userAccount.login,
    buildSignup: () => patterns.userAccount.signup,
  },
  print: {
    buildInvoicePrintPage: (invoiceId: string) =>
      patterns.print.invoice.replace(":invoiceId", invoiceId),
    buildProposalPrintPage: (proposalId: string) =>
      patterns.print.proposal.replace(":proposalId", proposalId),
  },
  subscription: {
    buildGetStarted: () => patterns.subscription.getStarted,
  },
  worksheets: {
    buildJobWorksheetsPage: () => patterns.worksheets.jobWorksheetsPage,
  },
};

const parsers = {
  report: {
    tryParseDailyMap: (router: RouterState): ITryParseDailyReportResult => {
      const result: ITryParseDailyReportResult = {
        isMatch: false,
      };

      if (router && router.location && router.location.pathname) {
        const dailyReportMatches = router.location.pathname.match(
          /report\/daily\/((\d|-)+)(\/(([a-z]|[A-Z]|[0-9]|-)+))?/
        );

        if (dailyReportMatches && dailyReportMatches.length === 6) {
          result.isMatch = true;
        }
      }

      return result;
    },
  },
  schedule: {
    tryParseMonthSchedule: (router: RouterState): ITryParseScheduleResult => {
      const result: ITryParseScheduleResult = {
        isMatch: false,
        dateInRoute: null,
        crewIdInRoute: null,
      };

      if (router && router.location && router.location.pathname) {
        const monthRouteMatches = router.location.pathname.match(
          /schedule\/month\/((\d|-)+)/
        );

        if (monthRouteMatches && monthRouteMatches.length === 3) {
          const date = parse(monthRouteMatches[1]);

          result.isMatch = true;
          result.dateInRoute = date;
        }
      }

      return result;
    },

    tryParseWeekSequence: (router: RouterState): ITryParseScheduleResult => {
      const result: ITryParseScheduleResult = {
        isMatch: false,
        dateInRoute: null,
        crewIdInRoute: null,
      };

      if (router && router.location && router.location.pathname) {
        const weekRouteMatches = router.location.pathname.match(
          /schedule\/week\/((\d|-)+)\/(([a-z]|[A-Z]|[0-9]|-)+)/
        );

        if (weekRouteMatches && weekRouteMatches.length === 5) {
          const date = parse(weekRouteMatches[1]);

          result.isMatch = true;
          result.dateInRoute = date;
          result.crewIdInRoute = weekRouteMatches[3];
        }
      }

      return result;
    },

    tryParseDaySequence: (
      router: RouterState,
      crews: Array<ICrew>
    ): ITryParseScheduleResult => {
      const result: ITryParseScheduleResult = {
        isMatch: false,
        dateInRoute: null,
        crewIdInRoute: null,
      };

      if (router && router.location && router.location.pathname) {
        const dayRouteMatches = router.location.pathname.match(
          /schedule\/day\/((\d|-)+)(\/(([a-z]|[A-Z]|[0-9]|-)+))?/
        );

        if (dayRouteMatches && dayRouteMatches.length === 6) {
          const date = parse(dayRouteMatches[1]);

          result.isMatch = true;
          result.dateInRoute = date;

          // Last bit of this route can either be a crew ID or a crew category ID.
          // Verify it's actually a crew ID before returning. Crew ID happens in mobile
          // while crew category ID happens in desktop/tablet.
          const potentialCrewId = dayRouteMatches[4];
          if (crews.some((c) => c.id === potentialCrewId)) {
            result.crewIdInRoute = potentialCrewId;
          }
        }
      }

      return result;
    },

    tryParseTimeWeek: (router: RouterState): ITryParseScheduleResult => {
      const result: ITryParseScheduleResult = {
        isMatch: false,
        dateInRoute: null,
        crewIdInRoute: null,
      };

      if (router && router.location && router.location.pathname) {
        const weekRouteMatches = router.location.pathname.match(
          /calendar\/week\/((\d|-)+)\/(([a-z]|[A-Z]|[0-9]|-)+)/
        );

        if (weekRouteMatches && weekRouteMatches.length === 5) {
          const date = parse(weekRouteMatches[1]);

          result.isMatch = true;
          result.dateInRoute = date;
          result.crewIdInRoute = weekRouteMatches[3];
        }
      }

      return result;
    },

    tryParseTimeDay: (
      router: RouterState,
      crews: Array<ICrew>
    ): ITryParseScheduleResult => {
      const result: ITryParseScheduleResult = {
        isMatch: false,
        dateInRoute: null,
        crewIdInRoute: null,
      };

      if (router && router.location && router.location.pathname) {
        const dayRouteMatches = router.location.pathname.match(
          /calendar\/day\/((\d|-)+)(\/(([a-z]|[A-Z]|[0-9]|-)+))?/
        );

        if (dayRouteMatches && dayRouteMatches.length === 6) {
          const date = parse(dayRouteMatches[1]);

          result.isMatch = true;
          result.dateInRoute = date;

          // Last bit of this route can either be a crew ID or a crew category ID.
          // Verify it's actually a crew ID before returning. Crew ID happens in mobile
          // while crew category ID happens in desktop/tablet.
          const potentialCrewId = dayRouteMatches[4];
          if (crews.some((c) => c.id === potentialCrewId)) {
            result.crewIdInRoute = potentialCrewId;
          }
        }
      }

      return result;
    },

    tryParseScheduleMap: (router: RouterState): ITryParseScheduleMapResult => {
      const result: ITryParseScheduleMapResult = {
        isMatch: false,
        dateInRoute: null,
      };

      if (router && router.location && router.location.pathname) {
        const mapRouteMatches = router.location.pathname.match(
          /schedule\/map\/((\d|-)+)(\/(([a-z]|[A-Z]|[0-9]|-)+))?/
        );

        if (mapRouteMatches && mapRouteMatches.length === 6) {
          const date = parse(mapRouteMatches[1]);

          result.isMatch = true;
          result.dateInRoute = date;
        }
      }

      return result;
    },
  },
};

export interface ITryParseScheduleResult {
  dateInRoute: Date | null;
  isMatch: boolean;
  crewIdInRoute: string | null;
}

export interface ITryParseScheduleMapResult {
  dateInRoute: Date | null;
  isMatch: boolean;
}

export interface ITryParseDailyReportResult {
  isMatch: boolean;
}

export interface IBuildCustomerDetailsRoute {
  customerId: string;
  search?: string;
  activeTab?: number;
  searchText?: string;
}

export { patterns, builders, parsers };

export function getDefaultRoute({
  defaultCrewScheduleType = null,
}: {
  defaultCrewScheduleType: CrewScheduleType | null;
}): string {
  const isSequenceBasedCrew =
    defaultCrewScheduleType === null ||
    defaultCrewScheduleType === CrewScheduleType.sequence;
  if (!isMobileOnly) {
    return isSequenceBasedCrew
      ? patterns.schedule.default
      : builders.schedule.buildTimeWeekRoute(
          dateService.formatAsIso(new Date())
        );
  } else {
    return isSequenceBasedCrew
      ? builders.schedule.buildSequenceDayRoute(
          dateService.formatAsIso(new Date()),
          "",
          ""
        )
      : builders.schedule.buildTimeDayRoute(
          dateService.formatAsIso(new Date())
        );
  }
}
