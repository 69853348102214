import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Schedule from "../../../containers/app/components/schedule";
import format from "date-fns/format";
import addDays from "date-fns/add_days";
import { IDayToLoad } from "../../../services/dayScheduleLoader";
import { actionCreators } from "../../../modules/actionCreators";
import { push, RouterAction } from "connected-react-router";
import {
  builders,
  builders as routerBuilders,
} from "../../../services/routing";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IRootState } from "../../../store";
import { ICrew } from "../../../models/ICrew";
import dateService from "../../../services/dateService";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { Path } from "history";
import { isLoadingScheduleDates } from "../../../services/scheduleService";
import { ICrewCategory } from "../../../models/ICrewCategory";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import { IScheduleColumn } from "./types/IScheduleColumn";
import {
  getDateForSchedule,
  getFilteredCrews,
  getPageHeaderText,
  getScheduleDayColumns,
  getSelectedDateStartOfWeek,
} from "../services/scheduleDayService";
import { ScheduleDayFilters } from "./ScheduleDayFilters";
import { useEnsureSchedulesLoadedForDay } from "../hooks/useEnsureSchedulesLoadedForDay";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { InitialDispatchPrompt } from "../../tenantSubscription/components/InitialDispatchPrompt";

interface IProps {
  crews: Array<ICrew>;
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  loadDaySchedulesStarting(
    daySchedulesToLoad: Array<IDayToLoad>,
    weeksUnscheduledMaintenanceJobsToLoad: Array<Date>,
    maxUnassignedWeekAlreadyLoaded: string | null
  ): any;
  redirect(path: Path): RouterAction;
  crewCategories: Array<ICrewCategory>;
}

interface IRouteParams {
  date: string;
  mapCrew: string;
  crewFilter: string;
}
const ScheduleSequenceDayPage: React.FunctionComponent<
  IProps & RouteComponentProps<IRouteParams>
> = (props) => {
  const { crews, daySchedules, redirect, weeksUnscheduledMaintenanceJobs } =
    props;
  const sequenceBasedCrews = crews.filter(
    (c) => c.scheduleType === CrewScheduleType.sequence
  );
  const params = props.match.params;

  useEnsureSchedulesLoadedForDay({
    crews,
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    params: params,
    includePrecedingDay: false,
  });

  useRedirectToSequenceCalendarIfNoSequenceCrews({ params });

  const scheduleDate = getDateForSchedule(params);
  const pageHeaderText = getPageHeaderText(scheduleDate);

  const filteredCrews = getFilteredCrews(sequenceBasedCrews, params);

  const isCurrentDate = dateService.areDatesEqual(
    dateService.getCurrentDate(),
    scheduleDate
  );

  const columns = getScheduleDayColumns({
    filteredCrews,
    daySchedules,
    scheduleDate,
    params,
    isCurrentDate,
    getRoutePath: routerBuilders.schedule.buildSequenceDayRoute,
  });

  const { hasMissingDates, loadingUnscheduledJobs } = isLoadingScheduleDates(
    getSelectedDateStartOfWeek(props.match.params),
    columns,
    weeksUnscheduledMaintenanceJobs
  );

  return (
    <PageWithNavBar2
      belowNavBarContent={<InitialDispatchPrompt />}
      subHeaderLeftSideContent={
        <ScheduleDayFilters
          params={props.match.params}
          crewsForCurrentSchedule={sequenceBasedCrews}
          getRoute={routerBuilders.schedule.buildSequenceDayRoute}
          mode={CrewScheduleType.sequence}
        />
      }
    >
      <Schedule
        customContainerClassName="schedule-week-container-day"
        pageHeaderText={pageHeaderText}
        onRowExpanded={() => {}}
        rows={[
          {
            columns: columns.map((c) => c as IScheduleColumn),
            id: "singleRow",
            name: "",
            expanded: true,
          },
        ]}
        columnHeaders={columns.map((c) => ({
          key: c?.crew?.id ?? "",
          columnHeader: c?.columnHeader ?? "",
          highlightHeader: isCurrentDate,
        }))}
        mode="day"
        isLoading={hasMissingDates}
        loadingFlexibleJobs={loadingUnscheduledJobs}
        nextSchedule={redirect.bind(
          null,
          routerBuilders.schedule.buildSequenceDayRoute(
            format(addDays(scheduleDate, 1), "YYYY-MM-DD"),
            params.mapCrew,
            params.crewFilter
          )
        )}
        weekForUnscheduledJobs={getSelectedDateStartOfWeek(props.match.params)}
        previousSchedule={redirect.bind(
          null,
          routerBuilders.schedule.buildSequenceDayRoute(
            format(addDays(scheduleDate, -1), "YYYY-MM-DD"),
            params.mapCrew,
            params.crewFilter
          )
        )}
        changeScheduleDate={(newDate) =>
          redirect(
            routerBuilders.schedule.buildSequenceDayRoute(
              format(newDate, "YYYY-MM-DD"),
              params.mapCrew,
              params.crewFilter
            )
          )
        }
      />
    </PageWithNavBar2>
  );
};

const mapStateToProps = (state: IRootState): Partial<IProps> => ({
  crews: state.crew.crews,
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
  crewCategories: state.common.crewCategories,
});

const mapDispatchToProps: Partial<IProps> = {
  loadDaySchedulesStarting: actionCreators.loadDaySchedulesStarting,
  redirect: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleSequenceDayPage as any);

function useRedirectToSequenceCalendarIfNoSequenceCrews({
  params,
}: {
  params: { date: string };
}) {
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const dispatch = useDispatch();
  useEffect(() => {
    const activeCrews = crews.filter((c) => !c.inactive);
    const hasTimeBasedCrews = activeCrews.some(
      (c) => c.scheduleType === CrewScheduleType.time
    );
    const hasSequenceBasedCrews = activeCrews.some(
      (c) => c.scheduleType === CrewScheduleType.sequence
    );
    if (hasTimeBasedCrews && !hasSequenceBasedCrews) {
      dispatch(push(builders.schedule.buildTimeDayRoute(params.date)));
    }
  }, [dispatch, crews, params.date]);
}
