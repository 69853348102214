import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { getDefaultRoute } from "../../../services/routing";
import UserNotificationsForm from "../forms/UserNotificationsForm";
import PageWithNavBar2 from "../PageWithNavBar2";
import { useUserSettings } from "../../../services/userSettingsService";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { UserSettingsType } from "../../../enums/userSettingsType";

const UserNotificationsFormPage = () => {
  const dispatch = useDispatch();
  const { getUserSettings } = useUserSettings();

  return (
    <>
      <PageWithNavBar2 billingContext />
      <UserNotificationsForm
        onClose={() => {
          dispatch(
            push(
              getDefaultRoute({
                defaultCrewScheduleType: getUserSettings<CrewScheduleType>(
                  UserSettingsType.defaultCrewScheduleType
                ),
              })
            )
          );
        }}
      />
    </>
  );
};

export { UserNotificationsFormPage };
