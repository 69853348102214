import { ICustomer } from "../../../models/ICustomer";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useGetAddressFields } from "../../../hooks/useGetAddressFields";
import Import from "./Import";

export default function CustomersImport({ onClose }: { onClose: () => void }) {
  const addressFields = useGetAddressFields();

  return (
    <Import<ICustomer>
      headerRecordType="Customers"
      successRecordType="customer(s)"
      uploadFile={remoteDataProvider.customerImportPreview}
      importSave={remoteDataProvider.customerImport}
      exampleFile="/assets/CustomerImportCsv.csv"
      onClose={onClose}
      headerRow={
        <tr>
          <th>Name</th>
          <th>Street number and name</th>
          <th>Apartment suite etc</th>
          <th>City</th>
          <th>{addressFields.administrativeAreaLabel}</th>
          <th>{addressFields.postCodeLabel}</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Primary phone</th>
          <th>Alternative phone</th>
          <th>Email address</th>
          <th>Administrator only notes</th>
          <th>Crew notes</th>
        </tr>
      }
      renderRow={(c) => (
        <tr>
          <td data-testid="name">{c.name}</td>
          <td data-testid="streetAndNumber">{c.streetAndNumber}</td>
          <td data-testid="apartmentSuite">{c.apartmentSuite}</td>
          <td data-testid="city">{c.city}</td>
          <td data-testid="state">{c.state}</td>
          <td data-testid="zip">{c.zip}</td>
          <td data-testid="latitude">{c.latitude}</td>
          <td data-testid="longitude">{c.longitude}</td>
          <td data-testid="phoneNumber">{c.phoneNumber}</td>
          <td data-testid="alternativePhoneNumber">
            {c.alternativePhoneNumber}
          </td>
          <td data-testid="emailAddress">
            {!!c.emailAddresses ? c.emailAddresses.join(", ") : ""}
          </td>
          <td data-testid="administratorOnlyNotes">
            {c.administratorOnlyNotes}
          </td>
          <td data-testid="notesForCrew">{c.notesForCrew}</td>
        </tr>
      )}
      columns={[
        {
          name: "Name",
          required: true,
        },
        { name: "PrimaryPhone " },
        { name: "AlternativePhone" },
        { name: "EmailAddress" },
        { name: "AdministratorOnlyNotes" },
        { name: "CrewNotes" },
        { name: "StreetNumberAndStreetName" },
        { name: "ApartmentSuiteEtc" },
        { name: "City" },
        { name: "State/Province" },
        { name: "Zip/PostalCode" },
        { name: "Latitude" },
        { name: "Longitude" },
      ]}
    />
  );
}
