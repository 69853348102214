import { DiscountType } from "../../../../enums/DiscountType";
import { ICustomer } from "../../../../models/ICustomer";
import { IInvoiceItem } from "../../../../models/IInvoiceItem";
import { createNewLineItem } from "../../../../services/invoiceFormService";
import { mapToFormLineItems } from "../../services/proposalService";
import { parseSourceText } from "../../../../services/richTextService";
import { IProposalExisting } from "../../models/IProposal";
import { IProposalTemplate } from "../../models/IProposalTemplate";
import { isDepositRequired } from "../../services/proposalService";
import getCustomerFields from "./getCustomerFields";
import getFormDataForNewProposal from "./getFormDataForNewProposal";
import getProposal from "./getProposal";
import { IFormData } from "./IFormData";
import { GetUserSettingsType } from "../../../../services/userSettingsService";
import { IProposalConfiguration } from "../../../../models/IProposalConfiguration";

export default function getFormDataForExistingProposal(
  proposals: Array<IProposalExisting>,
  proposalId: string,
  customer: ICustomer | null,
  proposalTemplates: Array<IProposalTemplate>,
  invoiceItems: Array<IInvoiceItem>,
  defaultTaxRate: number | null,
  getUserSettings: GetUserSettingsType,
  proposalConfiguration: IProposalConfiguration
): IFormData {
  const proposal = getProposal(proposals, proposalId);
  if (!proposal) {
    return getFormDataForNewProposal({
      customer,
      replyToEmailAddress: "",
      proposalTemplates,
      invoiceItems,
      defaultTaxRate,
      getUserSettings,
      proposalConfiguration,
    });
  }

  const customerFields = getCustomerFields(customer);
  return {
    quickBooksCustomerId: proposal.quickBooksCustomerId,
    deliveryMethod: proposal.deliveryMethod,
    proposalDate: proposal.proposalDate,
    validUntilDate: proposal.validUntilDate,
    amountAdjustments: {
      taxRate: proposal.taxRate,
      discount: {
        type: proposal.discount?.type ?? DiscountType.percent,
        amount: proposal.discount?.amount ?? null,
        percent: proposal.discount?.percent ?? null,
      },
    },
    showLineItemPrices: !proposal.hideLineItemPrices,
    lineItems: mapToFormLineItems(proposal.lineItems) ?? [createNewLineItem(1)],
    customerPhoneNumber: customerFields.customerPhoneNumber,
    customerPhoneNumberOptedIntoSms:
      customerFields.customerPhoneNumberOptedIntoSms,
    emailFields: {
      replyToEmailAddress: "",
      customerEmailAddresses: customerFields.customerEmailAddresses,
    },
    files: proposal.files.map((f) => ({
      ...f,
      actualWidth: null,
      actualHeight: null,
    })),
    depositSettings: proposal.depositSettings ?? null,
    depositRequired: isDepositRequired(proposal?.depositSettings),
    addConvenienceFee: proposal.addConvenienceFee,
    introText: parseSourceText(proposal.introText),
    footerText: parseSourceText(proposal.footerText),
    depositItem: {
      itemId: proposal.depositItemId,
      name: proposal.depositItemName,
      description: proposal.depositItemDescription,
    },
    captureCustomerPaymentMethod: proposal.captureCustomerPaymentMethod,
    captureCustomerPaymentMethodOptional:
      proposal.captureCustomerPaymentMethodOptional,
    summary: proposal.summary ?? "",
    subject: proposal.subject,
  };
}
