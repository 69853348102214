import { addMinutes } from "date-fns";
import timeService from "../../../services/timeService";
import { useMemo } from "react";

export function JobArrivalWindowSelection({
  isCrewTimeBased,
  startTime,
  arrivalWindowDurationMinutes,
  onArrivalWindowChange,
}: {
  isCrewTimeBased: boolean;
  startTime: string;
  endTime: string;
  arrivalWindowDurationMinutes: number | null;
  onArrivalWindowChange(newValue: number | null): void;
}) {
  const windows = useMemo(
    () => [
      { value: 0, label: "None" },
      { value: 15, label: "15 min" },
      { value: 30, label: "30 min" },
      { value: 45, label: "45 min" },
      { value: 60, label: "1 hr" },
      { value: 120, label: "2 hr" },
      { value: 180, label: "3 hr" },
      { value: 240, label: "4 hr" },
    ],
    []
  );

  if (!isCrewTimeBased) {
    return null;
  }

  return (
    <>
      <label data-testid="arrivalWindowLabel" htmlFor="arrivalWindow">
        Arrival window{" "}
        {(arrivalWindowDurationMinutes ?? 0) > 0 && startTime ? (
          <small>
            ({timeService.formatTimeForDisplay(startTime)}-
            {timeService.formatTimeForDisplay(
              addMinutes(
                timeService.parseTime(startTime).getTime(),
                arrivalWindowDurationMinutes ?? 0
              ).toLocaleTimeString("en-US", { hour12: false })
            )}
            )
          </small>
        ) : null}
      </label>
      <select
        className="form-control"
        id="arrivalWindow"
        data-testid="arrivalWindow"
        value={arrivalWindowDurationMinutes?.toString() ?? "0"}
        onChange={(e) => {
          switch (e.currentTarget.value) {
            case "0":
              onArrivalWindowChange(null);
              break;
            default:
              onArrivalWindowChange(
                e.currentTarget.value ? parseInt(e.currentTarget.value) : null
              );
              break;
          }
        }}
      >
        {windows.map((w) => (
          <option key={w.value.toString()} value={w.value.toString()}>
            {w.label}
          </option>
        ))}
      </select>
    </>
  );
}
