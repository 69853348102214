import { ICrew } from "./ICrew";
import { ICrewViewConfiguration } from "./ICrewViewConfiguration";
import { ITodoTemplate } from "./ITodoTemplate";
import { ICrewCategory } from "./ICrewCategory";
import { IAdminViewConfiguration } from "./IAdminViewConfiguration";
import { ICustomerCategory } from "./ICustomerCategory";
import { ICompanyProfile } from "./ICompanyProfile";
import { TimeZone } from "./TimeZone";
import { ICustomerNotificationsConfiguration } from "./ICustomerNotificationsConfiguration";
import { IOptionalCapabilitiesAllowed } from "./IOptionalCapabilitiesAllowed";
import { TenantPlan } from "../enums/tenantPlan";
import { ICustomerCommunicationTemplate } from "./ICustomerCommunicationTemplate";
import { UserAccountRole } from "../enums/userAccountRole";
import { TenantIndustry } from "../enums/tenantIndustry";
import { IInvoiceConfiguration } from "./IInvoiceConfiguration";
import {
  ITemplatedMessage,
  ITemplatedMessageDefaults,
} from "./IITemplatedMessage";
import { ITenantFeatureFlags } from "./ITenantFeatureFlags";
import { SubscriptionType } from "../enums/subscriptionType";
import { IReminderConfiguration } from "./IReminderConfiguration";
import { IProposalEmailConfiguration } from "./IProposalEmailConfiguration";
import { IUserAccount } from "./IUserAccount";
import { IUserSetting } from "./IUserSetting";
import { TollFreeVerificationStatus } from "../enums/tollFreeVerificationStatus";
import { CrewScheduleType } from "../slices/schedule/enums/crewScheduleType";
import { SubscriptionFrequency } from "../enums/subscriptionFrequency";
import { IProposalConfiguration } from "./IProposalConfiguration";
import { IWorksheetView } from "../slices/worksheets/models/IWorksheet";

export interface IInitialLoad extends ICompanyProfile {
  crews: Array<ICrew>;
  isRegistered: boolean;
  tenantName: string;
  tenantId: string;
  imagePrefix: string;
  tenantDateAddedUtc: string | null;
  userAccountId: string;
  userAccountEmail: string | null;
  userAccountRole: UserAccountRole;
  tenantCrewViewConfiguration: ICrewViewConfiguration;
  tenantAdminViewConfiguration: IAdminViewConfiguration;
  invoiceConfiguration: IInvoiceConfiguration;
  proposalConfiguration: IProposalConfiguration;
  customerNotificationsConfiguration: ICustomerNotificationsConfiguration;
  invoiceBeforeReminderConfiguration: IReminderConfiguration;
  invoiceAfterReminderConfiguration: IReminderConfiguration;
  proposalBeforeReminderConfiguration: IReminderConfiguration;
  proposalAfterReminderConfiguration: IReminderConfiguration;
  proposalEmailConfiguration: IProposalEmailConfiguration;
  todoTemplates: Array<ITodoTemplate>;
  crewCategories: Array<ICrewCategory>;
  customerCategories: Array<ICustomerCategory>;
  isQuickBooksEnabled: boolean;
  quickBooksDeliveryAllowed: boolean;
  timeZone: TimeZone;
  anyJobExists: boolean;
  industry: TenantIndustry;
  country: string | null;
  showDispatchPrompt: boolean;
  defaultCrewScheduleType: CrewScheduleType | null;

  payrixPublicApiKey: string;
  payrixMerchantAccountId: string;
  payrixOnboardingAllowed: boolean;
  paymentsConvenienceFeePercentForDisplay: number;
  customerUrlRoot: string;

  tenantSubscriptionStatus: TenantSubscriptionStatus;
  subscriptionPaymentFailureDate: string | null;
  tenantTrialDaysRemaining: number | null;
  canManageSubscription: boolean;
  isNonProductionEnvironment: boolean;
  optionalCapabilitiesAllowed: IOptionalCapabilitiesAllowed | null;
  tenantPlan: TenantPlan | null;
  subscriptionFrequency: SubscriptionFrequency | null;
  invoiceTemplate: ICustomerCommunicationTemplate | null;
  contractBillingTemplate: ICustomerCommunicationTemplate | null;
  proposalTemplate: ICustomerCommunicationTemplate | null;
  customerNotificationTemplateOptions: Array<ITemplatedMessage>;
  customerNotificationTemplateDefaults: Array<ITemplatedMessageDefaults>;
  featureFlags: ITenantFeatureFlags;
  subscriptionType: SubscriptionType;
  tollFreeVerificationStatus: TollFreeVerificationStatus;
  tollFreeVerificationSubmittedDate: string | null;
  customerTextingRequiresDedicatedNumber: boolean;
  customerTextingAllowedOnBasicPlan: boolean;
  customerTextMessagesSentRecently: boolean | null;
  userAccounts: Array<IUserAccount>;
  userSettings: Array<IUserSetting>;
  worksheets: Array<IWorksheetView>;
  showTermsOfServiceUpdatedModal: boolean;

  subscriptionCancellationPending: boolean;
  subscriptionRenewalDate: string | null;
}

export enum TenantSubscriptionStatus {
  Trial = 0,
  TrialExpired = 1,
  Subscribed = 2,
  Canceled = 3,
  TrialUnavailable = 4,
}
