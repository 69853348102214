import { TollFreeVerificationStatus } from "../../../enums/tollFreeVerificationStatus";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";

export function useShowVerificationEnrollmentMessage() {
  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const tollFreeVerificationStatus = useApplicationStateSelector(
    (s) => s.common.tollFreeVerificationStatus
  );

  const showVerificationMessage =
    tollFreeVerificationStatus === TollFreeVerificationStatus.notStarted &&
    tenantSubscriptionStatus === TenantSubscriptionStatus.Subscribed;

  return showVerificationMessage;
}
