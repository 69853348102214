import { FormData } from "./WorksheetForm.types";
import { WorksheetFormBody } from "./WorksheetFormBody";
import { getEmptyContentItem } from "./WorksheetForm.functions";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import Alert from "../../../containers/app/components/Alert";

export function WorksheetForm({
  worksheetId,
  onSaveComplete,
  onCancel,
}: {
  worksheetId: string | null;
  onSaveComplete: () => void;
  onCancel: () => void;
}) {
  const worksheets = useApplicationStateSelector((s) => s.worksheet.worksheets);

  let defaultValues: FormData | null = null;
  if (worksheetId === null) {
    defaultValues = getNewWorksheetDefaultValues();
  } else {
    const worksheet = worksheets.find((w) => w.id === worksheetId);

    if (worksheet) {
      defaultValues = {
        title: worksheet.title,
        inactive: worksheet.inactive,
        contentItems: worksheet.contentItems.map((ci) => ({
          id: ci.id,
          serverId: ci.id,
          text: ci.text,
          required: ci.required,
          type: ci.type.toString(),
          optionListOptions: ci.optionListOptions,
        })),
      };
    }
  }

  return (
    <>
      {defaultValues !== null ? (
        <WorksheetFormBody
          worksheetId={worksheetId}
          defaultValues={defaultValues}
          onSaveComplete={onSaveComplete}
          onCancel={onCancel}
        />
      ) : (
        <Alert
          closeForm={onCancel}
          message="The worksheet was not found. Please refresh your browser to get an updated list."
        />
      )}
    </>
  );
}

function getNewWorksheetDefaultValues(): FormData {
  return {
    title: "",
    inactive: false,
    contentItems: [getEmptyContentItem()],
  };
}
