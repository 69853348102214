import IPhoto, { IPhotoThumbnail } from "../models/IPhoto";
import dataProvider from "./dataProvider";
import { IUploadResult } from "./fileUpload";
import { IFormDataFile } from "../containers/app/components/files/ExistingFile";

const tenantsAllowedLargeSizes = [
  "fcefd5fc-e69f-4e43-bbf3-e6c0d98ab409",
  "2c03c4aa-62ae-431b-8450-c302b669e7bd",
];
export default {
  getFileSizeAllowedInMegabytes(tenantId: string) {
    if (tenantsAllowedLargeSizes.includes(tenantId.toLowerCase())) {
      return 80;
    }

    return 15;
  },

  getPhotoPropertiesForImgSrc: function (
    imagePrefix: string,
    photoId: string,
    thumbnails: Array<IPhotoThumbnail>,
    thumbnailKey: number
  ): IPhotoProperiesForImgSrc | null {
    const matchingItem = thumbnails.find(
      (t) => t.thumbnailKey === thumbnailKey
    );

    if (!matchingItem) {
      console.log("matchingItem not found");
      return null;
    } else {
      const fileComponents = getFileComponents(matchingItem.imagePath);
      const path = fileComponents.success
        ? fileComponents.linkUrl
        : matchingItem.imagePath;
      return {
        url: `${imagePrefix}/${path}`,
        width: matchingItem.actualWidth,
        height: matchingItem.actualHeight,
      };
    }
  },

  getPhotoPropertiesForImgSrcWithoutPhotoId: function (
    tenantId: string,
    imagePrefix: string,
    imagePath: string,
    contentType: string,
    thumbnails: Array<IPhotoThumbnail>,
    thumbnailKey: number
  ): IPhotoProperiesForImgSrc {
    const matchingItem = thumbnails.find(
      (t) => t.thumbnailKey === thumbnailKey
    );

    if (!matchingItem) {
      console.log("matchingItem not found");
      return {
        url: dataProvider.getPhotoImageWithoutIdSource(
          tenantId,
          imagePath,
          contentType,
          thumbnailKey
        ),
        width: undefined,
        height: undefined,
      };
    } else {
      const fileComponents = getFileComponents(matchingItem.imagePath);
      const path = fileComponents.success
        ? fileComponents.linkUrl
        : matchingItem.imagePath;
      return {
        url: `${imagePrefix}/${path}`,
        width: matchingItem.actualWidth,
        height: matchingItem.actualHeight,
      };
    }
  },

  parseUploadResult(result: Array<IUploadResult>): {
    imagePath: string;
    actualHeight: number;
    actualWidth: number;
    thumbnails: Array<IPhotoThumbnail>;
  } {
    const baseImage = result.find((r) => r.thumbnailKey === null);
    if (!baseImage) {
      throw new Error("base image not found");
    }

    const thumbnails = result
      .filter((r) => r !== baseImage && r.thumbnailKey)
      .map((r) => ({
        ...r,
        thumbnailKey: r.thumbnailKey as number,
      }));

    return {
      imagePath: baseImage.imagePath,
      actualHeight: baseImage.actualHeight,
      actualWidth: baseImage.actualWidth,
      thumbnails,
    };
  },
};

export function mapPhotoToDataFile(photo: IPhoto) {
  return {
    ...photo,
    id: null,
    actualHeight: null,
    actualWidth: null,
  };
}

export function isPhoto(
  photoId: string | null,
  imagePath: string | null,
  p: IFormDataFile
) {
  if (photoId) {
    return p.id === photoId;
  } else if (imagePath) {
    return p.imagePath === imagePath;
  } else {
    return false;
  }
}

export function getFileName(filePath: string) {
  const components = getFileComponents(filePath);
  if (!components.success) {
    return filePath;
  }

  return components.name;
}

export function getFileComponents(filePath: string): IFileComponents {
  const regexResult = filePath.match(
    /(^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}\/)(.+)/
  );

  if (!regexResult || regexResult.length !== 3) {
    console.error(`unable to get file name for path '${filePath}`);
    return { success: false, path: null, name: null, linkUrl: null };
  }

  return {
    success: true,
    path: regexResult[1],
    name: regexResult[2],
    linkUrl: regexResult[1] + encodeURIComponent(regexResult[2]),
  };
}

export function isImageByType(fileType: string) {
  return fileType.toLowerCase().startsWith("image");
}

export function isVideoByType(fileType: string) {
  return fileType.toLowerCase().startsWith("video");
}

export function isImageFile(file: IFormDataFile) {
  return (
    typeof file.contentType === "string" && isImageByType(file.contentType)
  );
}

export function isVideoFile(file: IFormDataFile) {
  return (
    typeof file.contentType === "string" && isVideoByType(file.contentType)
  );
}

export interface IPhotoProperiesForImgSrc {
  url: string;
  width: number | undefined;
  height: number | undefined;
}

interface IFileComponents {
  success: boolean;
  path: string | null;
  name: string | null;
  linkUrl: string | null;
}
