import CustomerSelection, {
  CustomerSelectionRecordType,
  IOption,
} from "../../../containers/app/components/CustomerSelection";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import CustomerAdditionalLocationSelection from "../../../containers/app/components/CustomerAdditionalLocationSelection";
import {
  Controller,
  useFieldArray,
  useForm,
  UseFormGetValues,
} from "react-hook-form";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import CustomerAdditionalLocationInfoToolTip from "../../../containers/app/components/CustomerAdditionalLocationInfoToolTip";
import { SelectInstance } from "react-select";
import { IOneTimeJob } from "../../../models/IOneTimeJob";
import { ICategoryIdentifierForSave } from "../../../models/ICategoryIdentifierForSave";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import CrewCategorySelection from "../../../containers/app/components/CrewCategorySelection";
import {
  getDefaultCrewId,
  getGrossRevenuePerVisitForForm,
} from "../../../services/jobService";
import { ICategoryIdentifier } from "../../../models/ICategoryIdentifier";
import dateService from "../../../services/dateService";
import constants from "../../../constants";
import JobBillingConfiguration from "../../../containers/app/components/JobBillingConfiguration";
import CustomerBalanceWarning from "../../../containers/app/components/CustomerBalanceWarning";
import { addDays, isValid, startOfWeek } from "date-fns";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { JobBillingType } from "../../../enums/jobBillingType";
import { ILineItem } from "../../../containers/app/components/InvoiceLineItem";
import { IDiscount } from "../../../models/IDiscount";
import { DiscountType } from "../../../enums/DiscountType";
import modelConversion from "../../../services/modelConversion";
import {
  getLineItemsForForm,
  getLineItemsForSave,
} from "../../../services/lineItemService";
import projectDataProvider, {
  CreateProjectRequest,
  ProjectVisitExisting,
  UpdateProjectRequest,
  VisitChangeOperation,
} from "../services/projectDataProvider";
import { IProject } from "../models/IProject";
import ModalDataLoader from "../../../containers/app/components/ModalDataLoader";
import { map } from "rxjs/operators";
import { projectActionCreators } from "../modules/project";
import { useLoadProject } from "../hooks/useLoadProject";
import parse from "date-fns/parse/index";
import { ICustomer } from "../../../models/ICustomer";
import { isTypeProjectBilling } from "../services/projectService";
import { areDiscountAndTaxRateEnabled } from "../../../containers/app/components/JobBillingConfiguration.functions";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { ManHours } from "../../../containers/app/components/ManHours";
import { ICrew } from "../../../models/ICrew";
import { getSortedCrews } from "../../../services/sortingService";
import Files from "../../../containers/app/components/files/Index";
import { IFormDataFile } from "../../../containers/app/components/files/ExistingFile";
import { isPhoto, mapPhotoToDataFile } from "../../../services/fileService";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import IPhoto from "../../../models/IPhoto";
import customerFinder from "../../../services/customerFinder";
import ProjectVisitGrid from "./ProjectVisitGrid";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { isStringSet } from "../../../services/stringService";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";
import { JobInstructionsForCrewField } from "../../../containers/app/components/JobInstructionsForCrewField";
import SubscriptionWarning from "../../tenantSubscription/components/SubscriptionWarning";

type BaseProps = {
  initialCustomerId?: string | null;
  initialCustomerAdditionalLocationId?: string | null;
  initialLineItems?: Array<ILineItem>;
  initialBillingType?: JobBillingType;
  billingTypeDisabled?: boolean;
  initialTaxRate?: number | null;
  initialDiscount?: IDiscount | null;
  opportunityId?: string | null;
  initialPaymentMethodOnFileAuthorized?: boolean | null;
  initialHideLineItemPrices?: boolean;
  proposalFiles?: Array<IPhoto>;
  proposalJobSummary?: string;
  onSaveComplete: () => void;
  onCancel: () => void;
} & (
  | {
      mode: "add";
      projectId?: undefined;
    }
  | {
      mode: "edit";
      projectId: string;
    }
);

type PropsWithVisits = BaseProps & {
  existingProjectVisits: Array<ProjectVisitExisting>;
};

interface IVisit {
  id: string | null;
  // react-hook overwrites the ID when rendering so need to track the original id separately
  originalId: string | null;
  crewId: string;
  date: string;
  flexibleJob: boolean;
  startTime: string | null;
  endTime: string | null;
}

export interface IProjectFormData {
  customerRecordType: CustomerSelectionRecordType | null;
  customerId: string | null;
  customerAdditionalLocationId: string | null;
  estimatedManHours: string;
  categories: Array<ICategoryIdentifierForSave>;
  amountPerVisit: string;
  billingType: JobBillingType;
  lineItems: Array<ILineItem>;
  notes: string;
  visits: Array<IVisit>;
  taxRate: number | null;
  discount: IDiscount;
  highlightCrewNotes: boolean;
  showCrewNotesOnAdminJobCards: boolean;
  paymentMethodOnFileAuthorized: boolean;
  hideLineItemPrices: boolean;
  files: Array<IFormDataFile>;
}

const ProjectFormBody: React.FunctionComponent<PropsWithVisits> = ({
  initialCustomerId,
  initialCustomerAdditionalLocationId,
  initialBillingType,
  initialLineItems,
  initialTaxRate,
  initialDiscount,
  opportunityId,
  onSaveComplete,
  onCancel,
  mode,
  projectId,
  existingProjectVisits,
  billingTypeDisabled,
  initialPaymentMethodOnFileAuthorized,
  initialHideLineItemPrices,
  proposalFiles,
  proposalJobSummary,
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const [hideAddAdditionalFiles, setHideAddAdditionalFiles] = useState(false);
  const customerElement = useRef<SelectInstance<IOption>>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const router = useApplicationStateSelector((s) => s.router);
  const defaultCrewId = getDefaultCrewId(crews, router);
  const isAdmin = useIsAdmin();
  const projects = useApplicationStateSelector((s) => s.project.projects);
  const addVisitRef = useRef<HTMLButtonElement>(null);
  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const hasTimeBasedCrew = crews.some(
    (c) => c.inactive === false && c.scheduleType === CrewScheduleType.time
  );
  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );

  let project: IProject | null = null;
  if (mode === "edit") {
    project = projects.find((p) => p.id === projectId) ?? null;
  }

  const { watch, control, setValue, getValues, register } =
    useForm<IProjectFormData>({
      defaultValues: getDefaultValues({
        project,
        initialCustomerId,
        initialCustomerAdditionalLocationId,
        initialLineItems,
        initialBillingType,
        initialTaxRate: initialTaxRate ?? invoiceConfiguration.taxRate,
        initialDiscount,
        mode,
        existingProjectVisits,
        defaultCrewId,
        initialPaymentMethodOnFileAuthorized,
        initialHideLineItemPrices,
        crews,
      }),
    });

  const {
    fields: visits,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visits",
  });

  const customerId = watch("customerId");
  const amountPerVisit = watch("amountPerVisit");
  const billingType = watch("billingType");
  const lineItems = watch("lineItems");
  const taxRate = watch("taxRate");
  const discount = watch("discount");
  const paymentMethodOnFileAuthorized = watch("paymentMethodOnFileAuthorized");
  const files = watch("files");

  // Focus on customer
  useFocusOnCustomerField({ customerElement, customerId });

  return (
    <SubscriptionWarning
      mode="alwaysLockedWhenNotSubscribed"
      onCancel={onCancel}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <FormContainerWithoutRedux
          size={hasTimeBasedCrew ? "xl" : "lg"}
          formHeader={getFormHeader({
            mode,
            customers,
            projects,
            projectId,
            billingTypeDisabled,
            initialBillingType,
          })}
          formType={FormTypesV2.multiDayJobForm}
          save={() => {
            const billingTypeForSave = getValues("billingType");
            const isProjectBillingType =
              isTypeProjectBilling(billingTypeForSave);

            if (mode === "add") {
              if (isProjectBillingType) {
                fullStoryTrack("Project Saved");
              }

              return projectDataProvider
                .createProject(
                  getCreateProjectPayload({
                    customerId,
                    getValues,
                    opportunityId,
                    billingTypeForSave,
                    isProjectBillingType,
                  })
                )
                .pipe(
                  map((response) => ({
                    ...response,
                    data: {
                      ...response.data,
                      updatedOneTimeJobs: [] as Array<string>,
                    },
                    project: null as IProject | null,
                  }))
                );
            } else {
              return projectDataProvider
                .updateProject(
                  getUpdateProjectPayload({
                    projectId,
                    getValues,
                    project,
                    isAdmin,
                    billingTypeForSave,
                    originalProjectVisits: existingProjectVisits,
                  })
                )
                .pipe(
                  map((response) => ({
                    success: true,
                    project: response.project,
                    data: {
                      updatedDaySchedules: response.updatedDaySchedules,
                      updatedFlexibleJobWeeks: response.updatedFlexibleJobWeeks,
                      updatedOneTimeJobs: response.updatedOneTimeJobs,
                      savedJobs: [],
                      changedCategories: [],
                      categories: [],
                    },
                  }))
                );
            }
          }}
          onSaveComplete={(result) => {
            onSaveComplete();

            dispatch(
              actionCreators.projectSave({
                updatedDaySchedules: result.data.updatedDaySchedules,
                updatedFlexibleJobWeeks: result.data.updatedFlexibleJobWeeks,
                savedJobs: result.data.savedJobs,
                opportunityId: opportunityId ?? project?.opportunityId ?? null,
                mode,
              })
            );

            if (mode === "edit" && result.project !== null) {
              dispatch(
                projectActionCreators.updateProject({
                  newProject: result.project,
                })
              );

              if (result.data.updatedOneTimeJobs.length > 0) {
                dispatch(
                  actionCreators.loadOneTimeJobsStart(
                    result.data.updatedOneTimeJobs
                  )
                );
              }
            }
          }}
          onCancel={() => {
            onCancel();
          }}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          validate={() => {
            if (!customerId) {
              return {
                valid: false,
                errorMessage: "A customer must be selected",
              };
            }

            if (fileUploading) {
              return {
                valid: false,
                errorMessage: "Please wait until all photos are uploaded",
              };
            }

            return {
              valid: true,
            };
          }}
        >
          <h5>Customer Information</h5>
          <div className="form-section">
            <div className="form-group" data-testid="customerContainer">
              <label htmlFor="customerSelectionInput" className="required">
                Customer
              </label>
              <Controller
                name="customerId"
                control={control}
                render={({ field }) => (
                  <CustomerSelection
                    inputId="customerSelectionInput"
                    selectRef={customerElement}
                    includeGroups={false}
                    value={{
                      recordType: "customer",
                      id: field.value,
                    }}
                    onCustomerClear={() => {
                      field.onChange(null);
                    }}
                    onCustomerSelection={(type, selectedValue) => {
                      if (type === "customer") {
                        const newCustomerId = selectedValue;
                        field.onChange(newCustomerId);

                        setValue("customerAdditionalLocationId", null);
                        setErrorMessage("");

                        if (newCustomerId) {
                          const customer =
                            customerFinder.getOptionalCustomerById(
                              newCustomerId,
                              customers
                            );
                          if (customer?.taxExempt) {
                            setValue("taxRate", null);
                          }
                        }
                      }
                    }}
                  />
                )}
              />
              {customerId ? (
                <div>
                  <CustomerBalanceWarning customerId={customerId ?? ""} />
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={() =>
                      dispatch(
                        actionCreators.forms.customer.showForm({
                          customerId,
                        })
                      )
                    }
                  >
                    Edit customer
                  </button>
                </div>
              ) : null}
            </div>

            <div className="form-group" data-testid="jobLocationContainer">
              <label htmlFor="customerAdditionalLocationId">Job location</label>
              <CustomerAdditionalLocationInfoToolTip />
              <Controller
                name="customerAdditionalLocationId"
                control={control}
                render={({ field }) => (
                  <CustomerAdditionalLocationSelection
                    forceDisable={getValues("customerRecordType") === "group"}
                    inputId="customerAdditionalLocationId"
                    value={field.value}
                    customerId={customerId || ""}
                    onCustomerAdditionalLocationSelection={(
                      customerAdditionalLocationId
                    ) => {
                      field.onChange(customerAdditionalLocationId);
                    }}
                  />
                )}
              />
            </div>
          </div>
          {isAdmin ? (
            <>
              <h5>Billing information</h5>
              <div className="form-section">
                <JobBillingConfiguration
                  taxRateAlreadySet={false}
                  customerId={customerId}
                  billingTypeDisabled={mode === "edit" || billingTypeDisabled}
                  values={{
                    grossRevenuePerVisit: amountPerVisit,
                    lineItems: lineItems,
                    billingType: billingType,
                    taxRate: taxRate,
                    discount: discount,
                    paymentMethodOnFileAuthorized:
                      paymentMethodOnFileAuthorized,
                  }}
                  onChange={(newValue) => {
                    setValue("amountPerVisit", newValue.grossRevenuePerVisit);
                    setValue("billingType", newValue.billingType);
                    setValue("lineItems", newValue.lineItems);
                    setValue("taxRate", newValue.taxRate);
                    setValue("discount", newValue.discount);
                    setValue(
                      "paymentMethodOnFileAuthorized",
                      newValue.paymentMethodOnFileAuthorized
                    );
                  }}
                  showAtProjectCompletion
                  perServiceLabel="Per visit"
                />
              </div>
            </>
          ) : null}

          <h5>Job Details</h5>
          <div className="form-section">
            <Controller
              control={control}
              name="estimatedManHours"
              render={({ field }) => (
                <ManHours
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  label={
                    billingType ===
                      JobBillingType.AtProjectCompletionLineItems ||
                    billingType === JobBillingType.AtProjectCompletionTotal
                      ? "Total man hours for project"
                      : "Total man hours per visit"
                  }
                />
              )}
            />

            {mode === "add" ? (
              <>
                <Controller
                  name="categories"
                  control={control}
                  render={({ field }) => (
                    <CrewCategorySelection
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                    />
                  )}
                />
              </>
            ) : null}
          </div>

          {mode === "add" ? (
            <>
              <h5>Crew View details</h5>
              <div className="form-section">
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <JobInstructionsForCrewField
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      proposalJobSummary={proposalJobSummary}
                    />
                  )}
                />
                <div
                  className="form-group d-flex flex-wrap"
                  style={{ columnGap: "20px", rowGap: "5px" }}
                >
                  <div className="custom-control custom-checkbox">
                    <input
                      id="highlightCrewNotes"
                      type="checkbox"
                      className="custom-control-input text-nowrap"
                      {...register("highlightCrewNotes")}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="highlightCrewNotes"
                    >
                      Show on crew schedule homepage
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      id="showCrewNotesOnAdminJobCards"
                      type="checkbox"
                      className="custom-control-input text-nowrap"
                      {...register("showCrewNotesOnAdminJobCards")}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="showCrewNotesOnAdminJobCards"
                    >
                      Show on admin job cards
                    </label>
                  </div>
                </div>
              </div>

              <Controller
                name="files"
                control={control}
                render={({ field }) => (
                  <Files
                    header="Attachments"
                    showHeader
                    files={field.value}
                    tenantId={tenantId || ""}
                    imagePrefix={imagePrefix ?? ""}
                    onFileUploadingStatusChange={(hasPendingPhotoAdd) => {
                      setFileUploading(hasPendingPhotoAdd);
                    }}
                    onFileAdded={(photo) => {
                      field.onChange([...field.value, photo]);
                    }}
                    onFileRemoved={(photoId, imagePath) => {
                      field.onChange(
                        field.value.filter(
                          (p) => !isPhoto(photoId, imagePath, p)
                        )
                      );
                    }}
                    onFileUpdated={(photoId, imagePath, caption) => {
                      field.onChange(
                        field.value.map((p) => {
                          if (isPhoto(photoId, imagePath, p)) {
                            return {
                              ...p,
                              caption,
                            };
                          } else {
                            return p;
                          }
                        })
                      );
                    }}
                    addAdditionalFiles={
                      (proposalFiles?.length ?? 0) > 0 &&
                      !hideAddAdditionalFiles ? (
                        <LinkButton2
                          buttonContents={
                            <small>Add proposal attachments</small>
                          }
                          testId="AddProposalAttachments"
                          onClick={() => {
                            setValue(
                              "files",
                              files.concat(
                                proposalFiles?.map((p) =>
                                  mapPhotoToDataFile(p)
                                ) ?? []
                              )
                            );
                            setHideAddAdditionalFiles(true);
                          }}
                        />
                      ) : null
                    }
                  />
                )}
              />
            </>
          ) : null}

          <h5>Visit details</h5>
          <div className="form-section">
            <div className="form-group">
              <ProjectVisitGrid
                visits={visits}
                crews={crews}
                control={control}
                register={register}
                remove={remove}
                showDelete={visits.length > 1}
                existingProjectVisits={existingProjectVisits}
              />
              <div className="text-center">
                <button
                  className="btn btn-link"
                  type="button"
                  ref={addVisitRef}
                  onClick={() => {
                    let existingId: string | null = null;
                    const itemIndex = visits.length;
                    if (itemIndex < existingProjectVisits.length) {
                      existingId = existingProjectVisits[itemIndex].id;
                    }

                    let crewId = defaultCrewId,
                      date = "";
                    let startTime = null;
                    let endTime = null;
                    const previousVisitIndex = itemIndex - 1;
                    if (previousVisitIndex >= 0) {
                      const updatedVisits = getValues("visits");
                      crewId =
                        updatedVisits[previousVisitIndex].crewId ??
                        defaultCrewId;
                      startTime =
                        updatedVisits[previousVisitIndex].startTime ?? null;
                      endTime =
                        updatedVisits[previousVisitIndex].endTime ?? null;
                      const previousRowDate =
                        updatedVisits[previousVisitIndex].date;
                      if (previousRowDate) {
                        const parsedPreviousRowDate = parse(previousRowDate);
                        if (isValid(parsedPreviousRowDate)) {
                          date = dateService.formatAsIso(
                            addDays(parsedPreviousRowDate, 1)
                          );
                        }
                      }
                    }

                    append(
                      {
                        id: existingId,
                        originalId: existingId,
                        crewId,
                        date,
                        startTime,
                        endTime,
                        flexibleJob: false,
                      },
                      {
                        shouldFocus: false,
                      }
                    );

                    setTimeout(() => {
                      if (addVisitRef.current) {
                        addVisitRef.current.scrollIntoView();
                      }
                    });
                  }}
                >
                  Add visit
                </button>
              </div>
            </div>
          </div>
        </FormContainerWithoutRedux>
      </div>
    </SubscriptionWarning>
  );
};

function useFocusOnCustomerField({
  customerElement,
  customerId,
}: {
  customerElement: React.RefObject<SelectInstance<IOption>>;
  customerId: string | null | undefined;
}) {
  const initialLoadProcessed = useRef(false);
  useEffect(() => {
    if (!initialLoadProcessed.current) {
      setTimeout(() => {
        if (customerElement.current && !customerId) {
          customerElement.current.focus();
        }
      });

      initialLoadProcessed.current = true;
    }
  }, [customerElement, customerId]);
}

function getFormHeader({
  mode,
  customers,
  projects,
  projectId,
  initialBillingType,
  billingTypeDisabled,
}: {
  mode: "add" | "edit";
  customers: ICustomer[];
  projects: IProject[];
  projectId: string | null | undefined;
  initialBillingType: JobBillingType | undefined;
  billingTypeDisabled: boolean | undefined;
}): string {
  if (mode === "add") {
    const isProjectMode =
      billingTypeDisabled &&
      typeof initialBillingType === "number" &&
      isTypeProjectBilling(initialBillingType);
    if (isProjectMode) {
      return "Add Project";
    } else {
      return "Add Multiple Jobs";
    }
  } else if (mode === "edit") {
    return `Update Project for ${
      customers.find(
        (c) => c.id === projects.find((p) => p.id === projectId)?.customerId
      )?.name ?? ""
    }`;
  } else {
    return "";
  }
}

function ProjectFormLoader(props: BaseProps) {
  const projectId = props.mode === "edit" ? props.projectId : "";

  const [existingProjectVisits, setExistingProjectVisits] = useState<
    Array<ProjectVisitExisting>
  >([]);

  const getProjectsCallback = useCallback(
    () =>
      projectDataProvider
        .getProjectVisits({ projectIds: [projectId] })
        .pipe(
          map(
            (projectVisits) =>
              projectVisits.find((v) => v.projectId === projectId)?.visits ?? []
          )
        ),
    [projectId]
  );
  const setProjectsCallback = useCallback(
    (v) => setExistingProjectVisits(v),
    [setExistingProjectVisits]
  );

  const { errorLoadingProject, projectMissing } = useLoadProject(projectId);

  if (props.mode === "add") {
    return <ProjectFormBody {...props} existingProjectVisits={[]} />;
  } else {
    return (
      <ModalDataLoader
        errorMessage={"Unable to load data for project."}
        loadData={getProjectsCallback}
        onDataLoaded={setProjectsCallback}
        onErrorAlertClose={props.onCancel}
        errorFromParent={errorLoadingProject}
        loadingFromParent={projectMissing}
      >
        <ProjectFormBody
          {...props}
          existingProjectVisits={existingProjectVisits}
        />
      </ModalDataLoader>
    );
  }
}

export default ProjectFormLoader;

function getDefaultValues({
  project,
  initialCustomerId,
  initialCustomerAdditionalLocationId,
  initialLineItems,
  initialBillingType,
  initialTaxRate,
  initialDiscount,
  mode,
  existingProjectVisits,
  defaultCrewId,
  initialPaymentMethodOnFileAuthorized,
  initialHideLineItemPrices,
  crews,
}: {
  project: IProject | null;
  initialCustomerId: string | null | undefined;
  initialCustomerAdditionalLocationId: string | null | undefined;
  initialLineItems: ILineItem[] | undefined;
  initialBillingType: JobBillingType | undefined;
  initialTaxRate: number | null | undefined;
  initialDiscount: IDiscount | null | undefined;
  mode: string;
  existingProjectVisits: ProjectVisitExisting[];
  defaultCrewId: string;
  initialPaymentMethodOnFileAuthorized: boolean | null | undefined;
  initialHideLineItemPrices: boolean | null | undefined;
  crews: Array<ICrew>;
}): Partial<IProjectFormData> {
  const lineItems =
    typeof project?.lineItems === "object"
      ? getLineItemsForForm(project)
      : initialLineItems ?? [];
  const billingType =
    project?.billingType ??
    initialBillingType ??
    JobBillingType.PerServiceTotal;
  const taxRate = project?.taxRate ?? initialTaxRate ?? null;
  const discount = project?.discount ??
    initialDiscount ?? {
      type: DiscountType.amount,
      amount: null,
      percent: null,
    };
  const grossRevenue = getGrossRevenuePerVisitForForm(
    billingType,
    typeof project?.grossRevenue === "number"
      ? project.grossRevenue.toString()
      : "",
    lineItems,
    discount,
    taxRate
  );
  const paymentMethodOnFileAuthorized =
    project?.paymentMethodOnFileAuthorized ??
    initialPaymentMethodOnFileAuthorized ??
    false;

  if (defaultCrewId) {
    if (!crews.some((c) => c.id === defaultCrewId && !c.inactive)) {
      defaultCrewId =
        getSortedCrews(crews).filter((c) => !c.inactive)?.[0]?.id ?? "";
    }
  }

  const visits =
    mode === "edit"
      ? existingProjectVisits.map((v) => ({
          id: v.id,
          originalId: v.id,
          // Default crew in, even if null due to flex job.
          // This will help when switching from flex to non-flex
          // and also all the "Not applicable..." option on the
          // crew drop-down to appear. If crewId was null, the option
          // wouldn't appear.
          crewId: v.crewId ?? defaultCrewId,
          date: v.date,
          flexibleJob: v.flexibleJob,
          startTime: v.startTime,
          endTime: v.endTime,
        }))
      : [
          {
            crewId: defaultCrewId,
            flexibleJob: false,
          } as IVisit,
        ];

  return {
    customerId: project?.customerId ?? initialCustomerId,
    customerAdditionalLocationId:
      project?.customerAdditionalLocationId ??
      initialCustomerAdditionalLocationId,
    amountPerVisit:
      typeof grossRevenue === "number" ? grossRevenue.toString() : "",
    categories: [],
    lineItems,
    billingType,
    visits: visits,
    taxRate,
    discount,
    estimatedManHours:
      typeof project?.estimatedManHours === "number"
        ? project?.estimatedManHours?.toString()
        : "",
    paymentMethodOnFileAuthorized,
    hideLineItemPrices:
      project?.hideLineItemPrices ?? initialHideLineItemPrices ?? false,
    files: [],
  };
}

function getCreateProjectPayload({
  customerId,
  getValues,
  opportunityId,
  billingTypeForSave,
  isProjectBillingType,
}: {
  customerId: string | null;
  getValues: UseFormGetValues<IProjectFormData>;
  opportunityId: string | null | undefined;
  billingTypeForSave: JobBillingType;
  isProjectBillingType: boolean;
}): CreateProjectRequest {
  const discountAndTaxRateEnabled =
    areDiscountAndTaxRateEnabled(billingTypeForSave);

  return {
    customerId,
    customerAdditionalLocationId: getValues("customerAdditionalLocationId"),
    jobs: buildDataForSave({
      getValues,
      isProjectBillingType,
      opportunityId,
      customerId,
      discountAndTaxRateEnabled,
    }),
    opportunityId: opportunityId ?? null,
    billingType: billingTypeForSave,
    estimatedManHours: isProjectBillingType
      ? modelConversion.convertStringToNumberOrNull(
          getValues("estimatedManHours")
        )
      : null,
    grossRevenue: isProjectBillingType
      ? getGrossRevenuePerVisitForForm(
          billingTypeForSave,
          getValues("amountPerVisit"),
          getValues("lineItems"),
          getValues("discount"),
          getValues("taxRate")
        )
      : null,
    lineItems:
      billingTypeForSave === JobBillingType.AtProjectCompletionLineItems
        ? getLineItemsForSave(getValues("lineItems"))
        : [],

    taxRate:
      isProjectBillingType && discountAndTaxRateEnabled
        ? getValues("taxRate")
        : null,

    discount:
      isProjectBillingType && discountAndTaxRateEnabled
        ? getValues("discount")
        : {
            amount: null,
            percent: null,
            type: DiscountType.percent,
          },

    paymentMethodOnFileAuthorized: getValues("paymentMethodOnFileAuthorized"),
    hideLineItemPrices: getValues("hideLineItemPrices"),
  };
}

function getUpdateProjectPayload({
  projectId,
  getValues,
  project,
  isAdmin,
  billingTypeForSave,
  originalProjectVisits,
}: {
  projectId: string;
  getValues: UseFormGetValues<IProjectFormData>;
  project: IProject | null;
  isAdmin: boolean;
  billingTypeForSave: JobBillingType;
  originalProjectVisits: Array<ProjectVisitExisting>;
}): UpdateProjectRequest {
  const newVisits = getValues("visits");

  const visitChangesPostAndPatch: Array<VisitChangeOperation> = newVisits.map(
    (v) => {
      let date: string | Date = v.date;
      let crewId: string | null = v.crewId;
      let startTime: string | null = isStringSet(v.startTime)
        ? dateService.formatTimeForSerialization(v.startTime)
        : v.startTime;
      let endTime: string | null = isStringSet(v.endTime)
        ? dateService.formatTimeForSerialization(v.endTime)
        : v.endTime;
      if (v.flexibleJob) {
        date = startOfWeek(date);
        crewId = null;
        startTime = null;
        endTime = null;
      }

      const body = {
        date: dateService.formatAsIso(date),
        flexibleJob: v.flexibleJob,
        crewId: crewId,
        startTime: startTime,
        endTime: endTime,
      };
      if (v.id) {
        return {
          method: "PATCH",
          id: v.id,
          body,
        };
      } else {
        return {
          method: "POST",
          body,
        };
      }
    }
  );

  const visitChangesDelete: Array<VisitChangeOperation> = originalProjectVisits
    .filter(
      (originalVisit) =>
        !newVisits.some((newVisit) => newVisit.id === originalVisit.id)
    )
    .map((originalVisit) => ({
      method: "DELETE",
      id: originalVisit.id,
    }));

  const discountAndTaxRateEnabled =
    areDiscountAndTaxRateEnabled(billingTypeForSave);
  return {
    id: projectId,
    customerId: getValues("customerId") ?? project?.customerId ?? undefined,
    customerAdditionalLocationId: getValues("customerAdditionalLocationId"),
    discount: isAdmin
      ? discountAndTaxRateEnabled
        ? getValues("discount")
        : { type: DiscountType.percent, percent: null, amount: null }
      : undefined,
    estimatedManHours: modelConversion.convertStringToNumberOrNull(
      getValues("estimatedManHours")
    ),
    grossRevenue: isAdmin
      ? getGrossRevenuePerVisitForForm(
          billingTypeForSave,
          getValues("amountPerVisit"),
          getValues("lineItems"),
          getValues("discount"),
          getValues("taxRate")
        )
      : undefined,
    billingType: isAdmin ? getValues("billingType") : undefined,
    lineItems: isAdmin
      ? getLineItemsForSave(getValues("lineItems"))
      : undefined,
    taxRate: isAdmin
      ? discountAndTaxRateEnabled
        ? getValues("taxRate")
        : null
      : undefined,
    paymentMethodOnFileAuthorized: isAdmin
      ? getValues("paymentMethodOnFileAuthorized")
      : undefined,
    visitChanges: [...visitChangesPostAndPatch, ...visitChangesDelete],
  };
}

function buildDataForSave({
  getValues,
  opportunityId,
  isProjectBillingType,
  discountAndTaxRateEnabled,
}: {
  customerId: string | null;
  getValues: UseFormGetValues<IProjectFormData>;
  opportunityId: string | null | undefined;
  isProjectBillingType: boolean;
  discountAndTaxRateEnabled: boolean;
}) {
  let formValues = getValues();

  return formValues.visits.map((visit) => {
    return {
      customerId: formValues.customerId,
      customerAdditionalLocationId: formValues.customerAdditionalLocationId,
      // The job categories need to included name for addonfly support.
      // This likely should be a separate interface type for saving so that we can save with name only.
      categories: formValues.categories as Array<ICategoryIdentifier>,
      estimatedManHours: !isProjectBillingType
        ? formValues.estimatedManHours || null
        : null,
      notes: formValues.notes,
      showCrewNotesOnAdminJobCards: formValues.showCrewNotesOnAdminJobCards,
      administratorOnlyNotes: "",
      highlightCrewNotes: formValues.highlightCrewNotes,
      order: 0,
      photos: formValues.files,
      precedingJobInstanceId: constants.idForFirstJob,
      billingType: formValues.billingType,
      grossRevenuePerVisit: !isProjectBillingType
        ? getGrossRevenuePerVisitForForm(
            formValues.billingType,
            formValues.amountPerVisit,
            formValues.lineItems,
            formValues.discount,
            formValues.taxRate
          )
        : null,
      hideLineItemPrices: formValues.hideLineItemPrices,
      lineItems:
        !isProjectBillingType &&
        formValues.billingType === JobBillingType.PerServiceLineItems
          ? formValues.lineItems
          : [],
      todoItems: [],
      id: "",
      todoTemplateId: null,
      todoItemsLocked: false,
      crewId: !visit.flexibleJob ? visit.crewId : null,
      flexibleJob: visit.flexibleJob,
      date: !visit.flexibleJob
        ? dateService.formatAsIso(visit.date)
        : dateService.formatAsIso(startOfWeek(visit.date)),
      startTime: dateService.formatTimeForSerialization(visit.startTime ?? ""),
      endTime: dateService.formatTimeForSerialization(visit.endTime ?? ""),
      taxRate:
        !isProjectBillingType && discountAndTaxRateEnabled
          ? formValues.taxRate
          : null,
      discount:
        !isProjectBillingType && discountAndTaxRateEnabled
          ? formValues.discount
          : {
              type: DiscountType.amount,
              amount: null,
              percent: null,
            },
      opportunityId: opportunityId,
      worksheets: [],
    } as Omit<
      IOneTimeJob,
      | "paymentMethodOnFileAuthorized"
      | "startTime"
      | "endTime"
      | "arrivalWindowDurationMinutes"
      | "notifyCustomer"
    >;
  });
}
