import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { WorksheetContentType } from "../enums/WorksheetContentType";
import worksheetsDataProvider from "../services/worksheetsDataProvider";
import { WorksheetFormQuestions } from "./WorksheetFormQuestions";
import { FormData } from "./WorksheetForm.types";
import { useDispatch } from "react-redux";
import { worksheetsActionCreators } from "../modules/worksheet";

export function WorksheetFormBody({
  worksheetId,
  onSaveComplete,
  onCancel,
  defaultValues,
}: {
  worksheetId: string | null;
  onSaveComplete: () => void;
  onCancel: () => void;
  defaultValues: FormData;
}) {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const { control, register, watch, getValues, setValue } = useForm<FormData>({
    defaultValues,
  });

  const { ref: registerTitleRef, ...registerTitle } = register("title");
  const titleRef = useFocusTitleFieldOnLoad({
    worksheetId,
  });

  const header =
    worksheetId === null ? "Add Worksheet" : `Update ${defaultValues.title}`;

  return (
    <FormContainerWithoutRedux
      formHeader={header}
      formType={FormTypesV2.worksheets}
      size="xl"
      save={() => {
        const values = getValues();
        const serverPayload = {
          ...values,
          contentItems: values.contentItems.map((ci) => ({
            ...ci,
            serverId: undefined,
            id: ci.serverId,
            type: parseInt(ci.type),
            optionListOptions:
              parseInt(ci.type) === WorksheetContentType.QuestionOptionList
                ? ci.optionListOptions
                : [],
          })),
        };

        if (worksheetId === null) {
          return worksheetsDataProvider.createWorksheet(serverPayload);
        } else {
          return worksheetsDataProvider.updateWorksheet(
            worksheetId,
            serverPayload
          );
        }
      }}
      onSaveComplete={(worksheet) => {
        dispatch(
          worksheetsActionCreators.loadSpecificWorksheets({
            worksheets: [worksheet],
          })
        );

        onSaveComplete();
      }}
      onCancel={onCancel}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <div className="form-group">
        <label htmlFor="title" className="required">
          Worksheet title
        </label>
        <input
          type="text"
          id="title"
          className="form-control"
          required
          maxLength={150}
          {...registerTitle}
          ref={(r) => {
            registerTitleRef(r);
            titleRef.current = r;
          }}
        />
      </div>

      {worksheetId !== null ? (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="inactive"
              {...register("inactive")}
            />
            <label className="custom-control-label" htmlFor="inactive">
              Inactive
            </label>
          </div>
        </div>
      ) : null}

      <WorksheetFormQuestions
        control={control}
        register={register}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
      />
    </FormContainerWithoutRedux>
  );
}

function useFocusTitleFieldOnLoad({
  worksheetId,
}: {
  worksheetId: string | null;
}) {
  const titleRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (titleRef.current && worksheetId === null) {
        titleRef.current.focus();
      }
    });

    return function cleanup() {
      clearTimeout(timerId);
    };
  }, [worksheetId]);
  return titleRef;
}
