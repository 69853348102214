import React, { useState, useMemo, useEffect, useRef } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import FormContainer2 from "../components/FormContainer2";
import { getJobInstanceV2 } from "../../../services/jobInstanceService";
import jobFinder from "../../../services/jobFinder";
import { IJob } from "../../../models/IJob";
import { TemplateVariableValues } from "../../../models/ICustomerNotificationsConfiguration";
import CustomerNotificationsTemplatedMessage from "../components/CustomerNotificationsTemplatedMessage";
import { CustomerNotificationTemplateType } from "../../../enums/customerNotificationTemplateType";
import { CustomerNotificationsDisabledAlert } from "../components/CustomerNotificationsDisabledAlert";
import { useShowVerificationEnrollmentMessage } from "../../../slices/tenantSubscription/hooks/useShowVerificationEnrollmentMessage";
import { TollFreeVerificationPrompt } from "../../../slices/tenantSubscription/components/TollFreeVerificationPrompt";

const CustomerNotificationForm: React.FunctionComponent<{}> = () => {
  const [text, setText] = useState("");
  const customerAndJobInstanceIds = useGetCustomerIds();
  const distinctCustomerIds = useMemo(
    () =>
      customerAndJobInstanceIds
        .map((c) => c.customerId)
        .filter(
          (value, index, self) => self.findIndex((c) => c === value) === index
        ),
    [customerAndJobInstanceIds]
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );
  const [variables, setVariables] = useState<TemplateVariableValues>({});
  const customerTextingAllowed = useApplicationStateSelector(
    (s) => s.common.optionalCapabilitiesAllowed.customerTexting
  );

  const formVisible = useApplicationStateSelector(
    (s) => s.forms.customerNotification.showForm
  );
  const previousFormVisible = useRef(false);
  useEffect(() => {
    if (formVisible && !previousFormVisible.current) {
      setText("");
      setVariables({});
    }

    previousFormVisible.current = formVisible;
  }, [formVisible, setText]);

  const showVerificationEnrollmentMessage =
    useShowVerificationEnrollmentMessage();

  const saveDisabled =
    !customerTextingAllowed || showVerificationEnrollmentMessage;

  let content: React.ReactNode;
  if (!customerTextingAllowed) {
    content = (
      <CustomerNotificationsDisabledAlert parentFormType="customerNotification" />
    );
  } else if (showVerificationEnrollmentMessage) {
    content = <TollFreeVerificationPrompt />;
  } else {
    content = (
      <>
        <div>
          <span className="text-primary">
            <strong data-testid="numberOfCustomers">
              {distinctCustomerIds.length}
            </strong>
          </span>{" "}
          customers selected
        </div>
        <CustomerNotificationsTemplatedMessage
          onChange={(newValue) => {
            setText(newValue.value);
            setSelectedTemplateId(newValue.selectedTemplateId);
            setVariables(newValue.variables);
          }}
          value={text}
          templateType={CustomerNotificationTemplateType.oneOff}
          selectedTemplateId={selectedTemplateId}
          variables={variables}
          required
        />
      </>
    );
  }

  return (
    <FormContainer2
      size="lg"
      formType={"customerNotification"}
      getFormData={() => {
        return {
          templateId: selectedTemplateId,
          templateVariables: variables,
          customerAndJobInstanceIds,
        };
      }}
      formHeader="Send Notification"
      saveButtonText="Send"
      hideSave={saveDisabled}
    >
      {content}
    </FormContainer2>
  );
};

export default CustomerNotificationForm;

function useGetCustomerIds(): Array<ICustomerAndJobInstanceIds> {
  const selectedJobInstanceIds: Array<string> = useApplicationStateSelector(
    (s) =>
      (s.forms.customerNotification.parameters
        ? s.forms.customerNotification.parameters.jobInstanceIds
        : []) || []
  );
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);

  const customers = useMemo(() => {
    const mappedJobInstances = selectedJobInstanceIds.map((jobInstanceId) =>
      getJobInstanceV2(
        daySchedules,
        weeksUnscheduledMaintenanceJobs,
        jobInstanceId
      )
    );
    const jobs = mappedJobInstances
      .filter((ji) => !!ji.jobInstance)
      .map((ji) => ({
        jobInstance: ji.jobInstance,
        job: jobFinder.getJobForDayScheduleV2(
          maintenanceJobs,
          oneTimeJobs,
          ji.jobInstance
        ),
      }));

    return jobs
      .filter((j) => !!j.jobInstance && !!j.job)
      .map((j) => ({
        customerId: (j.job as IJob).customerId as string,
        jobInstanceId: j.jobInstance.id,
      }));
  }, [
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    selectedJobInstanceIds,
    maintenanceJobs,
    oneTimeJobs,
  ]);

  return customers;
}

interface ICustomerAndJobInstanceIds {
  customerId: string;
  jobInstanceId: string;
}
