import * as signalR from "@microsoft/signalr";
import { url, setSignalrConnectionId } from "./remoteDataProvider";
import { IJobInstance } from "../models/IJobInstance";
import auth from "./Auth";
import { IApplicationState } from "../modules";
import { Store, AnyAction } from "redux";
import { actionCreators } from "../modules/actionCreators";
import { IDaySchedule } from "../models/IDaySchedule";
import { IRemoteScheduleChanged } from "../models/IRemoteScheduleChanged";
import { IRemoteJobChanged } from "../models/IRemoteJobChanged";
import { IRemoteCustomerNotificationAdded } from "../models/IRemoteCustomerNotificationAdded";
import { opportunitiesActionCreators } from "../slices/sales/modules/opportunity";
import { proposalsActionCreators } from "../slices/sales/modules/proposal";
import { projectActionCreators } from "../slices/schedule/modules/project";
import { Subject } from "rxjs";
import { tap, throttleTime } from "rxjs/operators";
import { worksheetsActionCreators } from "../slices/worksheets/modules/worksheet";

let retryCount = 0;

let connection: signalR.HubConnection | null = null;
let store: Store<IApplicationState, AnyAction> | null = null;
let reconnectThrottle = new Subject<{}>();

let userRequestedClose = false;
let connectionOpen = false;
let isReconnect = false;
let haveReconnectEventsRegistered = false;

export function signalRRegisterCommands(
  storeArgument: Store<IApplicationState, AnyAction>
) {
  const accessToken = auth.getAccessToken();
  store = storeArgument;

  if (accessToken && !connection) {
    connection = createConnection(accessToken, store);
  }
}

export function ensureConnectionOpenOnLogin() {
  const accessToken = auth.getAccessToken();

  if (!store) {
    throw new Error("Store not initialize");
  }

  if (accessToken && !connection) {
    connection = createConnection(accessToken, store);
  }
}

export function reconnect() {
  if (connection && connectionOpen) {
    // Calling connection.stop without setting connectionClosed
    // will result in connection reopening using signalr reconnect logic
    connection.stop();
  }
}

export function closeConnection() {
  userRequestedClose = true;
  if (connection) {
    connection.stop();
  }
}

function createConnection(
  accessToken: string,
  store: Store<IApplicationState, AnyAction>
) {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${url}/adminhub`, {
      accessTokenFactory: () => auth.getAccessToken() || "",
    })
    .configureLogging(signalR.LogLevel.Warning)
    .build();

  connection.on("UpdateDayScheduleJobInstance", (jobInstance: IJobInstance) => {
    const action = actionCreators.remoteJobInstanceUpdated(jobInstance);
    store.dispatch(action);
  });

  connection.on("JobInstanceFieldUpdate", (fieldUpdate: IFieldUpdate) => {
    const action = actionCreators.remoteJobInstanceFieldUpdated(
      fieldUpdate.jobInstanceId,
      fieldUpdate.fieldName,
      fieldUpdate.value
    );
    store.dispatch(action);
  });

  connection.on(
    "DayScheduleFieldUpdate",
    (fieldUpdate: IDayScheduleFieldUpdate) => {
      const action = actionCreators.remoteDayScheduleFieldUpdated(
        fieldUpdate.dayScheduleId,
        fieldUpdate.fieldName,
        fieldUpdate.value
      );
      store.dispatch(action);
    }
  );

  connection.on(
    "ScheduleChanged",
    (scheduleChanged: IRemoteScheduleChanged) => {
      const action = actionCreators.remoteScheduleChanged(scheduleChanged);
      store.dispatch(action);
    }
  );

  connection.on("JobChanged", (jobChanged: IRemoteJobChanged) => {
    const action = actionCreators.remoteJobChanged(jobChanged);
    store.dispatch(action);
  });

  connection.on(
    "CustomerChanged",
    (customerChanged: { customerId: string }) => {
      const action = actionCreators.remoteCustomerChanged(
        customerChanged.customerId
      );
      store.dispatch(action);
    }
  );

  connection.on("CrewChanged", (crewChanged: { crewId: string }) => {
    const action = actionCreators.remoteCrewChanged(crewChanged.crewId);
    store.dispatch(action);
  });

  connection.on(
    "CustomerNotificationAdded",
    (customerNotificationAdded: IRemoteCustomerNotificationAdded) => {
      const action = actionCreators.remoteCustomerNotificationAdded(
        customerNotificationAdded
      );
      store.dispatch(action);
    }
  );

  connection.on("OpportunityBoardChanged", () => {
    const action = opportunitiesActionCreators.reloadBoard();
    store.dispatch(action);
  });

  connection.on(
    "OpportunityChanged",
    ({ opportunityId }: { opportunityId: string }) => {
      const action = opportunitiesActionCreators.reloadOpportunity({
        opportunityId,
      });
      store.dispatch(action);
    }
  );

  connection.on("ProposalChanged", ({ proposalId }: { proposalId: string }) => {
    const action = proposalsActionCreators.reloadProposal({
      proposalId,
    });
    store.dispatch(action);
  });

  connection.on(
    "ProjectRemoved",
    ({ projectIds }: { projectIds: Array<string> }) => {
      const action = projectActionCreators.removeProjects({
        projectIds,
      });

      store.dispatch(action);
    }
  );

  connection.on(
    "ProjectsChanged",
    ({ projectIds }: { projectIds: Array<string> }) => {
      const action = projectActionCreators.reloadProjects({
        projectIds,
      });

      store.dispatch(action);
    }
  );

  connection.on("InitialLoadDataChanged", () => {
    store.dispatch(actionCreators.updateInitialLoad());
  });

  connection.on(
    "WorksheetChanged",
    ({ worksheetId }: { worksheetId: string }) => {
      const action = worksheetsActionCreators.reloadWorksheet({
        worksheetId,
      });
      store.dispatch(action);
    }
  );

  connection.on(
    "WorksheetDeleted",
    ({ worksheetId }: { worksheetId: string }) => {
      const action = worksheetsActionCreators.deleteWorksheet({
        worksheetId,
      });
      store.dispatch(action);
    }
  );

  const connect = () => {
    connection
      .start()
      .then(() => {
        console.log("signalr connection open");

        connectionOpen = true;

        // Reset the retry connect
        retryCount = 0;

        if (isReconnect) {
          console.log("signalr reconnect complete");
          reconnectThrottle.next({});
        }

        connection.invoke("getConnectionId").then((connectionId) => {
          setSignalrConnectionId(connectionId);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  connection.onclose(() => {
    console.log("signalr disconnected");
    console.log(`signalr userRequestedClose: ${userRequestedClose}`);
    connectionOpen = false;

    retryCount++;
    if (hasExceededRetryCount() && !userRequestedClose) {
      window.setTimeout(() => {
        console.log(`signalr trying to reconnect. retryCount: ${retryCount}`);
        isReconnect = true;
        connect();
      }, 2000 * (2 ^ retryCount));
    } else if (!userRequestedClose) {
      console.log("signalr exceeded retry count");
    }
  });

  connect();

  function ensureConnectionOpen() {
    console.log("signalr ensureConnectionOpen");
    console.log(`connectionOpen: ${connectionOpen}`);
    console.log(`hasExceededRetryCount: ${hasExceededRetryCount()}`);

    if (!connectionOpen && hasExceededRetryCount() && connect) {
      isReconnect = true;
      console.log("signalr ensureConnectionOpen connect");
      connect();
    }
  }

  if (!haveReconnectEventsRegistered) {
    window.addEventListener("online", () => {
      console.log("The network connection has been restablished.");
      ensureConnectionOpen();
    });

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log("The tab is now visible");
        ensureConnectionOpen();
      }
    });

    reconnectThrottle
      .pipe(
        tap(() => console.log("reconnect initiated")),
        throttleTime(15 * 60 * 1000)
      )
      .subscribe({
        next: () => {
          console.log("running store updates for reconnect");

          store.dispatch(actionCreators.updateInitialLoad());
          store.dispatch(actionCreators.markReduxStoreCacheStale());
        },

        error: (e) => {
          console.error("signalr error in reconnect throttle");
          console.error(e);
        },
      });

    haveReconnectEventsRegistered = true;
  }

  return connection;
}

function hasExceededRetryCount() {
  return retryCount < 5;
}

interface IFieldUpdate {
  jobInstanceId: string;
  fieldName: keyof IJobInstance;
  value: any;
}

interface IDayScheduleFieldUpdate {
  dayScheduleId: string;
  fieldName: keyof IDaySchedule;
  value: any;
}
