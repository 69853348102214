import { Fragment } from "react";
import { useDispatch } from "react-redux";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { INotesModalJobs } from "../../../containers/app/pages/completedWork/NotesModal";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { ICustomer } from "../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import {
  IWorkNotInvoicedCustomer,
  IWorkNotInvoicedFailedInvoice,
  IWorkNotInvoicedJob,
  IWorkNotInvoicedJobInstance,
  IWorkNotInvoicedProject,
} from "../../../models/IWorkNotInvoicedCustomer";
import { actionCreators } from "../../../modules/actionCreators";
import billingReportService from "../../../services/billingReportService";
import { formatCurrency } from "../../../services/currencyFormatter";
import {
  getLocationName,
  mapProjectNotInvoicedJobToInvoiceJobInstance,
  mapWorkNotInvoicedJobToInvoiceJobInstance,
} from "../../../services/invoiceFormService";
import { getTotalHoursForJobInstances } from "../../../services/invoiceService";
import JobHelper from "../../../services/jobHelper";
import { getSortedItemsV2 } from "../../../services/sortingService";
import useSalesEnabled from "../../sales/hooks/useSalesEnabled";
import BillingWorkNotInvoicedActionButtons from "./BillingWorkNotInvoicedActionButtons";
import BillingWorkNotInvoicedInfo from "./BatchIneligibleWarning";
import { fullStoryTrack } from "../../../services/fullStoryService";
import BillingListCustomerPopover from "./BillingListCustomerPopover";
import {
  buildCustomerAddress,
  getJobInstanceDates,
} from "./BillingWorkNotInvoicedTable.functions";
import { isStringSet } from "../../../services/stringService";
import { ResponsiveTableMobileCard } from "../../../libraries/tableLayout/ResponsiveTableMobileCard";

const purchaseOrderNumberPaddingClass = "pl-4";
export function BillingWorkNotInvoicedTable({
  reportData,
  reloadData,
  setNotesModalJobs,
  selectedJobs,
  onJobSelected,
  clearSelectedJobs,
}: {
  reportData: Array<IWorkNotInvoicedCustomer>;
  reloadData: () => void;
  setNotesModalJobs: (arg: INotesModalJobs) => void;
  selectedJobs: Array<string>;
  onJobSelected: (jobIds: Array<string>, checked: boolean) => void;
  clearSelectedJobs: () => void;
}) {
  const dispatch = useDispatch();
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const isSalesEnabled = useSalesEnabled();
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );

  const batchEligibleJobs = reportData
    .flatMap((d) => d.jobs)
    .filter((j) => j.batchEmailEligible)
    .map((j) => j.id);
  const allJobsChecked = batchEligibleJobs.every((j) =>
    selectedJobs.includes(j)
  );
  const selectAllId = "selectAllCheckbox";

  const enableJobSelection = batchEligibleJobs.length > 0;
  const purchaseOrderNumberSet = reportData.some(
    (customer) =>
      customer.jobs.some((job) =>
        job.jobInstances.some((jobInstance) =>
          isStringSet(jobInstance.purchaseOrderNumber)
        )
      ) ||
      customer.projects.some((project) =>
        project.jobInstances.some((jobInstance) =>
          isStringSet(jobInstance.purchaseOrderNumber)
        )
      )
  );

  return (
    <>
      <div className="d-block d-lg-none">
        {getSortedItemsV2(reportData, ["name"]).map((customer, index) => {
          const combinedRows = [
            ...customer.jobs.map(
              (j) =>
                ({
                  ...j,
                  type: "job",
                } as IWorkNotInvoicedJob & { type: "job" })
            ),
            ...customer.projects.map(
              (p) =>
                ({
                  ...p,
                  type: "project",
                } as IWorkNotInvoicedProject & { type: "project" })
            ),
            ...customer.failedInvoices.map(
              (f) =>
                ({
                  ...f,
                  id: f.contractBillingHistoryItemId,
                  type: "failedInvoice",
                } as IWorkNotInvoicedFailedInvoice & {
                  id: string;
                  type: "failedInvoice";
                })
            ),
          ];

          return (
            <div className="mb-4" key={customer.id}>
              <div className="d-flex justify-content-between mb-3">
                <BillingListCustomerPopover
                  recordId={customer.id}
                  customerId={customer.id}
                  customerName={
                    <h4 className="text-primary">
                      <u>{customer.name}</u>
                    </h4>
                  }
                  customerPhoneNumber={customer.phoneNumber}
                  customerAlternativePhoneNumber={
                    customer.alternativePhoneNumber
                  }
                  customerAddress={buildCustomerAddress(customer)}
                  idPrefix={"mobile"}
                />
                {customer.jobs.length > 0 || customer.projects.length > 0 ? (
                  <BillingWorkNotInvoicedActionButtons
                    customerName={customer.name}
                    jobInstanceIds={[
                      ...customer.jobs.flatMap((c) =>
                        c.jobInstances.map((ji) => ji.id)
                      ),
                      ...customer.projects.flatMap((p) =>
                        p.jobInstances.map((ji) => ji.id)
                      ),
                    ]}
                    onCreateInvoiceClick={() => {
                      clearSelectedJobs();
                      fullStoryTrack("Create Invoice Clicked");
                      dispatch(
                        actionCreators.forms.invoice.showForm({
                          customerId: customer.id,
                          defaultQuickBooksCustomerId:
                            customer.quickBooksCustomerId,
                          projects: customer.projects.map((project) =>
                            mapProjectNotInvoicedJobToInvoiceJobInstance({
                              project,
                              customer,
                              customers,
                              customerAdditionalLocations,
                            })
                          ),
                          jobInstances: customer.jobs.flatMap((job) =>
                            mapWorkNotInvoicedJobToInvoiceJobInstance({
                              job,
                              customer,
                              customers,
                              customerAdditionalLocations,
                            })
                          ),
                        })
                      );
                    }}
                    onDoNotInvoiceSaveComplete={() => {
                      reloadData();
                    }}
                  />
                ) : null}
              </div>
              {combinedRows.map((job) => (
                <div key={job.id}>
                  <ResponsiveTableMobileCard
                    row={job}
                    cardMargin="mb-1"
                    columns={[
                      {
                        header: "Address",
                        cell: ({ row: job }) =>
                          job.type === "job" || job.type === "project"
                            ? getLocationName(
                                customer,
                                job,
                                customers,
                                customerAdditionalLocations
                              )
                            : getLocationNameForFailedInvoice(
                                customer,
                                customers,
                                customerAdditionalLocations
                              ),
                        key: "address",
                      },
                      {
                        header: "Frequency",
                        cell: ({ row: job }) => {
                          if (job.type === "job") {
                            return JobHelper.getJobFrequencyForDisplay(
                              null,
                              null,
                              job.frequency,
                              job.seasonalScheduleFrequency,
                              job.seasonalScheduleStart,
                              job.seasonalScheduleEnd,
                              job.jobInstances.map((ji) => ji.date)
                            );
                          } else if (job.type === "project") {
                            return "Project";
                          } else {
                            return "Contract Billing";
                          }
                        },
                        key: "frequency",
                      },
                      {
                        header: "Service(s)",
                        cell: ({ row: job }) => {
                          if (job.type === "job" || job.type === "project") {
                            return job.services;
                          }

                          return null;
                        },
                        key: "services",
                      },
                      {
                        header: "# of visits",
                        cell: ({ row: job }) =>
                          job.type === "job" || job.type === "project"
                            ? job.jobInstances.length
                            : "",
                        key: "numberOfVisits",
                      },
                      {
                        header: "Date(s)",
                        cell: ({ row: job }) =>
                          job.type === "job" || job.type === "project"
                            ? getJobInstanceDates(job.jobInstances)
                            : "",
                        key: "dates",
                        testId: "dates",
                      },
                      {
                        header: "Total hours",
                        cell: ({ row: job }) => {
                          if (job.type === "job") {
                            return getTotalHoursForJobInstances(
                              job.jobInstances
                            );
                          } else if (job.type === "project") {
                            return getTotalHoursForJobInstances(
                              job.jobInstances
                            );
                          }

                          return null;
                        },
                        key: "totalHours",
                      },
                      {
                        header: "Amount",
                        cell: ({ row: job }) => {
                          if (job.type === "job") {
                            return job.grossRevenuePerVisit
                              ? formatCurrency(
                                  job.grossRevenuePerVisit *
                                    job.jobInstances.length
                                )
                              : null;
                          } else if (job.type === "project") {
                            return typeof job.amount === "number"
                              ? formatCurrency(job.amount)
                              : "";
                          } else {
                            return job.amount;
                          }
                        },
                        key: "totalRevenue",
                      },
                      {
                        header: "Estimate #",
                        cell: ({ row: job }) => {
                          if (
                            job.type === "failedInvoice" ||
                            !job.proposalNumber
                          ) {
                            return null;
                          }

                          return job.proposalLookupId ? (
                            <a
                              data-testid="proposalNumberLink"
                              href={`${customerUrlRoot}/proposal/${job.proposalLookupId}?ref=admin`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              {job.proposalNumber ?? ""}
                            </a>
                          ) : (
                            <span>{job.proposalNumber}</span>
                          );
                        },
                        key: "proposalNumber",
                        hidden: !isSalesEnabled,
                      },
                      {
                        header: "PO #",
                        cell: ({ row: job }) =>
                          job.type === "job" || job.type === "project"
                            ? getPurchaseOrderNumbers(job.jobInstances)
                            : null,
                        key: "purchaseOrderNumber",
                      },
                      {
                        header: "Crew feedback",
                        cell: ({ row: job }) =>
                          (job.type === "job" || job.type === "project") &&
                          job.jobInstances.some((ji) => ji.showNotesLink) ? (
                            <LinkButton2
                              className=""
                              inlineButton
                              onClick={() =>
                                setNotesModalJobs({
                                  name: customer.name,
                                  jobInstances: job.jobInstances
                                    .filter((ji) => ji.showNotesLink)
                                    .map((ji) => ({
                                      id: ji.id,
                                      date: ji.date,
                                      showNotesLink: ji.showNotesLink,
                                    })),
                                })
                              }
                              buttonContents="View"
                            />
                          ) : null,
                        key: "crewFeedback",
                      },
                      {
                        header: "",
                        cell: ({ row: job }) =>
                          job.type === "failedInvoice" ? (
                            <BillingWorkNotInvoicedActionButtons
                              customerName={customer.name}
                              contractBillingHistoryItemId={
                                job.contractBillingHistoryItemId
                              }
                              onCreateInvoiceClick={() => {
                                clearSelectedJobs();
                                fullStoryTrack(
                                  "Create Invoice Failed Contract Clicked"
                                );
                                dispatch(
                                  actionCreators.forms.invoice.showForm({
                                    customerId: customer.id,
                                    defaultQuickBooksCustomerId:
                                      customer.quickBooksCustomerId,
                                    jobInstances: [],
                                    contractBillingHistoryItemId:
                                      job.contractBillingHistoryItemId,
                                  })
                                );
                              }}
                              onDoNotInvoiceSaveComplete={() => {
                                reloadData();
                              }}
                            />
                          ) : null,
                        key: "failedInvoiceButton",
                        isButtonCell: true,
                      },
                    ]}
                    rowIndex={index}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>

      <div className="d-none d-lg-block table-responsive pb-4">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              {areCreditCardsEnabled ? (
                <th>
                  <div className="custom-control custom-checkbox">
                    {enableJobSelection ? (
                      <>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={selectAllId}
                          checked={allJobsChecked}
                          onChange={(e) =>
                            onJobSelected(batchEligibleJobs, !allJobsChecked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={selectAllId}
                        >
                          <span className="sr-only">
                            {allJobsChecked
                              ? "Unselect All Jobs"
                              : "Select All Jobs"}
                          </span>
                        </label>
                      </>
                    ) : null}
                  </div>
                </th>
              ) : null}
              <th>Customer</th>
              <th>Frequency</th>
              <th>Service(s)</th>
              <th>Date(s)</th>
              <th className="text-right"># of visits</th>
              <th className="text-right">Total hours</th>
              <th className="text-right">Amount</th>
              {isSalesEnabled ? (
                <th className="text-center">Estimate #</th>
              ) : null}
              {purchaseOrderNumberSet ? (
                <th
                  data-testid="purchaseOrderNumberHeader"
                  className={purchaseOrderNumberPaddingClass}
                >
                  PO #
                </th>
              ) : null}
              <th className="text-center">Crew feedback</th>
              <th className="text-right">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {getSortedItemsV2(reportData, ["name"]).map((customer) => (
              <Fragment key={customer.id}>
                <tr>
                  {areCreditCardsEnabled ? <td></td> : null}
                  <td
                    colSpan={
                      isSalesEnabled && purchaseOrderNumberSet
                        ? 10
                        : isSalesEnabled || purchaseOrderNumberSet
                        ? 9
                        : 8
                    }
                    data-testid="customerName"
                  >
                    <BillingListCustomerPopover
                      recordId={customer.id}
                      customerId={customer.id}
                      customerName={customer.name}
                      customerPhoneNumber={customer.phoneNumber}
                      customerAlternativePhoneNumber={
                        customer.alternativePhoneNumber
                      }
                      customerAddress={buildCustomerAddress(customer)}
                      idPrefix=""
                    />
                  </td>
                  <td className="text-right">
                    {customer.jobs.length > 0 ||
                    customer.projects.length > 0 ? (
                      <BillingWorkNotInvoicedActionButtons
                        customerName={customer.name}
                        jobInstanceIds={[
                          ...customer.jobs.flatMap((c) =>
                            c.jobInstances.map((ji) => ji.id)
                          ),
                          ...customer.projects.flatMap((p) =>
                            p.jobInstances.map((ji) => ji.id)
                          ),
                        ]}
                        onCreateInvoiceClick={() => {
                          clearSelectedJobs();
                          fullStoryTrack("Create Invoice Clicked");
                          dispatch(
                            actionCreators.forms.invoice.showForm({
                              customerId: customer.id,
                              defaultQuickBooksCustomerId:
                                customer.quickBooksCustomerId,
                              projects: customer.projects.map((project) =>
                                mapProjectNotInvoicedJobToInvoiceJobInstance({
                                  project,
                                  customer,
                                  customers,
                                  customerAdditionalLocations,
                                })
                              ),
                              jobInstances: customer.jobs.flatMap((job) =>
                                mapWorkNotInvoicedJobToInvoiceJobInstance({
                                  job,
                                  customer,
                                  customers,
                                  customerAdditionalLocations,
                                })
                              ),
                            })
                          );
                        }}
                        onDoNotInvoiceSaveComplete={() => {
                          reloadData();
                        }}
                      />
                    ) : null}
                  </td>
                </tr>

                {customer.projects.map((project) => {
                  return (
                    <tr key={project.id}>
                      {areCreditCardsEnabled ? <td></td> : null}
                      <td className="pl-3" data-testid="locationName">
                        {getLocationName(
                          customer,
                          project,
                          customers,
                          customerAdditionalLocations
                        )}
                      </td>
                      <td data-testid="frequency">Project</td>
                      <td data-testid="services">{project.services}</td>
                      <td data-testid="date">
                        {getJobInstanceDates(project.jobInstances)}
                      </td>
                      <td className="text-right" data-testid="numberOfVisits">
                        {project.jobInstances.length}
                      </td>
                      <td className="text-right" data-testid="totalHours">
                        {getTotalHoursForJobInstances(project.jobInstances)}
                      </td>
                      <td className="text-right" data-testid="totalRevenue">
                        {typeof project.amount === "number"
                          ? formatCurrency(project.amount)
                          : ""}
                      </td>
                      {isSalesEnabled ? (
                        <td
                          data-testid="proposalNumber"
                          className="text-center"
                        >
                          <a
                            href={`${customerUrlRoot}/proposal/${project.proposalLookupId}?ref=admin`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            {project.proposalNumber ?? ""}{" "}
                          </a>
                        </td>
                      ) : null}
                      {purchaseOrderNumberSet ? (
                        <td
                          data-testid="purchaseOrderNumber"
                          className={purchaseOrderNumberPaddingClass}
                        >
                          {getPurchaseOrderNumbers(project.jobInstances)}
                        </td>
                      ) : null}
                      <td data-testid="crewFeedback" className="text-center">
                        {project.jobInstances.some((ji) => ji.showNotesLink) ? (
                          <LinkButton2
                            className=""
                            onClick={() =>
                              setNotesModalJobs({
                                name: customer.name,
                                jobInstances: project.jobInstances
                                  .filter((ji) => ji.showNotesLink)
                                  .map((ji) => ({
                                    id: ji.id,
                                    date: ji.date,
                                    showNotesLink: ji.showNotesLink,
                                  })),
                              })
                            }
                            buttonContents="View"
                          />
                        ) : null}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}

                {customer.failedInvoices.map((failedInvoice) => {
                  return (
                    <tr key={failedInvoice.contractBillingHistoryItemId}>
                      {areCreditCardsEnabled ? <td></td> : null}
                      <td className="pl-3" data-testid="locationName">
                        {getLocationNameForFailedInvoice(
                          customer,
                          customers,
                          customerAdditionalLocations
                        )}
                      </td>
                      <td data-testid="frequency">Contract Billing</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right" data-testid="invoiceAmount">
                        {formatCurrency(failedInvoice.amount)}
                      </td>
                      <td></td>
                      {isSalesEnabled ? (
                        <td data-testid="proposalNumberPlaceholder"></td>
                      ) : null}
                      {purchaseOrderNumberSet ? (
                        <td data-testid="purchaseOrderNumberPlaceholderForFailedInvoices"></td>
                      ) : null}
                      <td className="text-right">
                        <BillingWorkNotInvoicedActionButtons
                          customerName={customer.name}
                          contractBillingHistoryItemId={
                            failedInvoice.contractBillingHistoryItemId
                          }
                          onCreateInvoiceClick={() => {
                            clearSelectedJobs();
                            fullStoryTrack(
                              "Create Invoice Failed Contract Clicked"
                            );
                            dispatch(
                              actionCreators.forms.invoice.showForm({
                                customerId: customer.id,
                                defaultQuickBooksCustomerId:
                                  customer.quickBooksCustomerId,
                                jobInstances: [],
                                contractBillingHistoryItemId:
                                  failedInvoice.contractBillingHistoryItemId,
                              })
                            );
                          }}
                          onDoNotInvoiceSaveComplete={() => {
                            reloadData();
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}

                {customer.jobs.map((job) => (
                  <tr key={job.id}>
                    {areCreditCardsEnabled ? (
                      <td style={{ width: "40px" }}>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={getJobCheckboxId(job)}
                            checked={selectedJobs.includes(job.id)}
                            onChange={(e) =>
                              onJobSelected([job.id], e.target.checked)
                            }
                            disabled={!job.batchEmailEligible}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={getJobCheckboxId(job)}
                          >
                            <span className="sr-only">Select Job</span>
                          </label>
                          <BillingWorkNotInvoicedInfo
                            job={job}
                            customer={customer}
                            onSaveComplete={reloadData}
                          />
                        </div>
                      </td>
                    ) : null}

                    <td className="pl-3" data-testid="locationName">
                      {getLocationName(
                        customer,
                        job,
                        customers,
                        customerAdditionalLocations
                      )}
                    </td>
                    <td data-testid="frequency">
                      {JobHelper.getJobFrequencyForDisplay(
                        null,
                        null,
                        job.frequency,
                        job.seasonalScheduleFrequency,
                        job.seasonalScheduleStart,
                        job.seasonalScheduleEnd,
                        job.jobInstances.map((ji) => ji.date)
                      )}
                    </td>
                    <td
                      style={{
                        maxWidth: "200px",
                        wordWrap: "break-word",
                      }}
                      data-testid="services"
                    >
                      {job.services}
                    </td>
                    <td data-testid="date">
                      {getJobInstanceDates(job.jobInstances)}
                    </td>
                    <td className="text-right" data-testid="numberOfVisits">
                      {job.jobInstances.length}
                    </td>
                    <td className="text-right" data-testid="totalHours">
                      {getTotalHoursForJobInstances(job.jobInstances)}
                    </td>
                    <td className="text-right" data-testid="totalRevenue">
                      {job.grossRevenuePerVisit
                        ? formatCurrency(
                            job.grossRevenuePerVisit * job.jobInstances.length
                          )
                        : null}
                    </td>
                    {isSalesEnabled ? (
                      <td data-testid="proposalNumber" className="text-center">
                        {job.proposalLookupId ? (
                          <a
                            data-testid="proposalNumberLink"
                            href={`${customerUrlRoot}/proposal/${job.proposalLookupId}?ref=admin`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            {job.proposalNumber ?? ""}
                          </a>
                        ) : (
                          <span>{job.proposalNumber}</span>
                        )}
                      </td>
                    ) : null}
                    {purchaseOrderNumberSet ? (
                      <td
                        data-testid="purchaseOrderNumber"
                        className={purchaseOrderNumberPaddingClass}
                      >
                        {getPurchaseOrderNumbers(job.jobInstances)}
                      </td>
                    ) : null}
                    <td data-testid="crewFeedback" className="text-center">
                      {job.jobInstances.some((ji) => ji.showNotesLink) ? (
                        <LinkButton2
                          className=""
                          onClick={() =>
                            setNotesModalJobs({
                              name: customer.name,
                              jobInstances: job.jobInstances
                                .filter((ji) => ji.showNotesLink)
                                .map((ji) => ({
                                  id: ji.id,
                                  date: ji.date,
                                  showNotesLink: ji.showNotesLink,
                                })),
                            })
                          }
                          buttonContents="View"
                        />
                      ) : null}
                    </td>
                    <td></td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function getPurchaseOrderNumbers(
  jobInstances: Array<IWorkNotInvoicedJobInstance>
) {
  return getSortedItemsV2(
    jobInstances.filter((ji) => isStringSet(ji.purchaseOrderNumber)),
    [(ji) => ji.purchaseOrderNumber.trim()]
  )
    .map((ji) => ji.purchaseOrderNumber.trim())
    .join(", ");
}

function getJobCheckboxId(job: IWorkNotInvoicedJob): string | undefined {
  return `selectJob_${job.id}`;
}

function getLocationNameForFailedInvoice(
  customer: IWorkNotInvoicedCustomer,
  customers: ICustomer[],
  customerAdditionalLocations: ICustomerAdditionalLocation[]
): React.ReactNode {
  return billingReportService.getLocationName(
    {
      id: customer.id,
      customerStreetAndNumber: customer.streetAndNumber,
      customerApartmentSuite: customer.apartmentSuite,
      customerLatitude: customer.latitude,
      customerLongitude: customer.longitude,
      customerAdditionalLocationId: null,
      customerAdditionalLocationName: null,
      customerAdditionalLocationsStreetAndNumber: null,
      customerAdditionalLocationsLatitude: null,
      customerAdditionalLocationsLongitude: null,
    },
    customers,
    customerAdditionalLocations
  );
}
