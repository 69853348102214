import { IDaySchedule } from "../models/IDaySchedule";
import { IJobInstance } from "../models/IJobInstance";
import timeService from "./timeService";
import {
  IUnscheduledJobInstance,
  IUnscheduledMaintenanceJob,
} from "../models/IUnscheduledMaintenanceJob";
import { IJob, JobType } from "../models/IJob";
import jobFinder, { IFoundJob } from "./jobFinder";
import {
  IMaintenanceJob,
  MaintenanceJobFrequency,
} from "../models/IMaintenanceJob";
import { IOneTimeJob } from "../models/IOneTimeJob";
import { ITimeRange } from "../models/ITimeRange";
import { IDistance } from "../models/IDistance";
import { ICustomer } from "../models/ICustomer";
import { ICrew } from "../models/ICrew";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import {
  getDistanceBetweenJobInstances,
  getDistanceFromCrewToJob,
  getDistanceFromJobToCrew,
} from "../containers/app/components/schedule/day/distanceCalculators";
import dateService from "./dateService";
import { getSortedItems } from "./sortingService";
import { actionCreators } from "../modules/actionCreators";
import { IAction } from "../modules/actionTypeDefinitions";
import { showButtonDropdown } from "../containers/app/components/Prompt";
import JobHelper from "./jobHelper";
import addressFormatter from "./addressFormatter";
import { IPromptMessageSection, promptActionCreators } from "../modules/prompt";
import jobHelper from "./jobHelper";
import { getNameForJob } from "./jobService";

export function getJobInstance(
  daySchedules: Array<IDaySchedule>,
  jobInstanceId: string
): {
  completeJobInstance: IJobInstance;
  dayScheduleForCompleteJobInstance: IDaySchedule;
} {
  if (!jobInstanceId) {
    console.error("completeJobInstanceId is required");
  }

  let completeJobInstance: IJobInstance | null = null;
  let dayScheduleForCompleteJobInstance: IDaySchedule | null = null;
  daySchedules.forEach((daySchedule) => {
    daySchedule.jobInstances.forEach((jobInstance) => {
      if (jobInstance.id === jobInstanceId) {
        completeJobInstance = jobInstance;
        dayScheduleForCompleteJobInstance = daySchedule;
      }
    });
  });

  if (!dayScheduleForCompleteJobInstance) {
    throw new Error(
      `no day schedule found for job instance id of ${jobInstanceId}`
    );
  }

  if (!completeJobInstance) {
    throw new Error(`no job instance found for id of ${jobInstanceId}`);
  }

  return { completeJobInstance, dayScheduleForCompleteJobInstance };
}

export function getJobInstanceV2(
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  jobInstanceId: string
): {
  jobInstance: IJobInstance;
  weekUnscheduledMaintenanceJobsForJobInstance: IUnscheduledMaintenanceJob | null;
  dayScheduleForJobInstance: IDaySchedule | null;
} {
  if (!jobInstanceId) {
    console.error("jobInstanceId is required");
  }

  let jobInstanceToReturn: IJobInstance | null = null;
  let dayScheduleForJobInstance: IDaySchedule | null = null;
  daySchedules.forEach((daySchedule) => {
    daySchedule.jobInstances.forEach((jobInstance) => {
      if (jobInstance.id === jobInstanceId) {
        jobInstanceToReturn = jobInstance;
        dayScheduleForJobInstance = daySchedule;
      }
    });
  });

  let weekUnscheduledMaintenanceJobsForJobInstance: IUnscheduledMaintenanceJob | null =
    null;
  weeksUnscheduledMaintenanceJobs.forEach((week) => {
    week.jobInstances.forEach((jobInstance) => {
      if (jobInstance.id === jobInstanceId) {
        jobInstanceToReturn = jobInstance;
        weekUnscheduledMaintenanceJobsForJobInstance = week;
      }
    });
  });

  if (!jobInstanceToReturn) {
    throw new Error(`no job instance found for id of ${jobInstanceId}`);
  }

  return {
    jobInstance: jobInstanceToReturn,
    dayScheduleForJobInstance,
    weekUnscheduledMaintenanceJobsForJobInstance,
  };
}

export function getTimedWorkedForJobInstance(jobInstance: IJobInstance) {
  return timeService.hasStartAndEndTimeSet(jobInstance)
    ? getTimeWorked(timeService.getActualTimeInMinutes(jobInstance) || 0)
    : null;
}

export function getEstimatedTimeWorked(
  crew: ICrew | undefined | null,
  estimatedManHours: number | null
) {
  if (crew && crew.typicalCrewSize && typeof estimatedManHours === "number") {
    return getTimeWorked(
      Math.round((estimatedManHours * 60) / crew.typicalCrewSize)
    );
  }
  return null;
}

export function isEstimatedManHoursSet(
  date: string | undefined,
  jobType: JobType,
  job: IJob
) {
  const estimatedManHours = jobHelper.getEstimatedManHours(date, jobType, job);
  if (typeof estimatedManHours === "number") {
    return true;
  } else if (typeof estimatedManHours === "string") {
    return estimatedManHours && estimatedManHours.trim();
  }

  return false;
}

export function getTimeWorked(input: number) {
  let formattedValue: string;
  if (input < 60) {
    formattedValue = `${input} minutes`;
  } else {
    const hours = Math.floor(input / 60);
    const remainingMinutes = input - hours * 60;

    if (remainingMinutes === 0) {
      formattedValue = `${hours} hours`;
    } else {
      formattedValue = `${hours} hours, ${remainingMinutes} minutes`;
    }
  }

  return {
    formattedValue,
    value: input,
  };
}

export function getMaintenanceJobs(
  jobInstanceIds: Array<string>,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>
) {
  return jobInstanceIds
    .map(
      (jobInstanceId) =>
        getJobInstanceV2(
          daySchedules,
          weeksUnscheduledMaintenanceJobs,
          jobInstanceId
        ).jobInstance
    )
    .map((jobInstance) =>
      jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, jobInstance)
    )
    .filter((foundJob) => foundJob?.type === JobType.maintenanceJob)
    .map((j) => j as unknown as IMaintenanceJob);
}

export function getTimeRangesForDisplay(
  timeRanges: Array<ITimeRange>,
  formatRange: (id: string, output: string) => React.ReactNode,
  notSetPlaceholder: string
) {
  timeRanges = timeRanges.filter((tr) => tr.startTime || tr.endTime);
  return timeRanges.length > 0
    ? timeRanges.map((tr) => {
        let output = "";
        if (tr.startTime && tr.endTime) {
          output = `${timeService.formatTimeForDisplay(
            tr.startTime
          )} - ${timeService.formatTimeForDisplay(tr.endTime)}`;
        } else if (tr.startTime) {
          output = `Started at ${timeService.formatTimeForDisplay(
            tr.startTime
          )}`;
        } else if (tr.endTime) {
          output = `Ended at ${timeService.formatTimeForDisplay(tr.endTime)}`;
        }
        return formatRange(tr.id, output);
      })
    : notSetPlaceholder;
}

export interface IPopulateDriveTimesResult extends IJobInstance {
  distanceFromPreviousJob: number | undefined;
  distanceFromPreviousJobErrorLoading: boolean;
  distanceToCrewLocation: number | null;
  distanceToCrewLocationJobErrorLoading: boolean;
}

export function populateDriveTimes(
  jobs: IMaintenanceJob[],
  oneTimeJobs: IOneTimeJob[],
  distances: IDistance[],
  filteredJobInstances: IJobInstance[],
  customers: ICustomer[],
  crew: ICrew,
  customerAdditionalLocations: ICustomerAdditionalLocation[]
): (
  value: IJobInstance,
  index: number,
  array: IJobInstance[]
) => IPopulateDriveTimesResult {
  return (filteredJob, index) => {
    const distanceFromPreviousJobResult =
      index > 0
        ? getDistanceBetweenJobInstances(
            jobs,
            oneTimeJobs,
            distances,
            filteredJobInstances[index - 1],
            filteredJob,
            customers,
            customerAdditionalLocations
          )
        : getDistanceFromCrewToJob(
            jobs,
            oneTimeJobs,
            distances,
            crew,
            filteredJob,
            customers,
            customerAdditionalLocations
          );
    const distanceToCrewLocationResult =
      index === filteredJobInstances.length - 1
        ? getDistanceFromJobToCrew(
            jobs,
            oneTimeJobs,
            distances,
            filteredJob,
            crew,
            customers,
            customerAdditionalLocations
          )
        : undefined;
    return {
      ...filteredJob,
      distanceFromPreviousJob: distanceFromPreviousJobResult
        ? distanceFromPreviousJobResult.distanceInSeconds
        : undefined,
      distanceFromPreviousJobErrorLoading: distanceFromPreviousJobResult
        ? distanceFromPreviousJobResult.errorLoadingDistance
        : false,
      distanceToCrewLocation: distanceToCrewLocationResult
        ? distanceToCrewLocationResult.distanceInSeconds
        : null,
      distanceToCrewLocationJobErrorLoading: distanceToCrewLocationResult
        ? distanceToCrewLocationResult.errorLoadingDistance
        : false,
    };
  };
}

export function getUnscheduledJobs(
  weekForUnscheduledJobs: Date,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  maintenanceJobs: Array<IMaintenanceJob>
) {
  const formattedDate = dateService.formatAsIso(weekForUnscheduledJobs);

  const currentAndPreviousJobInstances = getSortedItems(
    weeksUnscheduledMaintenanceJobs
      .filter((w) => {
        return dateService.formatAsIso(w.week) <= formattedDate;
      })
      .map((w) => ({ ...w, normalizedDate: dateService.formatAsIso(w.week) })),
    "normalizedDate",
    true
  ).reduce((prev, current) => {
    return [
      ...prev,
      ...current.jobInstances.filter((jobInstance) => {
        const isRecurringJob = jobInstance.jobId;
        const wasRecurringJobAlreadyAdded = prev.some(
          (p) => p.jobId === jobInstance.jobId
        );
        if (isRecurringJob && wasRecurringJobAlreadyAdded) {
          return false;
        }

        const isCurrentWeek = dateService.areDatesEqual(
          weekForUnscheduledJobs,
          current.week
        );

        if (jobInstance.complete && !isCurrentWeek) {
          return false;
        }

        if (jobInstance.skipped && !isCurrentWeek) {
          return false;
        }

        if (
          jobInstance.cutoffDate &&
          dateService.formatAsIso(jobInstance.cutoffDate) <= formattedDate
        ) {
          return false;
        }

        if (
          !isCurrentWeek &&
          isRecurringJob &&
          isJobInactive(current.week, jobInstance, maintenanceJobs)
        ) {
          return false;
        }

        return true;
      }),
    ];
  }, [] as Array<IJobInstance>);

  return currentAndPreviousJobInstances;
}

export function getJobInstanceDeletePromptAction({
  promptMessage,
  markMaintenanceJobsInactiveSubMessage,
  hasMaintenanceJob,
  jobInstanceIds,
}: {
  promptMessage: string;
  markMaintenanceJobsInactiveSubMessage:
    | string
    | null
    | Array<IPromptMessageSection>;
  hasMaintenanceJob: boolean;
  jobInstanceIds: Array<string>;
}) {
  const markMaintenanceJobsInactiveSaveText = hasMaintenanceJob
    ? "Remove future visits"
    : "Remove";
  const markMaintenanceJobsInactiveAction =
    actionCreators.jobInstanceDeleteStart(jobInstanceIds, true);

  const doNotMarkMaintenanceJobsInactiveSaveText = "Just this visit";
  const doNotMarkMaintenanceJobsInactiveAction =
    actionCreators.jobInstanceDeleteStart(jobInstanceIds, false);

  let primarySaveText: string,
    primarySaveAction: IAction,
    secondarySaveText: string,
    secondarySaveAction: IAction,
    subMessage: string | null | Array<IPromptMessageSection>;
  if (!showButtonDropdown()) {
    primarySaveText = markMaintenanceJobsInactiveSaveText;
    primarySaveAction = markMaintenanceJobsInactiveAction;
    subMessage = markMaintenanceJobsInactiveSubMessage;

    secondarySaveText = doNotMarkMaintenanceJobsInactiveSaveText;
    secondarySaveAction = doNotMarkMaintenanceJobsInactiveAction;
  } else {
    primarySaveText = hasMaintenanceJob
      ? doNotMarkMaintenanceJobsInactiveSaveText
      : markMaintenanceJobsInactiveSaveText;
    primarySaveAction = doNotMarkMaintenanceJobsInactiveAction;
    subMessage = null;

    secondarySaveText = markMaintenanceJobsInactiveSaveText;
    secondarySaveAction = markMaintenanceJobsInactiveAction;
  }

  return promptActionCreators.showPrompt({
    promptMessage,
    promptSaveText: primarySaveText,
    promptSubMessage: subMessage,
    confirmationAction: primarySaveAction,
    promptSubMessageClassName: undefined,
    promptCancelText: "Cancel",
    secondaryButtons: hasMaintenanceJob
      ? [
          {
            text: secondarySaveText,
            action: secondarySaveAction,
          },
        ]
      : [],
  });
}

export function getJobAddresses(
  jobInstances: IJobInstance[],
  jobs: IMaintenanceJob[],
  oneTimeJobs: IOneTimeJob[],
  customers: ICustomer[],
  customerAdditionalLocations: ICustomerAdditionalLocation[]
) {
  return jobInstances
    .filter((jobInstance) => {
      return (
        jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, jobInstance) !==
        null
      );
    })
    .map((jobInstance) => {
      const job = jobFinder.getJobForDayScheduleV2(
        jobs,
        oneTimeJobs,
        jobInstance
      ) as IJob;

      const address = addressFormatter.getAddressForJob(
        job,
        customers,
        customerAdditionalLocations
      );

      if (address.latitude === null) {
        throw new Error("customer.latitude not set");
      }

      if (address.longitude === null) {
        throw new Error("customer.longitude not set");
      }

      return {
        jobInstanceId: jobInstance.id,
        latitude: address.latitude as number,
        longitude: address.longitude as number,
      };
    });
}

export function getSortedUnscheduledJobs(
  jobInstanceWithJobs: Array<{
    jobInstance: IJobInstance;
    job: IFoundJob;
  }>,
  customers: ICustomer[],
  customerAdditionalLocations: ICustomerAdditionalLocation[]
) {
  return [...jobInstanceWithJobs].sort((a, b) => {
    const aName = getNameForJob({
      job: a.job,
      customers,
      customerAdditionalLocations,
      fallbackToAddressIfAdditionalLocationNameNotSet: true,
    });
    const bName = getNameForJob({
      job: b.job,
      customers,
      customerAdditionalLocations,
      fallbackToAddressIfAdditionalLocationNameNotSet: true,
    });
    if (aName.toLocaleLowerCase() < bName.toLocaleLowerCase()) {
      return -1;
    }
    if (aName.toLocaleLowerCase() > bName.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });
}

function isJobInactive(
  date: string | Date,
  jobInstance: IUnscheduledJobInstance,
  maintenanceJobs: Array<IMaintenanceJob>
) {
  const maintenanceJob = maintenanceJobs.find(
    (j) => j.id === jobInstance.jobId
  );
  if (!maintenanceJob) {
    return false;
  }

  let frequency = maintenanceJob.frequency;
  if (
    typeof maintenanceJob.seasonalScheduleFrequency === "number" &&
    JobHelper.isInSeasonalPeriod(
      dateService.formatAsIso(date),
      maintenanceJob.seasonalScheduleStart,
      maintenanceJob.seasonalScheduleEnd
    )
  ) {
    frequency = maintenanceJob.seasonalScheduleFrequency;
  }

  if (frequency === MaintenanceJobFrequency.None) {
    return true;
  }

  return false;
}
