import { JobHistoryStatus } from "../../../../models/IJobHistory";
import { getTimeRangesForDisplay } from "../../../../services/jobInstanceService";
import { IJobHistoryInstanceWithCalculatedProps } from "./CustomerDetailsHistory.types";

export function CrewDetailsHistoryStatusCell({
  jobHistoryInstance,
}: {
  jobHistoryInstance: IJobHistoryInstanceWithCalculatedProps;
}) {
  const actualTimesContent = getTimeRangesForDisplay(
    jobHistoryInstance.timeRanges,
    (id, output) => <div key={id}>{output}</div>,
    ""
  );

  const scheduledTimesContent = jobHistoryInstance.scheduleTimes;

  let statusProperties: { label: string; colorClass: string } | null = null;
  if (jobHistoryInstance.status === JobHistoryStatus.InProgress) {
    statusProperties = {
      label: "In progress",
      colorClass: "badge-primary",
    };
  } else if (jobHistoryInstance.status === JobHistoryStatus.Paused) {
    statusProperties = {
      label: "Paused",
      colorClass: "schedule-job-paused",
    };
  } else if (jobHistoryInstance.status === JobHistoryStatus.Skipped) {
    statusProperties = {
      label: "Skipped",
      colorClass: "badge-warning",
    };
  } else if (jobHistoryInstance.status === JobHistoryStatus.Completed) {
    statusProperties = {
      label: "Completed",
      colorClass: "badge-success",
    };
  }

  if (
    !actualTimesContent &&
    !scheduledTimesContent &&
    statusProperties === null
  ) {
    return null;
  }

  return (
    <>
      {statusProperties !== null ? (
        <div
          className={`badge badge-pill ${statusProperties.colorClass}`}
          data-testid="statusBadge"
        >
          {statusProperties.label}
        </div>
      ) : null}

      {!!scheduledTimesContent ? (
        <div data-testid="scheduleTimes">
          Scheduled times: <div>{scheduledTimesContent}</div>
        </div>
      ) : null}

      {!!actualTimesContent ? (
        <div data-testid="actualTimes">
          Actual times: <div>{actualTimesContent}</div>
        </div>
      ) : null}
    </>
  );
}
