import { ajax } from "rxjs/ajax";
import { map, timeout } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { IJobInstanceWorksheet } from "../models/IJobInstanceWorksheet";

const jobInstanceWorksheetsDataProvider = {
  getWorksheetsForJobInstance: ({
    jobInstanceId,
  }: {
    jobInstanceId: string;
  }) => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`JobInstance/${jobInstanceId}/worksheets`), headers)
        .pipe(
          timeout(10000),
          map(
            (result) =>
              result.response.worksheets as Array<IJobInstanceWorksheet>
          )
        )
    );
  },

  updateWorksheetResponses: ({
    jobInstanceId,
    worksheetForJobInstanceId,
    payload,
  }: {
    jobInstanceId: string;
    worksheetForJobInstanceId: string;
    payload: {
      completed: boolean;
      contentItemResponses: Array<{ id: string; response: string }>;
    };
  }) => {
    return executeWithHeaders((headers) =>
      ajax
        .patch(
          buildUrl(
            `JobInstance/${jobInstanceId}/worksheets/${worksheetForJobInstanceId}`
          ),
          payload,
          headers
        )
        .pipe(timeout(10000))
    );
  },
};

export default jobInstanceWorksheetsDataProvider;
