export enum WorksheetContentType {
  QuestionShortAnswer = 0,
  QuestionLongAnswer = 1,
  QuestionCheckbox = 2,
  QuestionFileUpload = 3,
  QuestionBoolean = 4,
  QuestionDate = 5,
  QuestionOptionList = 6,
  QuestionNumber = 7,
}
