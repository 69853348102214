import React, { CSSProperties } from "react";
import { isStringSet } from "../../services/stringService";
import { TableColumns } from "./TableColumn";
import { getCellContents } from "./getCellContents";
import getCellHeader from "./getHeaderContents";
import RowProperties from "./rowProperties";
import { isColumnVisible } from "./isColumnVisible";

export type Breakpoint = "sm" | "md" | "lg" | "xl";
export default function ResponsiveTable<T extends RowProperties>({
  rows,
  columns,
  renderMobile,
  tableClass,
  breakpoint = "lg",
  responsiveTableContainerStyle,
  alwaysShowTable,
  tableTestId,
}: {
  rows: Array<T>;
  columns: TableColumns<T>;
  renderMobile: ({ row, index }: { row: T; index: number }) => React.ReactNode;
  tableClass?: string;
  breakpoint?: Breakpoint;
  responsiveTableContainerStyle?: Partial<CSSProperties>;
  alwaysShowTable?: boolean;
  tableTestId?: string;
}) {
  const visibleColumns = columns.filter((column) =>
    isColumnVisible(column, null)
  );

  let tableCss = `table ${tableClass ?? ""}`;
  if (!alwaysShowTable) {
    tableCss += ` d-none d-${breakpoint}-table`;
  }

  return (
    <>
      <div style={responsiveTableContainerStyle}>
        <table className={tableCss} data-testid={tableTestId}>
          {visibleColumns.some((c) => isStringSet(c.width ?? null)) ? (
            <colgroup>
              {visibleColumns.map((c) => (
                <col key={c.key} width={c.width ?? ""} />
              ))}
            </colgroup>
          ) : null}
          <thead>
            <tr>
              {visibleColumns.map((column) => (
                <th
                  key={column.key}
                  className={column.headerClassName ?? ""}
                  style={column.headerStyle ?? {}}
                >
                  {getCellHeader<T>(column, {
                    displayType: "desktop",
                    rowIndex: null,
                  })}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => {
              return (
                <React.Fragment key={row.id}>
                  <tr data-testid={row.testId}>
                    {visibleColumns.map((column) => (
                      <td
                        key={column.key}
                        style={column.style ?? {}}
                        className={column.cellClassName ?? ""}
                        data-testid={column.testId}
                      >
                        {getCellContents<T>(row, column, "desktop", rowIndex)}
                      </td>
                    ))}
                  </tr>
                  {typeof row.errorMessage === "string" ? (
                    <tr>
                      <td
                        colSpan={visibleColumns.length}
                        className="text-danger"
                      >
                        {row.errorMessage}
                      </td>
                    </tr>
                  ) : null}
                  {!!row.contentBelowRow ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {row.contentBelowRow}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        {!alwaysShowTable ? (
          <div className={`d-block d-${breakpoint}-none`}>
            {rows.map((row, index) => (
              <React.Fragment key={row.id}>
                {renderMobile({ row, index })}
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}
