import { JobType } from "./IJob";
import { ISeasonalDate, MaintenanceJobFrequency } from "./IMaintenanceJob";
import { ITimeRange } from "./ITimeRange";

export enum BatchIneligibleReasonType {
  lineItems = 0,
  customerEmail = 1,
  quickBooksCustomer = 2,
  depositExists = 3,
  totalLessThanMinimumAmount = 4,
}

export interface IWorkNotInvoicedCustomer {
  quickBooksCustomerId: string;
  id: string;
  name: string;
  phoneNumber: string | null;
  alternativePhoneNumber: string | null;
  jobs: Array<IWorkNotInvoicedJob>;
  failedInvoices: Array<IWorkNotInvoicedFailedInvoice>;
  projects: Array<IWorkNotInvoicedProject>;
  streetAndNumber: string;
  apartmentSuite: string | null;
  city: string;
  state: string;
  zip: string;
  latitude: number | null;
  longitude: number | null;
  taxExempt: boolean;
}

export interface IWorkNotInvoicedJob {
  id: string;
  jobType: JobType;
  frequency: MaintenanceJobFrequency;
  seasonalScheduleFrequency: MaintenanceJobFrequency | null;
  seasonalScheduleStart: ISeasonalDate | null;
  seasonalScheduleEnd: ISeasonalDate | null;
  grossRevenuePerVisit: number | null;
  services: string;
  proposalNumber: string | null;
  proposalLookupId: string | null;
  batchIneligibleReasons: Array<BatchIneligibleReasonType> | null;

  customerAdditionalLocationId: string | null;
  customerAdditionalLocationName: string | null;
  customerAdditionalLocationStreetAndNumber: string;
  customerAdditionalLocationLatitude: number | null;
  customerAdditionalLocationLongitude: number | null;

  jobInstances: Array<IWorkNotInvoicedJobInstance>;

  batchEmailEligible: boolean;
}

export interface IWorkNotInvoicedProject {
  id: string;
  amount: number | null;
  services: string;
  proposalNumber: string | null;
  proposalLookupId: string | null;

  customerAdditionalLocationId: string | null;
  customerAdditionalLocationName: string | null;
  customerAdditionalLocationStreetAndNumber: string;
  customerAdditionalLocationLatitude: number | null;
  customerAdditionalLocationLongitude: number | null;

  jobInstances: Array<IWorkNotInvoicedProjectJobInstance>;
}

export interface IWorkNotInvoicedFailedInvoice {
  contractBillingHistoryItemId: string;
  amount: number;
}

export interface IWorkNotInvoicedJobInstance {
  id: string;
  date: string;
  actualManHours?: number;
  showNotesLink: boolean;
  timeRanges: Array<ITimeRange>;
  purchaseOrderNumber: string;
}

export interface IWorkNotInvoicedProjectJobInstance
  extends IWorkNotInvoicedJobInstance {
  services: string;

  customerAdditionalLocationId: string | null;
  customerAdditionalLocationName: string | null;
  customerAdditionalLocationStreetAndNumber: string;
  customerAdditionalLocationLatitude: number | null;
  customerAdditionalLocationLongitude: number | null;
}
