import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { ICrew } from "../../../../models/ICrew";
import { IDaySchedule } from "../../../../models/IDaySchedule";
import { IJobInstance } from "../../../../models/IJobInstance";
import dateService from "../../../../services/dateService";
import {
  getSortedCrews,
  getSortedItemsV2,
} from "../../../../services/sortingService";
import JobInstanceCategories from "../../components/JobInstanceCategories";
import JobInstanceCategoriesContainer from "../../components/JobInstanceCategoriesContainer";
import LinkButton2 from "../../components/LinkButton2";
import JobCardContainer from "../../components/schedule/JobCardContainer";
import { CrewScheduleType } from "../../../../slices/schedule/enums/crewScheduleType";
import JobHelper from "../../../../services/jobHelper";
import { formatTimeForCalendaryDisplay } from "../../../../slices/schedule/services/formatTimeForCalendaryDisplay";
import { useGetJobNameFunction } from "../../../../slices/schedule/hooks/useGetJobNameFunction";
import ContextMenu from "../../components/schedule/jobCard/ContextMenu";
import { IFoundJob } from "../../../../services/jobFinder";

interface IProps {
  date: string;
  selectedCrewIds: Array<string>;
}

const initialCountToShow = 40;

const MonthDay: React.FunctionComponent<IProps> = ({
  date,
  selectedCrewIds,
}) => {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const [showAll, setShowAll] = useState(false);

  const getJobName = useGetJobNameFunction({
    fallbackToAddressIfAdditionalLocationNameNotSet: true,
  });

  const jobInstancesMoving = useApplicationStateSelector(
    (s) => s.scheduleUi.jobInstancesMoving
  );

  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const { crewsWithJobInstancesToShow, totalJobInstanceCount } = useMemo(() => {
    const selectedCrews = getSortedCrews(
      crews.filter((c) => !c.inactive)
    ).filter((c) => selectedCrewIds.includes(c.id));

    const crewsWithJobInstances = selectedCrews.map((crew) => {
      const daySchedule = getDaySchedule(daySchedules, crew, date);

      let jobInstances = daySchedule?.jobInstances ?? [];
      if (crew.scheduleType === CrewScheduleType.time) {
        jobInstances = JobHelper.getCrewJobsOrderedByTime(
          jobInstances,
          maintenanceJobs,
          oneTimeJobs,
          customers,
          customerAdditionalLocations
        );
      }
      return {
        crew: crew,
        jobInstances: jobInstances,
      };
    });

    const totalJobInstanceCount = crewsWithJobInstances.flatMap(
      (c) => c.jobInstances
    ).length;

    const visibleJobInstances = crewsWithJobInstances
      .flatMap((c) =>
        c.jobInstances.map((ji) => ({
          jobInstance: ji,
          crew: c.crew,
        }))
      )
      .filter((_, index) => index < initialCountToShow || showAll);

    const groupedCrewsWithVisibleJobInstances = groupBy(
      visibleJobInstances,
      (t) => t.crew
    );

    const crewsWithVisibleJobs = getSortedItemsV2(
      Array.from(groupedCrewsWithVisibleJobInstances.keys()),
      ["name"]
    );

    const crewsWithoutJobInstancesToInclude = selectedCrews
      .filter(
        (c) =>
          !crewsWithVisibleJobs.some(
            (crewWithVisibleJobs) => crewWithVisibleJobs.id === c.id
          )
      )
      .filter((c) => {
        const crewCountLimited =
          totalJobInstanceCount !== visibleJobInstances.length;

        if (!crewCountLimited) {
          return true;
        } else {
          const firstCrewWithJobs = crewsWithVisibleJobs[0];
          return c.name <= firstCrewWithJobs.name;
        }
      });

    const crewsWithJobInstancesToShow = getSortedItemsV2(
      [
        ...crewsWithoutJobInstancesToInclude.map((crew) => ({
          dayScheduleId: getDaySchedule(daySchedules, crew, date)?.id as string,
          crew,
          jobInstances: [],
        })),
        ...crewsWithVisibleJobs.map((crew) => ({
          dayScheduleId: getDaySchedule(daySchedules, crew, date)?.id as string,
          crew: crew as ICrew,
          jobInstances: [
            ...(groupedCrewsWithVisibleJobInstances
              .get(crew)
              ?.map((g) => g.jobInstance) ?? ([] as Array<IJobInstance>)),
          ],
        })),
      ],
      [(c) => c.crew.name]
    );

    return { crewsWithJobInstancesToShow, totalJobInstanceCount };
  }, [
    date,
    daySchedules,
    selectedCrewIds,
    crews,
    showAll,
    maintenanceJobs,
    oneTimeJobs,
    customers,
    customerAdditionalLocations,
  ]);

  return (
    <>
      {crewsWithJobInstancesToShow.map((crewWithJobInstances) => {
        return (
          <React.Fragment key={crewWithJobInstances.crew.id}>
            <Droppable
              droppableId={
                crewWithJobInstances.dayScheduleId ??
                `${date}_${crewWithJobInstances.crew.id}`
              }
              type={"monthschedule"}
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <h6>{crewWithJobInstances.crew.name}</h6>
                  {crewWithJobInstances.jobInstances.map((ji, index) => {
                    const { jobName, job } = getJobName(ji);
                    const saving = jobInstancesMoving.includes(ji.id);

                    return (
                      <Draggable key={ji.id} draggableId={ji.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <JobCard
                              crew={crewWithJobInstances.crew}
                              jobInstance={ji}
                              jobName={jobName}
                              saving={saving}
                              job={job}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </React.Fragment>
        );
      })}

      {totalJobInstanceCount > initialCountToShow ? (
        <LinkButton2
          buttonContents={
            showAll ? `Show first ${initialCountToShow}` : "Show all"
          }
          onClick={() => setShowAll(!showAll)}
        />
      ) : null}
    </>
  );
};

export default React.memo(MonthDay);

function JobCard({
  crew,
  jobInstance,
  jobName,
  saving,
  job,
}: {
  crew: ICrew;
  jobInstance: IJobInstance;
  jobName: string;
  saving: boolean;
  job: IFoundJob | null;
}) {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  return (
    <JobCardContainer
      jobInstance={jobInstance}
      selected={false}
      showAlertBorder={false}
      className="mt-2"
    >
      {() => (
        <>
          <div data-testid="monthDayJobText">
            {crew.scheduleType === CrewScheduleType.time &&
            jobInstance.startTime ? (
              <span className="font-weight-light">
                {formatTimeForCalendaryDisplay(jobInstance.startTime) + " "}
              </span>
            ) : null}
            <span>{jobName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <JobInstanceCategoriesContainer
                visible={(job?.categories.length ?? 0) > 0 || saving}
              >
                {saving ? (
                  <div className="saving">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      fixedWidth={true}
                      title="Saving..."
                    />
                  </div>
                ) : null}

                <JobInstanceCategories
                  jobInstanceId={jobInstance.id}
                  categories={job?.categories ?? []}
                />
              </JobInstanceCategoriesContainer>
            </div>
            {jobInstance.projectId ? (
              <div>{`(${jobInstance.projectJobOrder}/${jobInstance.projectJobCount})`}</div>
            ) : null}
          </div>

          {job ? (
            <ContextMenu
              jobInstance={jobInstance}
              nameForJob={jobName}
              hideSkip={false}
              jobType={job.type}
              hideUpdateCompletionInformation={false}
              crew={crew}
              isContextMenuOpen={isContextMenuOpen}
              setIsContextMenuOpen={setIsContextMenuOpen}
              cardContextMenuSize="sm"
            />
          ) : null}
        </>
      )}
    </JobCardContainer>
  );
}

function getDaySchedule(
  daySchedules: IDaySchedule[],
  crew: ICrew,
  date: string
) {
  return daySchedules.find(
    (ds) => ds.crewId === crew.id && dateService.areDatesEqual(ds.date, date)
  );
}

function getItemStyle(isDragging: boolean, draggableStyle: any) {
  const s: CSSProperties = {
    margin: "0 0 8px 0",
    ...draggableStyle,
  };

  if (isDragging) {
    s.filter = "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.5))";
  }

  return s;
}

function groupBy<T, U>(
  items: Array<T>,
  keyGetter: (t: T) => U
): Map<ICrew, Array<T>> {
  const map = new Map();
  items.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
