import { useRef } from "react";
import { UseFormGetValues, UseFormRegister } from "react-hook-form";
import { IOnboardingFormData } from "./IOnboardingFormData";

interface IProps {
  register: UseFormRegister<IOnboardingFormData>;
  getValues: UseFormGetValues<IOnboardingFormData>;
}

const TermsConditionsTab: React.FunctionComponent<IProps> = ({
  register,
  getValues,
}) => {
  const paymentsPortalPasswordConfirmedRef = useRef<HTMLInputElement | null>(
    null
  );
  const {
    ref: paymentsPortalPasswordConfirmedFormRef,
    ...paymentsPortalPasswordConfirmedRemainingProps
  } = register("paymentsPortalPasswordConfirmed", {
    onChange: (e) => {
      const paymentsPortalPassword = getValues("paymentsPortalPassword");
      const paymentsPortalPasswordConfirmed = e.currentTarget.value;

      const showPasswordMismatchError =
        paymentsPortalPassword.trim() !== "" &&
        paymentsPortalPasswordConfirmed.trim() !== "" &&
        paymentsPortalPassword !== paymentsPortalPasswordConfirmed;

      paymentsPortalPasswordConfirmedRef.current?.setCustomValidity(
        showPasswordMismatchError ? "Passwords do not match" : ""
      );
    },
  });

  const paymentsPortalUsernameRef = useRef<HTMLInputElement | null>(null);
  const {
    ref: paymentsPortalUsernameFormRef,
    ...paymentsPortalUsernameRemainingProps
  } = register("paymentsPortalUsername", {
    setValueAs: (v) => (typeof v === "string" ? v.trim() : v),
    onChange: (e) => {
      const paymentsPortalUsername = e.currentTarget.value.trim();

      const isValidUsername = /^[A-Za-z0-9]+$/.test(paymentsPortalUsername);

      paymentsPortalUsernameRef.current?.setCustomValidity(
        !isValidUsername ? "Username can only contain letters and numbers" : ""
      );
    },
  });

  return (
    <>
      <div className="mb-2">
        Please review and print or save the documents provided in the links
        below. These documents are also accessible from within your account.
      </div>

      <div className="mb-2">
        <div>
          By checking the box below next to "<strong>I agree</strong>" you
          hereby acknowledge and agree as follows:
        </div>

        <div>
          <ul>
            <li>
              I have read, understand, agree and consent to the terms set forth
              at the{" "}
              <a
                href="https://payments.youraspire.com/bank_disclosures"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bank Disclosure Page
              </a>
              ;
            </li>
            <li>
              I have read, understand, agree and consent to the terms set forth
              in the{" "}
              <a
                href="https://payments.youraspire.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Merchant Terms and Conditions
              </a>
              ;
            </li>
            <li>
              I attest that the information provided in this application is true
              and correct to the best of my knowledge; and
            </li>
            <li>
              If you are entering into these terms and conditions on behalf of a
              company or business, you represent and warrant that you are
              authorized by such company or business to bind it to these terms
              and conditions.
            </li>
          </ul>
        </div>

        <div className="custom-control custom-checkbox mb-1">
          <input
            type="checkbox"
            className="custom-control-input"
            id="agreeTermsAndConditions"
            required
            {...register("agreeTermsAndConditions")}
          />
          <label
            className="custom-control-label"
            htmlFor="agreeTermsAndConditions"
          >
            I agree to the information above
          </label>
        </div>

        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="attestInformationCorrect"
            required
            {...register("attestInformationCorrect")}
          />
          <label
            className="custom-control-label"
            htmlFor="attestInformationCorrect"
          >
            I attest that the information provided in this application is
            correct to the best of my knowledge as an authorized signer for this
            business
          </label>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="paymentsPortalUsername" className="required">
          Payments portal username
        </label>
        <input
          type="text"
          className="form-control"
          id="paymentsPortalUsername"
          {...paymentsPortalUsernameRemainingProps}
          ref={(r) => {
            paymentsPortalUsernameFormRef(r);
            paymentsPortalUsernameRef.current = r;
          }}
          required
        />
      </div>

      <div className="form-row">
        <div className="col-12 col-md-6 form-group">
          <label htmlFor="paymentsPortalPassword" className="required">
            Payments portal password
          </label>
          <input
            type="password"
            className="form-control"
            id="paymentsPortalPassword"
            required
            {...register("paymentsPortalPassword")}
          />
        </div>
        <div className="col-12 col-md-6 form-group">
          <label htmlFor="paymentsPortalPasswordConfirmed" className="required">
            Confirm password
          </label>
          <input
            type="password"
            className="form-control"
            id="paymentsPortalPasswordConfirmed"
            required
            {...paymentsPortalPasswordConfirmedRemainingProps}
            ref={(r) => {
              paymentsPortalPasswordConfirmedFormRef(r);
              paymentsPortalPasswordConfirmedRef.current = r;
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TermsConditionsTab;
