import React, { useEffect, useState } from "react";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useIsResolution } from "../../../hooks/useIsResolution";
import { IGetStartedContentState } from "../models/IGetStartedContentState";
import getStartedContentStateDataProvider from "../services/getStartedContentStateDataProvider";
import Spinner from "../../../containers/app/components/Spinner";
import GenericErrorMessage from "../../../containers/app/components/GenericErrorMessage";
import { logError } from "../../../services/errorLogger";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { Modal, ModalHeader, ModalBody } from "reactstrap/lib";
import { Content, getWalkthroughs } from "./GetStartedPage.contents";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { TenantIndustry } from "../../../enums/tenantIndustry";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";

export function GetStartedPage() {
  const {
    loading,
    errorLoading,
    data: getStartedContentState,
    setData: setGetStartedContentState,
  } = useLoadGetStartedContentState();

  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );

  let content: React.ReactNode;
  if (loading) {
    content = <Spinner />;
  } else if (errorLoading) {
    content = <GenericErrorMessage />;
  } else {
    content = (
      <div className="container">
        <div className="d-flex justify-content-between">
          <h1>Let's Get Started!</h1>
        </div>
        <div>
          Learn how Crew Control can streamline your business.
          {isInTrial(tenantSubscriptionStatus) ? (
            <div
              className="d-block d-lg-inline mt-2 ml-lg-1"
              data-testid="salesEmail"
            >
              Got questions? Email{" "}
              <a href="mailto:CrewControlProductTeam@youraspire.com">
                CrewControlProductTeam@youraspire.com
              </a>
            </div>
          ) : null}
        </div>

        <div className="my-4">
          <WalkThroughs
            getStartedContentState={getStartedContentState}
            setGetStartedContentState={setGetStartedContentState}
          />
        </div>

        {/* Commented out until we add setup tasks */}
        {/* <div className="my-4">
          <SetupTasks
            getStartedContentState={getStartedContentState}
            setGetStartedContentState={setGetStartedContentState}
          />
        </div> */}
      </div>
    );
  }

  return <PageWithNavBar2 billingContext={true}>{content}</PageWithNavBar2>;
}

function isInTrial(tenantSubscriptionStatus: TenantSubscriptionStatus) {
  return (
    tenantSubscriptionStatus === TenantSubscriptionStatus.Trial ||
    tenantSubscriptionStatus === TenantSubscriptionStatus.TrialExpired ||
    tenantSubscriptionStatus === TenantSubscriptionStatus.TrialUnavailable
  );
}

function WalkThroughs({
  getStartedContentState,
  setGetStartedContentState,
}: {
  getStartedContentState: Array<IGetStartedContentState>;
  setGetStartedContentState: React.Dispatch<
    React.SetStateAction<Array<IGetStartedContentState>>
  >;
}) {
  const industry = useApplicationStateSelector((s) => s.common.industry);
  const technicianIndustry = isTechnicianIndustry(industry);

  return (
    <>
      <Section
        headerText="Walkthroughs"
        idPrefix="walkthroughs"
        getStartedContentState={getStartedContentState}
        setGetStartedContentState={setGetStartedContentState}
        content={getWalkthroughs().filter(
          (w) =>
            w.audience === "all" ||
            (w.audience === "crew" && !technicianIndustry) ||
            (w.audience === "technician" && technicianIndustry)
        )}
        containerTestId="walkthroughsContainer"
      />
    </>
  );
}

function isTechnicianIndustry(industry: TenantIndustry | null) {
  switch (industry) {
    case TenantIndustry.CleaningMaidServices:
    case TenantIndustry.JanitorialServices:
    case TenantIndustry.Plumbing:
    case TenantIndustry.HVAC:
    case TenantIndustry.Electrical:
    case TenantIndustry.Handyman:
    case TenantIndustry.ChimneySweepHearth:
    case TenantIndustry.GarageDoor:
    case TenantIndustry.Painting:
    case TenantIndustry.Septic:
    case TenantIndustry.ApplianceRepair:
    case TenantIndustry.Roofing:
    case TenantIndustry.Restoration:
    case TenantIndustry.WindowCleaning:
    case TenantIndustry.PressureWashers:
    case TenantIndustry.CarpetCleaning:
    case TenantIndustry.FlooringTiling:
    case TenantIndustry.GlassInstallation:
    case TenantIndustry.SecuritySystems:
    case TenantIndustry.PoolSpa:
      return true;
    default:
      return false;
  }
}

// function SetupTasks({
//   getStartedContentState,
//   setGetStartedContentState,
// }: {
//   getStartedContentState: Array<IGetStartedContentState>;
//   setGetStartedContentState: React.Dispatch<
//     React.SetStateAction<Array<IGetStartedContentState>>
//   >;
// }) {
//   return (
//     <>
//       <Section
//         headerText="Setup Tasks"
//         idPrefix="setupTasks"
//         content={getSetupTasks()}
//         getStartedContentState={getStartedContentState}
//         setGetStartedContentState={setGetStartedContentState}
//         containerTestId="setupTasksContainer"
//       />
//     </>
//   );
// }

function Section({
  headerText,
  idPrefix,
  content,
  getStartedContentState,
  setGetStartedContentState,
  containerTestId,
}: {
  headerText: string;
  idPrefix: string;
  content: Array<Content>;
  getStartedContentState: Array<IGetStartedContentState>;
  setGetStartedContentState: React.Dispatch<
    React.SetStateAction<Array<IGetStartedContentState>>
  >;
  containerTestId: string;
}) {
  const [includeHidden, setIncludeHidden] = useState(false);
  const [page, setPage] = useState(0);
  const isMediumScreenOrLarger = useIsResolution("md");

  const pageSize = isMediumScreenOrLarger ? 4 : 2;

  const availableContent = getAvailableContent({
    content,
    includeHidden,
    getStartedContentState,
  });

  const visibleContent = availableContent.filter(
    (c, index) => index >= page * pageSize && index < (page + 1) * pageSize
  );

  return (
    <>
      <SectionHeader
        headerText={headerText}
        includeHidden={includeHidden}
        onIncludeHiddenChange={(newValue) => {
          setIncludeHidden(newValue);

          // Go to first page so we don't so an empty page that could occur if items that were showing are hidden
          if (!newValue) {
            const newAvailableContentLength = getAvailableContent({
              content,
              includeHidden: newValue,
              getStartedContentState,
            });

            moveToPreviousPageIfCurrentPageEmpty({
              page,
              pageSize,
              setPage,
              availableContent: newAvailableContentLength,
            });
          }
        }}
        idPrefix={idPrefix}
      />

      {availableContent.length === 0 ? (
        <SectionNoContentAvailable />
      ) : (
        <div
          className="d-flex"
          style={{ columnGap: "10px" }}
          data-testid={containerTestId}
        >
          <PagingButton
            onClick={() => {
              setPage(page - 1);
            }}
            icon={faChevronLeft}
            title="Previous page"
            visible={page > 0}
            testId="previousPageButton"
          />

          <div
            className="bg-secondary p-3"
            style={{
              flexGrow: 1,
              display: "grid",
              gridTemplateColumns: `repeat(${pageSize === 4 ? "2" : "1"}, 1fr)`,
              gap: "20px",
              minHeight: "330px",
              gridAutoRows: "1fr",
            }}
          >
            {visibleContent.map((content) => (
              <ContentCard
                key={content.id}
                content={content}
                getStartedContentState={getStartedContentState}
                onHideToggle={(newHiddenValue) => {
                  hideContentOptimistically({
                    content,
                    getStartedContentState,
                    setGetStartedContentState,
                    newHiddenValue,
                  });

                  getStartedContentStateDataProvider
                    .updatetGetStartedContentState(content.id, {
                      hidden: newHiddenValue,
                    })
                    .subscribe({
                      next: () => {
                        // Nothing to do here since update applied optimistically
                      },

                      error: () => {
                        logError("unable to save get started content state");
                      },
                    });

                  moveToPreviousPageIfCurrentPageEmpty({
                    page,
                    pageSize,
                    availableContent,
                    setPage,
                  });
                }}
              />
            ))}

            {/* Ensure that 4 elements are visible in the grid. If only the top row is visible, the elements will stretch and be too tall. */}
            <EmptyContentElements
              pageSize={pageSize}
              visibleContentLength={visibleContent.length}
            />
          </div>

          <PagingButton
            icon={faChevronRight}
            onClick={() => {
              setPage(page + 1);
            }}
            title="Next page"
            visible={(page + 1) * pageSize < availableContent.length}
            testId="nextPageButton"
          />
        </div>
      )}
    </>
  );
}

function getAvailableContent({
  content,
  includeHidden,
  getStartedContentState,
}: {
  content: Content[];
  includeHidden: boolean;
  getStartedContentState: IGetStartedContentState[];
}) {
  return content.filter((content) => {
    if (includeHidden) {
      return true;
    }

    const hidden = isContentHidden({ getStartedContentState, content });

    return !hidden;
  });
}

function moveToPreviousPageIfCurrentPageEmpty({
  page,
  pageSize,
  availableContent,
  setPage,
}: {
  page: number;
  pageSize: number;
  availableContent: Content[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) {
  if (page > 0 && page * pageSize >= availableContent.length - 1) {
    setPage(page - 1);
  }
}

function hideContentOptimistically({
  content,
  getStartedContentState,
  setGetStartedContentState,
  newHiddenValue,
}: {
  content: Content;
  getStartedContentState: IGetStartedContentState[];
  setGetStartedContentState: React.Dispatch<
    React.SetStateAction<IGetStartedContentState[]>
  >;
  newHiddenValue: boolean;
}) {
  const existingStateExists = getStartedContentState.some((s) =>
    areIdsEqual(s, content)
  );
  const newGetStartedContentState = existingStateExists
    ? getStartedContentState.map((s) => {
        if (areIdsEqual(s, content)) {
          return {
            ...s,
            hidden: newHiddenValue,
          };
        } else {
          return s;
        }
      })
    : [
        ...getStartedContentState,
        {
          contentId: content.id,
          hidden: newHiddenValue,
        },
      ];

  setGetStartedContentState(newGetStartedContentState);
}

function PagingButton({
  icon,
  onClick,
  title,
  visible,
  testId,
}: {
  icon: IconProp;
  onClick: () => void;
  title: string;
  visible: boolean;
  testId: string;
}) {
  return (
    <button
      type="button"
      className="link-button"
      data-testid={testId}
      onClick={onClick}
      style={{
        visibility: !visible ? "hidden" : undefined,
      }}
    >
      <FontAwesomeIcon icon={icon} size="2x" title={title} />
    </button>
  );
}

function ContentCard({
  content,
  getStartedContentState,
  onHideToggle,
}: {
  content: Content;
  getStartedContentState: IGetStartedContentState[];
  onHideToggle: (newIsHiddenValue: boolean) => void;
}) {
  const [showLoomVideo, setShowLoomVideo] = useState(false);

  return (
    <>
      <div className="card" key={content.id} data-testid="contentCard">
        <div className="card-body">
          <h5 className="card-titled d-flex justify-content-between">
            <div>
              <LinkButton2
                className="text-left"
                buttonContents={content.header}
                inlineButton
                onClick={() => {
                  fullStoryTrack("Get Started Content Clicked");
                  setShowLoomVideo(true);
                }}
                style={{ fontSize: "1.25rem" }}
                testId="contentHeader"
              />
            </div>

            <div className="ml-2">
              <LinkButton2
                buttonContents={
                  isContentHidden({
                    content: content,
                    getStartedContentState,
                  })
                    ? "Unhide"
                    : "Hide"
                }
                inlineButton
                onClick={() => {
                  onHideToggle(
                    !isContentHidden({
                      content: content,
                      getStartedContentState,
                    })
                  );
                }}
              />
            </div>
          </h5>
          <p className="card-text" data-testid="contentDetails">
            {content.details}
          </p>

          <span
            className="badge badge-pill badge-light px-0"
            data-testid="contentTime"
          >
            {content.lengthInMinutes}{" "}
            {content.lengthInMinutes > 1 ? "minutes" : "minute"}
          </span>
        </div>
      </div>
      {showLoomVideo ? (
        <Modal
          isOpen
          toggle={() => {
            setShowLoomVideo(false);
          }}
          size="lg"
        >
          <ModalHeader
            toggle={() => {
              setShowLoomVideo(false);
            }}
          >
            {content.header}
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
              }}
            >
              <iframe
                title={content.header}
                src={content.loomEmbeddedUrl}
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: 0,
                }}
              ></iframe>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
}

function EmptyContentElements({
  pageSize,
  visibleContentLength,
}: {
  pageSize: number;
  visibleContentLength: number;
}) {
  let emptyElements = [];
  const emptyElementLength = pageSize - visibleContentLength;
  if (emptyElementLength > 0) {
    for (let i = 0; i < emptyElementLength; i++) {
      emptyElements.push({
        id: i,
      });
    }
  }

  return (
    <>
      {emptyElements.map((e) => (
        <div key={e.id} data-testid="emptyCell"></div>
      ))}
    </>
  );
}

function SectionNoContentAvailable() {
  return (
    <div className="alert alert-secondary border">
      You've made it through everything. Congratulations!
    </div>
  );
}

function isContentHidden({
  getStartedContentState,
  content,
}: {
  getStartedContentState: IGetStartedContentState[];
  content: Content;
}) {
  const state = getStartedContentState.find((contentState) =>
    areIdsEqual(contentState, content)
  );

  const hidden = !state ? false : state.hidden;
  return hidden;
}

function areIdsEqual(
  contentState: IGetStartedContentState,
  content: Content
): boolean {
  // case insensitive comparison
  return (
    contentState.contentId.localeCompare(content.id, undefined, {
      sensitivity: "base",
    }) === 0
  );
}

function SectionHeader({
  headerText,
  includeHidden,
  onIncludeHiddenChange,
  idPrefix,
}: {
  headerText: string;
  includeHidden: boolean;
  onIncludeHiddenChange: (newValue: boolean) => void;
  idPrefix: string;
}) {
  const checkboxId = `${idPrefix}_includeHidden`;
  return (
    <div className="d-flex justify-content-between align-items-baseline">
      <h3>{headerText}</h3>
      <div className="custom-control custom-checkbox mr-sm-2 ml-2">
        <input
          id={checkboxId}
          type="checkbox"
          className="custom-control-input"
          checked={includeHidden}
          onChange={(e) => {
            onIncludeHiddenChange(e.currentTarget.checked);
          }}
        />
        <label htmlFor={checkboxId} className="custom-control-label">
          Include hidden
        </label>
      </div>
    </div>
  );
}

function useLoadGetStartedContentState() {
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [data, setData] = useState<Array<IGetStartedContentState>>([]);

  useEffect(() => {
    const subscription = getStartedContentStateDataProvider
      .getGetStartedContentState()
      .subscribe({
        next: (result) => {
          setLoading(false);
          setData(result);
        },

        error: () => {
          setLoading(false);
          setErrorLoading(true);
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  return { loading, errorLoading, data, setData };
}

// function getSetupTasks(): Array<Content> {
//   return [];
// }
