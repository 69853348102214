import React, { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { IJobInstance } from "../../../../../models/IJobInstance";
import { actionCreators } from "../../../../../modules/actionCreators";
import { JobType } from "../../../../../models/IJob";
import { buildFormDataFromJob as buildOneTimeJobFormDataFromJob } from "../../../forms/OneTimeJobForm";
import { buildFormDataFromJob as buildMaintenanceTimeJobFormDataFromJob } from "../../../forms/MaintenanceJobForm";
import jobFinder from "../../../../../services/jobFinder";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import { logError } from "../../../../../services/errorLogger";
import {
  getJobInstanceDeletePromptAction,
  getJobInstanceV2,
} from "../../../../../services/jobInstanceService";
import dateService from "../../../../../services/dateService";
import CardContextMenuEditSubMenu from "../../CardContextMenuEditSubMenu";
import CardContextMenuButtonLink from "../../CardContextMenuButtonLink";
import { IMaintenanceJob } from "../../../../../models/IMaintenanceJob";
import { useHideChildrenIfOtherSubmenusShown } from "../../../../../hooks/useHideChildrenIfOtherSubmenuShown";
import useIsAdmin from "../../../../../hooks/useIsAdmin";
import { useIsProjectBillingJobInstance } from "../../../../../slices/schedule/hooks/useIsProjectBillingJobInstance";
import { ICrew } from "../../../../../models/ICrew";
import { CrewScheduleType } from "../../../../../slices/schedule/enums/crewScheduleType";
import { useGetCustomerFromStore } from "../../../../../hooks/useGetCustomerFromStore";
import { isStringSet } from "../../../../../services/stringService";
import { useUserSettings } from "../../../../../services/userSettingsService";

interface IProps {
  jobInstance: IJobInstance;
  crew: ICrew | null;
  showDetailsLink: boolean;
  hideSkip?: boolean;
  hideUpdateCompletionInformation?: boolean;
  nameForJob: string;
  jobType: JobType;
  skipContextMenuCloseClass: string;
  setShowProjectForm(newValue: boolean): void;
  setShowJobInstanceTimeForm(newValue: boolean): void;
  setShowDetails(newValue: boolean): void;
  setShowCompletionInfoForm(newValue: boolean): void;
}

const ContextMenuButtons: React.FunctionComponent<IProps> = ({
  jobInstance,
  crew,
  showDetailsLink,
  hideSkip,
  hideUpdateCompletionInformation,
  nameForJob,
  jobType,
  skipContextMenuCloseClass,
  setShowProjectForm,
  setShowJobInstanceTimeForm,
  setShowDetails,
  setShowCompletionInfoForm,
}) => {
  const dispatch = useDispatch();
  const { getUserSettings } = useUserSettings();
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const crewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );

  const [showAddChildren, setShowAddChildren] = useState(false);
  const [showEditChildren, setShowEditChildren] = useState(false);
  const [showCopyChildren, setShowCopyChildren] = useState(false);
  const isAdmin = useIsAdmin();
  const isProjectBilling = useIsProjectBillingJobInstance(
    jobInstance.projectId
  );
  const isMultiVisitSeries = !isProjectBilling && jobInstance.projectId;

  const editAndCopy = useMemo(
    () => [setShowEditChildren, setShowCopyChildren],
    [setShowEditChildren, setShowCopyChildren]
  );

  const addAndEdit = useMemo(
    () => [setShowAddChildren, setShowEditChildren],
    [setShowAddChildren, setShowEditChildren]
  );

  const addAndCopy = useMemo(
    () => [setShowAddChildren, setShowCopyChildren],
    [setShowAddChildren, setShowCopyChildren]
  );

  useHideChildrenIfOtherSubmenusShown(showEditChildren, addAndCopy);
  useHideChildrenIfOtherSubmenusShown(showCopyChildren, addAndEdit);
  useHideChildrenIfOtherSubmenusShown(showAddChildren, editAndCopy);

  const foundJob = jobFinder.getJobForDayScheduleV3(
    jobInstance.id,
    maintenanceJobs,
    oneTimeJobs,
    daySchedules,
    weeksUnscheduledMaintenanceJobs
  );

  const customer = useGetCustomerFromStore(foundJob?.customerId ?? null);
  const isTextingAvailable =
    customer?.phoneNumberOptedIntoSms && isStringSet(customer.phoneNumber);

  if (!foundJob) {
    // Should never happen so not worrying about handling more elegantly
    logError(`ContextMenuButtons - unable to find job for ${jobInstance.id}`);
  }

  return (
    <React.Fragment>
      <div className="context-menu-buttons">
        {isMultiVisitSeries ? (
          <CardContextMenuEditSubMenu
            text="Add"
            skipContextMenuCloseClass={skipContextMenuCloseClass}
            showChildren={showAddChildren}
            setShowChildren={setShowAddChildren}
          >
            <div className="context-menu-buttons">
              <p>
                <CardContextMenuButtonLink
                  buttonContents="Visit"
                  onClick={() => {
                    if (foundJob) {
                      const foundJobInstance = getJobInstanceV2(
                        daySchedules,
                        weeksUnscheduledMaintenanceJobs,
                        jobInstance.id
                      );
                      let crewId: string =
                        crews.filter((c) => !c.inactive)?.[0]?.id ?? "";
                      let flexibleJob: boolean = false;
                      if (foundJobInstance.dayScheduleForJobInstance) {
                        crewId =
                          foundJobInstance.dayScheduleForJobInstance.crewId;
                        flexibleJob = false;
                      } else if (
                        foundJobInstance.weekUnscheduledMaintenanceJobsForJobInstance
                      ) {
                        flexibleJob = true;
                      }

                      let defaultFormDataForOneTimeJob =
                        buildOneTimeJobFormDataFromJob({
                          job: foundJob,
                          crewId,
                          date: "",
                          flexibleJob,
                          crewCategories,
                          clearCollectionIds: true,
                          projectId: jobInstance.projectId,
                        });

                      // Clear photo IDs so they're saved properly.  Otherwise, server-side will treat them as updates.
                      defaultFormDataForOneTimeJob.photos =
                        defaultFormDataForOneTimeJob.photos.map((p) => ({
                          ...p,
                          id: null,
                        }));

                      dispatch(
                        actionCreators.forms.oneTimeJob.showForm({
                          headerOverride: `Add Visit for ${customer?.name}`,
                          defaultFormData: defaultFormDataForOneTimeJob,
                        })
                      );
                    }
                  }}
                />
              </p>
            </div>
          </CardContextMenuEditSubMenu>
        ) : null}
        {showDetailsLink ? (
          <p>
            <CardContextMenuButtonLink
              buttonContents="Details"
              onClick={() => {
                setShowDetails(true);
              }}
            />
          </p>
        ) : null}
        <CardContextMenuEditSubMenu
          text="Edit"
          skipContextMenuCloseClass={skipContextMenuCloseClass}
          showChildren={showEditChildren}
          setShowChildren={setShowEditChildren}
        >
          <div className="context-menu-buttons">
            <p>
              <CardContextMenuButtonLink
                buttonContents="Customer"
                onClick={() => {
                  if (foundJob) {
                    dispatch(
                      actionCreators.forms.customer.showForm({
                        customerId: foundJob.customerId,
                      })
                    );
                  }
                }}
              />
            </p>
            {foundJob?.customerAdditionalLocationId && (
              <p>
                <CardContextMenuButtonLink
                  buttonContents="Job Location"
                  onClick={() => {
                    if (foundJob) {
                      dispatch(
                        actionCreators.forms.customerAdditionalLocation.showForm(
                          {
                            customerAdditionalLocationId:
                              foundJob.customerAdditionalLocationId,
                          }
                        )
                      );
                    }
                  }}
                />
              </p>
            )}
            {isProjectBilling ? (
              <p>
                <CardContextMenuButtonLink
                  buttonContents="Project"
                  onClick={() => {
                    setShowProjectForm(true);
                  }}
                />
              </p>
            ) : null}
            <p>
              <CardContextMenuButtonLink
                buttonContents={
                  jobInstance.jobId
                    ? "Recurring Job"
                    : isProjectBilling
                    ? "Visit"
                    : "Job"
                }
                onClick={() =>
                  jobInstance.jobId
                    ? dispatch(
                        actionCreators.forms.maintenanceJob.showForm({
                          maintenanceJobId: jobInstance.jobId,
                        })
                      )
                    : dispatch(
                        actionCreators.forms.oneTimeJob.showForm({
                          oneTimeJobId: jobInstance.id,
                        })
                      )
                }
              />
            </p>
            {crew?.scheduleType === CrewScheduleType.time &&
            jobType === JobType.maintenanceJob ? (
              <p>
                <CardContextMenuButtonLink
                  buttonContents={`Job Card Times`}
                  onClick={() => setShowJobInstanceTimeForm(true)}
                />
              </p>
            ) : null}
            <p>
              <CardContextMenuButtonLink
                buttonContents="Job Card Notes"
                onClick={() =>
                  dispatch(
                    actionCreators.forms.crewNotes.showForm({
                      jobInstanceId: jobInstance.id,
                    })
                  )
                }
                className="notes-for-crew"
              />
            </p>
            {!hideUpdateCompletionInformation && (
              <p>
                <CardContextMenuButtonLink
                  buttonContents="Completion Info"
                  onClick={() => setShowCompletionInfoForm(true)}
                  className="set-time-taken"
                />
              </p>
            )}
            {isAdmin ? (
              <p>
                <CardContextMenuButtonLink
                  buttonContents="Billing Details"
                  onClick={() =>
                    dispatch(
                      actionCreators.forms.billingDetails.showForm({
                        jobInstanceId: jobInstance.id,
                      })
                    )
                  }
                />
              </p>
            ) : null}
          </div>
        </CardContextMenuEditSubMenu>
        <p>
          <CardContextMenuButtonLink
            buttonContents="Move"
            onClick={() =>
              dispatch(
                actionCreators.forms.moveJobInstance.showForm({
                  jobInstanceIds: [jobInstance.id],
                })
              )
            }
          />
        </p>
        {jobInstance.complete ? null : (
          <Fragment>
            {!hideSkip ? (
              <p>
                <CardContextMenuButtonLink
                  buttonContents={jobInstance.skipped ? "Unskip" : "Skip"}
                  onClick={() =>
                    dispatch(
                      actionCreators.jobInstanceToggleSkippedStart(
                        [jobInstance.id],
                        !jobInstance.skipped,
                        false
                      )
                    )
                  }
                  className="toggle-skip"
                />
              </p>
            ) : null}
          </Fragment>
        )}
        <CardContextMenuEditSubMenu
          text="Copy"
          skipContextMenuCloseClass={skipContextMenuCloseClass}
          showChildren={showCopyChildren}
          setShowChildren={setShowCopyChildren}
        >
          <div className="context-menu-buttons">
            <p>
              <CardContextMenuButtonLink
                buttonContents="Single Job"
                onClick={() => {
                  if (foundJob) {
                    const foundJobInstance = getJobInstanceV2(
                      daySchedules,
                      weeksUnscheduledMaintenanceJobs,
                      jobInstance.id
                    );
                    let crewId: string =
                      crews.filter((c) => !c.inactive)?.[0]?.id ?? "";
                    let date: string = "";
                    let flexibleJob: boolean = false;
                    if (foundJobInstance.dayScheduleForJobInstance) {
                      crewId =
                        foundJobInstance.dayScheduleForJobInstance.crewId;
                      date = foundJobInstance.dayScheduleForJobInstance.date;
                      flexibleJob = false;
                    } else if (
                      foundJobInstance.weekUnscheduledMaintenanceJobsForJobInstance
                    ) {
                      date = dateService.formatAsIso(
                        foundJobInstance
                          .weekUnscheduledMaintenanceJobsForJobInstance.week
                      );
                      flexibleJob = true;
                    }

                    let defaultFormDataForOneTimeJob =
                      buildOneTimeJobFormDataFromJob({
                        job: foundJob,
                        crewId,
                        date,
                        flexibleJob,
                        crewCategories,
                        clearCollectionIds: true,
                      });

                    // Clear photo IDs so they're saved properly.  Otherwise, server-side will treat them as updates.
                    defaultFormDataForOneTimeJob.photos =
                      defaultFormDataForOneTimeJob.photos.map((p) => ({
                        ...p,
                        id: null,
                      }));

                    dispatch(
                      actionCreators.forms.oneTimeJob.showForm({
                        headerOverride: "Copy Job Card",
                        defaultFormData: defaultFormDataForOneTimeJob,
                      })
                    );
                  }
                }}
              />
            </p>
            {foundJob?.type === JobType.maintenanceJob ? (
              <p>
                <CardContextMenuButtonLink
                  buttonContents="Recurring Job"
                  onClick={() => {
                    if (foundJob) {
                      let defaultFormDataForMaintenanceJob =
                        buildMaintenanceTimeJobFormDataFromJob({
                          job: foundJob as unknown as IMaintenanceJob,
                          crewCategories,
                          getUserSettings,
                          clearCollectionIds: true,
                        });

                      // Clear photo IDs so they're saved properly.  Otherwise, server-side will treat them as updates.
                      defaultFormDataForMaintenanceJob.photos =
                        defaultFormDataForMaintenanceJob.photos.map((p) => ({
                          ...p,
                          id: null,
                        }));

                      dispatch(
                        actionCreators.forms.maintenanceJob.showForm({
                          headerOverride: "Copy Recurring Job",
                          defaultFormData: defaultFormDataForMaintenanceJob,
                        })
                      );
                    }
                  }}
                />
              </p>
            ) : null}
          </div>
        </CardContextMenuEditSubMenu>
        {isTextingAvailable ? (
          <p>
            <CardContextMenuButtonLink
              buttonContents="Text"
              onClick={() =>
                dispatch(
                  actionCreators.forms.customerNotification.showForm({
                    jobInstanceIds: [jobInstance.id],
                  })
                )
              }
            />
          </p>
        ) : null}
        {!jobInstance.complete ? (
          <p>
            <CardContextMenuButtonLink
              buttonContents="Remove"
              onClick={() =>
                dispatch(
                  getJobInstanceDeletePromptAction({
                    promptMessage: `Are you sure you want to remove ${nameForJob}'s job?`,
                    markMaintenanceJobsInactiveSubMessage:
                      jobType === JobType.maintenanceJob
                        ? [
                            {
                              text: "This will remove all future job visits for this recurring job from ",
                            },
                            {
                              text: `${dateService.formatDateForDisplay(
                                dateService.getCurrentDate()
                              )} `,
                              bold: true,
                            },
                            {
                              text: "onward for this customer but keep any visits in the past for historical reporting.",
                            },
                          ]
                        : null,
                    hasMaintenanceJob: jobType === JobType.maintenanceJob,
                    jobInstanceIds: [jobInstance.id],
                  })
                )
              }
            />
          </p>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default ContextMenuButtons;
