import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap/lib";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { IPromptMessageSection } from "../../../modules/prompt";
import Spinner from "./Spinner";

interface IProps {
  showPrompt: boolean;
  promptMessage: string | JSX.Element;
  promptSaveText?: string | null;
  promptCancelText?: string | null;
  promptSubMessage?: string | null | Array<IPromptMessageSection>;
  promptSubMessageClassName?: string;
  secondaryButtons?: Array<ISecondaryButton> | null;
  onConfirm: () => void;
  onCancel: () => void;
  showSpinner?: boolean;
  fadeInModal?: boolean;
}

interface ISecondaryButton {
  text: string;
  onClick(): void;
}

const Prompt: React.FunctionComponent<IProps> = ({
  showPrompt,
  promptMessage,
  promptSaveText,
  promptCancelText,
  promptSubMessage,
  promptSubMessageClassName,
  onConfirm,
  onCancel,
  secondaryButtons,
  showSpinner,
  fadeInModal,
}) => {
  const [expandButtons, setExpandButtons] = useState(false);

  const primarySaveButton = (
    <button
      className="btn btn-primary"
      type="button"
      onClick={() => {
        onConfirm();
      }}
      data-testid="promptSave"
    >
      {promptSaveText || "Yes"}
    </button>
  );

  let saveButtons: JSX.Element;
  if (!showButtonDropdown()) {
    saveButtons = (
      <>
        {primarySaveButton}
        {!!secondaryButtons
          ? secondaryButtons.map((b) => (
              <div
                key={b.text}
                className="btn btn-secondary"
                onClick={b.onClick}
              >
                {b.text}
              </div>
            ))
          : null}
      </>
    );
  } else if (!!secondaryButtons && secondaryButtons.length > 0) {
    saveButtons = (
      <ButtonDropdown
        direction="down"
        isOpen={expandButtons}
        toggle={() => setExpandButtons(!expandButtons)}
      >
        {primarySaveButton}
        <DropdownToggle split color="primary" data-testid="buttonToggle" />
        <DropdownMenu positionFixed={true} right={true}>
          {secondaryButtons.map((secondaryButton) => (
            <DropdownItem
              key={secondaryButton.text}
              onClick={(e) => {
                e.preventDefault();
                secondaryButton.onClick();
              }}
            >
              {secondaryButton.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  } else {
    saveButtons = primarySaveButton;
  }

  return (
    <Modal
      isOpen={showPrompt}
      backdrop="static"
      toggle={onCancel}
      fade={fadeInModal}
    >
      <ModalBody>
        {showSpinner ? <Spinner /> : null}

        <div style={{ whiteSpace: "pre-line" }}>{promptMessage}</div>
        {!!promptSubMessage ? (
          <div
            style={{ marginTop: "10px" }}
            className={promptSubMessageClassName}
          >
            <small>
              {typeof promptSubMessage === "string"
                ? promptSubMessage
                : promptSubMessage.map((m, index) => {
                    if (m.bold) {
                      return (
                        <strong
                          className={getSubMessageClassName(m)}
                          key={index}
                        >
                          {m.text}
                        </strong>
                      );
                    } else {
                      return (
                        <span className={getSubMessageClassName(m)} key={index}>
                          {m.text}
                        </span>
                      );
                    }
                  })}
            </small>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        {saveButtons}
        <button className="btn btn-secondary" onClick={onCancel} type="button">
          {promptCancelText || "No"}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default Prompt;

export function showButtonDropdown() {
  return isMobileOnly;
}

function getSubMessageClassName(m: IPromptMessageSection) {
  let className = "";

  if (m.dangerColor) {
    className += " text-danger";
  }

  if (m.newBlock) {
    className += " d-block";
  }

  return className;
}
