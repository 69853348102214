import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import constants from "../../../constants";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { parseTimeZone, TimeZone } from "../../../models/TimeZone";
import { ICommonState } from "../../../modules/common";
import addressFormatter from "../../../services/addressFormatter";
import AddressComponents2, {
  IAddressComponents,
} from "../components/AddressComponents2";
import conditionalRenderer from "../components/conditionalRenderer";
import FormContainer2 from "../components/FormContainer2";
import TimeZoneSelect from "../components/TimeZoneSelect";
import modalConversion from "../../../services/modelConversion";
import CompanyProfileFormLogoSelect from "./CompanyProfileFormLogoSelect";
import Spinner from "../components/Spinner";
import ImageWithDeleteOverlay from "../components/ImageWithDeleteOverlay";
import { ICompanyLogo } from "../../../models/ICompanyProfile";
import PhoneNumberField from "../components/PhoneNumberField";

interface IFormData {
  tenantName: string;
  timeZone: TimeZone;
  contactPhoneNumber: string;
  companyLogo: ICompanyLogo | null;
  emailAddress: string;

  address: IAddressComponents;
}

const CompanyProfileForm: React.FunctionComponent<{}> = () => {
  const common = useApplicationStateSelector((s) => s.common);
  const addressStreetAndNumberRef = useRef<HTMLInputElement>(null);

  const [uploadingLogo, setUploadingLogo] = useState(false);

  const originalFormData = useRef<IFormData>(buildDefaultValues(common));
  const { control, register, getValues, watch, setValue } = useForm<IFormData>({
    defaultValues: buildDefaultValues(common),
  });

  const companyLogo = watch("companyLogo");

  return (
    <FormContainer2
      formHeader="Company Profile"
      formType="companyProfile"
      getFormData={() => {
        const sourceAddress = getValues("address");
        const formData = {
          ...getValues(),
          ...sourceAddress,
          latitude: modalConversion.convertStringToNumberOrNull(
            sourceAddress.latitude
          ),
          longitude: modalConversion.convertStringToNumberOrNull(
            sourceAddress.longitude
          ),
        } as any;

        // Address fields are added as top-level properties
        delete formData.address;
        // Removing places property as its not currently support for company profiles.
        delete formData.placeId;

        return formData;
      }}
      hasFormDataChanged={() => {
        return (
          JSON.stringify(getValues()) !==
          JSON.stringify(originalFormData.current)
        );
      }}
    >
      {uploadingLogo ? <Spinner /> : null}

      <div className="form-group">
        <label htmlFor="companyName" className="required">
          Company name
        </label>
        <input
          type="text"
          className="form-control"
          id="companyName"
          {...register("tenantName")}
          required={true}
          maxLength={constants.companyNameMaxLength}
        />
      </div>

      <div className="form-group">
        <div>
          <label>Company logo</label>
        </div>

        {companyLogo ? (
          <div className="mb-4">
            <ImageWithDeleteOverlay
              src={`${common.imagePrefix}/${encodeURIComponent(
                companyLogo.path
              )}`}
              height={companyLogo.height}
              width={companyLogo.width}
              alt="Company Logo"
              onDelete={() => setValue("companyLogo", null)}
            />
          </div>
        ) : null}

        <CompanyProfileFormLogoSelect
          onFileUploaded={(v) => {
            setValue("companyLogo", v);
          }}
          setFileUploading={setUploadingLogo}
        />
      </div>

      <div className="form-group">
        <label htmlFor="contactPhoneNumber" className="required">
          Contact phone number
        </label>

        <Controller
          name="contactPhoneNumber"
          control={control}
          render={({ field }) => (
            <PhoneNumberField
              id="contactPhoneNumber"
              className="form-control"
              required
              {...field}
            />
          )}
        />
      </div>

      <div className="form-group">
        <label htmlFor="emailAddress" className="required">
          Contact email address
        </label>

        <input
          type="email"
          className="form-control"
          id="emailAddress"
          {...register("emailAddress")}
          required={true}
        />
      </div>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <AddressComponents2
            streetAndNumberId="profileAddress"
            streetAndNumberName="profileAddress"
            streetAndNumberRef={addressStreetAndNumberRef}
            value={field.value}
            hideCoordinates={true}
            onChange={(newAddress) => {
              field.onChange(newAddress);
            }}
          />
        )}
      />

      <div className="form-group">
        <label htmlFor="timeZone" className="required">
          Time zone
        </label>
        <Controller
          name="timeZone"
          control={control}
          render={({ field }) => (
            <TimeZoneSelect
              className="form-control"
              id="timeZone"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>

      <div>
        {/* Note: This link only works when hosted and not running in dev mode due to rewrite rules */}
        <a
          href="/static/js/LICENSE.txt"
          target="_blank"
          rel="noopener noreferrer"
        >
          <small>View open source licenses</small>
        </a>
      </div>
    </FormContainer2>
  );
};

export default conditionalRenderer(
  CompanyProfileForm,
  (s) => s.forms.companyProfile.showForm
);

function buildDefaultValues(common: ICommonState): IFormData {
  return {
    tenantName: common.tenantName ?? "",
    timeZone: parseTimeZone(common.timeZone),
    contactPhoneNumber: common.contactPhoneNumber,
    emailAddress: common.emailAddress,
    companyLogo: common.companyLogo,
    address: {
      streetAndNumber: common.streetAndNumber ?? "",
      apartmentSuite: "",
      city: common.city ?? "",
      state: common.state ?? "",
      zip: common.zip ?? "",
      latitude: common.latitude
        ? addressFormatter.formatLatLng(common.latitude, null)
        : "",
      longitude: common.longitude
        ? addressFormatter.formatLatLng(common.longitude, null)
        : "",
      latitudeSignificantDigits: null,
      longitudeSignificantDigits: null,
    },
  };
}
