import React, { useState } from "react";
import { builders as routerBuilders } from "../../../../services/routing";
import { RouteComponentProps } from "react-router-dom";
import { actionCreators as formActionCreators } from "../../../../formGenerator";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { getNormalizedPhoneNumber } from "../../../../services/phoneNumberService";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { actionCreators } from "../../../../modules/actionCreators";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import TabContentContainer from "../../components/DetailsPageTabContentContainer";
import CrewMemberPayrollHistory from "./CrewMemberPayrollHistory";
import RecordDetails from "../../components/RecordDetails";
import ErrorJumbotron from "../../components/ErrorJumbotron";
import PageWithNavBar2 from "../../PageWithNavBar2";
import { NoteWithModal } from "../../components/NoteWithModal";
import { isStringSet } from "../../../../services/stringService";
import useSalesEnabled from "../../../../slices/sales/hooks/useSalesEnabled";
import { useIsResolution } from "../../../../hooks/useIsResolution";
import MobileDetailsPageTabContents from "../../components/MobileDetailsPageTabContents";
import { ICrewMember } from "../../../../models/ICrewMember";
import { CrewMemberSendAppInstructions } from "../../components/CrewMemberSendAppInstructions";
import LinkButton2 from "../../components/LinkButton2";

export interface IRouteParams {
  crewMemberId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

const noteFieldStyle = {
  maxWidth: "500px",
};
const CrewMemberDetails: React.FunctionComponent<IProps> = ({ match }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const { crewMemberId } = match.params;
  const crewMembers = useApplicationStateSelector((s) => s.crew.crewMembers);
  const crewMember = crewMembers.find((cm) => cm.id === crewMemberId);

  const normalizedPhoneNumber = getNormalizedPhoneNumber(
    crewMember?.phoneNumber ?? ""
  );
  const noneText = "None";
  const salesEnabled = useSalesEnabled();

  const isDesktopView = useIsResolution("lg");

  return crewMember ? (
    <RecordDetails
      backLinkText="Crew Members"
      backLinkUrl={routerBuilders.manage.buildCrewMembersRoute()}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      clearErrorMessage={() => setErrorMessage("")}
      recordName={crewMember.name}
      recordTypeForActions="crew member"
      getEditAction={() =>
        formActionCreators.crewMember.showForm({
          crewMemberId: match.params.crewMemberId,
        })
      }
      deleteFunction={() => remoteDataProvider.deleteCrewMember(crewMemberId)}
      getDeleteCompleteAction={() =>
        actionCreators.crewMemberDeleteComplete(crewMemberId)
      }
      deleteRedirectRoute={routerBuilders.manage.buildCrewMembersRoute()}
      labelFieldRows={[
        {
          key: "0",
          fields: [
            {
              label: "Phone",
              value: !normalizedPhoneNumber ? (
                noneText
              ) : (
                <a
                  href={"tel:" + normalizedPhoneNumber}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {normalizedPhoneNumber}
                </a>
              ),
            },
            {
              label: "Email",
              value: !crewMember.emailAddress ? (
                noneText
              ) : (
                <a href={"mailto:" + crewMember.emailAddress}>
                  {crewMember.emailAddress}
                </a>
              ),
            },
            {
              label: "Status",
              value: crewMember.inactive ? "Inactive" : "Active",
            },
            {
              label: "On-site Invoicing",
              value: crewMember.allowOnsiteInvoicing ? "Yes" : "No",
              testId: "OnSiteInvoicing",
            },
            {
              label: "On-site estimating",
              value: crewMember.allowOnsiteEstimating ? "Yes" : "No",
              hidden: !salesEnabled,
              testId: "OnSiteEstimating",
            },
            {
              label: "On-the-way notification",
              value: crewMember.allowOnTheWayNotification ? "Yes" : "No",
              testId: "OnTheWayNotification",
            },
            {
              label: "Mobile application",
              value: <MobileApplicationIndicator crewMember={crewMember} />,
              testId: "MobileApplication",
            },
            {
              label: "Notes",
              value: <NoteWithModal text={crewMember.notes ?? ""} />,
              hidden: !isStringSet(crewMember.notes),
              style: noteFieldStyle,
            },
          ],
        },
      ]}
    >
      {isDesktopView ? (
        <TabContent activeTab="1">
          <TabPane tabId="1">
            <TabContentContainer>
              <CrewMemberPayrollHistory
                crewMemberId={crewMemberId}
                onError={(error) => setErrorMessage(error)}
                onErrorClear={() => setErrorMessage("")}
              />
            </TabContentContainer>
          </TabPane>
        </TabContent>
      ) : (
        <>
          <MobileDetailsPageTabContents>
            <hr />
            <CrewMemberPayrollHistory
              crewMemberId={crewMemberId}
              onError={(error) => setErrorMessage(error)}
              onErrorClear={() => setErrorMessage("")}
            />
          </MobileDetailsPageTabContents>
        </>
      )}
    </RecordDetails>
  ) : (
    <PageWithNavBar2 billingContext={true} notFluid={true}>
      <ErrorJumbotron details="Crew member not found." />
    </PageWithNavBar2>
  );
};

export default CrewMemberDetails;

function MobileApplicationIndicator({
  crewMember,
}: {
  crewMember: ICrewMember;
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      {crewMember.allowMobileApplication ? "Yes" : "No"}

      {crewMember.allowMobileApplication ? (
        <>
          {" "}
          <LinkButton2
            inlineButton
            buttonContents={"(send instructions)"}
            onClick={() => setShowConfirmation(true)}
          />
        </>
      ) : null}

      {showConfirmation ? (
        <CrewMemberSendAppInstructions
          crewMemberId={crewMember.id}
          crewMemberName={crewMember.name}
          onClose={() => {
            setShowConfirmation(false);
          }}
          cancelButton="Cancel"
        />
      ) : null}
    </>
  );
}
