import { ICategoryIdentifier } from "./ICategoryIdentifier";
import { ITimeRange } from "./ITimeRange";
import { ICrewMemberIdentifier } from "./ICrewMemberIdentifier";

export interface IJobHistory {
  jobInstances: Array<IJobHistoryInstance>;
}

export interface IJobHistoryInstance {
  id: string;
  additionalLocationId: string | null;
  categories: Array<ICategoryIdentifier>;
  date: string;
  timeRanges: Array<ITimeRange>;
  grossRevenuePerVisit: number | null;
  actualCrewMembers: number | null;
  estimatedManHours: number | null;
  showNotesLink: boolean;
  crewId: string | null;
  isInWeeksFlexibleJobsHolder: boolean;
  crewMembers: Array<ICrewMemberIdentifier>;
  scheduledStartTime: string | null;
  scheduledEndTime: string | null;
  status: JobHistoryStatus;
}

export enum JobHistoryStatus {
  InProgress = 1,
  Paused = 2,
  Skipped = 3,
  Completed = 4,
}
