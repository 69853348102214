import React, { useEffect } from "react";
import {
  IAddressForInvoiceForCustomer,
  IAddressForInvoiceForTenant,
  IInvoiceForPrint,
  ILineItemForInvoiceForCustomer,
} from "../../../models/IInvoiceForPrint";
import { formatCurrency } from "../../../services/currencyFormatter";
import dateService from "../../../services/dateService";
import { isStringSet } from "../../../services/stringService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrintContainer } from "../../../containers/app/components/PrintContainer";
import { PrintImages } from "../../../containers/app/components/PrintImages";

interface IProps {
  invoice: IInvoiceForPrint;
  onLoadComplete: () => void;
}

const InvoicePrint: React.FunctionComponent<IProps> = ({
  invoice,
  onLoadComplete,
}) => {
  useEffect(() => {
    if (!invoice.logo) {
      onLoadComplete();
    }
  }, [invoice, onLoadComplete]);

  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);

  return (
    <PrintContainer printTypeTitleCased="Invoice" printTypeLowerCased="invoice">
      <>
        <div>
          <div className="billing-item-print-header billing-item-print-content">
            <div>
              <div data-testid="tenantName">{invoice.tenantName}</div>
              <div data-testid="tenantContactPhone">
                {invoice.tenantContactPhoneNumber}
              </div>
              <div data-testid="tenantEmailAddress">
                {invoice.tenantEmailAddress}
              </div>
              <Address testPrefix="tenant" address={invoice.tenantAddress} />
            </div>
            <div className="text-center">
              {invoice.logo ? (
                <img
                  src={invoice.logo.imageUrl}
                  width={invoice.logo.width}
                  height={invoice.logo.height}
                  alt="Company Logo"
                  onLoad={() => {
                    if (onLoadComplete) {
                      onLoadComplete();
                    }
                  }}
                  onError={() => {
                    if (onLoadComplete) {
                      onLoadComplete();
                    }
                  }}
                />
              ) : null}
            </div>
            <div className="text-right">
              {invoice.number ? (
                <div data-testid="number">Invoice #: {invoice.number}</div>
              ) : null}
              {invoice.purchaseOrderNumber ? (
                <div data-testid="purchaseOrderNumber">
                  PO #: {invoice.purchaseOrderNumber}
                </div>
              ) : null}
              <div data-testid="date">
                Invoice date: {dateService.formatDateForDisplay(invoice.date)}
              </div>
              {isStringSet(invoice.dueDate) ? (
                <div data-testid="dueDate">
                  Due date: {dateService.formatDateForDisplay(invoice.dueDate)}
                </div>
              ) : null}

              {isStringSet(invoice.datePaid) ? (
                <h4
                  className="mt-3 text-success text-right"
                  data-testid="paidIndicator"
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                  Invoice Paid!
                </h4>
              ) : null}
            </div>
          </div>

          <div className="d-flex">
            <div className="border billing-item-print-box">
              <div className="p-2">
                <h6 style={{ margin: 0 }}>Bill to:</h6>
                <div data-testid="customerName">{invoice.customerName}</div>
                <Address
                  testPrefix="customer"
                  address={invoice.customerAddress}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="mt-4 mb-5">
              {invoice.introTextHtml ? (
                <div
                  data-testid="introText"
                  dangerouslySetInnerHTML={{
                    // HTML was safely generated on server
                    __html: invoice.introTextHtml,
                  }}
                ></div>
              ) : null}

              {isStringSet(invoice.summary) ? (
                <div
                  className="mt-5"
                  data-testid="invoiceSummaryContainer"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {invoice.summary}
                </div>
              ) : null}
            </div>

            {invoice.locationsWithLineItems.map((l) => {
              let header = "";
              if (isStringSet(l.name) && isStringSet(l.address)) {
                header = `${l.name} - ${l.address}`;
              } else if (isStringSet(l.name)) {
                header = l.name;
              } else if (isStringSet(l.address)) {
                header = l.address;
              }

              const isOnlyCustomerLocationLineItems =
                invoice.locationsWithLineItems.length === 1 &&
                invoice.locationsWithLineItems[0]
                  .customerAdditionalLocationId === null;
              return (
                <div className="mt-3 mb-4" key={l.customerAdditionalLocationId}>
                  {!isOnlyCustomerLocationLineItems ? (
                    <>
                      <h5
                        data-testid="lineItemHeader"
                        className="font-weight-normal mb-3"
                      >
                        {header}
                      </h5>
                    </>
                  ) : null}

                  <LineItems lineItems={l.lineItems} invoice={invoice} />
                </div>
              );
            })}

            <div className="mt-3 d-flex justify-content-end">
              <table>
                <tbody>
                  <tr>
                    <td>Subtotal: </td>
                    <td className="pl-3 text-right" data-testid="subtotal">
                      {formatCurrency(invoice.subtotal)}
                    </td>
                  </tr>
                  {typeof invoice.discountAmount === "number" &&
                  invoice.discountAmount !== 0 ? (
                    <tr data-testid="discountAmountContainer">
                      <td>Discount: </td>
                      <td
                        className="pl-3 text-right"
                        data-testid="discountAmount"
                      >
                        ({formatCurrency(invoice.discountAmount)})
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Taxes: </td>
                    <td className="pl-3 text-right" data-testid="taxAmount">
                      {formatCurrency(invoice.taxAmount ?? 0)}
                    </td>
                  </tr>
                  {invoice.balance > 0 &&
                  (invoice.depositCreditAmount > 0 ||
                    invoice.previousPayments > 0) ? (
                    <>
                      {(invoice.taxAmount ?? 0) > 0 ? (
                        <tr>
                          <td>Total after taxes:</td>
                          <td
                            className="pl-3 text-right"
                            data-testid="totalAfterTaxes"
                          >
                            {formatCurrency(invoice.totalAmount)}
                          </td>
                        </tr>
                      ) : null}
                      {invoice.depositCreditAmount > 0 ? (
                        <tr>
                          <td>Prepaid deposit:</td>
                          <td className="pl-3 text-right" data-testid="deposit">
                            {"(" +
                              formatCurrency(invoice.depositCreditAmount ?? 0) +
                              ")"}
                          </td>
                        </tr>
                      ) : null}
                      {invoice.previousPayments > 0 ? (
                        <tr>
                          <td>Previous payment(s):</td>
                          <td
                            className="pl-3 text-right"
                            data-testid="previousPayments"
                          >
                            {"(" +
                              formatCurrency(invoice.previousPayments ?? 0) +
                              ")"}
                          </td>
                        </tr>
                      ) : null}
                    </>
                  ) : null}

                  {getConvenienceFeeForTotal(invoice) > 0 ? (
                    <tr>
                      <td>Convenience fee:</td>
                      <td
                        className="pl-3 text-right"
                        data-testid="convenienceFee"
                      >
                        {formatCurrency(getConvenienceFeeForTotal(invoice))}
                      </td>
                    </tr>
                  ) : null}

                  <tr>
                    <td>{invoice.balance > 0 ? "Balance due:" : "Total"}</td>
                    <td className="pl-3 text-right" data-testid="total">
                      {formatCurrency(
                        invoice.balance > 0
                          ? invoice.balance
                          : invoice.totalAmount +
                              getConvenienceFeeForTotal(invoice)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {isStringSet(invoice.signatureImagePath) ? (
              <div data-testid="signatureContainer">
                <div className="mt-2">
                  By signing below, I agree the services provided are to my
                  expectation and agree to pay for services rendered under the
                  terms and conditions below
                </div>
                <img
                  src={`${imagePrefix}/${invoice.signatureImagePath}`}
                  alt="Signature"
                  data-testid="signatureImage"
                />
              </div>
            ) : null}

            {imagePrefix ? (
              <PrintImages files={invoice.files} filePrefix={imagePrefix} />
            ) : null}

            {invoice.footerTextHtml ? (
              <div
                data-testid="footerText"
                className="mt-3"
                dangerouslySetInnerHTML={{
                  // HTML was safely generated on server
                  __html: invoice.footerTextHtml,
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </>
    </PrintContainer>
  );
};

function getConvenienceFeeForTotal(invoice: IInvoiceForPrint) {
  if (!invoice.datePaid) {
    return 0;
  }

  return invoice.convenienceFeePaid ?? 0;
}

export default InvoicePrint;

function LineItems({
  invoice,
  lineItems,
}: {
  invoice: IInvoiceForPrint;
  lineItems: Array<ILineItemForInvoiceForCustomer>;
}) {
  const isLineItemServiceDateSet = lineItems.some((li) =>
    isStringSet(li.serviceDate)
  );

  return (
    <>
      {lineItems.length > 0 ? (
        <div
          data-testid="lineItemContainer"
          style={{
            display: "grid",
            gap: "20px 15px",
            gridTemplateColumns: `${
              isLineItemServiceDateSet ? "min-content" : ""
            } 2.5fr ${
              !invoice.hideLineItemPrices
                ? "min-content min-content min-content"
                : "min-content"
            }`,
          }}
        >
          {isLineItemServiceDateSet ? (
            <div
              data-testid="serviceDateHeader"
              className="font-weight-bold text-nowrap border-bottom"
            >
              Service date
            </div>
          ) : null}
          <div
            data-testid="nameHeader"
            className="font-weight-bold text-nowrap border-bottom"
          >
            Product / Service
          </div>
          <div className={"font-weight-bold text-right border-bottom"}>
            Quantity
          </div>
          {!invoice.hideLineItemPrices ? (
            <>
              <div
                data-testid="unitPriceHeader"
                className={
                  "font-weight-bold text-right text-nowrap border-bottom"
                }
              >
                Unit price
              </div>
              <div
                data-testid="totalHeader"
                className={"font-weight-bold text-right border-bottom"}
              >
                Total
              </div>
            </>
          ) : null}

          {lineItems.map((li, liIndex) => {
            const columnStyle = {
              marginTop: liIndex === 0 ? "-15px" : undefined,
            };
            return (
              <React.Fragment key={li.id}>
                {isLineItemServiceDateSet ? (
                  <div data-testid="lineItemServiceDate" style={columnStyle}>
                    {li.serviceDate
                      ? dateService.formatDateForDisplay(li.serviceDate)
                      : ""}
                  </div>
                ) : null}
                <div style={columnStyle}>
                  {isStringSet(li.name) ? (
                    <div data-testid="lineItemName">{li.name}</div>
                  ) : null}
                  <div
                    data-testid="lineItemDescription"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {li.description}
                  </div>
                </div>
                <div
                  className={"text-right"}
                  data-testid="lineItemQuantity"
                  style={columnStyle}
                >
                  {li.quantity}
                </div>

                {!invoice.hideLineItemPrices ? (
                  <>
                    <div
                      className={"text-right"}
                      data-testid="lineItemAmountPerItem"
                      style={columnStyle}
                    >
                      {formatCurrency(li.amountPerItem, true)}
                    </div>
                    <div
                      className={"text-right"}
                      data-testid="lineItemTotal"
                      style={columnStyle}
                    >
                      {formatCurrency(li.total, true)}
                    </div>
                  </>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

function Address({
  testPrefix,
  address,
}: {
  testPrefix: string;
  address: IAddressForInvoiceForTenant | IAddressForInvoiceForCustomer;
}) {
  let cityStateSeparator = "";
  if (isStringSet(address.city) && isStringSet(address.state)) {
    cityStateSeparator = ", ";
  }

  return (
    <>
      <div data-testid={`${testPrefix}AddressLineOne`}>
        {address.streetAndNumber}

        {"apartmentSuite" in address && isStringSet(address.apartmentSuite) ? (
          <> {address.apartmentSuite}</>
        ) : null}
      </div>

      <div data-testid={`${testPrefix}AddressLineTwo`}>
        {address.city}
        {cityStateSeparator}
        {address.state} {address.zip}
      </div>
    </>
  );
}
