import { useState } from "react";
import ContainerHeader from "../../../containers/app/pages/manage/ContainerHeader";
import ResponsiveTable from "../../../libraries/tableLayout/ResponsiveTable";
import { ResponsiveTableMobileCard } from "../../../libraries/tableLayout/ResponsiveTableMobileCard";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { TableColumns } from "../../../libraries/tableLayout/TableColumn";
import { WorksheetForm } from "./WorksheetForm";
import worksheetsDataProvider from "../services/worksheetsDataProvider";
import { IWorksheetView } from "../models/IWorksheet";
import Prompt from "../../../containers/app/components/Prompt";
import Spinner from "../../../containers/app/components/Spinner";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";
import { worksheetsActionCreators } from "../modules/worksheet";

export function WorksheetsPage() {
  const [showInactive, setShowInactive] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const worksheets = useApplicationStateSelector((s) => s.worksheet.worksheets);
  const filteredWorksheets = worksheets.filter(
    (w) => !w.inactive || showInactive
  );

  const columns: TableColumns<IWorksheetView> = [
    {
      header: "Title",
      cell: "title",
      key: "title",
      testId: "title",
    },
    {
      key: "inactive",
      header: () => "Inactive?",
      testId: "inactive",
      cell: ({ row: v }) => (v.inactive ? "Yes" : "No"),
    },
    {
      key: "buttons",
      header: "",
      isButtonCell: true,
      cell: ({ row: v, displayType }) => (
        <div
          className={`d-flex flex-nowrap ${
            displayType === "desktop" ? "justify-content-end" : ""
          }`}
          style={{ gap: "10px" }}
        >
          <EditWorksheetButton item={v} />

          <DeleteWorksheetButton item={v} />
        </div>
      ),
    },
  ];

  return (
    <ContainerHeader
      addButton={
        <button
          className="btn btn-primary"
          onClick={() => {
            setShowAddForm(true);
          }}
        >
          Add Worksheet
        </button>
      }
      pageHeader={`Job Worksheets`}
      messageToShow=""
      showInactiveFilter={false}
      customFilters={
        <div className="mt-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="include-inactive"
              checked={showInactive}
              onChange={(e) => {
                const newShowInactiveValue = e.currentTarget.checked;
                setShowInactive(newShowInactiveValue);
              }}
            />
            <label className="custom-control-label" htmlFor="include-inactive">
              Show inactive
            </label>
          </div>
        </div>
      }
      itemCount={filteredWorksheets?.length ?? 0}
      onInactiveFilterChanged={(newShowInactive) =>
        setShowInactive(newShowInactive)
      }
      showInactive={showInactive}
      notFluid={true}
    >
      <ResponsiveTable
        tableClass="table-striped"
        rows={getSortedItemsV2(filteredWorksheets ?? [], ["title"])}
        breakpoint="sm"
        columns={columns}
        renderMobile={({ row, index }) => {
          return (
            <>
              <ResponsiveTableMobileCard
                row={row}
                columns={columns}
                rowIndex={index}
              />
            </>
          );
        }}
      />

      {showAddForm ? (
        <WorksheetForm
          worksheetId={null}
          onSaveComplete={() => {
            setShowAddForm(false);
          }}
          onCancel={() => {
            setShowAddForm(false);
          }}
        />
      ) : null}
    </ContainerHeader>
  );
}

function EditWorksheetButton({ item }: { item: IWorksheetView }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <button
        type="button"
        className="btn btn-secondary btn-sm"
        onClick={() => setShowModal(true)}
      >
        Edit
      </button>
      {showModal ? (
        <WorksheetForm
          worksheetId={item.id}
          onSaveComplete={() => {
            setShowModal(false);
          }}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </div>
  );
}

function DeleteWorksheetButton({ item }: { item: IWorksheetView }) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState<string>("");

  return (
    <>
      {deleting ? <Spinner /> : null}

      <button
        type="button"
        className="btn btn-secondary btn-sm"
        onClick={() => setShowModal(true)}
      >
        Delete
      </button>
      {showModal ? (
        <Prompt
          showPrompt
          promptMessage={`Are you sure you want to delete the "${item.title}" worksheet?`}
          promptSubMessage={errorDeleting}
          promptSubMessageClassName="text-danger"
          onConfirm={() => {
            setDeleting(true);
            worksheetsDataProvider.deleteWorksheet(item.id).subscribe({
              next: () => {
                setDeleting(false);
                dispatch(
                  worksheetsActionCreators.deleteWorksheet({
                    worksheetId: item.id,
                  })
                );
              },

              error: (err) => {
                setDeleting(false);
                setErrorDeleting(getErrorMessageFromError(err));
              },
            });
          }}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </>
  );
}
