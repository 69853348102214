import React, { useState } from "react";
import { IUserAccount } from "../../../models/IUserAccount";
import dataProvider from "../../../services/dataProvider";
import Prompt from "./Prompt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";

interface IProps {
  userAccount: IUserAccount;
  currentUserIsTenantOwner: boolean;
  reloadUserAccounts(): void;
  setFormWaiting(waiting: boolean): void;
  setErrorMessage(message: string): void;
}

const ManageAdminUsersUserActions: React.FunctionComponent<IProps> = ({
  userAccount,
  currentUserIsTenantOwner,
  reloadUserAccounts,
  setErrorMessage,
  setFormWaiting,
}) => {
  const currentUserAccountId = useApplicationStateSelector(
    (s) => s.common.userAccountId
  );
  const [showDeletedPrompt, setShowDeletedPrompt] = useState(false);

  return (
    <>
      <Prompt
        showPrompt={showDeletedPrompt}
        promptMessage={`Are you sure you want to delete the admin user ${userAccount.emailAddress}?`}
        onConfirm={() => {
          setErrorMessage("");
          setFormWaiting(true);
          setShowDeletedPrompt(false);

          dataProvider.deleteUserAccount(userAccount.id).subscribe(
            () => {
              setFormWaiting(false);
              reloadUserAccounts();
            },
            (err: any) => {
              setFormWaiting(false);
              setErrorMessage(
                getErrorMessageFromError(
                  err,
                  "An unknown error occurred when deleting the admin user."
                )
              );
            }
          );
        }}
        onCancel={() => setShowDeletedPrompt(false)}
      />
      {currentUserIsTenantOwner && userAccount.id !== currentUserAccountId ? (
        <div className="text-right pr-0 mb-2">
          <button
            className="btn btn-link m-0 p-0 text-dark"
            onClick={() => setShowDeletedPrompt(true)}
          >
            <FontAwesomeIcon icon={faTrash} title="Delete" />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default ManageAdminUsersUserActions;
