const hostnameLowercase = window.location.hostname.toLowerCase();

let appInsightsConnectionString = "";
let perfDevToolsEnabled = false;
let useProductionGoogleAnalyticsCategories = false;
let stripePublicKey = "";

let isAutomatedTest = false;
try {
  isAutomatedTest = window.sessionStorage.getItem("automated_test") === "1";
} catch (err) {
  console.error(`error checking isAutomatedTest: ${err}`);
}

let googleTagManagerId = "GTM-MMR72MK";
if (
  hostnameLowercase === "localhost" ||
  hostnameLowercase === "greenerpastures-local-frontend.azurewebsites.net"
) {
  perfDevToolsEnabled = true;
  appInsightsConnectionString = process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING_LOCAL as string;
  stripePublicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY as string;
} else if (
  hostnameLowercase === "greenerpastures-staging-frontend.azurewebsites.net" ||
  hostnameLowercase === "greenerpastures-staging-frontend.azureedge.net"
) {
  appInsightsConnectionString = process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING_TEST as string;
  stripePublicKey = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY as string;
} else {
  appInsightsConnectionString = process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING_PROD as string;
  useProductionGoogleAnalyticsCategories = true;
  stripePublicKey = process.env.REACT_APP_STRIPE_PROD_PUBLIC_KEY as string;
}

export default {
  perfDevToolsEnabled,
  appInsightsConnectionString,
  useProductionGoogleAnalyticsCategories,
  stripePublicKey,
  isAutomatedTest,
  googleTagManagerId,
};
