interface IProps {
  id: string;
  checked: boolean;
  onChecked(newValue: boolean): void;
  linkButtonStyle?: React.CSSProperties;
}

const RegisterTermsOfServiceAndPrivacy: React.FunctionComponent<IProps> = ({
  id,
  checked,
  onChecked,
  linkButtonStyle,
}) => {
  return (
    <>
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            required={true}
            type="checkbox"
            className="custom-control-input branded-public-custom-control-input"
            checked={checked}
            onChange={(e) => {
              onChecked(e.target.checked);
            }}
            id={id}
            data-testid="agreeTermsCheckBox"
          />
          <label
            className="custom-control-label branded-public-custom-control-label"
            htmlFor={id}
            id={id + "-label"}
            data-testid="agreeTerms"
          >
            <span id={id + "-span"}>I agree to Crew Control's</span>{" "}
            <a
              href="https://www.youraspire.com/legal/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
              style={linkButtonStyle}
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://www.youraspire.com/privacy-statement"
              target="_blank"
              rel="noopener noreferrer"
              style={linkButtonStyle}
            >
              Privacy Policy
            </a>
          </label>
        </div>
      </div>
    </>
  );
};

export default RegisterTermsOfServiceAndPrivacy;
