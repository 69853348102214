import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import React, { CSSProperties, useEffect, useState } from "react";
import { TenantPlan } from "../../../enums/tenantPlan";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import SubscriptionFormPlanSelection from "./SubscriptionFormPlanSelection";
import { SubscriptionFrequency } from "../../../enums/subscriptionFrequency";

const stripeStyling = {
  style: {
    base: {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontSize: "16px",
      fontWeight: "400",
      color: "#495057",
      backgroundColor: "#fff",
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

interface IProps {
  numberOfCrews: number;
  setNumberOfCrews(newValue: number): void;

  cardHolderName: string;
  setCardHolderName(newValue: string): void;

  selectedTenantPlan: TenantPlan;
  setSelectedTenantPlan(newValue: TenantPlan): void;

  selectedSubscriptionFrequency: SubscriptionFrequency;
  setSelectedSubscriptionFrequency(newValue: SubscriptionFrequency): void;
}

const SubscriptionFormElements: React.FunctionComponent<IProps> = ({
  numberOfCrews,
  setNumberOfCrews,
  cardHolderName,
  setCardHolderName,
  selectedTenantPlan,
  setSelectedTenantPlan,
  selectedSubscriptionFrequency,
  setSelectedSubscriptionFrequency,
}) => {
  const isFormOpen = useApplicationStateSelector(
    (s) => s.forms.subscription.showForm
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const [focusedStripeField, setFocusedStripeField] =
    useState<StripeField | null>(null);

  useEffect(() => {
    setCardHolderName("");
  }, [isFormOpen, setCardHolderName]);

  useEffect(() => {
    const activeCrewCount = crews.filter((c) => !c.inactive).length;
    setNumberOfCrews(activeCrewCount);
  }, [crews, setNumberOfCrews]);

  return (
    <>
      <div>
        <div className="form-group">
          <SubscriptionFormPlanSelection
            tenantPlan={selectedTenantPlan}
            onTenantPlanChange={(newPlan) => setSelectedTenantPlan(newPlan)}
            subscriptionFrequency={selectedSubscriptionFrequency}
            onSubscriptionFrequencyChange={setSelectedSubscriptionFrequency}
            numberOfCrews={numberOfCrews}
            disableSelection={false}
          />
        </div>
      </div>
      <div className="mb-3">
        <small>
          By clicking the Pay button below, you acknowledge and agree that this
          transaction is governed by the terms and conditions located here:{" "}
          <a
            href="https://www.youraspire.com/legal/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{" "}
          ("Crew Control Terms"). If you are entering into this subscription
          agreement on behalf of a company or business, you represent and
          warrant that you are authorized by such company or business to bind it
          to the Crew Control Terms. YOUR SUBSCRIPTION WILL AUTO-RENEW FOR
          ADDITIONAL ANNUAL OR MONTHLY TERMS, AS APPLICABLE, UNLESS YOU CANCEL
          YOUR SUBSCRIPTION IN ADVANCE OF RENEWAL WITHIN THE SERVICE OR BY
          CONTACTING SUPPORT AS DETAILED IN THE TERMS OF SERVICE.
        </small>
      </div>
      <div>
        <div className="form-group">
          <label htmlFor="cardHolderName" className="required">
            Cardholder Name
          </label>
          <input
            type="text"
            className="form-control fs-mask"
            id="cardHolderName"
            required={true}
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.currentTarget.value)}
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label>Card number</label>
          <div
            className="form-control fs-mask"
            style={getFocusedStyle("number", focusedStripeField)}
          >
            <CardNumberElement
              options={stripeStyling}
              onFocus={() => setFocusedStripeField("number")}
              onBlur={() => {
                clearFocusedStripeField(
                  "number",
                  focusedStripeField,
                  setFocusedStripeField
                );
              }}
            />
          </div>
        </div>
        <div className="d-flex align-content-stretch">
          <div
            className="form-group mr-2 flex-grow-1 fs-mask"
            style={{ flexBasis: 0 }}
          >
            <label>Expiration</label>
            <div
              className="form-control"
              style={getFocusedStyle("expiry", focusedStripeField)}
            >
              <CardExpiryElement
                options={stripeStyling}
                onFocus={() => setFocusedStripeField("expiry")}
                onBlur={() => {
                  clearFocusedStripeField(
                    "expiry",
                    focusedStripeField,
                    setFocusedStripeField
                  );
                }}
              />
            </div>
          </div>

          <div
            className="form-group ml-2 flex-grow-1 fs-mask"
            style={{ flexBasis: 0 }}
          >
            <label>CVC</label>
            <div
              className="form-control"
              style={getFocusedStyle("cvc", focusedStripeField)}
            >
              <CardCvcElement
                options={stripeStyling}
                onFocus={() => setFocusedStripeField("cvc")}
                onBlur={() => {
                  clearFocusedStripeField(
                    "cvc",
                    focusedStripeField,
                    setFocusedStripeField
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionFormElements;

function getFocusedStyle(
  fieldKey: StripeField,
  focusedStripeField: StripeField | null
): React.CSSProperties | undefined {
  const focusContainerStyling: Partial<CSSProperties> = {
    borderColor: "#a1d6f4",
    boxShadow: "0 0 0 0.2rem rgb(47 164 231 / 25%)",
    outline: 0,
  };

  return focusedStripeField === fieldKey ? focusContainerStyling : undefined;
}

function clearFocusedStripeField(
  fieldKey: StripeField,
  focusedStripeField: string | null,
  setFocusedStripeField: React.Dispatch<
    React.SetStateAction<StripeField | null>
  >
) {
  if (focusedStripeField === fieldKey) {
    setFocusedStripeField(null);
  }
}

type StripeField = "number" | "expiry" | "cvc";
