import format from "date-fns/format";
import parse from "date-fns/parse";
import { ICrew } from "../../../models/ICrew";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { builders, emptyCategoryCode } from "../../../services/routing";
import dateService from "../../../services/dateService";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { doesCrewHaveCategory } from "../../../services/crewCategoryService";
import startOfWeek from "date-fns/start_of_week";
import { CrewScheduleType } from "../enums/crewScheduleType";
import constants from "../../../constants";

export function getDateForSchedule(currentParams: { date: string }) {
  let date = currentParams.date ? parse(currentParams.date) : new Date();
  return format(date, "YYYY-MM-DD");
}

export function getScheduleDayColumns({
  filteredCrews,
  daySchedules,
  scheduleDate,
  params,
  isCurrentDate,
  getRoutePath,
}: {
  filteredCrews: ICrew[];
  daySchedules: IDaySchedule[];
  scheduleDate: string;
  params: {
    date: string;
    mapCrew: string;
    crewFilter: string;
  };
  isCurrentDate: boolean;
  getRoutePath(date: string, mapCrew?: string, crewFilter?: string): string;
}) {
  const columns = getSortedCrews(filteredCrews).map((crew: ICrew) => {
    const daySchedule = daySchedules.find(
      (d) => d.date === scheduleDate && d.crewId === crew.id
    );

    if (daySchedule) {
      const isMapOpen = params.mapCrew === crew.id;
      const mapUrl = isMapOpen
        ? getRoutePath(
            dateService.formatAsIso(scheduleDate),
            "",
            params.crewFilter
          )
        : getRoutePath(
            dateService.formatAsIso(scheduleDate),
            crew.id,
            params.crewFilter
          );
      return {
        date: scheduleDate,
        dayScheduleId: daySchedule.id,
        crew: crew,
        additionalLink: null,
        mapLinkProps: {
          url: mapUrl,
          text: isMapOpen
            ? constants.scheduleHideMapLinkText
            : constants.scheduleShowMapLinkText,
        },
        isMapOpen,
        loading: daySchedule.initialLoadRunning,
        errorLoading: daySchedule.errorLoading || false,
        columnHeader: getColumnHeader({
          crew,
          isCurrentDate,
          scheduleDate,
        }),
      };
    } else {
      return null;
    }
  });

  const filteredColumns = columns.filter((c) => c !== null);

  return filteredColumns;
}

export function getSelectedCrewId({
  params,
  crews,
  mode,
  requireActualCrewId,
}: {
  params: { crewFilter: string };
  crews: Array<ICrew>;
  mode?: CrewScheduleType;
  requireActualCrewId?: boolean;
}) {
  if (
    params.crewFilter &&
    (params.crewFilter !== emptyCategoryCode || !requireActualCrewId)
  ) {
    return params.crewFilter;
  }

  const sortedCrew = getSortedCrews(crews);
  const activeSortedCrews = sortedCrew.filter(
    (c) =>
      !c.inactive && (c.scheduleType === mode || typeof mode === "undefined")
  );
  if (activeSortedCrews.length > 0) {
    return activeSortedCrews[0].id;
  }

  if (sortedCrew.length === 0) {
    return "";
  }

  return sortedCrew[0].id;
}

export function getSortedCrews(crews: Array<ICrew>) {
  const newCrews = [...crews];
  newCrews.sort((a, b) => {
    if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
      return 1;
    }

    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    }

    return 0;
  });
  return newCrews;
}

export function getPageHeaderText(scheduleDate: string) {
  return (
    <div
      style={{
        display: "inline-block",
        textDecoration: isMobileOnly ? "underline" : undefined,
      }}
    >
      {format(scheduleDate, isMobileOnly ? "ddd" : "dddd")}{" "}
      <span
        style={{
          display: "inline-block",
          textDecoration: isMobileOnly ? "underline" : undefined,
        }}
      >
        ({format(scheduleDate, "MMM D")})
      </span>
    </div>
  );
}

export function getFilteredCrews(
  crews: Array<ICrew>,
  params: { crewFilter: string }
) {
  let filteredCrews = crews
    .filter((c) => !c.inactive || c.id === getSelectedCrewId({ params, crews }))
    .filter((crew) =>
      !isMobileOnly
        ? params.crewFilter === emptyCategoryCode ||
          doesCrewHaveCategory(crew, [params.crewFilter])
        : crew.id === getSelectedCrewId({ params, crews })
    );

  if (filteredCrews.length === 0) {
    const sortedCrew = getSortedCrews(crews);
    const activeSortedCrews = sortedCrew.filter((c) => !c.inactive);
    filteredCrews = [
      activeSortedCrews.length > 0 ? activeSortedCrews[0] : sortedCrew[0],
    ];
  }

  return filteredCrews.filter((c) => typeof c === "object");
}

export function getSelectedDateStartOfWeek(params: { date: string }) {
  const currentParams = params;
  return startOfWeek(getDateForSchedule(currentParams));
}

function getColumnHeader({
  crew,
  isCurrentDate,
  scheduleDate,
}: {
  crew: ICrew;
  isCurrentDate: boolean;
  scheduleDate: string;
}) {
  return !isMobileOnly ? (
    <Link
      to={
        crew.scheduleType === CrewScheduleType.time
          ? builders.schedule.buildTimeWeekRoute(scheduleDate, crew.id)
          : builders.schedule.buildSequenceWeekRoute(scheduleDate, crew.id)
      }
      className={isCurrentDate ? "text-white" : ""}
    >
      {crew.name}
    </Link>
  ) : (
    <span className={isCurrentDate ? "text-white" : ""}>{crew.name}</span>
  );
}
