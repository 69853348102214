import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BillingReportJobStatusFilter } from "../../../../enums/billingReportJobStatusFilter";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import LinkButton2 from "../../components/LinkButton2";
import { IFilters } from "./Filters";

interface IPill {
  label: string;
  value: string;
  clear(): void;
}

export function FilterPills({
  filterValues,
  onFilterValuesChange,
}: {
  filterValues: IFilters;
  onFilterValuesChange(newValues: IFilters): void;
}) {
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const customerCategories = useApplicationStateSelector(
    (s) => s.common.customerCategories
  );

  const pills: Array<IPill> = [];

  if (filterValues.customerName) {
    pills.push({
      label: "Customer name",
      value: filterValues.customerName,
      clear: () =>
        onFilterValuesChange({
          ...filterValues,
          customerName: "",
        }),
    });
  }

  if (filterValues.customerCategoryId) {
    const matchingCustomerCategory = customerCategories.find(
      (c) => c.id === filterValues.customerCategoryId
    );
    pills.push({
      label: "Customer tag",
      value: matchingCustomerCategory?.name ?? "<not found>",
      clear: () =>
        onFilterValuesChange({
          ...filterValues,
          customerCategoryId: "",
        }),
    });
  }

  if (filterValues.category) {
    pills.push({
      label: "Service",
      value: filterValues.category,
      clear: () =>
        onFilterValuesChange({
          ...filterValues,
          category: "",
        }),
    });
  }

  if (filterValues.crewIds && filterValues.crewIds.length > 0) {
    pills.push({
      label: "Crews",
      value: getSortedItemsV2(
        filterValues.crewIds
          .map((crewId) => crews.find((crew) => crew.id === crewId))
          .filter((crew) => typeof crew === "object"),
        [(c) => c?.name ?? ""]
      )
        .map((crew) => crew?.name)
        .join(", "),
      clear: () =>
        onFilterValuesChange({
          ...filterValues,
          crewIds: [],
        }),
    });
  }

  if (filterValues.jobStatus !== BillingReportJobStatusFilter.All.toString()) {
    let jobStatusValue: string = "";
    if (
      filterValues.jobStatus ===
      BillingReportJobStatusFilter.Completed.toString()
    ) {
      jobStatusValue = "Completed";
    } else if (
      filterValues.jobStatus ===
      BillingReportJobStatusFilter.NotCompleted.toString()
    ) {
      jobStatusValue = "Not completed";
    }

    pills.push({
      label: "Job status",
      value: jobStatusValue,
      clear: () =>
        onFilterValuesChange({
          ...filterValues,
          jobStatus: BillingReportJobStatusFilter.All.toString(),
        }),
    });
  }

  return (
    <>
      {pills.length > 0 ? (
        <div className="my-2 d-flex flex-wrap" style={{ columnGap: "20px" }}>
          {pills.map((p) => (
            <div key={p.label}>
              <span className="badge badge-pill badge-secondary">
                {p.label}: {p.value}
                <LinkButton2
                  style={{ verticalAlign: "baseline" }}
                  onClick={() => p.clear()}
                  buttonContents={
                    <span className="badge badge-pill badge-secondary pl-1">
                      <FontAwesomeIcon
                        icon={faX}
                        size="sm"
                        title="Remove filter"
                        id={`${p.label}_RemoveFilter`}
                      />
                    </span>
                  }
                />
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}
