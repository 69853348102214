import React, { useEffect, useState } from "react";
import { finalize } from "rxjs/operators";
import { IUserAccount } from "../../../models/IUserAccount";
import dataProvider from "../../../services/dataProvider";
import { UserAccountRole } from "../../../enums/userAccountRole";
import { SaveUserAccountRequest } from "../../../services/remoteDataProvider";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";

interface IProps {
  userAccount: IUserAccount;
  formWaiting: boolean;
  currentUserIsTenantOwner: boolean;
  reloadUserAccounts(): void;
  setFormWaiting(waiting: boolean): void;
  setErrorMessage(message: string): void;
  currentUserId: string;
}

const OwnerRoleId = "-1";

const ManageAdminUsersRoleSelection: React.FunctionComponent<IProps> = ({
  userAccount,
  formWaiting,
  currentUserIsTenantOwner,
  reloadUserAccounts,
  setErrorMessage,
  setFormWaiting,
  currentUserId,
}) => {
  const [value, setValue] = useState(UserAccountRole.administrator.toString());

  useEffect(() => {
    setValue(getDropDownValue(userAccount.isTenantOwner, userAccount.role));
  }, [userAccount.role, userAccount.isTenantOwner]);

  const updateUserAccount = (
    body: SaveUserAccountRequest,
    errorCallback?: () => void
  ) => {
    setErrorMessage("");
    setFormWaiting(true);

    dataProvider
      .saveUserAccount(userAccount.id, body)
      .pipe(finalize(() => setFormWaiting(false)))
      .subscribe(
        () => {
          reloadUserAccounts();
        },
        (err: any) => {
          setErrorMessage(
            getErrorMessageFromError(
              err,
              "An unknown error occurred updating the user's role."
            )
          );

          if (errorCallback) {
            errorCallback();
          }
        }
      );
  };

  const isCurrentUser = currentUserId === userAccount.id;

  return (
    <>
      <select
        aria-label="Role"
        className="form-control"
        value={value}
        disabled={
          formWaiting ||
          (value === OwnerRoleId && !currentUserIsTenantOwner) ||
          isCurrentUser
        }
        onChange={(e) => {
          setValue(e.currentTarget.value);
          if (e.currentTarget.value === OwnerRoleId) {
            updateUserAccount({
              role: UserAccountRole.administrator,
              isTenantOwner: true,
            });
          } else {
            updateUserAccount(
              { role: parseInt(e.currentTarget.value), isTenantOwner: false },
              () =>
                setValue(
                  getDropDownValue(userAccount.isTenantOwner, userAccount.role)
                )
            );
          }
        }}
      >
        {currentUserIsTenantOwner || value === OwnerRoleId ? (
          <option value={OwnerRoleId}>Owner</option>
        ) : null}
        <option value={UserAccountRole.administrator.toString()}>Admin</option>
        <option value={UserAccountRole.scheduler.toString()}>Scheduler</option>
      </select>
    </>
  );
};

export default ManageAdminUsersRoleSelection;

function getDropDownValue(isTenantOwner: boolean, role: UserAccountRole) {
  let newValue: string;
  if (isTenantOwner) {
    newValue = OwnerRoleId;
  } else {
    newValue = role.toString();
  }
  return newValue;
}
