import { useParams } from "react-router";
import { ScheduleTimePage } from "./ScheduleTimePage";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { builders } from "../../../services/routing";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import format from "date-fns/format";
import addDays from "date-fns/add_days";
import {
  getDateForSchedule,
  getFilteredCrews,
  getPageHeaderText,
  getScheduleDayColumns,
  getSelectedDateStartOfWeek,
} from "../services/scheduleDayService";
import dateService from "../../../services/dateService";
import { IScheduleColumn } from "./types/IScheduleColumn";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { ScheduleDayFilters } from "./ScheduleDayFilters";
import { useEnsureSchedulesLoadedForDay } from "../hooks/useEnsureSchedulesLoadedForDay";
import { isLoadingScheduleDates } from "../../../services/scheduleService";
import { InitialDispatchPrompt } from "../../tenantSubscription/components/InitialDispatchPrompt";

export function ScheduleTimeDayPage() {
  const dispatch = useDispatch();
  const params = useParams<{
    date: string;
    mapCrew: string;
    crewFilter: string;
  }>();

  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const timeBasedCrews = crews.filter(
    (c) => c.scheduleType === CrewScheduleType.time
  );
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );

  useEnsureSchedulesLoadedForDay({
    crews,
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    params: params,
    includePrecedingDay: true,
  });

  const scheduleDate = getDateForSchedule(params);
  const pageHeaderText = getPageHeaderText(scheduleDate);

  const filteredCrews = getFilteredCrews(timeBasedCrews, params);

  const isCurrentDate = dateService.areDatesEqual(
    dateService.getCurrentDate(),
    scheduleDate
  );

  const columns = getScheduleDayColumns({
    filteredCrews,
    daySchedules,
    scheduleDate,
    params,
    isCurrentDate,
    getRoutePath: builders.schedule.buildTimeDayRoute,
  });

  const { hasMissingDates, loadingUnscheduledJobs } = isLoadingScheduleDates(
    getSelectedDateStartOfWeek(params),
    columns,
    weeksUnscheduledMaintenanceJobs
  );

  return (
    <PageWithNavBar2
      belowNavBarContent={<InitialDispatchPrompt />}
      subHeaderLeftSideContent={
        <ScheduleDayFilters
          params={params}
          crewsForCurrentSchedule={timeBasedCrews}
          getRoute={builders.schedule.buildTimeDayRoute}
          mode={CrewScheduleType.time}
        />
      }
    >
      <ScheduleTimePage
        isLoading={hasMissingDates}
        isLoadingFlexibleJobs={loadingUnscheduledJobs}
        weekForUnscheduledJobs={getSelectedDateStartOfWeek(params)}
        rows={[
          {
            columns: columns.map((c) => c as IScheduleColumn),
            id: "singleRow",
            name: "",
            expanded: true,
          },
        ]}
        columnHeaders={columns.map((c) => ({
          key: c?.crew?.id ?? "",
          columnHeader: c?.columnHeader ?? "",
          highlightHeader: isCurrentDate,
        }))}
        mode="week"
        pageHeaderText={pageHeaderText}
        currentWeekDate={scheduleDate}
        onRowExpanded={() => {}}
        onRowReorder={() => {}}
        changeScheduleDate={(newDate) => {
          dispatch(
            push(
              builders.schedule.buildTimeDayRoute(
                format(newDate, "YYYY-MM-DD"),
                params.mapCrew,
                params.crewFilter
              )
            )
          );
        }}
        onNextSchedule={() => {
          dispatch(
            push(
              builders.schedule.buildTimeDayRoute(
                format(addDays(scheduleDate, 1), "YYYY-MM-DD"),
                params.mapCrew,
                params.crewFilter
              )
            )
          );
        }}
        onPreviousSchedule={() => {
          dispatch(
            push(
              builders.schedule.buildTimeDayRoute(
                format(addDays(scheduleDate, -1), "YYYY-MM-DD"),
                params.mapCrew,
                params.crewFilter
              )
            )
          );
        }}
      />
    </PageWithNavBar2>
  );
}
