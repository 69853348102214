import React, { useState, useRef } from "react";
import { ChangeEvent } from "react";

import dateFnsParse from "date-fns/parse";

import remoteDataProvider from "../../../../services/remoteDataProvider";
import Spinner from "../../components/Spinner";
import { finalize, timeout } from "rxjs/operators";
import { isMobileOnly } from "react-device-detect";
import { downloadBlob } from "../../../../services/csvDownloadService";
import { DateFilterOptions } from "../../../../enums/dateFilterOptions";
import ColumnSelector from "./ColumnSelector";
import { CompletedWorkReportColumn } from "../../../../enums/completedWorkReportColumn";
import { updateDatesOnFilter } from "../../../../services/dateFilterService";
import AdditionalFilters from "./AdditionalFilters";
import { FilterPills } from "./FilterPills";
import DateFilterHeadless from "../../components/DateFilterHeadless";
import ExportButton from "../../components/ExportButton";

export interface IProps {
  filterValues: IFilters;
  onLoadReport(): void;
  onFilterChange(newFilterValues: IFilters): void;

  visibleColumns: Array<CompletedWorkReportColumn>;
  onVisibleColumnsChange(newValue: Array<CompletedWorkReportColumn>): void;
}

export interface IFilters {
  frequency: DateFilterOptions;
  startingDate: string;
  endingDate: string;
  customerName: string;
  category: string;
  customerCategoryId: string;
  jobStatus: string;
  crewIds: Array<string>;
}

const Filters: React.FunctionComponent<IProps> = ({
  filterValues,
  onLoadReport,
  onFilterChange,
  visibleColumns,
  onVisibleColumnsChange,
}) => {
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [showErrorLoadingReport, setShowErrorLoadingReport] = useState(false);
  const [dateFilterErrorMessage, setDateFilterErrorMessage] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const updateFilterValues = (newFilterValues: IFilters) => {
    onFilterChange(newFilterValues);
  };

  function setFilterValue(e: ChangeEvent<HTMLInputElement>) {
    updateFilterValues({
      ...filterValues,
      [e.target.name as keyof IFilters]: e.target.value,
    });
  }

  const responsiveBreakpoint = "sm";

  return (
    <React.Fragment>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          onLoadReport();
        }}
      >
        <div
          className={`d-${responsiveBreakpoint}-flex justify-content-between`}
          style={{ columnGap: "10px" }}
        >
          <div
            className="d-flex flex-wrap align-items-end"
            style={{ columnGap: "10px", rowGap: "10px" }}
          >
            <DateFilterHeadless
              filters={{
                startingDate: filterValues.startingDate,
                endingDate: filterValues.endingDate,
                frequency: filterValues.frequency,
              }}
              onFiltersChanged={(newFilters) => {
                newFilters = updateDatesOnFilter(newFilters);
                updateFilterValues({
                  ...filterValues,
                  startingDate: newFilters.startingDate ?? "",
                  endingDate: newFilters.endingDate ?? "",
                  frequency: newFilters.frequency,
                });
              }}
              showAllDates={false}
              setErrorMessage={(m) => setDateFilterErrorMessage(m)}
            >
              {({ dateRangeElements, customDateElements }) => (
                <React.Fragment>
                  <div>{dateRangeElements}</div>
                  {customDateElements !== null ? (
                    <>
                      <div>{customDateElements.startingDateElement}</div>
                      <div>{customDateElements.endingDateElement}</div>
                    </>
                  ) : null}
                </React.Fragment>
              )}
            </DateFilterHeadless>
            <div>
              <label htmlFor="customerFilter">Customer name</label>
              <input
                type="text"
                className="form-control"
                id="customerFilter"
                name="customerName"
                value={filterValues.customerName}
                onChange={setFilterValue}
              />
            </div>
            <div>
              <div className="d-flex" style={{ columnGap: "10px" }}>
                <AdditionalFilters
                  values={filterValues}
                  onFilterValuesChange={(newValue) =>
                    updateFilterValues({
                      ...filterValues,
                      ...newValue,
                    })
                  }
                />

                <ColumnSelector
                  visibleColumns={visibleColumns}
                  onVisibleColumnsChange={onVisibleColumnsChange}
                />
              </div>
            </div>
          </div>

          <div className={`mt-3 mt-${responsiveBreakpoint}-0`}>
            <label className="d-none d-sm-block">&nbsp;</label>
            <div
              className="d-flex justify-content-sm-end flex-nowrap"
              style={{ columnGap: "10px" }}
            >
              <div className="d-md-block">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.reportValidity();
                      if (!formRef.current.checkValidity()) {
                        return;
                      }
                    }

                    if (dateFilterErrorMessage) {
                      return;
                    }

                    onLoadReport();
                  }}
                  type="button"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Load Report
                </button>
              </div>
              {!isMobileOnly ? (
                <ExportButton
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.reportValidity();
                      if (!formRef.current.checkValidity()) {
                        return;
                      }
                    }

                    if (dateFilterErrorMessage) {
                      return;
                    }

                    setLoadingExcel(true);
                    setShowErrorLoadingReport(false);
                    remoteDataProvider
                      .downloadJobInstancesCsv(
                        dateFnsParse(filterValues.startingDate),
                        dateFnsParse(filterValues.endingDate),
                        filterValues.customerName,
                        filterValues.category,
                        filterValues.crewIds,
                        parseInt(filterValues.jobStatus),
                        filterValues.customerCategoryId
                      )
                      .pipe(
                        timeout(20000),
                        finalize(() => setLoadingExcel(false))
                      )
                      .subscribe(
                        (blob) => {
                          downloadBlob(blob, "CompletedWork.csv");
                        },
                        () => setShowErrorLoadingReport(true)
                      );
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        {dateFilterErrorMessage ? (
          <div className="text-danger">{dateFilterErrorMessage}</div>
        ) : null}
      </form>

      <FilterPills
        filterValues={filterValues}
        onFilterValuesChange={(newValues) => updateFilterValues(newValues)}
      />

      {loadingExcel ? <Spinner /> : null}
      {showErrorLoadingReport ? (
        <div
          className={"alert alert-dismissible fade show alert-danger"}
          role="alert"
        >
          The Excel file was unable to be downloaded. Please check your Internet
          connection and try again.
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={(e) => setShowErrorLoadingReport(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Filters;
