import uuidv4 from "uuid/v4";
import { WorksheetContentType } from "../enums/WorksheetContentType";

export function getEmptyContentItem() {
  return {
    id: uuidv4(),
    serverId: null,
    text: "",
    type: WorksheetContentType.QuestionShortAnswer.toString(),
    required: false,
    optionListOptions: [getEmptyOption()],
  };
}

export function getEmptyOption() {
  return {
    id: uuidv4(),
    label: "",
  };
}
