import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { IDaySchedule } from "../../../models/IDaySchedule";
import {
  IJobInstance,
  oneTimeJobType,
  maintenanceJobType,
} from "../../../models/IJobInstance";
import TextareaAutosize from "react-autosize-textarea/lib";
import { ITodoItem } from "../components/ManageTodoItems";
import uuidv4 from "uuid/v4";
import Files from "../components/files/Index";
import { IFormDataFile } from "../components/files/ExistingFile";
import { isPhoto } from "../../../services/fileService";
import JobTodoItems from "../components/JobTodoItems";
import { IMaintenanceJob } from "../../../models/IMaintenanceJob";
import jobFinder from "../../../services/jobFinder";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import LinkButton2 from "../components/LinkButton2";
import { fullStoryTrack } from "../../../services/fullStoryService";

interface IProps {
  jobInstanceId: string;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  daySchedules: Array<IDaySchedule>;
  tenantId: string | null;
  imagePrefix: string;
  jobs: Array<IMaintenanceJob>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  showCrewViewConfigurationForm(parameters: any): void;
  showCustomerJobHistory: boolean;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
  hasPendingPhotoAdd: boolean;
  notesFromPreviousJob: string;
}

interface IFormData {
  highlightCrewNotes: boolean;
  showCrewNotesOnAdminJobCards: boolean;
  jobNotes: string;
  todoTemplateId: string;
  todoItems: Array<ITodoItem>;
  useCustomTodoItems: boolean;
  photos: Array<IFormDataFile>;
  administratorOnlyNotes: string;
}

class CrewNotesForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const formData = this.getDefaultFormData();
    this.state = {
      formData: formData,
      originalFormData: formData,
      hasPendingPhotoAdd: false,
      notesFromPreviousJob: "",
    };

    this.getFormData = this.getFormData.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      const formData = this.getDefaultFormData();

      let notesFromPreviousJob = "";
      const jobInstance = this.getJobInstance();
      if (!!jobInstance) {
        notesFromPreviousJob = jobInstance.notesFromPreviousJob;
      }

      this.setState({
        formData,
        originalFormData: formData,
        notesFromPreviousJob,
      });
    }
  }

  getDefaultFormData() {
    const jobInstance = this.getJobInstance();
    if (jobInstance) {
      return {
        highlightCrewNotes: jobInstance.highlightCrewNotes,
        showCrewNotesOnAdminJobCards: jobInstance.showCrewNotesOnAdminJobCards,
        jobNotes: jobInstance.jobNotes || "",
        useCustomTodoItems: jobInstance.useCustomTodoItems,
        todoItems: jobInstance.todoItems.map((i) => ({
          ...i,
          tempId: uuidv4(),
        })),
        todoTemplateId: jobInstance.todoTemplateId || "",
        photos: jobInstance.photos.map((p) => ({
          ...p,
          contentType: p.contentType,
          imagePath: p.imagePath,
          actualWidth: null,
          actualHeight: null,
        })),
        administratorOnlyNotes: jobInstance.administratorOnlyNotes,
      };
    } else {
      return {
        highlightCrewNotes: false,
        showCrewNotesOnAdminJobCards: false,
        jobNotes: "",
        useCustomTodoItems: false,
        todoItems: [],
        todoTemplateId: "",
        photos: [],
        administratorOnlyNotes: "",
      };
    }
  }

  getJobInstance(): IJobInstance | undefined {
    const jobInstanceId = this.props.jobInstanceId;
    let jobInstance: IJobInstance | undefined = undefined;
    this.props.daySchedules.forEach((ds) => {
      ds.jobInstances.forEach((ji) => {
        if (ji.id === jobInstanceId) {
          jobInstance = ji;
        }
      });
    });

    if (!jobInstance) {
      this.props.weeksUnscheduledMaintenanceJobs.forEach((ds) => {
        ds.jobInstances.forEach((ji) => {
          if (ji.id === jobInstanceId) {
            jobInstance = ji;
          }
        });
      });
    }

    return jobInstance;
  }

  getFormData() {
    return {
      ...this.state.formData,
      todoItems: this.state.formData.todoItems.filter((i) => i.text.trim()),
      todoTemplateId: this.state.formData.todoTemplateId || null,
    };
  }

  validate() {
    if (this.state.hasPendingPhotoAdd) {
      return {
        valid: false,
        errorMessage: "Please wait until all photos are uploaded",
      };
    }

    return { valid: true };
  }

  getUpdatedTodoItems(useCustomItems: boolean): Partial<IFormData> {
    if (
      useCustomItems &&
      !this.state.formData.todoTemplateId &&
      this.state.formData.todoItems.length === 0
    ) {
      const jobInstance = this.getJobInstance();
      if (
        jobInstance &&
        jobInstance.type === maintenanceJobType &&
        jobInstance.jobId
      ) {
        const job = jobFinder.getJobById(this.props.jobs, jobInstance.jobId);
        if (job) {
          if (job.todoTemplateId) {
            return {
              todoTemplateId: job.todoTemplateId,
              todoItems: [],
            };
          } else {
            return {
              todoTemplateId: "",
              todoItems: job.todoItems.map((t) => ({ ...t, tempId: uuidv4() })),
            };
          }
        }
      }
    }

    return {};
  }

  render() {
    const { formData } = this.state;
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
    } = this.props;

    const jobInstance = this.getJobInstance();
    if (!jobInstance) {
      return null;
    }

    return (
      <FormContainer
        setErrorMessage={setErrorMessage}
        validate={this.validate}
        getFormData={this.getFormData}
        formHeader="Job Card Notes"
        showForm={showForm}
        errorMessage={errorMessage}
        saving={saving}
        startSave={startSave}
        cancel={cancel}
        formKey="crewNotes"
        hasFormDataChanged={() => {
          return (
            JSON.stringify(this.state.formData) !==
            JSON.stringify(this.state.originalFormData)
          );
        }}
      >
        {!this.props.showCustomerJobHistory ? (
          <div
            className="alert alert-info"
            style={{ padding: "5px", paddingLeft: "10px" }}
            data-testid="customerHistoryAlert"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            <small>
              Give your crew members access to view historic job notes, crew
              notes and attachments. Click{" "}
              <LinkButton2
                buttonContents={<small>here</small>}
                style={{
                  textDecoration: "underline",
                }}
                inlineButton
                className="text-reset"
                onClick={() => {
                  fullStoryTrack("Notes Form - Open Crew View Configuration");
                  this.props.showCrewViewConfigurationForm({});
                }}
                testId="openCrewViewConfiguration"
              />{" "}
              and select Customer Job History check box!
            </small>
          </div>
        ) : null}
        <div className="form-group">
          <label htmlFor="administratorOnlyNotes">
            Administrator only notes
          </label>
          <TextareaAutosize
            maxRows={10}
            className="form-control"
            name="administratorOnlyNotes"
            id="administratorOnlyNotes"
            value={formData.administratorOnlyNotes}
            onChange={(e) => {
              this.setState({
                formData: {
                  ...formData,
                  administratorOnlyNotes: e.currentTarget.value,
                },
              });
            }}
          />
        </div>
        <h5>Details for crew</h5>
        <div className="form-group">
          <label htmlFor="jobNotes">Job card notes</label>
          <TextareaAutosize
            maxRows={10}
            className="form-control"
            name="jobNotes"
            id="jobNotes"
            value={formData.jobNotes}
            onChange={(e) => {
              this.setState({
                formData: {
                  ...formData,
                  jobNotes: e.currentTarget.value,
                },
              });
            }}
          />
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="highlightCrewNotes"
              name="highlightCrewNotes"
              checked={formData.highlightCrewNotes}
              onChange={(e) => {
                this.setState({
                  formData: {
                    ...formData,
                    highlightCrewNotes: e.target.checked,
                  },
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="highlightCrewNotes"
            >
              Show note on crew schedule homepage
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showCrewNotesOnAdminJobCards"
              name="showCrewNotesOnAdminJobCards"
              checked={formData.showCrewNotesOnAdminJobCards}
              onChange={(e) => {
                this.setState({
                  formData: {
                    ...formData,
                    showCrewNotesOnAdminJobCards: e.target.checked,
                  },
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showCrewNotesOnAdminJobCards"
            >
              Show note on admin job cards
            </label>
          </div>
        </div>
        {jobInstance.type !== oneTimeJobType && !jobInstance.todoItemsLocked ? (
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="useCustomTodoItems"
                checked={formData.useCustomTodoItems}
                disabled={jobInstance.todoItemsLocked}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      ...this.getUpdatedTodoItems(value),
                      useCustomTodoItems: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="useCustomTodoItems"
              >
                Use custom checklist items
              </label>
            </div>
          </div>
        ) : null}
        {formData.useCustomTodoItems || jobInstance.type === oneTimeJobType ? (
          <JobTodoItems
            value={formData.todoItems}
            todoTemplateId={formData.todoTemplateId}
            locked={jobInstance.todoItemsLocked}
            onChange={(newTodoItems, newTodoTemplateId) => {
              this.setState({
                formData: {
                  ...formData,
                  todoTemplateId: newTodoTemplateId,
                  todoItems: newTodoItems.map((i) => ({
                    ...i,
                    id: i.id || "",
                    completed: false,
                  })),
                },
              });
            }}
          />
        ) : null}
        {!!this.state.notesFromPreviousJob ? (
          <div className="form-group">
            <label>Notes From Previous Job</label>
            <TextareaAutosize
              maxRows={10}
              className="form-control"
              value={this.state.notesFromPreviousJob}
              readOnly={true}
            />
          </div>
        ) : null}

        <div className="form-group">
          <Files
            header="Attachments"
            showHeader={true}
            files={this.state.formData.photos}
            tenantId={this.props.tenantId || ""}
            imagePrefix={this.props.imagePrefix}
            onFileUploadingStatusChange={(hasPendingPhotoAdd) => {
              this.setState({
                hasPendingPhotoAdd,
              });
            }}
            onFileAdded={(photo) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: [
                    ...this.state.formData.photos,
                    {
                      ...photo,
                    },
                  ],
                },
              });
            }}
            onFileRemoved={(photoId, imagePath) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: this.state.formData.photos.filter(
                    (p) => !isPhoto(photoId, imagePath, p)
                  ),
                },
              });
            }}
            onFileUpdated={(photoId, imagePath, caption) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: this.state.formData.photos.map((p) => {
                    if (isPhoto(photoId, imagePath, p)) {
                      return {
                        ...p,
                        caption,
                      };
                    } else {
                      return p;
                    }
                  }),
                },
              });
            }}
          />
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
  jobs: state.job.jobs,
  jobInstanceId: state.forms.crewNotes.parameters
    ? state.forms.crewNotes.parameters.jobInstanceId
    : null,
  showForm: state.forms.crewNotes.showForm,
  errorMessage: state.forms.crewNotes.errorMessage,
  saving: state.forms.crewNotes.saving,
  tenantId: state.common.tenantId,
  imagePrefix: state.common.imagePrefix || "",
  showCustomerJobHistory:
    state.common.crewViewConfiguration.showCustomerJobHistory,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.crewNotes.startSaving,
  cancel: actionCreators.forms.crewNotes.cancelForm,
  setErrorMessage: actionCreators.forms.crewNotes.setErrorMessage,
  showCrewViewConfigurationForm:
    actionCreators.forms.crewViewConfiguration.showForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewNotesForm);
