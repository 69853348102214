import dataProvider from "../services/dataProvider";
import { Observable, EMPTY } from "rxjs";
import { IRootState } from "../store";
import { loadCustomersAndChildRecords } from "../services/customerService";
import { reconnect } from "../services/signalr";
import { IInvoiceRetryChargeParameters } from "./formParameters/IInvoiceRetryChargeParameters";
import { IInvoiceFormParameters } from "./formParameters/IInvoiceFormParameters";
import invoiceDataProvider, {
  IInvoiceRetryChargeRequest,
} from "../slices/billing/services/invoiceDataProvider";
import { IBulkCopyJobsParameters } from "./formParameters/IBulkCopyJobsParameters";
import jobDataProvider, {
  CopyJobsRequest,
} from "../slices/schedule/services/jobDataProvider";

// Used for forms using FormContainerWithoutRedux
export enum FormTypesV2 {
  "quickBooksCustomer" = 1,
  "opportunity" = 2,
  "nonQuickBooksInvoiceItem" = 3,
  "proposal" = 4,
  "invoiceSendForm" = 5,
  "proposalSendForm" = 6,
  "batchEmailInvoiceForm" = 7,
  "multiDayJobForm" = 8,
  "proposalTemplateForm" = 9,
  "resendReceiptForm" = 10,
  "clientSupportExtendTrial" = 11,
  "linkQuickBooksCustomerForm" = 12,
  "userNotifications" = 13,
  "invoiceConfiguration" = 14,
  "quickBooksInvoiceItem" = 15,
  "fixInvoiceForQuickBooksForm" = 16,
  "jobInstanceTimeForm" = 17,
  "customerNotificationDefaults" = 18,
  "tollFreeVerificationForm" = 19,
  "updateJobCompletionInformation" = 20,
  "customerCommunicationReminders" = 21,
  "customerProposalEmailConfiguration" = 22,
  "invoicePayment" = 23,
  "reactivateSubscription" = 24,
  "worksheets" = 25,
  "worksheetForJobInstance" = 26,
}

const formTypes = {
  crew: {
    actionPrefix: "Crew",
    saveFunction: dataProvider.saveCrew,
  } as IFormType<any, any>,

  crewCategories: {
    actionPrefix: "crewCategories",
    saveFunction: dataProvider.saveCrewCategories,
  } as IFormType<any, any>,

  crewMember: {
    actionPrefix: "CrewMember",
    saveFunction: dataProvider.saveCrewMember,
  } as IFormType<any, any>,

  maintenanceJob: {
    actionPrefix: "MaintenanceJob",
    saveFunction: dataProvider.saveMaintenanceJob,
    timeout: 120_000,
  } as IFormType<any, any>,

  oneTimeJob: {
    actionPrefix: "OneTimeJob",
    saveFunction: dataProvider.saveOneTimeJob,
    timeout: 120_000,
    postSaveProcessor: (state, _, data): Observable<any> => {
      if (data.savedJobs) {
        // Find customers not loaded and get them loaded
        const customerIds = data.savedJobs.map((j: any) => j.customerId);
        const missingCustomerIds = customerIds.filter(
          (jobCustomerId: string) =>
            !state.customer.customers.some(
              (existingCustomer) => jobCustomerId === existingCustomer.id
            )
        );

        if (missingCustomerIds.length > 0) {
          return loadCustomersAndChildRecords(missingCustomerIds, state);
        }
      }

      return EMPTY;
    },
  } as IFormType<any, any>,

  moveJobInstance: {
    actionPrefix: "MoveJobInstance",
    saveFunction: dataProvider.moveJobInstance,
  } as IFormType<any, any>,

  markComplete: {
    actionPrefix: "MarkComplete",
    saveFunction: dataProvider.markComplete,
  } as IFormType<any, any>,

  registerNewCompany: {
    actionPrefix: "registerNewCompany",
    saveFunction: dataProvider.register,
    timeout: 30000,
    postSaveProcessor: () => {
      // Reconnect to signalr now that the user is associated with a tenant.
      // This will put them in the correct group to receive updates.
      reconnect();

      return EMPTY;
    },
  } as IFormType<any, any>,

  registerExistingCompany: {
    actionPrefix: "registerExistingCompany",
    saveFunction: dataProvider.register,
    postSaveProcessor: () => {
      // Reconnect to signalr now that the user is associated with a tenant.
      // This will put them in the correct group to receive updates.
      reconnect();

      return EMPTY;
    },
  } as IFormType<any, any>,

  publishSchedule: {
    actionPrefix: "publishSchedule",
    saveFunction: dataProvider.publishSchedule,
  } as IFormType<any, any>,

  crewNotes: {
    actionPrefix: "crewNotes",
    saveFunction: dataProvider.saveJobInstanceCrewNotes,
  } as IFormType<any, any>,

  crewViewConfiguration: {
    actionPrefix: "crewViewConfiguration",
    saveFunction: dataProvider.saveCrewViewConfiguration,
  } as IFormType<any, any>,

  adminViewConfiguration: {
    actionPrefix: "adminViewConfiguration",
    saveFunction: dataProvider.saveAdminViewConfiguration,
  } as IFormType<any, any>,

  billingDetails: {
    actionPrefix: "billingDetails",
    saveFunction: dataProvider.saveJobInstance,
  } as IFormType<any, any>,

  todoTemplate: {
    actionPrefix: "todoTemplate",
    saveFunction: dataProvider.saveTodoTemplate,
  } as IFormType<any, any>,

  daySchedule: {
    actionPrefix: "daySchedule",
    saveFunction: dataProvider.saveDaySchedule,
  } as IFormType<any, any>,

  customer: {
    actionPrefix: "customer",
    saveFunction: dataProvider.saveCustomer,
  } as IFormType<any, any>,

  invoice: {
    actionPrefix: "invoice",
    saveFunction: dataProvider.saveInvoice,
    timeout: 20000,
    timeoutError: (payload: any, state: IRootState) => {
      // Customize timeout message when adding an invoice
      if (!payload.id && state.common.isQuickBooksEnabled) {
        return "An error occurred while saving. Please ensure the invoice was not already created in QuickBooks Online then and try again.";
      } else {
        return null;
      }
    },
  } as IFormType<IInvoiceFormParameters, any>,

  customerAdditionalLocation: {
    actionPrefix: "customerAdditionalLocation",
    saveFunction: dataProvider.saveCustomerAdditionalLocation,
  } as IFormType<any, any>,

  shiftSchedules: {
    actionPrefix: "shiftSchedules",
    saveFunction: dataProvider.shiftSchedules,
    timeout: 60000,
  } as IFormType<any, any>,

  scheduledDispatchSettings: {
    actionPrefix: "scheduledDispatchSettings",
    saveFunction: dataProvider.saveScheduledDispatchSettings,
  } as IFormType<any, any>,

  customerNotification: {
    actionPrefix: "customerNotification",
    saveFunction: dataProvider.customerNotification,
  } as IFormType<any, any>,

  customerNotificationDefaults: {
    actionPrefix: "customerNotificationDefaults",
    saveFunction: dataProvider.customerNotification,
  } as IFormType<any, any>,

  customerContractBilling: {
    actionPrefix: "customerContractBilling",
    saveFunction: dataProvider.saveCustomerPaymentConfiguration,
  } as IFormType<any, any>,

  payrollTimeRange: {
    actionPrefix: "payrollTimeRange",
    saveFunction: dataProvider.savePayrollTimeRange,
  } as IFormType<any, any>,

  subscription: {
    actionPrefix: "subscription",
  } as IFormType<any, any>,

  companyProfile: {
    actionPrefix: "companyProfile",
    saveFunction: dataProvider.saveTenant,
  } as IFormType<any, any>,

  customerNotificationsConfiguration: {
    actionPrefix: "customerNotificationsConfiguration",
    saveFunction: dataProvider.saveCustomerNotificationsConfiguration,
  } as IFormType<any, any>,

  customerPaymentMethod: {
    actionPrefix: "customerPaymentMethod",
  } as IFormType<any, any>,

  invoiceRetryCharge: {
    actionPrefix: "invoiceRetryCharge",
    saveFunction: invoiceDataProvider.saveInvoiceRetryCharge,
  } as IFormType<IInvoiceRetryChargeParameters, IInvoiceRetryChargeRequest>,

  bulkCopyJobs: {
    actionPrefix: "bulkCopyJobs",
    saveFunction: jobDataProvider.copyJobs,
  } as IFormType<IBulkCopyJobsParameters, CopyJobsRequest>,
};

export { formTypes };

// Generic is used by types derived in actionCreators and formReducer
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IFormType<TParameters, TPayload> {
  actionPrefix: string;
  saveFunction?: Function;
  timeout?: number;
  timeoutError?: string | ((payload: any, state: IRootState) => string);
  postSaveProcessor?: (
    state: IRootState,
    parameters: TParameters,
    data: any
  ) => Observable<any>;
}
