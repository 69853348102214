import {
  faCheck,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import CardAddress from "../../../containers/app/components/CardAddress";
import CardCustomerName from "../../../containers/app/components/CardCustomerName";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import dateService from "../../../services/dateService";
import { isImageByType } from "../../../services/fileService";
import { preventClearingAllCards } from "../../../services/selectedCardService";
import { OpportunityProposalStatus } from "../enums/opportunityProposalStatus";
import { IOpportunity } from "../models/IOpportunity";
import { IProposalExisting } from "../models/IProposal";
import { opportunitiesActionCreators } from "../modules/opportunity";
import OpportunityCardContainer from "./OpportunityCardContainer";
import OpportunityCardContextMenu from "./OpportunityCardContextMenu";
import CardImages from "../../../containers/app/components/CardImages";
import ProposalStatusSelection from "./ProposalStatusSelection";
import ProposalViewedIndicator from "./ProposalViewedIndicator";
import ProposalRejectedReason from "./ProposalRejectedReason";
import { getTotal } from "../../../services/lineItemService";
import { formatCurrency } from "../../../services/currencyFormatter";
import CardNonImageFiles from "../../../containers/app/components/CardNonImageFiles";
import { ProposalDepositStatus } from "../../../enums/proposalDepositStatus";
import CardPhoneNumbers from "../../../containers/app/components/CardPhoneNumbers";
import { isStringSet } from "../../../services/stringService";
import { showPaymentMethodNotAuthorizedIndicator } from "./OpportunityCard.functions";
import { round } from "../../../services/roundingService";
import { getNameToShow } from "../services/opportunityService";
import { DepositType } from "../../../enums/DepositType";
import React from "react";
import { NoteWithHyperlinks } from "../../../containers/app/components/NoteWithHyperlinks";

interface IProps {
  opportunity: IOpportunity;
  index: number;
  isProposalLane?: boolean;
  scrollIntoView: boolean;
}

const OpportunityCard: React.FunctionComponent<IProps> = ({
  opportunity,
  index,
  isProposalLane,
  scrollIntoView,
}) => {
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const opportunitiesMoving = useApplicationStateSelector(
    (s) => s.opportunity.opportunitiesMoving
  );
  const opportunitiesSelected = useApplicationStateSelector(
    (s) => s.opportunity.opportunitiesSelected
  );
  const proposals = useApplicationStateSelector((s) => s.proposal.proposals);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isUnarchiving, setIsUnarchiving] = useState(false);
  const [isProposalStatusUpdating, setIsProposalStatusUpdating] =
    useState(false);

  const dispatch = useDispatch();

  const { divRef } = useScrollIntoView(scrollIntoView);

  const selected = opportunitiesSelected.some((o) => o === opportunity.id);

  const assignedToName = useGetAssignedToName({ opportunity });

  var { nameToShow, customer, address } = getNameToShow(
    customers,
    opportunity,
    customerAdditionalLocations
  );

  const imageFiles = opportunity.files.filter((f) =>
    isImageByType(f.contentType)
  );
  const nonImageFiles = opportunity.files.filter(
    (f) => !imageFiles.includes(f)
  );

  const proposal = getProposal(opportunity, proposals);

  const hasFiles = imageFiles.length > 0 || nonImageFiles.length > 0;
  const isMoving = opportunitiesMoving.some((m) => m === opportunity.id);
  const isSaving =
    isMoving || isDeleting || isUnarchiving || isProposalStatusUpdating;
  const hasProposalBeenViewed = !!proposal?.lastViewedDateTime;
  const isRejectedReasonSet = isStringSet(proposal?.rejectedReason ?? null);
  const showIconAndProposalStatusBar =
    hasFiles ||
    isSaving ||
    isProposalLane ||
    hasProposalBeenViewed ||
    isRejectedReasonSet;

  let { containerClasses, useWhiteText } = getCardStyling(
    opportunity,
    isProposalLane,
    selected
  );

  return (
    <div ref={divRef} data-testid="opportunityCardForScrolling">
      <Draggable
        draggableId={opportunity.id}
        index={index}
        isDragDisabled={isMobile && !selected}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              ),
              userSelect: "none",
            }}
            onClick={(e) => {
              preventClearingAllCards(e);
              dispatch(
                opportunitiesActionCreators.toggleSelectOpportunity({
                  opportunityId: opportunity.id,
                })
              );
            }}
          >
            <OpportunityCardContainer
              style={{ minHeight: "45px" }}
              selected={selected}
              additionalClasses={containerClasses}
            >
              <div className="row">
                <div className="col-6 col-md-12 col-xl-6">
                  <div
                    className="font-weight-bold"
                    data-testid="opportunityName"
                    style={{ minHeight: "1.5rem" }}
                  >
                    <CardCustomerName
                      customerId={opportunity.customerId}
                      customerName={nameToShow}
                      entityId={opportunity.id}
                      useWhiteText={useWhiteText}
                    />
                  </div>
                  {customer ? (
                    <>
                      <CardPhoneNumbers
                        phoneNumber={customer.phoneNumber}
                        alternativePhoneNumber={customer.alternativePhoneNumber}
                        useWhiteText={useWhiteText}
                      />

                      <CardAddress
                        address={address ?? customer}
                        entityId={opportunity.id}
                        useWhiteText={useWhiteText}
                      />
                    </>
                  ) : null}

                  {isStringSet(proposal?.proposalNumber ?? null) ? (
                    <div className="mt-2" data-testid="proposalNumber">
                      Estimate #: {proposal?.proposalNumber}
                    </div>
                  ) : null}

                  {opportunity.notes ? (
                    <NoteWithHyperlinks
                      notes={opportunity.notes}
                      testId="opportunityNotesContainer"
                    />
                  ) : null}

                  {proposal?.lineItems && proposal?.lineItems?.length > 0 ? (
                    <div data-testid="totalAmountContainer">
                      Total:{" "}
                      {formatCurrency(
                        getTotal({
                          taxRate: proposal.taxRate,
                          lineItems: proposal.lineItems.map((li) => {
                            return {
                              id: li.id,
                              amountPerItem: li.amount,
                              quantity: li.quantity,
                              taxable: li.taxable,
                              optional: li.optional,
                              selected: li.selected,
                            };
                          }),
                          discount: proposal.discount,
                        })
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="col-6 col-md-12 col-xl-6">
                  {isStringSet(assignedToName) ? (
                    <div className="mt-1" data-testid="assignedToContainer">
                      Assigned to: {assignedToName}
                    </div>
                  ) : null}

                  {proposal ? (
                    <div className="mt-1" data-testid="validUntilContainer">
                      Valid until:{" "}
                      {dateService.formatDateForDisplay(
                        proposal.validUntilDate
                      )}
                    </div>
                  ) : null}

                  {opportunity.proposalDepositStatus ===
                    ProposalDepositStatus.none &&
                  (proposal?.depositSettings ?? null) !== null ? (
                    <div
                      data-testid="depositRequiredStatusContainer"
                      className="mt-1"
                    >
                      Deposit required:{" "}
                      {proposal?.depositSettings?.type ===
                      DepositType.percent ? (
                        <>
                          {round(
                            (proposal?.depositSettings?.percent ?? 0) * 100,
                            0
                          )}
                          %
                        </>
                      ) : (
                        formatCurrency(proposal?.depositSettings?.amount ?? 0)
                      )}
                    </div>
                  ) : null}

                  {opportunity.lastSentDateTime ? (
                    <div className="mt-1" data-testid="sentContainer">
                      {`Sent: ${dateService.formatDateTimeForDateTimeDisplay(
                        opportunity.lastSentDateTime
                      )}`}
                    </div>
                  ) : null}

                  {opportunity.proposalDepositStatus ===
                  ProposalDepositStatus.unpaid ? (
                    <div className="font-weight-bold mt-1">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="lg"
                        className="mr-1 text-danger"
                      />
                      Deposit unpaid
                    </div>
                  ) : null}

                  {opportunity.proposalDepositStatus ===
                  ProposalDepositStatus.paid ? (
                    <div className="font-weight-bold mt-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="lg"
                        className="mr-1"
                      />
                      Deposit paid
                      {opportunity.proposalDepositTransactionDate
                        ? ` on ${dateService.formatDateForDisplay(
                            opportunity.proposalDepositTransactionDate
                          )}`
                        : ""}
                    </div>
                  ) : null}

                  {showPaymentMethodNotAuthorizedIndicator(
                    opportunity,
                    proposal
                  ) ? (
                    <div
                      className="font-weight-bold mt-1"
                      data-testid="paymentMethodNotAuthorizedContainer"
                    >
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="lg"
                        className="mr-1 text-danger"
                      />
                      Payment method not authorized
                    </div>
                  ) : proposal?.paymentMethodOnFileAuthorized ? (
                    <div
                      className="font-weight-bold mt-1"
                      data-testid="paymentMethodAuthorizedContainer"
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="lg"
                        className="mr-1"
                      />
                      Payment method authorized
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                className="mt-2 d-flex justify-content-between align-items-baseline"
                style={{
                  display: !showIconAndProposalStatusBar ? "none" : undefined,
                }}
              >
                <div className="d-flex" style={{ columnGap: "10px" }}>
                  {isSaving ? (
                    <div>
                      <FontAwesomeIcon
                        size="lg"
                        icon={faSpinner}
                        fixedWidth={true}
                        spin={true}
                        title="Saving..."
                      />
                    </div>
                  ) : null}

                  <ProposalRejectedReason proposal={proposal} />

                  <ProposalViewedIndicator proposal={proposal} />

                  <CardImages imageFiles={imageFiles} />

                  <CardNonImageFiles
                    nonImageFiles={nonImageFiles}
                    id={opportunity.id}
                  />
                </div>

                {isProposalLane ? (
                  <div
                    onClick={(e) => {
                      // Stop propagation to prevent card from being selected
                      e.stopPropagation();
                    }}
                  >
                    <ProposalStatusSelection
                      setIsProposalStatusUpdating={setIsProposalStatusUpdating}
                      opportunity={opportunity}
                      proposal={proposal}
                      customerName={customer?.name ?? ""}
                    />
                  </div>
                ) : null}
              </div>
              {customer ? (
                <OpportunityCardContextMenu
                  opportunity={opportunity}
                  proposal={proposal}
                  setIsDeleting={setIsDeleting}
                  setIsUnarchiving={setIsUnarchiving}
                  customer={customer}
                  isProposalLane={isProposalLane ?? false}
                />
              ) : null}
            </OpportunityCardContainer>
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default OpportunityCard;

function getCardStyling(
  opportunity: IOpportunity,
  isProposalLane: boolean | undefined,
  selected: boolean
) {
  let containerClasses = "";
  let useWhiteText = false;
  if (isProposalLane && !selected) {
    if (opportunity.proposalStatus === OpportunityProposalStatus.Accepted) {
      containerClasses = "bg-primary";
      useWhiteText = true;
    } else if (
      opportunity.proposalStatus === OpportunityProposalStatus.Scheduled
    ) {
      containerClasses = "bg-success";
      useWhiteText = true;
    } else if (
      opportunity.proposalStatus === OpportunityProposalStatus.Rejected
    ) {
      containerClasses = "bg-warning";
      useWhiteText = true;
    }
  }

  if (useWhiteText) {
    containerClasses += " text-white";
  }

  return { containerClasses, useWhiteText };
}

function getItemStyle(isDragging: boolean, draggableStyle: any) {
  const s: CSSProperties = {
    margin: "0 0 8px 0",
    ...draggableStyle,
  };

  if (isDragging) {
    s.filter = "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.5))";
  }

  return s;
}

function getProposal(
  opportunity: IOpportunity,
  proposals: Array<IProposalExisting>
) {
  if (!opportunity.proposalId) {
    return null;
  }

  return proposals.find((p) => p.id === opportunity.proposalId) ?? null;
}

function useScrollIntoView(scrollIntoView: boolean) {
  const divRef = useRef<HTMLDivElement>(null);

  const hasScrolled = useRef(false);

  useEffect(() => {
    if (scrollIntoView && !hasScrolled.current) {
      if (divRef.current) {
        divRef.current.scrollIntoView();

        // Adjust for header lane headers that are sticky.
        // There doesn't appear to be a way to add offset to scrollIntoView
        window.scrollBy(0, -40);

        hasScrolled.current = true;
      }
    }
  }, [scrollIntoView]);

  return { divRef };
}

function useGetAssignedToName({ opportunity }: { opportunity: IOpportunity }) {
  const userAccounts = useApplicationStateSelector(
    (s) => s.common.userAccounts
  );

  if (!isStringSet(opportunity.assignedTo)) {
    return "";
  } else {
    const userAccount = userAccounts.find(
      (ua) => ua.id === opportunity.assignedTo
    );

    if (!userAccount) {
      return "";
    } else if (isStringSet(userAccount.name)) {
      return userAccount.name;
    } else {
      return userAccount.emailAddress;
    }
  }
}
