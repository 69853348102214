import React, { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { Link } from "react-router-dom";
import { builders } from "../../../services/routing";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { isStringSet } from "../../../services/stringService";
import { IAddress } from "../../../models/IAddress";
import addressFormatter from "../../../services/addressFormatter";

interface IProps {
  recordId: string;
  customerId: string;
  customerName: string | JSX.Element;
  customerPhoneNumber: string | null;
  customerAlternativePhoneNumber: string | null;
  customerAddress: IAddress | null;
  idPrefix: string;
}

const BillingListCustomerPopover: React.FunctionComponent<IProps> = ({
  recordId,
  customerId,
  customerName,
  customerPhoneNumber,
  customerAlternativePhoneNumber,
  customerAddress,
  idPrefix,
}) => {
  const [showLink, setShowLink] = useState(false);
  const spanId = `${idPrefix}_name_${recordId}`;

  return (
    <>
      <span id={spanId}>
        <LinkButton2
          buttonContents={customerName}
          onClick={() => setShowLink(true)}
          testId="customerNameLink"
          style={{ textAlign: "left" }}
          inlineButton
        />
      </span>
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showLink}
        target={spanId}
        toggle={() => {
          setShowLink(!showLink);
        }}
      >
        <PopoverBody>
          <Link
            to={builders.manage.buildCustomerDetailsRoute({
              customerId: customerId,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setShowLink(false);
            }}
          >
            <strong data-testid="openCustomerRecord">
              Open Customer Record
            </strong>
          </Link>

          {customerAddress ? (
            <div className="mt-2" data-testid="addressContainer">
              <Address address={customerAddress} />
            </div>
          ) : null}

          {isStringSet(customerPhoneNumber) ? (
            <PhoneNumber
              containerTestId="phoneNumberContainer"
              phoneNumberToDisplay={customerPhoneNumber}
            />
          ) : null}

          {isStringSet(customerAlternativePhoneNumber) ? (
            <PhoneNumber
              containerTestId="alternativePhoneNumberContainer"
              prefix={"Alt: "}
              phoneNumberToDisplay={customerAlternativePhoneNumber}
            />
          ) : null}
        </PopoverBody>
      </Popover>
    </>
  );
};

export default BillingListCustomerPopover;

function Address({ address }: { address: IAddress }) {
  const formattedAddress = addressFormatter.formatAddressEntity(address);

  return (
    <a
      href={addressFormatter.getGoogleMapsAddressLink(address)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
      data-testid="address-link"
    >
      {formattedAddress}
    </a>
  );
}

function PhoneNumber({
  phoneNumberToDisplay,
  containerTestId,
  prefix,
}: {
  phoneNumberToDisplay: string;
  containerTestId: string;
  prefix?: string;
}) {
  return (
    <div className="mt-2" data-testid={containerTestId}>
      <a
        href={"tel:" + phoneNumberToDisplay}
        onClick={(e) => e.stopPropagation()}
      >
        {prefix ?? ""}
        {phoneNumberToDisplay}
      </a>
    </div>
  );
}
