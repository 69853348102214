import CustomerSelection, {
  IOption,
} from "../../../containers/app/components/CustomerSelection";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { useEffect, useRef, useState } from "react";
import CustomerAdditionalLocationSelection from "../../../containers/app/components/CustomerAdditionalLocationSelection";
import { IFormDataFile } from "../../../containers/app/components/files/ExistingFile";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import TextareaAutosize from "react-autosize-textarea";
import { opportunitiesActionCreators } from "../modules/opportunity";
import { IOpportunity } from "../models/IOpportunity";
import Files from "../../../containers/app/components/files/Index";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { isPhoto } from "../../../services/fileService";
import CustomerAdditionalLocationInfoToolTip from "../../../containers/app/components/CustomerAdditionalLocationInfoToolTip";
import { logError } from "../../../services/errorLogger";
import opportunityDataProvider from "../services/opportunityDataProvider";
import { SelectInstance } from "react-select";
import SubscriptionWarning from "../../tenantSubscription/components/SubscriptionWarning";
import { getAssignedToOptions } from "../services/opportunityService";
import CustomerBalanceWarning from "../../../containers/app/components/CustomerBalanceWarning";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IProps {
  defaultCustomerId?: string;
  opportunityId?: string;
  onSaveComplete: () => void;
  onCancel: () => void;
}

interface IFormData {
  customerId: string | null;
  customerAdditionalLocationId: string | null;
  notes: string;
  files: Array<IFormDataFile>;
  assignedTo: string;
}

const OpportunityForm: React.FunctionComponent<IProps> = ({
  defaultCustomerId,
  opportunityId,
  onSaveComplete,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const userAccounts = useApplicationStateSelector(
    (s) => s.common.userAccounts
  );
  const [fileUploading, setFileUploading] = useState(false);

  const opportunities = useApplicationStateSelector(
    (s) => s.opportunity.opportunities
  );

  const customerElement = useRef<SelectInstance<IOption>>(null);

  useEffect(() => {
    // Timeout to allow ref to be set
    setTimeout(() => {
      if (!opportunityId) {
        customerElement.current?.focus();
      }
    });
  }, [opportunityId]);

  const { watch, control, setValue, getValues, register } = useForm<IFormData>({
    defaultValues: getDefaultValues(
      opportunityId,
      defaultCustomerId,
      opportunities
    ),
  });
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const customerId = watch("customerId");

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <SubscriptionWarning
        mode="alwaysLockedWhenNotSubscribed"
        onCancel={() => {
          onCancel();
        }}
      >
        <FormContainerWithoutRedux
          formHeader={(opportunityId ? "Edit" : "Add") + " Opportunity"}
          formType={FormTypesV2.opportunity}
          save={() =>
            opportunityDataProvider.saveOpportunity({
              id: opportunityId,
              ...getValues(),
            } as Partial<IOpportunity>)
          }
          onSaveComplete={(data) => {
            dispatch(
              opportunityId
                ? opportunitiesActionCreators.editOpportunity(data)
                : opportunitiesActionCreators.addOpportunity(data)
            );
            onSaveComplete();
          }}
          onCancel={() => {
            onCancel();
          }}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          validate={() => {
            if (fileUploading) {
              return {
                valid: false,
                errorMessage: "Please wait until all files are uploaded",
              };
            }

            if (!getValues("customerId")) {
              return {
                valid: false,
                errorMessage: "A customer must be selected",
              };
            }

            return {
              valid: true,
            };
          }}
        >
          <h5>Contact Information</h5>
          <div className="form-section">
            <div className="form-group" data-testid="customerContainer">
              <label htmlFor="customerId" className="required">
                Customer
              </label>
              <Controller
                name="customerId"
                control={control}
                render={({ field }) => (
                  <CustomerSelection
                    inputId="customerId"
                    selectRef={customerElement}
                    includeGroups={false}
                    value={{
                      recordType: "customer",
                      id: field.value,
                    }}
                    onCustomerClear={() => {
                      field.onChange(null);
                    }}
                    onCustomerSelection={(type, selectedValue) => {
                      if (type === "customer") {
                        const newCustomerId = selectedValue;
                        field.onChange(newCustomerId);
                        setValue("customerAdditionalLocationId", null);
                      }
                    }}
                  />
                )}
              />
              {customerId ? (
                <div>
                  <CustomerBalanceWarning customerId={customerId} />
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={() =>
                      dispatch(
                        actionCreators.forms.customer.showForm({
                          customerId,
                        })
                      )
                    }
                  >
                    Edit customer
                  </button>
                </div>
              ) : null}
            </div>
            <div className="form-group" data-testid="jobLocationContainer">
              <label htmlFor="customerAdditionalLocationId">Job location</label>
              <CustomerAdditionalLocationInfoToolTip />
              <Controller
                name="customerAdditionalLocationId"
                control={control}
                render={({ field }) => (
                  <CustomerAdditionalLocationSelection
                    inputId="customerAdditionalLocationId"
                    value={field.value}
                    customerId={customerId || ""}
                    onCustomerAdditionalLocationSelection={(
                      customerAdditionalLocationId
                    ) => {
                      field.onChange(customerAdditionalLocationId);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <h5>Opportunity</h5>
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="assignedTo">Assigned to</label>
              <select
                id="assignedTo"
                {...register("assignedTo")}
                className="form-control"
              >
                <option value=""></option>
                {getAssignedToOptions(userAccounts).map((ua) => (
                  <option key={ua.id} value={ua.id}>
                    {ua.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="notes">Notes</label>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    maxRows={10}
                    id="notes"
                    className="form-control"
                    name="notes"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="files"
            control={control}
            render={({ field }) => (
              <Files
                header="Attachments"
                showHeader
                files={field.value}
                tenantId={tenantId ?? ""}
                imagePrefix={imagePrefix ?? ""}
                allowExcelAndWord={true}
                onFileUploadingStatusChange={(hasPendingFileAdd) => {
                  setFileUploading(hasPendingFileAdd);
                }}
                onFileAdded={(photo) => {
                  field.onChange([...field.value, photo]);
                }}
                onFileRemoved={(photoId, imagePath) => {
                  field.onChange(
                    field.value.filter((p) => !isPhoto(photoId, imagePath, p))
                  );
                }}
                onFileUpdated={(photoId, imagePath, caption) => {
                  field.onChange(
                    field.value.map((p) => {
                      if (isPhoto(photoId, imagePath, p)) {
                        return {
                          ...p,
                          caption,
                        };
                      } else {
                        return p;
                      }
                    })
                  );
                }}
              />
            )}
          />
        </FormContainerWithoutRedux>
      </SubscriptionWarning>
    </div>
  );
};

export default OpportunityForm;

function getDefaultValues(
  opportunityId: string | undefined,
  defaultCustomerId: string | undefined,
  opportunities: IOpportunity[]
) {
  let defaultValues: IFormData = {
    customerId: defaultCustomerId ?? null,
    customerAdditionalLocationId: null,
    notes: "",
    files: [],
    assignedTo: "",
  };
  if (opportunityId) {
    const opportunity = opportunities.find((o) => o.id === opportunityId);
    if (opportunity) {
      defaultValues = {
        ...opportunity,
        assignedTo: opportunity.assignedTo ?? "",
        files: opportunity.files.map((f) => ({
          ...f,
          actualWidth: null,
          actualHeight: null,
        })),
      };
    } else {
      logError(`opportunity ${opportunityId} not found`);
    }
  }

  return defaultValues;
}
