import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import modalConversion from "../../../services/modelConversion";
import { IRootState } from "../../../store";
import addressFormatter from "../../../services/addressFormatter";
import AddressComponents2, {
  IAddressComponents,
} from "../components/AddressComponents2";
import {
  validateLatitudeOptional,
  validateLongitudeOptional,
} from "../../../services/latLngService";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import TextareaAutosize from "react-autosize-textarea";

interface IProps {
  customerId: string | null;
  customerAdditionalLocationId: string | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(payload: any): void;
  cancel(): void;
  setErrorMessage(message: string): void;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  defaultStreetAndNumber: string | null | undefined;
  defaultName: string | null | undefined;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
}

interface IFormData {
  id?: string;
  name: string;
  customerId: string;
  inactive: boolean;
  address: IAddressComponents;
  administratorOnlyNotes: string;
  notesForCrew: string;
}

class CustomerAdditionalLocationForm extends React.Component<IProps, IState> {
  private addressRef: React.RefObject<HTMLInputElement>;
  private nameRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    const formData = this.getEmptyFormData();
    this.state = {
      formData: formData,
      originalFormData: formData,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.validate = this.validate.bind(this);
    this.getFormData = this.getFormData.bind(this);

    this.addressRef = React.createRef();
    this.nameRef = React.createRef();
  }

  getEmptyAddress(): IAddressComponents {
    return {
      city: "",
      latitude: "",
      longitude: "",
      state: "",
      streetAndNumber: "",
      apartmentSuite: "",
      zip: "",
      latitudeSignificantDigits: null,
      longitudeSignificantDigits: null,
    };
  }

  getEmptyFormData(): IFormData {
    return {
      customerId: "",
      inactive: false,
      name: "",
      notesForCrew: "",
      administratorOnlyNotes: "",
      address: this.getEmptyAddress(),
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;

    if (props.showForm && prevProps.showForm !== props.showForm) {
      let formData: IFormData;
      if (!props.customerAdditionalLocationId) {
        formData = this.getEmptyFormData();

        if (!props.customerId) {
          throw new Error(
            "CustomerId not set when adding a new customer additional location"
          );
        } else {
          formData.customerId = props.customerId;
        }

        if (props.defaultStreetAndNumber) {
          formData.address.streetAndNumber = props.defaultStreetAndNumber;
        }

        if (props.defaultName) {
          formData.name = props.defaultName;
        }

        this.setState({
          formData,
          originalFormData: formData,
        });

        setTimeout(() => {
          if (!formData.name) {
            if (this.nameRef.current) {
              this.nameRef.current.focus();
            }
          } else {
            if (this.addressRef.current) {
              this.addressRef.current.focus();
            }
          }
        });
      } else {
        const customerAdditionalLocation =
          props.customerAdditionalLocations.find(
            (c) => c.id === props.customerAdditionalLocationId
          );
        if (customerAdditionalLocation) {
          formData = {
            customerId: customerAdditionalLocation.customerId,
            inactive: customerAdditionalLocation.inactive,
            name: customerAdditionalLocation.name || "",
            notesForCrew: customerAdditionalLocation.notesForCrew,
            administratorOnlyNotes:
              customerAdditionalLocation.administratorOnlyNotes,
            address: {
              city: customerAdditionalLocation.city || "",
              streetAndNumber: customerAdditionalLocation.streetAndNumber || "",
              apartmentSuite: customerAdditionalLocation.apartmentSuite || "",
              state: customerAdditionalLocation.state || "",
              latitude: customerAdditionalLocation.latitude
                ? addressFormatter.formatLatLng(
                    customerAdditionalLocation.latitude,
                    customerAdditionalLocation.latitudeSignificantDigits
                  )
                : "",
              longitude: customerAdditionalLocation.longitude
                ? addressFormatter.formatLatLng(
                    customerAdditionalLocation.longitude,
                    customerAdditionalLocation.longitudeSignificantDigits
                  )
                : "",
              latitudeSignificantDigits:
                customerAdditionalLocation.latitudeSignificantDigits,
              longitudeSignificantDigits:
                customerAdditionalLocation.longitudeSignificantDigits,
              placeId: customerAdditionalLocation.placeId,
              zip: customerAdditionalLocation.zip || "",
            },
          };
          this.setState({
            formData,
            originalFormData: formData,
          });
        } else {
          console.error("unable to find customer additional location");
        }
      }
    }
  }

  handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const fieldName = event.target.name;
    this.handleChangeWithFieldName(fieldName, event.target.value);
  }

  handleChangeWithFieldName(fieldName: string, value: any) {
    const formDataToUpdate = { ...this.state.formData };
    (formDataToUpdate as any)[fieldName as keyof IFormData] = value;

    this.setState({
      formData: formDataToUpdate,
    });
  }

  handleAddressChange(newAddressValue: IAddressComponents) {
    this.setState({
      formData: {
        ...this.state.formData,
        address: newAddressValue,
      },
    });
  }

  getFormData() {
    const formData: ICustomerAdditionalLocation = {
      name: this.state.formData.name,
      inactive: this.state.formData.inactive,
      customerId: this.state.formData.customerId,
      city: this.state.formData.address.city,
      latitude: modalConversion.convertStringToNumberOrNull(
        this.state.formData.address.latitude
      ),
      longitude: modalConversion.convertStringToNumberOrNull(
        this.state.formData.address.longitude
      ),
      latitudeSignificantDigits:
        this.state.formData.address.latitudeSignificantDigits,
      longitudeSignificantDigits:
        this.state.formData.address.longitudeSignificantDigits,
      placeId: this.state.formData.address.placeId,
      state: this.state.formData.address.state,
      streetAndNumber: this.state.formData.address.streetAndNumber,
      apartmentSuite: this.state.formData.address.apartmentSuite,
      zip: this.state.formData.address.zip,
      id: this.state.formData.id ? this.state.formData.id : "",
      administratorOnlyNotes: this.state.formData.administratorOnlyNotes,
      notesForCrew: this.state.formData.notesForCrew,
    };

    return formData;
  }

  validate() {
    const result = { valid: true, errorMessage: "" };

    const latitudeValidationResult = validateLatitudeOptional(
      this.state.formData.address.latitude
    );
    if (!latitudeValidationResult.valid) {
      return {
        valid: false,
        errorMessage: latitudeValidationResult.error,
      };
    }

    const longitudeValidationResult = validateLongitudeOptional(
      this.state.formData.address.longitude
    );
    if (!longitudeValidationResult.valid) {
      return {
        valid: false,
        errorMessage: longitudeValidationResult.error,
      };
    }

    return result;
  }

  render() {
    const {
      customerAdditionalLocations,
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      customerAdditionalLocationId,
      defaultStreetAndNumber,
    } = this.props;
    const { formData } = this.state;

    let formHeader = "Add Additional Location";
    if (customerAdditionalLocationId) {
      const customer = customerAdditionalLocations.find(
        (j) => j.id === customerAdditionalLocationId
      );
      if (customer) {
        formHeader = `Update Additional Location`;
      }
    }

    return (
      <React.Fragment>
        <FormContainer
          setErrorMessage={setErrorMessage}
          validate={this.validate}
          getFormData={this.getFormData}
          formHeader={formHeader}
          showForm={showForm}
          errorMessage={errorMessage}
          saving={saving}
          startSave={startSave}
          cancel={cancel}
          formKey="customer"
          disableFade={!!defaultStreetAndNumber}
          saveButtonText={
            customerAdditionalLocationId ? "Update location" : "Add location"
          }
          hasFormDataChanged={() => {
            return (
              JSON.stringify(this.state.formData) !==
              JSON.stringify(this.state.originalFormData)
            );
          }}
        >
          <div className="form-group">
            <label htmlFor="name">Location name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              value={formData.name}
              onChange={this.handleChange}
              placeholder="Type a name for your crews to see"
              ref={this.nameRef}
            />
          </div>
          <AddressComponents2
            streetAndNumberId="inputAddress"
            streetAndNumberName="inputAddress"
            value={formData.address}
            onChange={this.handleAddressChange}
            streetAndNumberRef={this.addressRef}
            showMap={true}
            showApartmentSuite
          />

          <div className="form-group">
            <label htmlFor="administratorOnlyNotes">
              Administrator only notes
            </label>
            <TextareaAutosize
              maxRows={10}
              id="administratorOnlyNotes"
              className="form-control"
              name="administratorOnlyNotes"
              value={formData.administratorOnlyNotes}
              onChange={(e) => {
                this.handleChangeWithFieldName(
                  "administratorOnlyNotes",
                  e.currentTarget.value
                );
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="notesForCrew">Crew notes</label>
            <TextareaAutosize
              maxRows={10}
              id="notesForCrew"
              className="form-control"
              name="notesForCrew"
              value={formData.notesForCrew}
              onChange={(e) => {
                this.handleChangeWithFieldName(
                  "notesForCrew",
                  e.currentTarget.value
                );
              }}
            />
          </div>

          {customerAdditionalLocationId ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="inactive"
                  name="inactive"
                  checked={formData.inactive}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({
                      formData: {
                        ...formData,
                        inactive: value,
                      },
                    });
                  }}
                />
                <label className="custom-control-label" htmlFor="inactive">
                  Inactive
                </label>
              </div>
            </div>
          ) : null}
        </FormContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  customerAdditionalLocationId: state.forms.customerAdditionalLocation
    .parameters
    ? (state.forms.customerAdditionalLocation.parameters
        .customerAdditionalLocationId as string)
    : null,
  defaultStreetAndNumber: state.forms.customerAdditionalLocation.parameters
    ? (state.forms.customerAdditionalLocation.parameters
        .defaultStreetAndNumber as string | undefined)
    : null,
  defaultName: state.forms.customerAdditionalLocation.parameters
    ? (state.forms.customerAdditionalLocation.parameters.defaultName as
        | string
        | undefined)
    : null,
  customerId: state.forms.customerAdditionalLocation.parameters
    ? (state.forms.customerAdditionalLocation.parameters.customerId as string)
    : null,
  showForm: state.forms.customerAdditionalLocation.showForm,
  errorMessage: state.forms.customerAdditionalLocation.errorMessage,
  saving: state.forms.customerAdditionalLocation.saving,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.customerAdditionalLocation.startSaving,
  cancel: actionCreators.forms.customerAdditionalLocation.cancelForm,
  setErrorMessage:
    actionCreators.forms.customerAdditionalLocation.setErrorMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAdditionalLocationForm);
