import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { actionCreators } from "../../../../modules/actionCreators";
import { getDaysToLoad } from "../../../../services/dayScheduleLoader";
import jobFinder from "../../../../services/jobFinder";
import { RouteComponentProps } from "react-router";
import dateService from "../../../../services/dateService";
import addressFormatter from "../../../../services/addressFormatter";
import { getNameForJob } from "../../../../services/jobService";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { getCategories } from "../../../../services/crewCategoryService";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { JobType } from "../../../../models/IJob";
import timeService from "../../../../services/timeService";
import { useSetPrintPageStyles } from "../../../../hooks/useSetPrintPageStyles";
import customerFinder from "../../../../services/customerFinder";
import { isStringSet } from "../../../../services/stringService";
import { useUserSettings } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { CrewScheduleType } from "../../../../slices/schedule/enums/crewScheduleType";
import JobHelper from "../../../../services/jobHelper";
import { IDaySchedule } from "../../../../models/IDaySchedule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

interface IRouteParams {
  crewId: string;
  date: string;
}

const SchedulePrint: React.FunctionComponent<
  RouteComponentProps<IRouteParams>
> = ({ history, match }) => {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const crewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const dispatch = useDispatch();
  const { getUserSettings } = useUserSettings();
  const [showCustomerPhoneNumber, setShowCustomerPhoneNumber] = useState(
    getUserSettings(UserSettingsType.printScheduleShowCustomerPhoneNumber) ??
      false
  );

  useSetPrintPageStyles("letter portrait", "0 .25in .25in .25in");

  const daySchedule: IDaySchedule | undefined = daySchedules.find(
    (ds) =>
      ds.crewId === match.params.crewId &&
      ds.date === match.params.date &&
      !ds.initialLoadRunning
  );
  const crew = crews.find((c) => c.id === match.params.crewId);
  if (!crew) {
    throw new Error(`could not find crew ${match.params.crewId}`);
  }

  const isTimeBasedCrew = crew.scheduleType === CrewScheduleType.time;

  const filteredJobInstances = useMemo(
    () =>
      !!daySchedule
        ? crew.scheduleType === CrewScheduleType.time
          ? JobHelper.getCrewJobsOrderedByTime(
              daySchedule.jobInstances,
              jobs,
              oneTimeJobs,
              customers,
              customerAdditionalLocations
            ).filter((ji) => !ji.skipped)
          : daySchedule.jobInstances.filter((ji) => !ji.skipped)
        : null,
    [
      crew,
      daySchedule,
      jobs,
      oneTimeJobs,
      customers,
      customerAdditionalLocations,
    ]
  );

  useEffect(() => {
    const scheduleDate = getDateForSchedule(match.params);
    const missingDates = getDaysToLoad({
      loadedDaySchedules: daySchedules,
      crewIds: [match.params.crewId],
      dates: [scheduleDate],
    });

    if (missingDates.length > 0) {
      dispatch(actionCreators.loadDaySchedulesStarting(missingDates, [], null));
    }
  }, [daySchedules, match.params, dispatch]);

  const { setUserSettings } = useUserSettings();

  return !!daySchedule && filteredJobInstances ? (
    <div className="container-fluid">
      <div className="print-schedule">
        <div className="d-print-none d-flex justify-content-between flex-wrap">
          <h3>
            <a
              href="/back"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Back To Previous Page
            </a>
          </h3>
          <div className="d-flex">
            <div className="mr-2">
              <h5>Display</h5>
              <div
                className="custom-control custom-checkbox"
                style={{ display: "inline-block" }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"showCustomerPhoneNumber"}
                  data-testid="showCustomerPhoneNumber"
                  checked={showCustomerPhoneNumber}
                  onChange={(e) => {
                    const newValue = e.currentTarget.checked;
                    setShowCustomerPhoneNumber(newValue);
                    setUserSettings(
                      UserSettingsType.printScheduleShowCustomerPhoneNumber,
                      newValue
                    );
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="showCustomerPhoneNumber"
                >
                  Customer phone number
                </label>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link text-dark"
                onClick={() => window.print()}
              >
                <FontAwesomeIcon icon={faPrint} size="3x" title="Print" />
              </button>
            </div>
          </div>
        </div>
        <h1 className="text-center">
          {crew.name} - {dateService.formatDateForDisplay(match.params.date)}
        </h1>
        {filteredJobInstances.map((jobInstance, index) => {
          const job = jobFinder.getJobForDayScheduleV2(
            jobs,
            oneTimeJobs,
            jobInstance
          );

          if (job === null) {
            return null;
          }

          const customer = customerFinder.getCustomerByJob(job, customers);

          const addressForJob = addressFormatter.getAddressForJob(
            job,
            customers,
            customerAdditionalLocations
          );

          return (
            <div
              key={jobInstance.id}
              className="mb-3"
              style={{ breakInside: "avoid" }}
            >
              <div className="row bg-secondary font-weight-bold">
                {isTimeBasedCrew ? (
                  <div className="col-3" data-testid="scheduledTime">
                    Scheduled time
                  </div>
                ) : (
                  <div className="col-1">Order</div>
                )}
                <div className="col-2">Name</div>
                <div className="col-2">Address</div>
                <div className="col-3">Service type</div>
                {!isTimeBasedCrew /* Hiding for time crews.  Don't have the space and unsure of value */ ? (
                  <>
                    <div className="col-1">Start</div>
                    <div className="col-1">End</div>
                    <div className="col-2">Crew size</div>
                  </>
                ) : null}
              </div>
              <div className="row bg-secondary">
                {isTimeBasedCrew ? (
                  <div
                    className="col-3 text-nowrap"
                    data-testid="scheduledTimeContainer"
                  >
                    {isStringSet(jobInstance.startTime) &&
                    isStringSet(jobInstance.endTime)
                      ? dateService.formatTimeForDisplay(
                          jobInstance.startTime
                        ) +
                        " - " +
                        dateService.formatTimeForDisplay(jobInstance.endTime)
                      : null}
                  </div>
                ) : (
                  <div className="col-1" data-testid="order">
                    {index + 1}
                  </div>
                )}
                <div className="col-2" data-testid="name">
                  <div>
                    {getNameForJob({
                      job,
                      customers,
                      customerAdditionalLocations,
                      fallbackToAddressIfAdditionalLocationNameNotSet: false,
                    })}
                  </div>
                  {showCustomerPhoneNumber ? (
                    <div data-testid="phoneNumberContainer">
                      {isStringSet(customer.phoneNumber) ? (
                        <div>
                          <small data-testid="customerPhoneNumber">
                            {customer.phoneNumber}
                          </small>
                        </div>
                      ) : null}

                      {isStringSet(customer.alternativePhoneNumber) ? (
                        <div>
                          <small data-testid="customerAlternativePhoneNumber">
                            {customer.alternativePhoneNumber}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="col-2" data-testid="address">
                  {addressFormatter.formatAddressForJob(
                    job,
                    customers,
                    customerAdditionalLocations
                  )}

                  {!addressFormatter.formatAddressForJob(
                    job,
                    customers,
                    customerAdditionalLocations
                  ) &&
                  addressForJob.latitude &&
                  addressForJob.longitude ? (
                    <>
                      Latitude: {addressForJob.latitude} Longitude:{" "}
                      {addressForJob.longitude}
                    </>
                  ) : null}
                </div>
                <div
                  className="col-3"
                  style={{ overflowWrap: "break-word" }}
                  data-testid="serviceTypes"
                >
                  {getSortedItemsV2(
                    getCategories(job.categories, crewCategories),
                    ["name"]
                  )
                    .map((c) => c.name)
                    .join(", ")}
                </div>

                {!isTimeBasedCrew /* Hiding for time crews.  Don't have the space and unsure of value */ ? (
                  <>
                    <div className="col-1">
                      {jobInstance.timeRanges.map((tr) => (
                        <div key={tr.id}>
                          {typeof tr.startTime === "string"
                            ? timeService.formatTimeForDisplay(tr.startTime)
                            : null}
                        </div>
                      ))}
                    </div>
                    <div className="col-1">
                      {jobInstance.timeRanges.map((tr) => (
                        <div key={tr.id}>
                          {typeof tr.endTime === "string"
                            ? timeService.formatTimeForDisplay(tr.endTime)
                            : null}
                        </div>
                      ))}
                    </div>
                    <div className="col-2">
                      {jobInstance.timeRanges.map((tr) => (
                        <div key={tr.id}>
                          {typeof tr.actualCrewMembers === "number" &&
                          typeof tr.startTime === "string"
                            ? tr.actualCrewMembers
                            : null}
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="row pt-2" style={{ minHeight: "75px" }}>
                <div className="col-1 font-weight-bold">Admin notes:</div>
                <div className="col-5">
                  <div
                    className="border rounded p-1"
                    style={{ height: "100%" }}
                    data-testid="adminNotes"
                  >
                    {isStringSet(customer?.notesForCrew ?? null) ? (
                      <div data-testid="customerNotesForCrew">
                        {customer.notesForCrew}
                      </div>
                    ) : null}

                    {job.type === JobType.maintenanceJob ? (
                      <div>{job.notes}</div>
                    ) : null}
                    <div>{jobInstance?.jobNotes}</div>
                  </div>
                </div>
                <div className="col-1 font-weight-bold">Crew notes:</div>
                <div className="col-5">
                  <div
                    className="border rounded p-1"
                    style={{ height: "100%" }}
                    data-testid="crewNotes"
                  >
                    {jobInstance?.notesFromCrew}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <span>Loading...</span>
  );
};

function getDateForSchedule(currentParams: IRouteParams) {
  // TODO: Validate that date is valid since parsing below (also validate other date params)
  let date = parse(currentParams.date);
  return format(date, "YYYY-MM-DD");
}

export default SchedulePrint;
