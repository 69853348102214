import React, { useEffect } from "react";

const PrivacyPolicyPage: React.FunctionComponent<{}> = () => {
  useEffect(() => {
    window.location.href = "https://www.youraspire.com/privacy-statement";
  }, []);

  return null;
};

export default PrivacyPolicyPage;
