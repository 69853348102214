import React, { CSSProperties } from "react";
import { connect, useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import { IAction } from "../../../../modules/actionTypeDefinitions";
import {
  DragDropContext,
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import ScheduleDay from "./day/index";
import Spinner from "../Spinner";
import { IRootState } from "../../../../store";
import { ICrew } from "../../../../models/ICrew";
import { IDaySchedule } from "../../../../models/IDaySchedule";
import constants from "../../../../constants";
import { IUnscheduledMaintenanceJob } from "../../../../models/IUnscheduledMaintenanceJob";
import {
  isClearAllCardsPrevented,
  preventClearingAllCards,
} from "../../../../services/selectedCardService";
import ErrorLoadingSchedule from "./ErrorLoadingSchedule";
import SelectedJobActions from "./SelectedJobActions";
import { getOrderedJobInstanceIdsForDrop } from "../../../../services/scheduleService";
import { getDestinationProperties } from "../../../../services/dropJobService";
import { selectJobInstancesInRange } from "../../../../services/selectJobInstancesInRange";
import { CrewScheduleType } from "../../../../slices/schedule/enums/crewScheduleType";
import { ScheduleHeader } from "../../../../slices/schedule/components/ScheduleHeader";
import UnscheduledJobs from "./UnscheduledJobs";
import { ScheduleSectionList } from "../../../../slices/schedule/components/ScheduleSectionList";
import { getSchedulesForColumns } from "../../../../slices/schedule/services/getSchedulesForColumns";
import { getColumnsFromRow } from "../../../../slices/schedule/services/getColumnsFromRow";
import JobsMap from "../JobsMap";
import { IScheduleRow } from "../../../../slices/schedule/components/types/IScheduleRow";
import { IJobInstance } from "../../../../models/IJobInstance";
import { isMobile } from "react-device-detect";
import { getUniqueItems } from "../../../../services/arrayService";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { TenantSubscriptionStatus } from "../../../../models/IInitialLoad";

export interface IScheduleColumnHeader {
  key: string;
  columnHeader: React.ReactNode;
  highlightHeader?: boolean;
}

interface IProps extends IOwnProps {
  dropJob: (
    jobInstanceIds: Array<string>,
    destinationDayScheduleId: string | null,
    destinationFlexibleJob: boolean,
    destinationFlexibleJobWeek: Date | null,
    destinationPrecedingJobInstanceId: string | null,
    destinationDayScheduleDate: Date | null,
    destinationDayCrewId: string | null,
    daySchedules: Array<IDaySchedule>
  ) => any;
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  customContainerClassName?: string;
  deleteJobInstanceInProgress: boolean;
  jobInstanceDragStart: (jobInstanceId: string) => any;
  jobInstanceDragEnd: () => any;
  selectedJobInstanceIds: Array<string>;
  toggleJobInstanceSelected(
    jobInstanceIds: Array<string>,
    selected?: boolean | undefined,
    singleJobInstanceToggle?: boolean | undefined
  ): IAction;
  loadDaySchedulesErrorClear(): IAction;
  lastSingleSelectedJobInstance: string | null;
  crews: Array<ICrew>;
}

export interface IOwnProps {
  nextSchedule: () => void;
  previousSchedule: () => void;
  pageHeaderText: JSX.Element;
  mode: "week" | "day";
  isLoading: boolean;
  loadingFlexibleJobs: boolean;
  weekForUnscheduledJobs: Date;
  changeScheduleDate(newDate: Date): any;
  rows: Array<IScheduleRow>;
  columnHeaders: Array<IScheduleColumnHeader>;
  onRowReorder?: (sourceIndex: number, destinationIndex: number) => void;
  onRowExpanded: (rowIndex: number) => void;
}

interface IState {}

class Schedule extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleClearAllJobCards = this.handleClearAllJobCards.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClearAllJobCards);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClearAllJobCards);
  }

  handleClearAllJobCards(event: MouseEvent) {
    // Clear selected job cards if user clicked on an item that wouldn't toggle card selection
    // or is the action bar
    if (
      this.props.selectedJobInstanceIds.length > 0 &&
      event.target &&
      !isClearAllCardsPrevented(event)
    ) {
      this.props.toggleJobInstanceSelected(
        this.props.selectedJobInstanceIds,
        false
      );
    }
  }

  public render() {
    let {
      pageHeaderText,
      mode,
      dropJob,
      nextSchedule,
      previousSchedule,
      isLoading,
      daySchedules,
      weeksUnscheduledMaintenanceJobs,
      weekForUnscheduledJobs,
      customContainerClassName,
      deleteJobInstanceInProgress,
      loadingFlexibleJobs,
      jobInstanceDragStart,
      jobInstanceDragEnd,
      selectedJobInstanceIds,
      changeScheduleDate,
      rows,
      columnHeaders,
      onRowReorder,
    } = this.props;
    let isMapVisible = rows.some((r) => r.columns.some((c) => c?.isMapOpen));
    if (isMapVisible) {
      const mapColumnIndex = rows.flatMap((r) =>
        r.columns
          .map((column, columnIndex) => ({
            column,
            columnIndex,
          }))
          .filter((c) => {
            return c.column?.isMapOpen;
          })
          .map((c) => c.columnIndex)
      );

      rows = rows
        .filter((r) => r.columns.some((c) => c?.isMapOpen))
        .map((r) => ({
          ...r,
          columns: r.columns.filter((c) => c?.isMapOpen),
        }));

      columnHeaders = columnHeaders.filter((_, columnHeaderIndex) =>
        mapColumnIndex.includes(columnHeaderIndex)
      );
    }

    const columnLoading = rows.some((r) => r.columns.some((c) => c?.loading));
    const errorLoading = rows.some((r) =>
      r.columns.some((c) => c?.errorLoading)
    );

    if (errorLoading) {
      return (
        <ErrorLoadingSchedule
          onRefreshSchedule={this.props.loadDaySchedulesErrorClear}
        />
      );
    }

    const rowColumns = getColumnsFromRow(rows[0]);

    return (
      <React.Fragment>
        <div>
          {!isLoading && !columnLoading ? (
            <DragDropContext
              onDragStart={(initial) => {
                jobInstanceDragStart(initial.draggableId);
              }}
              onDragEnd={(result) => {
                jobInstanceDragEnd();

                if (result.type === constants.droppableTypeJob) {
                  const jobInstanceId = result.draggableId;
                  if (!result.destination) {
                    return;
                  }

                  const hasPositionChanged =
                    result.source.droppableId !==
                      result.destination.droppableId ||
                    result.source.index !== result.destination.index;

                  let hasMovedInSameTimeBasedCrew = false;
                  if (
                    result.source.droppableId === result.destination.droppableId
                  ) {
                    const daySchedule = this.props.daySchedules.find(
                      (ds) => ds.id === result.source.droppableId
                    );
                    const crew = this.props.crews.find(
                      (c) => c.id === daySchedule?.crewId
                    );
                    if (crew?.scheduleType === CrewScheduleType.time) {
                      hasMovedInSameTimeBasedCrew = true;
                    }
                  }

                  if (!hasPositionChanged || hasMovedInSameTimeBasedCrew) {
                    return;
                  }

                  const {
                    droppableId: destinationDroppableId,
                    index: destinationIndex,
                  } = result.destination;

                  let destinationDayScheduleId: string | null = null;
                  let destinationPrecedingJobInstanceId: string | null = null;
                  let destinationFlexibleJob: boolean = false;
                  let destinationFlexibleJobWeek: Date | null = null;
                  let destinationDayScheduleDate: Date | null = null;
                  let destinationDayCrewId: string | null = null;

                  if (
                    destinationDroppableId !== constants.flexibleJobDroppableId
                  ) {
                    destinationDayScheduleId = destinationDroppableId;

                    const destinationProperties = getDestinationProperties(
                      destinationIndex,
                      daySchedules,
                      destinationDayScheduleId,
                      result.source.droppableId,
                      result.source.index
                    );
                    destinationPrecedingJobInstanceId =
                      destinationProperties.destinationPrecedingJobInstanceId;
                    destinationDayScheduleDate =
                      destinationProperties.destinationDayScheduleDate;
                    destinationDayCrewId =
                      destinationProperties.destinationDayCrewId;
                  } else {
                    destinationFlexibleJob = true;
                    destinationFlexibleJobWeek = weekForUnscheduledJobs;
                  }

                  const jobInstanceIds = getUniqueItems([
                    ...selectedJobInstanceIds,
                    jobInstanceId,
                  ]);

                  dropJob(
                    getOrderedJobInstanceIdsForDrop(
                      jobInstanceIds,
                      daySchedules,
                      weeksUnscheduledMaintenanceJobs
                    ),
                    destinationDayScheduleId,
                    destinationFlexibleJob,
                    destinationFlexibleJobWeek,
                    destinationPrecedingJobInstanceId,
                    destinationDayScheduleDate,
                    destinationDayCrewId,
                    daySchedules
                  );
                } else if (result.type === constants.droppableTypeRow) {
                  if (onRowReorder && result.destination) {
                    onRowReorder(result.source.index, result.destination.index);
                  }
                }
              }}
            >
              <ScheduleHeader
                pageHeaderText={pageHeaderText}
                previousSchedule={previousSchedule}
                nextSchedule={nextSchedule}
                unscheduledJobsElement={
                  <UnscheduledJobs
                    weekForUnscheduledJobs={weekForUnscheduledJobs}
                    loadingFlexibleJobs={loadingFlexibleJobs}
                    jobInstanceBeingDraggedFromTimeCalendar={null}
                    renderJobCardWithWrapper={({
                      element,
                      jobInstance,
                      jobIndex,
                      selected,
                      selectedJobInstanceIds,
                      crew,
                      draggableStyleOverride,
                      isAnyCardDragged,
                      isDraggedCard,
                      onJobCardMultiSelected,
                    }) => (
                      <DraggableJobCard
                        jobInstance={jobInstance}
                        jobIndex={jobIndex}
                        selected={selected}
                        selectedJobInstanceIds={selectedJobInstanceIds}
                        crew={crew}
                        draggableStyleOverride={draggableStyleOverride}
                        isAnyCardDragged={isAnyCardDragged}
                        isDraggedCard={isDraggedCard}
                        onJobCardMultiSelected={onJobCardMultiSelected}
                      >
                        {element}
                      </DraggableJobCard>
                    )}
                  />
                }
                changeScheduleDate={changeScheduleDate}
                baseDate={rows?.[0]?.columns[0]?.date as string}
              />

              <ScheduleSectionList
                rows={rows}
                columnHeaders={columnHeaders}
                onRowExpanded={(rowIndex) => this.props.onRowExpanded(rowIndex)}
                customContainerClassName={customContainerClassName}
                isCalendarHeader={false}
                renderRow={(scheduleRow, rowIndex) => {
                  const columns = getColumnsFromRow(scheduleRow);
                  return (
                    <>
                      <div
                        className={
                          "schedule-week-container " +
                          (customContainerClassName || "")
                        }
                        style={{ marginBottom: "0" }}
                      >
                        {columns.map((col, index) => (
                          <React.Fragment key={index}>
                            <ScheduleDay
                              dayScheduleId={col.dayScheduleId}
                              crew={col.crew}
                              date={col.date}
                              additionalLink={col.additionalLink}
                              mode={mode}
                              isMapVisible={isMapVisible}
                              scheduleDayIndex={index}
                              mapLinkProps={col.mapLinkProps}
                              hideHeaderElement={true}
                              columnHeader={<></>}
                              isLastColumn={index === columns.length - 1}
                              onJobCardMultiSelected={(jobInstanceId) => {
                                this.props.toggleJobInstanceSelected(
                                  selectJobInstancesInRange({
                                    daySchedules: getSchedulesForColumns(
                                      scheduleRow,
                                      daySchedules
                                    ),
                                    newSelectedJobInstance: jobInstanceId,
                                    lastSingleSelectedJobInstance:
                                      this.props.lastSingleSelectedJobInstance,
                                  }),
                                  true,
                                  false
                                );
                              }}
                              renderJobCardWithWrapper={({
                                element,
                                jobInstance,
                                jobIndex,
                                selected,
                                selectedJobInstanceIds,
                                crew,
                                draggableStyleOverride,
                                isAnyCardDragged,
                                isDraggedCard,
                                onJobCardMultiSelected,
                              }) => (
                                <DraggableJobCard
                                  jobInstance={jobInstance}
                                  jobIndex={jobIndex}
                                  selected={selected}
                                  selectedJobInstanceIds={
                                    selectedJobInstanceIds
                                  }
                                  crew={crew}
                                  draggableStyleOverride={
                                    draggableStyleOverride
                                  }
                                  isAnyCardDragged={isAnyCardDragged}
                                  isDraggedCard={isDraggedCard}
                                  onJobCardMultiSelected={
                                    onJobCardMultiSelected
                                  }
                                >
                                  {element}
                                </DraggableJobCard>
                              )}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  );
                }}
                renderRowlessSchedule={() => (
                  <div
                    className={
                      "schedule-week-container " +
                      (customContainerClassName || "")
                    }
                  >
                    {rowColumns.map((col, index) => (
                      <React.Fragment key={index}>
                        <ScheduleDay
                          dayScheduleId={col.dayScheduleId}
                          key={index}
                          crew={col.crew}
                          date={col.date}
                          additionalLink={col.additionalLink}
                          mode={mode}
                          isMapVisible={isMapVisible}
                          scheduleDayIndex={index}
                          isLastColumn={index === rowColumns.length - 1}
                          mapLinkProps={col.mapLinkProps}
                          columnHeader={columnHeaders[index].columnHeader}
                          highlightHeader={columnHeaders[index].highlightHeader}
                          onJobCardMultiSelected={(jobInstanceId) => {
                            this.props.toggleJobInstanceSelected(
                              selectJobInstancesInRange({
                                daySchedules: getSchedulesForColumns(
                                  rows[0],
                                  daySchedules
                                ),
                                newSelectedJobInstance: jobInstanceId,
                                lastSingleSelectedJobInstance:
                                  this.props.lastSingleSelectedJobInstance,
                              }),
                              true
                            );
                          }}
                          renderJobCardWithWrapper={({
                            element,
                            jobInstance,
                            jobIndex,
                            selected,
                            selectedJobInstanceIds,
                            crew,
                            draggableStyleOverride,
                            isAnyCardDragged,
                            isDraggedCard,
                            onJobCardMultiSelected,
                          }) => (
                            <DraggableJobCard
                              jobInstance={jobInstance}
                              jobIndex={jobIndex}
                              selected={selected}
                              selectedJobInstanceIds={selectedJobInstanceIds}
                              crew={crew}
                              draggableStyleOverride={draggableStyleOverride}
                              isAnyCardDragged={isAnyCardDragged}
                              isDraggedCard={isDraggedCard}
                              onJobCardMultiSelected={onJobCardMultiSelected}
                            >
                              {element}
                            </DraggableJobCard>
                          )}
                        />
                      </React.Fragment>
                    ))}
                    {isMapVisible && rows[0].columns[0] ? (
                      <div style={{ width: "100%" }}>
                        <JobsMap
                          dayScheduleId={rows[0].columns[0].dayScheduleId}
                          closeMapUrl={rows[0].columns[0].mapLinkProps.url}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              />

              {deleteJobInstanceInProgress ? <Spinner /> : null}
            </DragDropContext>
          ) : (
            <Spinner />
          )}
          {selectedJobInstanceIds.length > 0 ? <SelectedJobActions /> : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  nextSchedule: ownProps.nextSchedule,
  previousSchedule: ownProps.previousSchedule,
  columnWithMapMode: state.scheduleUi.columnWithMapMode,
  pageHeaderText: ownProps.pageHeaderText,
  mode: ownProps.mode,
  isLoading: ownProps.isLoading,
  loadingFlexibleJobs: ownProps.loadingFlexibleJobs,
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
  weekForUnscheduledJobs: ownProps.weekForUnscheduledJobs,
  deleteJobInstanceInProgress: state.scheduleUi.deleteJobInstanceInProgress,
  selectedJobInstanceIds: state.scheduleUi.selectedJobInstanceIds,
  changeScheduleDate: ownProps.changeScheduleDate,
  rows: ownProps.rows,
  columnHeaders: ownProps.columnHeaders,
  onRowReorder: ownProps.onRowReorder,
  onRowExpanded: ownProps.onRowExpanded,
  lastSingleSelectedJobInstance: state.scheduleUi.lastSingleSelectedJobInstance,
  crews: state.crew.crews,
});

const mapDispatchToProps = {
  dropJob: actionCreators.dropJob,
  jobInstanceDragStart: actionCreators.jobInstanceDragStart,
  jobInstanceDragEnd: actionCreators.jobInstanceDragEnd,
  toggleJobInstanceSelected: actionCreators.jobInstanceToggleSelected,
  loadDaySchedulesErrorClear: actionCreators.loadDaySchedulesErrorClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);

function DraggableJobCard({
  jobInstance,
  jobIndex,
  children,
  selected,
  selectedJobInstanceIds,
  onJobCardMultiSelected,
  crew,
  draggableStyleOverride,
  isAnyCardDragged,
  isDraggedCard,
}: {
  jobInstance: IJobInstance;
  jobIndex: number;
  children: JSX.Element;
  selected: boolean;
  selectedJobInstanceIds: Array<string>;
  onJobCardMultiSelected?: (jobInstanceId: string) => void;
  crew: ICrew | null;
  draggableStyleOverride?: React.CSSProperties;
  isAnyCardDragged: boolean;
  isDraggedCard: boolean;
}): JSX.Element {
  const dispatch = useDispatch();

  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );

  function getStyle(
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
  ) {
    const defaultMargins = "0 0 8px 0";

    if (!snapshot.isDragging && crew?.scheduleType === CrewScheduleType.time) {
      return {
        margin: defaultMargins,
      };
    }

    const newStyles: CSSProperties = {
      margin: defaultMargins,
      ...draggableStyleOverride,
      ...style,
    };

    if (selected && isAnyCardDragged && !isDraggedCard) {
      newStyles.opacity = 0.3;
    }

    if (snapshot.isDragging) {
      newStyles.filter = "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.5))";
    }

    if (snapshot.isDragging && snapshot.isDropAnimating) {
      newStyles.transitionDuration = "0.001s";
    }

    if (snapshot.isDropAnimating) {
      newStyles.transitionDuration = `0.001s`;
    }

    return newStyles;
  }

  return (
    <Draggable
      draggableId={jobInstance.id}
      index={jobIndex}
      isDragDisabled={
        (isMobile && !selected) ||
        tenantSubscriptionStatus === TenantSubscriptionStatus.Canceled
      }
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getStyle(provided.draggableProps.style, snapshot),
            userSelect: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            preventClearingAllCards(e);
            dispatch(
              actionCreators.jobInstanceToggleSelected(
                [jobInstance.id],
                undefined,
                true
              )
            );

            const cardSelected = !selectedJobInstanceIds.includes(
              jobInstance.id
            );
            if (e.shiftKey && onJobCardMultiSelected && cardSelected) {
              onJobCardMultiSelected(jobInstance.id);
            }
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}
